import {
  Grid,
  Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  Divider,
  TextField,
  FormGroup,
  Checkbox,
} from "@mui/material";
import "./style.css";
import Buttons from "../../common/button";
import { Input } from "../../common/input";

export const EditRole = () => {
  return (
    <>
      <Grid container marginBottom={3.6}>
        <Grid item xs={12} sm={12} md={12} xl={12} sx={{ mb: 3.6 }}>
          <span className="subscriberColumn1">Roles / Permission</span>
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} lg={12}>
              <Box className={"role-descript"}>
                <Grid container flexDirection={"column"}>
                  <Grid
                    item
                    xl={4.9}
                    lg={4}
                    md={6}
                    xs={12}
                    sm={10}
                    sx={{ mb: 2 }}
                  >
                    <Input
                      label="Role"
                      placeholder="Platform Admin"
                      required={true}
                      autoFocus={true}
                    />
                  </Grid>
                  <Grid item xl={4.9} lg={4} md={6} xs={1} sm={10}>
                    <FormControl
                      variant="standard"
                      sx={{ width: "100%", marginTop: "20px" }}
                    >
                      <FormLabel sx={{ mb: 0.1 }} className="labelClass">
                        Description<span className="asterick-desc">*</span>
                      </FormLabel>
                      <TextField
                        multiline
                        className="messageTextField height-desc"
                        placeholder="Platform Admin"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems={"center"}
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item xs={6} sm={6} md={5} xl={4} lg={4}>
                    <div className="row-name">Premium Description</div>
                  </Grid>
                  <Grid item xs={6} sm={6} md={5} xl={6} lg={6}>
                    <FormGroup>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6.5} md={4} lg={3} xl={2}>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="View"
                          />
                        </Grid>
                        <Grid item xs={12} sm={3} md={6} lg={6} xl={6}>
                          <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Edit"
                          />
                        </Grid>
                      </Grid>
                    </FormGroup>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 1 }} />

                <Grid
                  container
                  alignItems={"center"}
                  spacing={2}
                  sx={{ mt: 4 }}
                >
                  <Grid item xs={0} lg={8} md={4} xl={8}></Grid>
                  <Grid item xs={12} lg={4} md={8} xl={4}>
                    <Grid container spacing={4} justifyContent={"end"}>
                      <Grid item xs={4} lg={4} md={4} sm={4}>
                        <Buttons
                          buttonType="submit"
                          variant="contained"
                          text="CANCEL"
                          type="secondary"
                        />
                      </Grid>
                      <Grid item xs={4} lg={4} md={4} sm={4}>
                        <Buttons
                          buttonType="submit"
                          variant="contained"
                          text="SAVE AS"
                          type="primary"
                        />
                      </Grid>
                      <Grid item xs={4} lg={4} md={4} sm={4}>
                        <Buttons
                          buttonType="submit"
                          variant="contained"
                          text="SAVE"
                          type="primary"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
