import {
  Card,
  CardContent,
  Typography,
  Grid,
  useMediaQuery,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Messages } from "../../../../constants/messages";
import { setUserSteps } from "../../../../redux/features/userStep/userStepSlice";
import Buttons from "../../../common/button";
import { ConfirmModal } from "../../../common/confirmmodal";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import "./privacyPolicy.css";
import {
  useGetPrivacyQuery,
  useGetTermsOfUseMutation,
} from "../../../../redux/services/authApiSlice";
import { Terminal } from "@mui/icons-material";
import {
  useDeleteUserMutation,
  useUpdateUserMutation,
} from "../../../../redux/services/userApiSlice";
import { logOut } from "../../../../redux/features/auth/authSlice";
import { handleErrors } from "../../../../utils";
import Loader from "../../../common/loader/loader";
import { CustomToolTip } from "../../../common/tooltip";

export const PrivacyPolicy = (props: any) => {
  const {
    activeStep,
    completed,
    modal,
    termofUseModal = false,
    setTermofUseModal = false,
    mobile,
  } = props;
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showTooltips, setShowTooltips] = useState(true);
  const [height, setHeight] = useState();
  const [termofUse, setTermofUse] = useState(termofUseModal);
  const userId = useSelector((state: any) => state?.auth?.userId);

  const dispatch = useDispatch();

  //API Integration
  const {
    data,
    isSuccess,
    refetch,
    isError,
    fulfilledTimeStamp,
    error,
    isLoading,
  } = useGetPrivacyQuery({
    refetchOnMountOrArgChange: true,
  });
  const [getTermsOfUse, getTermsofUseInfo] = useGetTermsOfUseMutation();
  const [updateUser, updateUserInfo] = useUpdateUserMutation();
  const [deleteUser, deleteUserInfo] = useDeleteUserMutation();

  const handleClick = () => {
    setTermofUse(true);
    if (termofUse) {
      updateUser({ userId: userId, hasAcceptedTnC: true });
    }
  };

  useEffect(() => {
    if (termofUse) {
      getTermsOfUse({ refetchOnMountOrArgChange: true });
    }
  }, [termofUse]);
  useEffect(() => {
    if (updateUserInfo?.isSuccess) {
      var newCompleted = {};
      Object.assign(newCompleted, completed);
      Object.assign(newCompleted, { [activeStep]: true });
      dispatch(
        setUserSteps({
          activeSteps: Object.keys(newCompleted)?.length,
          completedSteps: newCompleted,
          planSelected: false,
        })
      );
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Your account has been created successfully",
        })
      );
    }
  }, [updateUserInfo?.isSuccess]);

  useEffect(() => {
    handleErrors(updateUserInfo, dispatch);
  }, [updateUserInfo?.isError]);

  // useEffect(() => {
  //   handleErrors({error,isError}, dispatch);
  // }, [isError]);

  const handleDisagree = () => {
    setConfirmModalOpen(true);
  };
  const onCancelcallBack = () => {
    setConfirmModalOpen(false);
  };
  const onConfirmCallBack = () => {
    setConfirmModalOpen(false);
    deleteUser(userId);
  };
  useEffect(() => {
    if (deleteUserInfo?.isSuccess) {
      dispatch(logOut());
      localStorage.clear();
      window.location.href = "/";
    }
  }, [deleteUserInfo?.isSuccess]);

  useEffect(() => {
    handleErrors(deleteUserInfo, dispatch);
  }, [deleteUserInfo?.isError]);

  const handleScroll = (e: any) => {
    let element = e.target;
    if (element) {
      if (
        element.scrollTop &&
        element.scrollHeight - element.scrollTop - element.clientHeight < 10
      ) {
        setDisabled(false);
        setShowTooltips(false);
      } else {
        setDisabled(true);
        setShowTooltips(true)
      }
    }
  };

  useEffect(() => {
    function Height() {
      const x: any = document.getElementById("appbar")?.offsetHeight;
      const height: any = document.getElementById("root")?.offsetHeight;
      const calculatedHeight: any = height - (x + 343);
      setHeight(calculatedHeight);
    }
    window.addEventListener("resize", Height);
    Height();
    return () => window.removeEventListener("resize", Height);
  }, []);

  const matches = useMediaQuery("(max-width:1300px)");

  return (
    <>
      <Loader
        show={
          isLoading ||
          getTermsofUseInfo?.isLoading ||
          deleteUserInfo?.isLoading ||
          updateUserInfo?.isLoading
        }
      />
      <Box className="mainBoxcContainer" marginBottom={modal ? "0" : "0"}>
        <Card
          className="privacyPolicyCard mainPrivacyPolicyCard"
          data-testid="main-card"
          sx={{
            ...(mobile
              ? { overflow: "scroll", height: "100vh" }
              : { marginTop: "51px" }),
            ...(modal && { boxShadow: "none" }),
          }}
        >
          <CardContent className={modal ? "padding-35px" : "padding-20px"}>
            {!modal && (
              <span className="privacyCardText">
                {termofUse ? "Terms Of Use" : "Privacy Policy"}
              </span>
            )}

            <div
              style={{ maxHeight: height }}
              dangerouslySetInnerHTML={
                termofUse
                  ? { __html: getTermsofUseInfo?.error?.data }
                  : { __html: error?.data }
              }
              className={
                modal ? "upperParagraph" : "policyConditions1 upperParagraph"
              }
              onScroll={handleScroll}
              data-testid="paragrph-test"
              id="termsConditions"
            ></div>
          </CardContent>
          {!modal && !mobile && (
            <Grid
              container
              spacing={2}
              className="conditionsButton"
              data-testid="disagree-btn"
            >
              <Grid item xs={12} sm={12} md={3} lg={3} xl={1.7}>
                <CustomToolTip title="Button will become active when you scroll to the bottom of the page" placement="top"  disableHoverListener={!showTooltips}>
                <Buttons
                  type="secondary"
                  variant="contained"
                  text="I DISAGREE"
                  disabled={disabled}
                  onClick={handleDisagree}
                ></Buttons>
                </CustomToolTip>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={2.6} id="activeBtn">
              <CustomToolTip title="Button will become active when you scroll to the bottom of the page" placement="top"  disableHoverListener={!showTooltips}>
                <Buttons
                  type="primary"
                  variant="contained"
                  disabled={disabled}
                  text={!termofUse ? "I ACKNOWLEDGE" : "I AGREE"}
                  onClick={handleClick}
                ></Buttons>
                </CustomToolTip>
              </Grid>
            </Grid>
          )}
        </Card>

        <div className="modelModal">
          <ConfirmModal
            className="confirmModal"
            key="disagree"
            open={confirmModalOpen}
            primaryText={Messages.CONFIRM}
            cancelButton="NO"
            confirmButton="YES"
            cancelCallback={onCancelcallBack}
            confirmCallback={onConfirmCallBack}
          />
        </div>
      </Box>
    </>
  );
};
