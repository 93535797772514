import { createSlice } from '@reduxjs/toolkit'

interface UserState {
  id: string,
  name: string,
  status:string,
}

const initialState = { id:  "", name: "",status:""} as UserState

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.id = action?.payload?.id;
      state.name = action?.payload?.name;
      state.status=action?.payload?.status;
    },
    clearUserState:(state)=> state=initialState
  },
})

export const { setUser,clearUserState } = userSlice.actions
export default userSlice.reducer
export const getUserInfo=(state:any)=>state.user;