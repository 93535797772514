import React, { useEffect, useState } from "react";
import { Modal } from "../../../common/modal/modal";
import { Checkbox, Grid, ListItemText, MenuItem } from "@mui/material";
import { RegExpressions } from "../../../../constants/regExp";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../../common/input";
import { CheckboxSelectInput } from "../../../common/selectinput";
import { CustomToolTip } from "../../../common/tooltip";
import { DataGrid, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { useGetDevicesMutation } from "../../../../redux/services/devicesApiSlice";
import {
  flattenedObjectToArray,
  handleErrors,
  handlePriceFormatting,
} from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import Buttons from "../../../common/button";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import "./customiseModal.css";
import { CustomePreviewModal } from "./customPreviewModal";
import { setBeneficiaryCount } from "../../../../redux/features/user/beneficiarySlice";
import { useCustomisationPlanMutation } from "../../../../redux/services/planApiSlice";
import Loader from "../../../common/loader/loader";

export const CustomiseModal = (props: any) => {
  const {
    customiseModalOpen: customizeModalOpen,
    setCustomiseModalOpen,
    beneficiaryTotal,
    id,
  } = props;
  const { handleSubmit, control, setValue, getValues, reset } = useForm({
    mode: "onChange" || "onSubmit",
    defaultValues: {
      beneficiaryCount: "",
      recurringAmount: "",
      fixedAmount: "",
      planDetails: "",
      devices: [],
      deviceTableData: [],
      beneficiaryTableCount: "",
    },
  });
  const { userInfo } = useSelector((state: any) => state?.auth);

  const { beneficiaryData, beneficiaryCount, isChange, selectedPlanId } =
    useSelector((state: any) => state?.beneficiary);

  const [tableData, setTableData] = useState<any>([]);
  const [benTableData, setBenTableData] = useState<any>([]);
  console.log(tableData, "dada");
  const [device, setDevice] = useState<any>([]);
  const [previewModal, setPreviewModal] = useState<any>(false);
  const [planBenCount, setPlanBenCount] = useState<any>();

  const dispatch = useDispatch();

  //   API INTEGRATION

  const [customPlan, customPlanData] = useCustomisationPlanMutation();
  console.log(customPlanData, "kkkkk");
  const unitAmount = customPlanData?.data?.result?.deviceCost;

  useEffect(() => {
    if (customizeModalOpen) {
      customPlan(id);
    }
  }, [customizeModalOpen]);

  // useEffect(() => {
  //   if (customPlanData?.isSuccess) {
  //     const data = customPlanData?.data?.result?.devices?.map(
  //       (x:any) => {
  //         value: x?.deviceTypeId,
  //       }
  //     );
  //     console.log(customPlanData?.data?.result, "plandata");
  //     setTableData(data);
  //   }
  // }, [customPlanData?.isSuccess]);

  useEffect(() => {
    if (customPlanData?.isSuccess) {
      const data = customPlanData?.data?.result?.devices?.map(
        (device: any, index: any) => {
          return {
            ...device,
            id: device?.deviceTypeId,
            deviceCostings: device?.DeviceCostings,
            igst: device?.igst,
          };
        }
      );
      const benData = [
        {
          id: customPlanData?.data?.result?.subscriptionPlanId,
          beneficiarySupported: "Beneficiaries included in your plan",
          beneficiaryCount: customPlanData?.data?.result?.beneficiaryCount,
          unitAmount: "-",
          currency: customPlanData?.data?.result?.currency,
          recurring: customPlanData?.data?.result?.recurringAmount,
          country: customPlanData?.data?.result?.billingCountry,
        },
        {
          id: "2",
          beneficiarySupported: "Select Additional beneficiaries",
          beneficiaryCount: 0,
          unitAmount: customPlanData?.data?.result?.unitAmount || "NA",
          currency: customPlanData?.data?.result?.currency,
          recurring: 0,
          country: customPlanData?.data?.result?.billingCountry,
        },
      ];
      setValue(
        "beneficiaryTableCount",
        customPlanData?.data?.result?.beneficiaryCount
      );
      setPlanBenCount(customPlanData?.data?.result?.beneficiaryCount);
      console.log(customPlanData, "plandata");
      setTableData(data);
      setBenTableData(benData);

      setDevice(data);
    }
  }, [customPlanData?.isSuccess]);

  useEffect(() => {
    handleErrors(customPlanData, dispatch);
  }, [customPlanData?.isError]);

  const handleChange = (e: any, rowData: any) => {
    const value: any = e?.target?.value;
    if (value >= 0) {
      const data = {
        ...benTableData[1],
        beneficiaryCount: value,
        recurring: rowData?.unitAmount * value,
      };
      console.log(data, "bendata");
      setBenTableData([benTableData[0], data]);
      // const parsedValue = parseInt(value);
      // if (!isNaN(parsedValue)) {
      //   setCount(parsedValue);
      // }
      // setRecuAmount(parsedValue * 10);
    }
    // else {
    //   const data = {
    //     ...benTableData[1],
    //     beneficiaryCount: value,
    //     recurring: rowData?.unitAmount * value,
    //   };
    //   setBenTableData([benTableData[0], data]);
    // }
  };

  const handleModalClose = () => {
    setCustomiseModalOpen(false);
  };

  const columns2: GridColDef[] = [
    {
      field: "beneficiarySupported",
      headerName: "",
      minWidth: 80,
      headerAlign: "center",
      align: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 2,
      // renderCell: (params) => {
      //   return "Beneficiaries supported";
      // },
    },
    // {
    //   field:"beneficary",
    //   headerName:"",
    //   minWidth:100,
    //   headerAlign:"left",
    //   sortable:false,
    //   cellClassName:"cell-padding",
    //   flex: 1.6
    // },
    {
      field: "beneficiaryCount",
      renderHeader: (params: GridColumnHeaderParams) => <b>Count</b>,
      minWidth: 50,
      headerAlign: "center",
      align: "center",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <Controller
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <div className="customizeInput">
                  {params?.row?.beneficiarySupported ===
                  "Select Additional beneficiaries" ? (
                    <div
                      className="minusSign"
                      onClick={() =>
                        handleChange(
                          {
                            target: {
                              value:
                                parseInt(benTableData[1]?.beneficiaryCount) - 1,
                            },
                          },
                          params?.row
                        )
                      }
                    >
                      -
                    </div>
                  ) : null}
                  <input
                    disabled={params?.row?.unitAmount === "-" ? true : false}
                    className="inputBox"
                    type="number"
                    min={1}
                    max={99}
                    required
                    value={params?.row?.beneficiaryCount}
                    onChange={(e: any) => {
                      field?.onChange(e);
                      handleChange(e, params?.row);
                    }}
                  />
                  {params?.row?.beneficiarySupported ===
                  "Select Additional beneficiaries" ? (
                    <div
                      className="addSign"
                      onClick={() =>
                        handleChange(
                          {
                            target: {
                              value:
                                parseInt(benTableData[1]?.beneficiaryCount) + 1,
                            },
                          },
                          params?.row
                        )
                      }
                    >
                      +
                    </div>
                  ) : null}
                </div>
              )}
              name="beneficiaryTableCount"
              rules={{
                pattern: {
                  value: RegExpressions?.twoDigitRegex,
                  message: "not allowed",
                },
              }}
              control={control}
            />
          </div>
        );
      },
    },
    {
      field: "unitAmount",
      headerName: "Unit Price",
      renderHeader: (params: GridColumnHeaderParams) => (
        <b>
          Unit Price
          <br />
          <center>({userInfo?.country === "IN" ? "INR" : "USD"})</center>
        </b>
      ),
      minWidth: 50,
      headerAlign: "right",
      align: "right",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        return params?.row?.unitAmount === "-"
          ? "-"
          : handlePriceFormatting(
              params?.row?.unitAmount,
              benTableData[0]?.currency
            );
      },
    },
    {
      field: "recurring",
      headerName: "Sub Total",
      renderHeader: (params: GridColumnHeaderParams) => (
        <b>
          Sub Total
          <br />
          <center>({userInfo?.country === "IN" ? "INR" : "USD"})</center>
        </b>
      ),
      minWidth: 80,
      headerAlign: "right",
      align: "right",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        return handlePriceFormatting(
          params?.row?.recurring,
          benTableData[0]?.currency
        );
      },
    },
  ];

  const labels: any = {
    "Blood Glucose": "Glucometer",
    "Blood Pressure": "BP Monitor",
    "Temperature": "Thermometer",
    "PulseOx": "Pulse Oximeter",
    "Weight": "Weighing Scale",
  };


  const columns1: GridColDef[] = [
    {
      field: "text",
      renderHeader: (params: GridColumnHeaderParams) => <b>Device Type</b>,
      minWidth: 120,
      headerAlign: "left",
      align: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
          <span>{labels[params?.row?.biometricType]}</span>
          </>
        )
        
      },
    },
    {
      field: "description",
      renderHeader: (params: GridColumnHeaderParams) => <b>Model No.</b>,
      minWidth: 100,
      headerAlign: "left",
      align: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 2,
      renderCell: (params) => {
        return (
          <div>
            <CustomToolTip title={params?.row?.model} placement="top">
              <p className="textSlice"> {params?.row?.model} </p>
            </CustomToolTip>
          </div>
        );
      },
    },
    {
      field: "qty",
      renderHeader: (params: GridColumnHeaderParams) => <b>Quantity</b>,
      minWidth: 120,
      headerAlign: "center",
      align: "center",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        console.log(params?.row?.quantity, "quantity");
        return (
          <div className="customizeInput">
            <div
              className="minusSign"
              onClick={() =>
                handleQtyChange(
                  {
                    target: {
                      value: parseInt(params?.row?.quantity) - 1,
                    },
                  },
                  params?.row
                )
              }
            >
              -
            </div>
            <Controller
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <input
                  defaultValue={1}
                  value={params?.row?.quantity}
                  className="inputBox"
                  onChange={(e: any) => handleQtyChange(e, params?.row)}
                  type="number"
                  name="quantity"
                  min={1}
                  max={500}
                  required
                />
              )}
              name="deviceTableData"
              rules={{
                pattern: {
                  value: RegExpressions?.five,
                  message: "not allowed",
                },
              }}
              control={control}
            />
            <div
              className="addSign"
              onClick={() =>
                handleQtyChange(
                  {
                    target: {
                      value: parseInt(params?.row?.quantity) + 1,
                    },
                  },
                  params?.row
                )
              }
            >
              +
            </div>
          </div>
        );
      },
    },
    {
      field: "deviceCost",
      headerName: "Unit Price",
      renderHeader: (params: GridColumnHeaderParams) => (
        <b>
          Unit Price
          <br />
          <center>({userInfo?.country === "IN" ? "INR" : "USD"})</center>
        </b>
      ),
      minWidth: 120,
      headerAlign: "right",
      align: "right",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        console.log(params?.row?.cost, "params");
        return handlePriceFormatting(
          params?.row?.cost,
          benTableData[0]?.currency
        );
      },
    },
    {
      field: "tax",
      renderHeader: (params: GridColumnHeaderParams) => (
        <b>
          GST
          <br />
          <center>({userInfo?.country === "IN" ? "INR" : "USD"})</center>
        </b>
      ),
      minWidth: 100,
      headerAlign: "right",
      sortable: false,
      align: "right",
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        let data = params?.row?.deviceCostings?.filter((item: any) => {
          return (
            item?.country ===
            (benTableData && benTableData?.length > 0
              ? benTableData[0]?.country
              : null)
          );
        });
        if (data && data?.length > 0) {
          const quantity = params?.row?.quantity;
          const deviceCost = params?.row?.deviceCost;
          const totaltax =
            (quantity * data[0]?.value * params?.row?.igst) / 100;
          // setTotalSum(totalSum+totalCost);
          return handlePriceFormatting(totaltax, benTableData[0]?.currency);
        }
        return 0;
      },
    },
    {
      field: "finalCost",
      renderHeader: (params: GridColumnHeaderParams) => (
        <b>
          Total Price
          <br />
          <center>({userInfo?.country === "IN" ? "INR" : "USD"})</center>
        </b>
      ),
      minWidth: 120,
      headerAlign: "right",
      align: "right",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        if (userInfo?.country === "IN") {
          let data = params?.row?.deviceCostings?.filter((item: any) => {
            return (
              item?.country ===
              (benTableData && benTableData?.length > 0
                ? benTableData[0]?.country
                : null)
            );
          });
          if (data && data?.length > 0) {
            const quantity = params?.row?.quantity;
            const deviceCost = params?.row?.deviceCost;
            const totalCost =
              quantity * data[0]?.value +
              (quantity * data[0]?.value * params?.row?.igst) / 100;
            // setTotalSum(totalSum+totalCost);
            return handlePriceFormatting(totalCost, benTableData[0]?.currency);
          } else {
            return 0;
          }
        } else {
          let data = params?.row?.deviceCostings?.filter((item: any) => {
            return (
              item?.country ===
              (benTableData && benTableData?.length > 0
                ? benTableData[0]?.country
                : null)
            );
          });
          console.log(params?.row, "dataaaaaaaaadddd");
          if (data && data?.length > 0) {
            const quantity = params?.row?.quantity;
            const deviceCost = params?.row?.deviceCost;
            const totalCost = quantity * data[0]?.value;
            // setTotalSum(totalSum+totalCost);
            return totalCost?.toFixed(2);
          } else {
            return 0;
          }
        }
      },
    },
  ];

  const [totalSum, setTotalSum] = useState<any>(0);

  useEffect(() => {
    const sum = tableData.reduce((acc: any, row: any) => {
      let data = row?.deviceCostings?.filter((item: any) => {
        return item?.country === benTableData[0]?.country;
      });
      if (data && data?.length > 0) {
        if (userInfo?.country === "IN") {
          return (
            acc +
            data[0]?.value * row?.quantity +
            (data[0]?.value * row?.quantity * row?.igst) / 100
          );
        } else {
          return acc + data[0]?.value * row?.quantity;
        }
      } else {
        return 0;
      }
    }, 0);
    setTotalSum(sum);
  }, [tableData, userInfo]);

  const handleQtyChange = (e: any, rowData: any) => {
    let dataArray = [];
    if (e?.target?.value >= 0) {
      for (let i = 0; i < tableData?.length; i++) {
        if (rowData?.deviceTypeId === tableData[i]?.deviceTypeId) {
          tableData[i].quantity = e?.target?.value;
          dataArray.push(tableData[i]);
        } else {
          dataArray.push(tableData[i]);
        }
      }
      setDevice(dataArray);
      setTableData(dataArray);
    }
  };
  console.log(tableData, "tableData");
  const handlePreviewModal = (event: any) => {
    event.preventDefault();
    let deviceQty = tableData?.reduce((acc: any, item: any) => {
      if (item?.quantity < 1 || item?.quantity % 1 !== 0) {
        acc = true;
      }
      return acc;
    }, false);
    if (benTableData && benTableData?.length > 0) {
      if (
        benTableData[1]?.beneficiaryCount === "" ||
        benTableData[1]?.beneficiaryCount < 0 ||
        benTableData[1]?.beneficiaryCount % 1 !== 0
      ) {
        dispatch(
          setAlert({
            open: true,
            alertType: "error",
            message:
              "Additional beneficiary count cannot less than 0 or a valid number.",
          })
        );
      } else if (benTableData[1]?.beneficiaryCount > 99) {
        return dispatch(
          setAlert({
            open: true,
            alertType: "error",
            message: "Beneficiary count should not be greater than 99.",
          })
        );
      } else if (deviceQty) {
        return dispatch(
          setAlert({
            open: true,
            alertType: "error",
            message: "Enter valid device quantity",
          })
        );
      } else {
        return setPreviewModal(true);
      }
    }
  };

  const handleCancel = () => {
    reset();
    setCustomiseModalOpen(false);
  };

  

  return (
    <>
      <Loader show={customPlanData?.isLoading} />
      <Modal
        handleClose={handleModalClose}
        open={customizeModalOpen}
        className="input-padding modal-title outer-pd-device reviewOrderModal"
        primaryText={`Customize ${customPlanData?.data?.result?.name?.toLowerCase()}`}
      >
        <form>
          <Grid container>
            <Grid item xs={12} sx={{ mt: 2 }}>
              {/* <p className="tableHeading">Beneficiaries Supported </p> */}
              <p className="tableHeading">
                Customize number of beneficiaries subscribed{" "}
                <span className="tableSubHeading">
                  (Plan minimum is{" "}
                  {customPlanData?.data?.result?.beneficiaryCount} nos)
                </span>
              </p>
              <div className="createPlanTable ">
                <div style={{ width: "100%" }} className="totalData">
                  <DataGrid
                    // paginationMode="server"
                    // autoPageSize
                    columns={columns2}
                    hideFooterPagination
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    sx={{
                      "& .MuiDataGrid-row:hover": {
                        backgroundColor: "white",
                      },
                    }}
                    autoHeight
                    rows={benTableData}
                    // getRowId={(device) => device?.id}
                  />
                  <div className="total">
                    <span style={{ fontWeight: "bold" }}>
                      Monthly Subscription Total&nbsp;: &nbsp;
                    </span>
                    <span>
                      {benTableData && benTableData?.length
                        ? benTableData[0]?.currency +
                          " " +
                          parseFloat(
                            (
                              benTableData[0]?.recurring +
                              benTableData[1]?.recurring
                            )?.toString()
                          )?.toFixed(2)
                        : parseFloat(totalSum?.toString())?.toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
              <p className="tableHeading">
                Customize device quantities{" "}
                <span className="tableSubHeading">
                  (Plan minimum is one/device type)
                </span>
              </p>
              <div className="createPlanTable ">
                <div style={{ width: "100%" }} className="totalData">
                  <DataGrid
                    // paginationMode="server"
                    // autoPageSize
                    columns={columns1}
                    hideFooterPagination
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    columnVisibilityModel={{
                      tax:
                        (benTableData && benTableData?.length > 0
                          ? benTableData[0]?.country
                          : null) === "IN"
                          ? true
                          : false,
                    }}
                    disableColumnMenu
                    sx={{
                      "& .MuiDataGrid-row:hover": {
                        backgroundColor: "white",
                      },
                    }}
                    autoHeight
                    rows={tableData}
                    getRowId={(device) => device?.id}
                  />
                  <div className="total">
                    <span style={{ fontWeight: "bold" }}>
                      One-Time Total&nbsp;: &nbsp;
                    </span>
                    <span>
                      {tableData && tableData?.length
                        ? tableData[0]?.currency +
                          " " +
                          handlePriceFormatting(
                            totalSum,
                            tableData[0]?.currency
                          )
                        : handlePriceFormatting(
                            totalSum,
                            tableData[0]?.currency
                          )}
                    </span>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <Grid container className="modalButton">
                <Grid item xs={3} sx={{ mr: 4 }}>
                  <Buttons
                    type="secondary"
                    variant="contained"
                    text="CANCEL"
                    onClick={handleCancel}
                  ></Buttons>
                </Grid>

                <Grid item xs={3}>
                  <Buttons
                    type="primary"
                    variant="contained"
                    buttonType="submit"
                    text="Review"
                    onClick={handlePreviewModal}
                  ></Buttons>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Modal>

      <CustomePreviewModal
        benTableData={benTableData}
        previewModal={previewModal}
        setPreviewModal={setPreviewModal}
        totalSum={totalSum}
        customPlanData={customPlanData}
        subscriptionPlanId={id}
        tableData={tableData}
        setTableData={setTableData}
        setCustomiseModalOpen={setCustomiseModalOpen}
      />
    </>
  );
};
