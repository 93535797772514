import { useForm, Controller } from "react-hook-form";
import { RegExpressions } from "../../../../constants/regExp";
import Buttons from "../../../common/button";
import { Modal } from "../../../common/modal/modal";
import { Grid } from "@mui/material";
import { Input } from "../../../common/input";
import {
  useSendOtpMutation,
  useUpdateEmailMobileMutation,
} from "../../../../redux/services/userApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import { handleErrors } from "../../../../utils";
import Loader from "../../../common/loader/loader";

export const EmailModal = (props: any) => {
  const {
    changeEmail,
    setChangeEmail,
    otpModal,
    setOtpModal,
    refetch,
    selectedEmail,
  } = props;

  const { handleSubmit, setValue, control, reset } = useForm({
    mode: "onChange" || "onSubmit",
  });
  const [email, setEmail] = useState();
  const [tokenId, setTokenId] = useState();

  const dispatch = useDispatch();
  const userId = useSelector((state: any) => state?.auth?.userId);

  const [sendOtp, sendOtpInfo] = useSendOtpMutation();
  const [updateEmail, updateEmailInfo] = useUpdateEmailMobileMutation();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onEmailCancelCallBack = () => {
    setChangeEmail(false);
    reset({
      Email: "",
    });
  };
  const onCancelCallBack = () => {
    setOtpModal(false);
    reset({
      otp: "",
    });
  };
  const onConfirmEmailCallBack = (data: any) => {
    updateEmail({
      userId: userId,
      email: data?.Email,
      mobileNumber: "",
      countryCode: "",
    });
    setEmail(data?.Email);
  };
  useEffect(() => {
    if (updateEmailInfo?.isSuccess) {
      if (changeEmail) {
        setOtpModal(true);
        dispatch(
          setAlert({
            alertType: "success",
            open: true,
            message: updateEmailInfo?.data?.message,
          })
        );
        setTokenId(updateEmailInfo?.data?.result[0]?.userTokenId);
      }
    }
  }, [updateEmailInfo?.isSuccess]);

  useEffect(() => {
    if (updateEmailInfo?.isError) {
      handleErrors(updateEmailInfo, dispatch);
    }
  }, [updateEmailInfo?.isError]);
  const onEmailOtpCallBack = (data: any) => {
    sendOtp({
      userId: userId,
      userTokenId: tokenId,
      otp: data?.otp,
      email: data?.Email,
    });
  };
  const onEmailResendOtp = () => {
    updateEmail({
      userId: userId,
      email: email,
      mobileNumber: "",
      countryCode: "",
    });
  };
  useEffect(() => {
    if (sendOtpInfo?.isSuccess) {
      if (otpModal) {
        dispatch(
          setAlert({
            open: true,
            alertType: "success",
            message: sendOtpInfo?.data?.result,
          })
        );
        setOtpModal(false);
        setChangeEmail(false);
        refetch();
        reset({ otp: "" });
      }
    }
  }, [sendOtpInfo?.isSuccess]);
  useEffect(() => {
    if (sendOtpInfo?.isError) {
      if (otpModal) {
        handleErrors(sendOtpInfo, dispatch);
        // setOtpModal(false);
      }
    }
  }, [sendOtpInfo?.isError]);
  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef?.current?.focus();
      }
    }, 0);
  }, [otpModal]);

  useEffect(() => {
    if (changeEmail) {
      setValue("Email", selectedEmail);
    }
  }, [changeEmail]);

  return (
    <>
      <Loader show={updateEmailInfo?.isLoading || sendOtpInfo?.isLoading} />
      <Modal
        className="accountSetingModal"
        key="changeEmail"
        open={changeEmail}
        primaryText="Enter New Email"
        handleClose={() => {
          setChangeEmail(false);
          reset({
            Email: "",
          });
        }}
      >
        <form onSubmit={handleSubmit(onConfirmEmailCallBack)}>
          <Grid container sx={{ marginTop: "40px" }}>
            <Grid item xs={12}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Enter New Email"
                    placeholder="Enter new email"
                    value={field.value}
                    required={true}
                    onChange={field.onChange}
                    helperText={error?.message ? error?.message : " "}
                    error={Boolean(error)}
                    inputRef={ref}
                    autoFocus={true}
                  />
                )}
                name="Email"
                control={control}
                rules={{
                  required: "Please enter your email",
                  pattern: {
                    value: RegExpressions?.Email,
                    message: "Please enter valid email",
                  },
                }}
              />
            </Grid>
          </Grid>
          <span className="otpMessage">An OTP will be send to the entered email.</span>
          <Grid container spacing={2} className="modalBtn">
            <Grid item xs={6} md={5} lg={4.6}>
              <Buttons
                type="secondary"
                variant="contained"
                onClick={onEmailCancelCallBack}
                text="CANCEL"
              ></Buttons>
            </Grid>
            <Grid item xs={6} md={5} lg={4.6}>
              <Buttons
                type="primary"
                variant="contained"
                buttonType="submit"
                text="SEND OTP"
              ></Buttons>
            </Grid>
          </Grid>
        </form>
      </Modal>
      <Modal
        className="accountSetingModal"
        key="sendOtp"
        open={otpModal}
        primaryText="Enter Email OTP"
        handleClose={() => {
          setOtpModal(false);
          reset({
            otp: "",
          });
        }}
      >
        <form onSubmit={handleSubmit(onEmailOtpCallBack)}>
          <Grid container sx={{ marginTop: "40px" }}>
            <Grid item xs={12}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Email OTP"
                    value={field.value}
                    required={true}
                    onChange={field.onChange}
                    helperText={error?.message ? error?.message : " "}
                    error={Boolean(error)}
                    inputRef={(e: any) => {
                      ref(e);
                      inputRef.current = e;
                    }}
                  />
                )}
                name="otp"
                control={control}
                rules={{
                  required: "Please enter OTP",
                  pattern: {
                    value: RegExpressions?.OtpCode,
                    message: "Please enter valid OTP",
                  },
                }}
              />
              <span className="resendOtp">
                {" "}
                <a href="#" onClick={onEmailResendOtp}>
                  {" "}
                  Resend OTP{" "}
                </a>{" "}
              </span>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="modalBtn">
            <Grid item xs={6} md={5} lg={4.6}>
              <Buttons
                type="secondary"
                variant="contained"
                onClick={onCancelCallBack}
                text="CANCEL"
              ></Buttons>
            </Grid>
            <Grid item xs={6} md={5} lg={4.6}>
              <Buttons
                type="primary"
                variant="contained"
                buttonType="submit"
                text="SUBMIT"
              ></Buttons>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
};
