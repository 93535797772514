import { createSlice } from "@reduxjs/toolkit";

interface filterState {
    manageOrderSearch: string;
    manageUserSearch: string;
    managePlanSearch:string;
  fromDate: string;
  toDate: string;
  orderStatus: string;
  location: string;
  userType: string;
  userStatus: string;
  planStatus:boolean|null;
  page: number;
  pageSize: number;
  category:string;
  campaignNameSearch: string;
}

const initialState = {
  manageOrderSearch: "",
  manageUserSearch: "",
  managePlanSearch:"",
  campaignNameSearch:"",
  fromDate: "",
  toDate: "",
  orderStatus: "",
  location: "",
  userType: "",
  planStatus:null,
  userStatus: "",
  pageSize: 25,
  page: 0,
  category:""
 
} as filterState;

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setPaginationFilter(state, { payload }) {
      state.pageSize = payload.pageSize;
      state.page = payload.page;
    },
    setManageOrdersFilters(state, { payload }) {
      state.manageOrderSearch = payload.search;
      state.toDate = payload.to;
      state.fromDate = payload.from;
      state.orderStatus = payload.orderStatus;
    },
    setManageUsersFilters(state, { payload }) {
      state.manageUserSearch = payload.search;
      state.location = payload.country;
      state.userStatus = payload.status;
      state.userType = payload.roleId;
    },
    setNotificationsFilters(state, { payload }) {
      state.fromDate = payload.from;
      state.toDate = payload.to;
    },
    setAuditLogsFilters(state, { payload }) {
      state.fromDate = payload.from;
      state.toDate = payload.to;
      state.category = payload.category;
    },
    setManagePlanFilters(state,{payload}){
      state.managePlanSearch=payload.search;
      state.planStatus=payload.isActive;
    },
    setManageCouponFilter(state, {payload}){
      state.campaignNameSearch=payload?.search;
      state.userStatus = payload.status;
    },
    clearFilterState:(state)=> state=initialState,

  },  
});

export const {setPaginationFilter,setManageCouponFilter,setManagePlanFilters,setManageOrdersFilters,setManageUsersFilters,setNotificationsFilters,setAuditLogsFilters,clearFilterState}=filterSlice.actions;
export default filterSlice.reducer;
export const getFilterInfo=(state:any)=>state.filters;
