import { createSlice } from "@reduxjs/toolkit";

interface BeneficiaryState {
  beneficiaryData: Array<any>;
  beneficiaryCount: number;
  planDetail: Object;
  selectedPlanId: string;
  isChange: boolean;
  beneficiaryAccounts : Object;
  isCustomize: boolean;
}

const initialState = {
  beneficiaryData: [],
  beneficiaryCount: 0,
  planDetail: {},
  selectedPlanId: "",
  isChange: false,
  beneficiaryAccounts : {},
  isCustomize: false,
} as BeneficiaryState;

const beneficiarySlice = createSlice({
  name: "beneficiary",
  initialState,
  reducers: {
    setBeneficiaryData(state, { payload }) {
      state.beneficiaryData = payload;
    },
    setBeneficiaryCount(state, { payload }) {
      state.beneficiaryCount = payload;
    },
    setPlanDetail(state, { payload }) {
      state.planDetail = payload;
    },
    setSelectedPlan(state, { payload }) {
      state.selectedPlanId = payload;
    },
    setChange(state, { payload }) {
      state.isChange = payload;
    },
    setIsCustomized(state, { payload }) {
      state.isCustomize = payload;
    },
    setBeneficaryAccountsCount(state, {payload}){
      state.beneficiaryAccounts = payload;
    },
    clearBeneficiaryState: (state) => (state = initialState),
  },
});

export const {
  setBeneficiaryData,
  setBeneficiaryCount,
  clearBeneficiaryState,
  setPlanDetail,
  setSelectedPlan,
  setBeneficaryAccountsCount,
  setChange,
  setIsCustomized,
} = beneficiarySlice.actions;
export default beneficiarySlice.reducer;
export const getBeneficiaryInfo = (state: any) => state.beneficiary;
export const getBeneficiaryAccountCount = (state :any) => state.beneficiary.beneficiaryAccounts;
