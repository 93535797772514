import { Tooltip } from "@mui/material";
import { Tooltiprops } from "../../../models/common";
import "./style.css";

export const CustomToolTip = (props: Tooltiprops) => {
    const { title, children, placement, open, onOpen, onClose,className, disableHoverListener } = props
    return (
        <>
            <Tooltip
                title={title}
                placement={placement}
                arrow
                onOpen={onOpen}
                onClose={onClose}
                className={className}
                open={open}  
                disableHoverListener={disableHoverListener}
            >
                <span>{children}</span>
            </Tooltip>
        </>
    )
}