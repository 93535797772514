import { apiRoutes } from "../../config/api";
import { BILLING_FREQUENCY_MAP } from "../../constants/constant";
import { apiSlice } from "./apiSlice";


const transformPlanDetails = (planData: any)=> {
  let planDetails = planData;
  let partnerOffering = planDetails.PartnerPlan?.length
    ? {...planDetails?.PartnerPlan[0]}
    : "";

  let billingFrequencyMap = BILLING_FREQUENCY_MAP.find(
    (map) =>
      map?.frequency == planDetails?.billingFrequency?.toLowerCase() &&
      map?.interval == planDetails?.billingInterval
  );

  if(partnerOffering) {
    let tempFreq = BILLING_FREQUENCY_MAP.find(
      (map) =>
        map?.frequency == partnerOffering?.billingFrequency?.toLowerCase() &&
        map?.interval == partnerOffering?.billingInterval
    );
    partnerOffering.billingFrequency = tempFreq?.value || "MONTH"
  }

  let billingFrequency = billingFrequencyMap?.value || "";
  // let billingInterval = billingFrequencyMap?.interval

  if(partnerOffering?.PlanPartnerMapping){
    delete partnerOffering?.PlanPartnerMapping;
  }

  
  if(planDetails?.planType == "PARTNER"){
    delete planDetails?.PartnerPlan
    planDetails = {
      ...planDetails,
      billingFrequency,
    }
  }else {
    planDetails = {
      ...planDetails,
      billingFrequency,
      partnerOffering,
    };
  }
  return planDetails;
}

export const planService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPlans: builder.query({
      query: (params: any) => ({
        url: apiRoutes.GETALLSUBSCRIPTIONPLAN,
        params: params,
        method: "GET",
      }),
      transformResponse : (response:any) => {
        let rows = response?.result.map((pl:any)=>{
        return transformPlanDetails(pl)
        })
        
        response.result = rows;

        return response

      }
    }),
    selectPlans: builder.mutation({
      query: (formData: any) => {
        return {
          url: apiRoutes.SELECTPLANS,
          method: "POST",
          body: formData,
        };
      },
    }),
    deactivatePlans: builder.mutation({
      query: (formData: any) => {
        const { id } = formData;
        return {
          url: `${apiRoutes.DEACTIVATEPLAN}/${id}`,
          method: "POST",
        };
      },
    }),
    planDetails: builder.mutation({
      query: (params: any) => {
        return {
          url: `${apiRoutes.PLAN}/${params?.id}`,
          method: "GET",
        };
      },
      transformResponse: (response: any) => {
        response.result = transformPlanDetails(response.result)
        return response;
      },
    }),
    customisationPlan: builder.mutation({
      query: (params: any) => {
        return {
          url: `${apiRoutes.CUSTOMISEPLAN}/${params?.id}`,
          method: "GET",
        };
      },
    }),
    createPlan: builder.mutation({
      query: (formData: any) => {
        return {
          url: apiRoutes.PLAN,
          method: "POST",
          body: formData,
        };
      },
    }),
    updatePlan: builder.mutation({
      query: (formData: any) => {
        const { id, ...data } = formData;
        return {
          url: `${apiRoutes.PLAN}/${id}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    getAllProduct: builder.mutation({
      query: (params: any) => {
        return {
          url: apiRoutes.GETPRODUCT,
          method: "GET",
          params: params,
        };
      },
    }),
    activatePlan: builder.mutation({
      query: (formData: any) => {
        const { id } = formData;
        return {
          url: `${apiRoutes.ACTIVATEPLAN}/${id}`,
          method: "POST",
        };
      },
    }),
    saveActivatePlan: builder.mutation({
      query: (formData: any) => {
        const { id } = formData;
        return {
          url: `${apiRoutes.SAVEACTIVATEPLAN}/${id}`,
          method: "PUT",
        };
      },
    }),
    cancelSubscriptionPlan: builder.mutation({
      query: (formData: any) => {
        const { userId, ...data } = formData;
        return {
          url: `${apiRoutes.CANCEL_SUBSCRIPTIONPLAN}/${userId}`,
          method: "POST",
          body: data,
        };
      },
    }),
    getSubscriptionPlan: builder.query({
      query: ({ id, ...data }) => {
        return {
          url: `${apiRoutes.USERSUBSCRIPTIONPLAN}/${id}`,
          method: "GET",
          params: data,
        };
      },
    }),
    getUserSubscriptionPlan: builder.mutation({
      query: (id) => {
        return {
          url: `${apiRoutes.USERSUBSCRIPTIONPLAN}/${id}`,
          method: "GET",
        };
      },
    }),
    getUpgradePlansById: builder.query({
      query: ({ parentPlanId, params }) => {
        return {
          url: `${apiRoutes?.GETUPGRADEPLANSBYID}/${parentPlanId}`,
          method: "GET",
          params: params,
        };
      },
    }),
    upgradePlansById: builder.mutation({
      query: ({ subscriptionPlanId, userSubscriptionId }) => {
        return {
          url: `${apiRoutes?.UPGRADEPLANSBYID}/${userSubscriptionId}`,
          method: "PUT",
          body: { subscriptionPlanId },
        };
      },
    }),
    checkUpgrade: builder.mutation({
      query: ({ subscriptionPlanId, userSubscriptionId }: any) => {
        return {
          url: `${apiRoutes?.CHECK_UPGRADE}`,
          method: "POST",
          body: {
            userSubscriptionId: userSubscriptionId,
            newPlanId: subscriptionPlanId,
          },
        };
      },
    }),
    activateSubscription: builder.mutation({
      query: (data) => {
        const { userId, ...rest } = data;
        return {
          url: `${apiRoutes?.ACTIVATESUBSCRIPTIONBYID}/${userId}`,
          method: "POST",
          body: rest,
        };
      },
    }),
    customizePlan: builder.mutation({
      query: (data) => {
        return {
          url: `${apiRoutes?.CUSTOMIZE_PLAN}`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteOrder: builder.mutation({
      query: (orderId) => ({
        url: `${apiRoutes?.ORDERDELETE}/${orderId}`,
        method: "DELETE",
      }),
    }),
  }),
});
export const {
  useGetAllPlansQuery,
  useSelectPlansMutation,
  useUpgradePlansByIdMutation,
  useDeactivatePlansMutation,
  useSaveActivatePlanMutation,
  usePlanDetailsMutation,
  useCustomisationPlanMutation,
  useCreatePlanMutation,
  useCheckUpgradeMutation,
  useUpdatePlanMutation,
  useGetUpgradePlansByIdQuery,
  useGetUserSubscriptionPlanMutation,
  useGetAllProductMutation,
useCustomizePlanMutation,
  useActivatePlanMutation,
  useGetSubscriptionPlanQuery,
  useCancelSubscriptionPlanMutation,
  useActivateSubscriptionMutation,
  useDeleteOrderMutation
} = planService;
