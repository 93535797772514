import React, { useEffect, useState } from "react";
import { Table } from "../../common/table";
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import "./style.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Divider, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import Buttons from "../../common/button";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { handleErrors } from "../../../utils";
import Loader from "../../common/loader/loader";
import "./style.css";
import {
  useDeleteCouponByIdMutation,
  useGetAllCouponsQuery,
  useUpdateCouponByIdMutation,
} from "../../../redux/services/manageCouponApiSlice";
import { CreateCouponModal } from "./createCouponModal";
import { ConfirmModal } from "../../common/confirmmodal";
import { Messages } from "../../../constants/messages";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { setManageCouponFilter, setPaginationFilter } from "../../../redux/features/filters/filtersSlice";
import { SEARCHBAR } from "../../common/search/searchbar";
import { SelectInput } from "../../common/selectinput";
import { DiscountType, UserStatus } from "../../../constants/enum";

export const ManageCoupon = (props: any) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [tableHeight, setTableHeight] = useState(0);
  const [selectedRowData, setSelectedRowData] = useState<any>();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [rowData, setRowData] = useState<any>([]);
  const [rowCount, setRowCount] = useState(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [couponId, setCouponId] = useState<any>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [deactivateModal, setDeactivateModal] = useState<boolean>(false);
  const [activateModal, setActivateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const filtersData = useSelector((state: any) => state.filters);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: filtersData?.pageSize,
    page: filtersData?.page,
  });
  const [disabled, setDisabled] = useState<boolean>(true);
  const [searchInput, setSerchInput] = useState<string>(
    filtersData?.campaignNameSearch
  );
  const [filterData, setFilterData] = useState<any>({
    search: filtersData?.campaignNameSearch,
    status: filtersData?.userStatus
  });
 
  const getFilteredParams = () => {
    var data = {};
    data = Object.fromEntries(
      Object.entries(filterData).filter(([_, v]) => v != "" && v != null)
    );
    return data;
  };  

  const userStatusOptions = [
    { text: "All", value: null },
    { text: "Active", value: UserStatus?.ACTIVE },
    { text: "Deactivated", value: UserStatus?.DEACTIVATED}
  ]

  //integration

  const {
    data,
    isSuccess,
    refetch,
    isError,
    fulfilledTimeStamp,
    error,
    isLoading,
  } = useGetAllCouponsQuery({
    limit: paginationModel?.pageSize,
    page: paginationModel?.page + 1,
    ...getFilteredParams()
  });

  const [updateCoupon, updateCouponData] = useUpdateCouponByIdMutation();
  const [deleteCoupon, deleteCouponData] = useDeleteCouponByIdMutation();

  useEffect(() => {
    if (isSuccess) {
      setRowData(
        data?.result.map((row: any, index: any) => ({
          id: index,
          ...row,
          createdAt: dayjs(row.createdAt)?.format("DD MMM, YYYY"),
          startsFrom: dayjs(row.startsFrom)?.format("DD MMM, YYYY"),
          expiresOn: dayjs(row.expiresOn)?.format("DD MMM, YYYY"),
        }))
      );
      setRowCount(data?.count);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      handleErrors({ error, isError }, dispatch);
    }
  }, [isError]);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
    dispatch(setPaginationFilter(newPaginationModel));
  };

  const calHeaight = () => {
    let targetCompo,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetCompo = window.document.querySelector<HTMLElement>(".manageCoupon");
    targetElement = targetCompo?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 25);
    setTableHeight(tableHeight);
  };

  useEffect(() => {
    calHeaight();
    const resizeObserver = new ResizeObserver(() => {
      calHeaight();
      setScreenWidth(window.innerWidth);
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleMenuClick = (event: any, id: any) => {
    setAnchorEl(event.currentTarget);
    setCouponId(rowData[id]?.couponCode);
    setSelectedRowData(rowData[id]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const DiscountTypeOption = [
    { text: "Product Discount", value: DiscountType.PRODUCT_DISCOUNT },
    { text: "Trial Period", value: DiscountType.TRIAL_PERIOD },
    {
      text: "Subscription Discount",
      value: DiscountType?.SUBSCRIPTION_DISCOUNT,
    },
  ];

  const columns: GridColDef[] = [
    {
      field: "campaignName",
      headerName: "Name",
      minWidth: 150,
      align: "left",
      cellClassName: "cell-padding textCapital",
      headerAlign: "left",
      sortable: false,
      flex: 1,
    },
    {
      field: "couponCode",
      headerName: "Coupon Code",
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-padding",
      minWidth: 150,
      sortable: false,
      flex: 2,
    },
    {
      field: "type",
      headerName: "Discount Type",
      minWidth: 150,
      cellClassName: "cell-padding textCapital",
      headerAlign: "left",
      align: "left",
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        console.log(params?.row, "paramsss")
        return (
          <>
          {
            params?.row?.type === "monthlyDiscount" ? 
            (<span>Monthly Discount</span>) 
            : params?.row?.type === "freeTrial" ? 
            (<span>Free Trial</span>) 
            : params?.row?.type === "productDiscount" ? 
            (<span>Product Discount</span>) 
            : null
          }
          </>
        );
      },
    },
    {
      field:"biilingCountry",
      headerName: "Billing Country",
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-padding",
      minWidth: 150,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.country === "IN" ? (
              <span >India</span>
            ) : (
              <span >United States</span>
            )}
          </>
        );
      },
    },
    {
      field: "startsFrom",
      headerName: "Start Date",
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-padding",
      minWidth: 150,
      sortable: false,
      flex: 1,
    },
    {
      field: "expiresOn",
      headerName: "End Date",
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-padding",
      minWidth: 150,
      sortable: false,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-padding",
      headerClassName: "lineBreak",
      minWidth: 150,
      sortable: false,
      flex: 1,
    },
   
    {
      field: "createdBy",
      headerName: "Created By",
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-padding",
      minWidth: 150,
      sortable: false,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      cellClassName: "cell-padding",
      minWidth: 150,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.status === "Active" ? (
              <span className="activeClass">Active</span>
            ) : (
              <span className="DeactiveClass">Deactivate</span>
            )}
          </>
        );
      },
    },

    {
      field: "action",
      sortable: false,
      align: "left",
      headerAlign: "left",
      headerClassName: "stickyColumn",
      headerName: "",
      cellClassName: "stickyCell cell-padding",
      width: 2,
      renderCell: (params) => (
        <IconButton
          onClick={(event: any) => handleMenuClick(event, params?.row?.id)}
        >
          <MoreVertIcon />
        </IconButton>
      ),
    },
  ];

  const handleCreateCampaign = () => {
    setOpenModal(true);
  };
  const handleEdit = () => {
    setAnchorEl(null);
    setOpenModal(true);
    setIsEdit(true);
  };

  const handleActivate = () => {
    setActivateModal(true);
    setAnchorEl(null);
  };

  const onConfirmActivate = () => {
    updateCoupon({
      couponCode: couponId,
      data: { status: "Active" },
    });
  };
  const onCancelActivate = () => {
    setActivateModal(false);
  };

  const handleDeactivate = () => {
    setDeactivateModal(true);
    setAnchorEl(null);
  };

  const onConfirmDeactivate = () => {
    updateCoupon({
      couponCode: couponId,
      data: { status: "Inactive" },
    });
  };
  const onCancelDeactivate = () => {
    setDeactivateModal(false);
  };

  useEffect(() => {
    if (updateCouponData?.isSuccess) {
      setDeactivateModal(false);
      setActivateModal(false);
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "status updated successfully.",
        })
      );
      refetch();
    }
  }, [updateCouponData?.isSuccess]);

  useEffect(() => {
    handleErrors(updateCouponData, dispatch);
  }, [updateCouponData?.isError]);

  useEffect(() => {
    if (deleteCouponData?.isSuccess) {
      setDeleteModal(false);
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Coupon deleted successfully.",
        })
      );
      refetch();
    }
  }, [deleteCouponData?.isSuccess]);

  useEffect(() => {
    handleErrors(deleteCouponData, dispatch);
  }, [deleteCouponData?.isError]);

  const handleDeleteCoupon = () => {
    setDeleteModal(true);
    setAnchorEl(null);
  };

  const onConfirmDelete = () => {
    deleteCoupon(couponId);
  };
  const onCancelDelete = () => {
    setDeleteModal(false);
  };

  const handleChange = (e:any, { type }:any) =>{
    if(type === "search"){
      if(e?.target?.value === null || e?.target?.value === ""){
        setSerchInput(e?.target?.value)
        setDisabled(true)
        setFilterData({...filterData, ["search"]: "" })
      } else{
        setSerchInput(e?.target?.value)
      }
    } else{
      const {name, value}= e?.target;
      setFilterData({...filterData, [name]: value })
    }
  }

  const handleSearch = (e:any) => {
    e?.preventDefault();
    // handle search call by using searchInput value
    setFilterData({ ...filterData, ["search"]: searchInput?.toLowerCase() });
  }

  const handleReset = () => {
    setFilterData({});
    setSerchInput("");
  };

  useEffect(() => {
    if (searchInput) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [searchInput]);

  useEffect(()=>{
    dispatch(setManageCouponFilter(filterData))
  },[filterData])

  return (
    <>
      <Loader
        show={
          isLoading ||
          updateCouponData?.isLoading ||
          deleteCouponData?.isLoading
        }
      />

<Grid container rowSpacing={1.2} columnSpacing={3} marginBottom={2}>
        <Grid item xl={4.8} lg={3.4} md={4.5} sm={7} xs={12}>
          <span className="subscriberColumn1">Manage Coupon</span>
          <span className="countFonts">({rowCount})</span>
        </Grid>
        <Grid item xl={2.5} lg={2.9} md={3} sm={5} xs={12}>
          <SEARCHBAR
            data-testid="search-bar"
            key="search-bar"
            value={searchInput}
            onChange={(e: any) => {
              handleChange(e, { type: "search" });
            }}
            setValue={setSerchInput}
            handleSearchClick={(e: any) => handleSearch(e)}
            disabled={disabled}
            placeholder="Search coupon name"
          />
        </Grid>
        <Grid item xl={1.5} lg={1.5} md={2.5} sm={3.3} xs={6}>
          <SelectInput
            value={filterData?.status ? filterData?.status : ""}
            className="filterStyle"
            placeholder="Status"
            onChange={handleChange}
            name="status"
            optionValue={userStatusOptions}
          />
        </Grid>
        <Grid item xl={1.1} lg={1.4} md={2} sm={3.5} xs={6}>
          <Buttons
            startIcon="reset"
            variant="contained"
            type="primary"
            className="resetButton"
            text="Reset"
            onClick={handleReset}
          />
        </Grid>
        <Grid item xl={2.1} lg={2.7} md={3.7} sm={5.2} xs={12}>
        <Buttons
            type="primary"
            variant="contained"
            onClick={handleCreateCampaign}
            text={"Create Coupon"}
            primaryBtnTextClass="createCouponBtnText"
            startIcon="add"
          ></Buttons>
        </Grid> 
      </Grid>



      {/* <Grid container justifyContent={"space-between"} sx={{ mb: "3vh" }}>
        <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
          <span className="manageCouponHeading">Manage Coupon</span>
        </Grid>
        <Grid item xs={12} sm={6} md={3.4} lg={2.3} xl={1.8}>
          <Buttons
            type="primary"
            variant="contained"
            onClick={handleCreatePlan}
            text={"Create Coupon"}
            primaryBtnTextClass="createCouponBtnText"
            startIcon="add"
          ></Buttons>
        </Grid>
      </Grid> */}
      <div className="manageCoupon">
        <Table
          rows={rowData}
          columns={columns}
          rowCount={rowCount}
          isPagination={true}
          onPaginationModelChange={handlePaginationModelChange}
          pagination={paginationModel}
          tableHeight={tableHeight}
        />
      </div>
      {openModal && (
        <CreateCouponModal
          open={openModal}
          setOpen={setOpenModal}
          refetchAllCoupons={() => refetch()}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          couponId={couponId}
        />
      )}
      <div className="modelModal">
        <ConfirmModal
          className="confirmModal"
          key="deactivate"
          open={deactivateModal}
          primaryText={Messages.DEACTIVATE}
          cancelButton="NO"
          confirmButton="YES"
          cancelCallback={onCancelDeactivate}
          confirmCallback={onConfirmDeactivate}
        />
      </div>
      <div className="modelModal">
        <ConfirmModal
          className="confirmModal"
          key="activate"
          open={activateModal}
          primaryText={Messages.ACTIVATE}
          cancelButton="NO"
          confirmButton="YES"
          cancelCallback={onCancelActivate}
          confirmCallback={onConfirmActivate}
        />
      </div>
      <div className="modelModal">
        <ConfirmModal
          className="confirmModal"
          key="delete"
          open={deleteModal}
          primaryText={Messages.DELETECOUPON}
          cancelButton="NO"
          confirmButton="YES"
          cancelCallback={onCancelDelete}
          confirmCallback={onConfirmDelete}
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ width: "4000px" }}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <Divider className="divider-mp" />
        {selectedRowData?.status === "Active" ? (
          <MenuItem onClick={handleDeactivate}>Deactivate</MenuItem>
        ) : (
          <MenuItem onClick={handleActivate}>Activate</MenuItem>
        )}
        {/* <Divider className="divider-mp" />
        <MenuItem onClick={handleDeleteCoupon}>Delete</MenuItem> */}
      </Menu>
    </>
  );
};
