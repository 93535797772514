import { Grid } from "@mui/material";
import { Modal } from "../../common/modal/modal";
import checkIcon from "../../../assets/icons/checkIcon.svg";
import Buttons from "../../common/button";
import { CustomToolTip } from "../../common/tooltip";
import { SubscriptionChanges } from "../../../constants/enum";
import { handlePriceFormatting } from "../../../utils";
import PlanCard from "../auth/userRegistration/planCard";
import PlanDisplayCard from "./planDisplayCard";

export const PreviewPlan = (props: any) => {
  const {
    selectedRow,
    isModalOpen,
    setModalOpen,
    baseBenCount,
    baseRecurringAmount,
    isView,
    createPlan,
    selectedOption,
    setSelectedOption,
    isUpgrade,
    partnerDetails,
    isUpdate,
    updatePlan
  } = props;

  console.log(selectedOption, "selectedOption");
  console.log(selectedRow, "selectedRow");
  console.log(baseRecurringAmount, "selectedRowwwwwwwwwwwwwwwwwww");

  const closeModal = () => {
    setModalOpen(false);
  };
  const handleSave = () => {
    if(isUpdate){
      updatePlan(selectedRow)
    }else{
      createPlan(selectedRow);
    }
  };
  const handleDraft = () => {
    createPlan({ ...selectedRow, draft: true });
  };


  return (
    <>
      <Modal
        key="preview plan"
        open={isModalOpen}
        primaryText={isView ? "View Plan" : "Preview Plan"}
        className="input-padding modal-title outer-pd pd previewModal"
        handleClose={closeModal}
        xs={5}
      >
        <Grid container justifyContent={"center"}  sx={{mt:2}}>
          {/* <Grid item lg={12} md={12} sm={12} xs={12} className="plan-box"> */}
            {/* <Grid container sx={{ mt: 3, mb: 3 }} justifyContent="center"> */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <PlanDisplayCard data={{...selectedRow, partnerDetails}} showButtons={false}/>
              </Grid>
          {/* </Grid> */}
          {/* </Grid> */}
        </Grid>
        {/* ------------------------------------- */}
        {!isView && (
          <Grid container sx={{ mt: 3 }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Buttons
                    type="secondary"
                    variant="contained"
                    text="CANCEL"
                    onClick={closeModal}
                  />
                </Grid>
                {
                  !isUpdate  && (
                    <Grid item xs={6}>
                  <Buttons
                    type="secondary"
                    variant="contained"
                    text="SAVE DRAFT"
                    onClick={handleDraft}
                  />
                </Grid>
                  )
                }
                
                <Grid item xs={isUpdate? 6 : 12}>
                  <Buttons
                    type="primary"
                    variant="contained"
                    onClick={handleSave}
                    text={isUpdate? "Save": "Save and Activate"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Modal>
    </>
  );
};
