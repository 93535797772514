import { useEffect, useState } from "react";
import "./manageUserHeader.css";
import { Grid } from "@mui/material";
import { SEARCHBAR } from "../../../common/search/searchbar";
import { SelectInput } from "../../../common/selectinput";
import { UserRoles, UserStatus } from "../../../../constants/enum";
import { useSelector } from "react-redux";
import Buttons from "../../../common/button";

export const PlatformSupportHeader = (props: any) => {
  const { screenWidth, filtersData, filterData, setFilterData, rowCount } = props;
  const [searchInput, setSerchInput] = useState<string>(
    filtersData?.manageUserSearch
  );
  const [disabled, setDisabled] = useState<boolean>(true);
  const { roleData } = useSelector((state: any) => state?.role);

  const userStatusOptions = [
    { text: "All", value: null },
    { text: "Active", value: UserStatus.ACTIVE },
    { text: "Deactivated", value: UserStatus.DEACTIVATED },
    { text: "Invited", value: UserStatus.INVITED },
  ];

  let userTypeOptions: any[] = [{ text: "All", value: null }];

  if (Array.isArray(roleData)) {
    const filterRoleData = roleData?.filter((row:any) => row?.roleName !== UserRoles?.PLATFORM_SUPER_ADMIN)
    filterRoleData?.map((x: any) => {
      userTypeOptions.push({
        text: x?.roleName,
        value: x?.roleId,
      });
    });
  }

  const handleChange = (e: any, { type }: any) => {
    if (type === "search") {
      if (e?.target?.value === null || e?.target?.value === "") {
        setSerchInput(e?.target?.value);
        setDisabled(true);
        setFilterData({ ...filterData, ["search"]: "" });
      } else {
        setSerchInput(e?.target?.value);
      }
    } else {
      const { name, value } = e?.target;
      setFilterData({ ...filterData, [name]: value });
    }
  };

  // const handleSearch = (e: any) => {
  //   e?.preventDefault();
  //   // handle search call by using searchInput value
  //   setFilterData({ ...filterData, ["search"]: searchInput?.toLowerCase() });
  // };

  const handleSearch = (e: any) => {
    e?.preventDefault();
    var mobileNumber = "";
    var searchNumberInput = "";
    if (searchInput.match(/[a-z]/i)) {
      searchNumberInput = searchInput;
    } else {
      searchNumberInput = searchInput
        ?.replace(/-/g, "")
        ?.split(" ")
        ?.filter(Boolean)
        ?.join("");
    }
    // const numericSearchInput = searchNumberInput?.replace(/\D/g, '');
    if (
      searchNumberInput?.startsWith("+91") ||
      searchNumberInput?.startsWith("+1")
    ) {
      if (searchNumberInput?.startsWith("+91")) {
        mobileNumber = searchNumberInput.slice(3);
      } else if (searchNumberInput?.startsWith("+1")) {
        mobileNumber = searchNumberInput.slice(2);
      }
      setFilterData({
        ...filterData,
        ["search"]: mobileNumber,
      });
    } else {
      setFilterData({
        ...filterData,
        ["search"]: searchNumberInput?.toLowerCase(),
      });
    }
  };

  const handleReset = () => {
    setFilterData({});
    setSerchInput("");
  };
  useEffect(() => {
    if (searchInput) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [searchInput]);

  return (
    <>
      <Grid
        container
        rowSpacing={1.2}
        columnSpacing={screenWidth > 1999 ? 3.8 : 2.6}
        marginBottom="3vh"
        justifyContent={"end"}
        className="manageOrderHeader"
      >
        <Grid
          item
          xl={2}
          lg={2.7}
          md={4}
          sm={7.2}
          xs={12}
          className="Manage-order-Container"
        >
          <span className="manageOrderHeading" data-testid="manage-title">
            Manage Users
          </span>
          <span className="countFonts">({rowCount})</span>
        </Grid>
        <Grid item xs={6} sm={4.8} md={4} lg={3} xl={3}>
          {" "}
          <SEARCHBAR
            key="search-bar"
            value={searchInput}
            onChange={(e: any) => {
              handleChange(e, { type: "search" });
            }}
            setValue={setSerchInput}
            handleSearchClick={(e: any) => handleSearch(e)}
            disabled={disabled}
            placeholder="Search name, mobile number"
          />
        </Grid>
        <Grid item xl={2.3} lg={2} md={4} sm={4} xs={6}>
          <SelectInput
            value={filterData?.roleId ? filterData?.roleId : ""}
            className="filterStyle"
            placeholder="User Type"
            onChange={handleChange}
            name="roleId"
            optionValue={userTypeOptions}
          />
        </Grid>
        <Grid item xl={2.3} lg={2} md={4} sm={4} xs={6}>
          <SelectInput
            value={filterData?.status ? filterData?.status : ""}
            className="filterStyle"
            placeholder="Status"
            onChange={handleChange}
            name="status"
            optionValue={userStatusOptions}
          />
        </Grid>
        <Grid item xl={2} lg={1.4} md={4} sm={4} xs={6}>
          <Buttons
            startIcon="reset"
            variant="contained"
            type="primary"
            className="resetButton"
            text="Reset"
            onClick={handleReset}
          />
        </Grid>
      </Grid>
    </>
  );
};
