import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./style.css";
export const DatePickers = (props:any) => {
    const{defaultValue,disabled,inputRef,label,onChange,onError,value,helperText,labelClassName,required ,error ,className, disableFuture , disablePast ,minDate}=props
    return (
       <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <InputLabel shrink
                className={labelClassName?`${labelClassName} "labelClass"`:"labelClass"}
                htmlFor="bootstrap-input"
                required={required}
                error={error}> {label}</InputLabel>
            <DatePicker
            className={`customDatePicker ${className}`}
            closeOnSelect={true}
            defaultValue={defaultValue}
            disabled={disabled}
            disableFuture={disableFuture ? disableFuture : false}
            disablePast={disablePast ? disablePast : false}
            inputRef={inputRef}
            onChange={onChange}
            onError={onError}
            value={value}
            minDate={minDate}
            format="DD/MM/YYYY"
            />

        </LocalizationProvider>
        <FormHelperText className="helperClass slice">{helperText}</FormHelperText>
        </>
       
    )

}

