import { Button } from '@mui/material';
import "./style.css";
import { ReactComponent as AddIcon } from '../../../assets/icons/plusIcon.svg';
import { ReactComponent as DownloadIcon } from "../../../assets/icons/whiteDownloadIcon.svg"
import { Buttonprops } from '../../../models/common';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import CircularProgress from '@mui/material/CircularProgress';

const Buttons = (props: Buttonprops) => {
  const { className, variant,textClassName,isLoading, disabled, text, startIcon, endIcon, onClick, type,buttonType , primaryBtnTextClass , SecoundaryBtnTextClass  } = props;
  function getButtonIcon(iconType: any) {
    switch (iconType) {
      case "add": return <AddIcon className='add' />;
      case "reset": return <FilterAltIcon className='reset'/>
      case "download" : return <DownloadIcon className='download'/>
    }
  }

  
  return (
    <div className={disabled?"btnwrapper":""}>
      <Button 
        data-testid="buttons"
        className={className ? `${type} ${className}` : `${type}`}
        variant={variant}
        disabled={disabled}
        startIcon={getButtonIcon(startIcon)}
        endIcon={endIcon}
        fullWidth={true}
        onClick={onClick}
        type={buttonType}
        disableFocusRipple={true}
      >
        {
          !isLoading?
        <span className= {`${textClassName}` + " " +(type  == "primary" ? `primaryText ${primaryBtnTextClass}`: `secondaryText ${SecoundaryBtnTextClass}`)}>{text}</span>:
        <CircularProgress className='circularProgress' />
      }
      </Button>
    </div>
  )
}
export default Buttons;


export const UploadButtons = (props: Buttonprops) => {
  const { className, variant,textClassName,isLoading, disabled, text, startIcon, endIcon, onClick, type,buttonType , primaryBtnTextClass , SecoundaryBtnTextClass  } = props;
  function getButtonIcon(iconType: any) {
    switch (iconType) {
      case "add": return <AddIcon className='add' />;
    }
  }
  return (
    <Button 
    data-testid="buttons"
      className={className ? `${type} ${className}` : `${type}`}
      variant={variant}
      disabled={disabled}
      startIcon={getButtonIcon(startIcon)}
      endIcon={endIcon}
      fullWidth={true}
      onClick={onClick}
      component="span"
      disableFocusRipple={true}
    >
      {
        !isLoading?
      <span className= {`${textClassName}` + " " +(type  == "primary" ? `primaryText ${primaryBtnTextClass}`: `secondaryText ${SecoundaryBtnTextClass}`)}>{text}</span>:
      <CircularProgress className='circularProgress' />
      }
    </Button>
  )
}