import { Grid } from "@mui/material";
import { Modal } from "../../../common/modal/modal"

export const UserTypeModal = (props: any) => {
    const { userModal, setUserModal } = props;
    const handleCancel = () => {
        setUserModal(false)
    }
    return (
        <Modal
            handleClose={handleCancel}
            primaryText={"USER TYPES"}
            open={userModal}
            xs={11}
            sm={11}
            md={11}
            lg={11}
            xl={11}
            className="accountSetingModal addFamilyModal"
        >
            <Grid container>
                <Grid item xs={12} sx={{ mt: 3 }}>
                    <span className="userTypes">Beneficiary: </span>
                    <span className="subtitleUsertype">This type of user takes  the biometric readings using the famhealth App. You can create as many beneficiaries as your subscription allows.</span>
                </Grid>

                <Grid item xs={12} sx={{ mt: 3 }}>
                    <span className="userTypes">Family: </span>
                    <span className="subtitleUsertype">This type of user can view the beneficiaries readings using the famhealth App. Typically, this type of users are children or other family member of the beneficiary. Each beneficiary can have upto 5 family members.</span>
                </Grid>
            </Grid>

        </Modal>
    )
}