import { apiSlice } from "./apiSlice";
import { apiRoutes } from "../../config/api";

export const orderApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrder: builder.mutation({
      query: (userId) => ({
        url: `${apiRoutes.GETORDERSBYUSERS}/${userId}`,
        method: "GET",
      }),
    }),
    getOrderSummary: builder.mutation({
      query: (orderId) => ({
        url: `${apiRoutes.GETORDERSUMMARY}/${orderId}`,
        method: "GET",
      }),
    }),
    addOrderAddress: builder.mutation({
      query: (orderData) => ({
        url: apiRoutes.ADD_ORDER_ADDRESS,
        method: "POST",
        body: { ...orderData },
      }),
    }),
    getOrderAddress: builder.mutation({
      query: (orderId) => ({
        url: `${apiRoutes.GET_ORDER_ADDRESS}/${orderId}`,
        method: "GET",
      }),
    }),
    updateOrderAddress: builder.mutation({
      query: (orderData) => ({
        url: apiRoutes.UPDATE_ORDER_ADDRESS,
        method: "PATCH",
        body: { ...orderData },
      }),
    }),
    addBeneficiary: builder.mutation({
      query: (beneficiaryData) => ({
        url: apiRoutes.ADDBENEFICIARY,
        method: "POST",
        body: { ...beneficiaryData },
      }),
    }),
    updateBeneficiary: builder.mutation({
      query: (beneficiaryData) => ({
        url: apiRoutes.UPDATEBENEFICIARY,
        method: "PUT",
        body: { ...beneficiaryData },
      }),
    }),
    createSubscription: builder.mutation({
      query: (orderId) => ({
        url: `${apiRoutes.CREATESUBSCRIPTION}/${orderId}`,
        method: "POST",
        body: {},
      }),
    }),
    stripeCheckout: builder.mutation({
      query: (orderId) => ({
        url: `${apiRoutes.STRIPE_CHECKOUT}/${orderId}`,
        method: "POST",
        body: {},
      }),
    }),
    deviceStripeCheckout: builder.mutation({
      query: (orderId) => ({
        url: `${apiRoutes.DEVICESTRIPE}/${orderId}`,
        method: "POST",
        body: {},
      }),
    }),
    getAllOrders: builder.query({
      query: (params: any) => ({
        url: apiRoutes.GETALLORDER,
        params: params,
        method: "GET",
      }),
    }),
    getAllOrdersByUserId: builder.mutation({
      query: (params: any) => ({
        url: apiRoutes.GETALLORDER,
        params: params,
        method: "GET",
      }),
    }),
    updateOrderStatus: builder.mutation({
      query: ({ orderId, ...data }) => ({
        url: `${apiRoutes.UPDATEORDER}/${orderId}`,
        method: "PATCH",
        body: data,
      }),
    }),
    updateOrderCoupon: builder.mutation({
      query: (applycoupon) => ({
        url: apiRoutes.UPDATEORDERCOUPON,
        method: "POST",
        body: applycoupon,
      }),
    }),
    getOrderByOrderId: builder.query({
      query: (orderId: any) => ({
        url: `${apiRoutes.UPDATEORDER}/${orderId}`,
        method: "GET",
      }),
    }),
    importOrders: builder.mutation({
      query: (formData: any) => ({
        url: apiRoutes.IMPORTORDERS,
        method: "POST",
        body: formData,
      }),
    }),
    getStripeDetails: builder.mutation({
      query: (orderId: any) => ({
        url: `${apiRoutes.GETSTRIPEDETAILS}/${orderId}`,
        method: "GET",
      }),
    }),
    updateOrderPlan: builder.mutation({
      query: (data:any) => {
        const { orderId, ...rest } = data;
        return {
          url: `${apiRoutes?.UPDATEORDERPLAN}/${orderId}`,
          method: "POST",
          body: rest,
        };
      },
    }),
    deleteBeneficiary: builder.mutation({
        query: (data:any) => {
          const { orderId, ...rest } = data;
          return {
            url: `${apiRoutes?.ADDBENEFICIARY}/${orderId}`,
            method: "DELETE",
            body: rest,
          };
        },
      }),
  }),
});

export const {
  useUpdateOrderCouponMutation,
  useGetAllOrdersByUserIdMutation,
  useCreateSubscriptionMutation,
  useStripeCheckoutMutation,
  useDeviceStripeCheckoutMutation,
  useUpdateOrderStatusMutation,
  useGetAllOrdersQuery,
  useAddOrderAddressMutation,
  useGetOrderAddressMutation,
  useUpdateOrderAddressMutation,
  useGetStripeDetailsMutation,
  useGetOrderMutation,
  useAddBeneficiaryMutation,
  useUpdateBeneficiaryMutation,
  useGetOrderSummaryMutation,
  useGetOrderByOrderIdQuery,
  useImportOrdersMutation,
  useUpdateOrderPlanMutation,
  useDeleteBeneficiaryMutation,
} = orderApiSlice;
