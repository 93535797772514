import { Header } from ".././header/header";
import { Box, Card, Grid, Step, StepLabel, Stepper } from "@mui/material";
import { privacySteps } from "../../../constants/constant";
import { useDispatch, useSelector } from "react-redux";
import Buttons from "../button";
import { reviewCheck } from "../../../assets/images";
import { useEffect, useState } from "react";
import {
  useGetOrderMutation,
  useGetStripeDetailsMutation,
} from "../../../redux/services/orderApiSlice";
import { JourneyStatus, OrderStatus } from "../../../constants/enum";
import { Page } from "../../../routes/config";
import { handleErrors, updateSteps } from "../../../utils";
import { useNavigate } from "react-router";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./style.css";
import { setOrder } from "../../../redux/features/order/orderSlice";
import { hourGlass } from "../../../assets/gifs";
import Loader from "../loader/loader";
import { apiRoutes, baseURL } from "../../../config/api";
import { useGetSubscriptionPlanQuery } from "../../../redux/services/planApiSlice";
import { setAlert } from "../../../redux/features/alert/alertSlice";

var interval: any = null;

export const ReviewAndPay = () => {
  const { userInfo } = useSelector((state: any) => state.auth);
  const [showContinue, setShowContinue] = useState<any>(false);
  const [activePlan, setActivePlan] = useState<any>(false);
  const {
    data,
    isSuccess,
    refetch,
    isError,
    fulfilledTimeStamp,
    error,
    isLoading,
  } = useGetSubscriptionPlanQuery(
    {
      id: userInfo?.userId,
    },
    {
      skip: userInfo?.userId === undefined ? true : false,
    }
  );
  const [orders, ordersData] = useGetOrderMutation();
  const [dataOrders, setDataOrders] = useState<any>();
  const [stripeDetails, stripeDetailsData] = useGetStripeDetailsMutation();
  const { id } = useSelector((state: any) => state?.order);
  const { orderStatus, invoiceUrl } = useSelector((state: any) => state?.order);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [redirectLink, setRedirectLink] = useState<any>("");
  const [pending, setPending] = useState(true);
  const [couponDetails, setCouponDetails] = useState<any>();
  const activeStep = useSelector((state: any) => state.userSteps.activeSteps);

  const completedSteps = useSelector(
    (state: any) => state.userSteps.completedSteps
  );
  useEffect(() => {
    orders(userInfo?.userId);
  }, []);
  useEffect(() => {
    if (orderStatus === OrderStatus.CONFIRMED) {
      // setPending(false);
      updateSteps(JourneyStatus.COMPLETED, completedSteps, dispatch);
    } else {
      interval = setInterval(() => {
        orders(userInfo?.userId);
      }, 4000);
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleClick = () => {
    dispatch(
      setAlert({
        open: false,
        alertType: "",
        message: "",
      })
    );
    navigate(`${Page.MANAGEUSERS}?showGuide=true`);
  };

  const [planData, setPlanData] = useState<any>();
  const [appliedCouponDiscount, setAppliedCouponDiscount] = useState<any>(0);
  console.log(appliedCouponDiscount,"yriiieui")

  useEffect(() => {
    if (isSuccess && data) {
      if (data?.result && data?.result?.length > 0) {
        setCouponDetails(data?.result[0]?.couponDetails);
        if (data?.result[0]?.couponDetails?.type === "monthlyDiscount") {
          let value =
            (data?.result[0]?.subscriptionFee *
              data?.result[0]?.couponDetails?.value) /
            100;
          setAppliedCouponDiscount(value);
        }
        setPlanData(data?.result[0]);
        if (
          data?.result[0]?.status?.toLowerCase() === "active" ||
          data?.result[0]?.status?.toLowerCase() === "trialing"
        ) {
          setShowContinue(false);
          clearInterval(interval);
          setActivePlan(true);
        } else {
          setActivePlan(false);
        }
      }
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      handleErrors({ error, isError }, dispatch);
    }
  }, [isError]);

  useEffect(() => {
    if (ordersData.isSuccess) {
      setPending(true);

      if (ordersData?.data?.result && ordersData?.data?.result?.length > 1) {
        const { orderId, orderType, orderDate, orderStatus, invoiceUrl } =
          ordersData?.data?.result[0];
        dispatch(
          setOrder({
            id: orderId,
            type: orderType,
            date: orderDate,
            orderStatus: orderStatus,
            invoiceUrl: invoiceUrl,
          })
        );
        setPending(false);
        clearInterval(interval);
        updateSteps(JourneyStatus.COMPLETED, completedSteps, dispatch);
      } else {
        if (
          ordersData?.data?.result &&
          ordersData?.data?.result?.length === 1
        ) {
          const { orderId, orderType, orderDate, orderStatus, invoiceUrl } =
            ordersData?.data?.result[0];
          if (
            orderStatus === OrderStatus.CONFIRMED ||
            orderStatus === OrderStatus.INTRANSIT ||
            orderStatus === OrderStatus.DELIVERED
          ) {
            setDataOrders(ordersData?.data?.result);
            stripeDetails(id);
            dispatch(
              setOrder({
                id: orderId,
                type: orderType,
                date: orderDate,
                orderStatus: orderStatus,
                invoiceUrl: invoiceUrl,
              })
            );
            // setPending(false);
            clearInterval(interval);
            updateSteps(JourneyStatus.COMPLETED, completedSteps, dispatch);
          } else {
          }
        }
      }
    }
  }, [ordersData?.isSuccess]);

  useEffect(() => {
    if (stripeDetailsData?.isSuccess) {
      const { orderId, orderType, orderDate, orderStatus, invoiceUrl } =
        dataOrders[0];
      if (stripeDetailsData?.data && stripeDetailsData?.data?.result) {
        if (stripeDetailsData?.data?.result?.paymentRequired) {
          setShowContinue(true);
          setRedirectLink(stripeDetailsData?.data?.result?.paymentlink);
          setPending(false);
        } else {
          setShowContinue(false);
          setPending(false);
        }
      }
    }
  }, [stripeDetailsData?.isSuccess]);

  useEffect(() => {
    if (stripeDetailsData?.isError) {
      // setPending(false);
      stripeDetails(id);
      handleErrors(stripeDetailsData, dispatch);
    }
  }, [stripeDetailsData?.isError]);

  useEffect(() => {
    if (ordersData?.isError) {
      handleErrors(ordersData, dispatch);
    }
  }, [ordersData?.isError]);

  const handleContinue = () => {
    interval = setInterval(() => {
      refetch();
    }, 4000);
    window.open(redirectLink, "window", "popup");
  };

  return (
    <>
      <Loader show={pending} showImage={false} />
      <Header />
      <div className="sideStepper">
        <Grid container className="stepperGridCotainer">
          <Grid item xs={3.15} sm={3.15} md={3.15} lg={3.15} xl={3.15}>
            <Grid container className="stepperItem">
              <Grid item xs={12} className="privacyPadding">
                <Stepper
                  nonLinear
                  activeStep={activeStep}
                  orientation="vertical"
                  className="steeperLineHeight"
                >
                  {privacySteps.map((step, index) => (
                    <Step key={step.label} completed={completedSteps[index]}>
                      <StepLabel
                        StepIconComponent={
                          !completedSteps[index]
                            ? CheckCircleOutlineIcon
                            : CheckCircleIcon
                        }
                        color="inherit"
                      >
                        <div className="textLabel">
                          <span>{step.label}</span>
                        </div>
                        {activeStep < 2 ? (
                          <span className="stepDescription">
                            {step.description}
                          </span>
                        ) : (
                          <></>
                        )}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item xs={12} className="sidebarHelpContent">
                <div className="stepperSidebarBottomContent">
                  <span className="helpContact">
                    {" "}
                    <a
                      href="https://familyprohealth.com/faq"
                      className="addunderline"
                    >
                      Help
                    </a>{" "}
                    &nbsp;|&nbsp;{" "}
                    <a
                      href="https://familyprohealth.com/contact-us"
                      className="addunderline"
                    >
                      Contact us
                    </a>
                  </span>
                  <center className="fphOrigin">
                    ©2023 familyprohealth Inc. All rights reserved.
                  </center>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8.85} sm={8.85} md={8.85} lg={8.85} xl={8.85}>
            <Grid
              item
              xs={12}
              className={
                activeStep != 4 ? "boxConatiner" : "boxContainerWoPadding"
              }
            >
              <div className="reviewDiv">
                <Card
                  className={`${
                    pending ? "paymentPendingIndex" : ""
                  } reviewPayCard  paymentPendingCard`}
                  data-testid="reviewPayCard"
                >
                  <Grid
                    container
                    sx={{ mb: 5, display: "flex", justifyContent: "center" }}
                  >
                    {!pending ? (
                      <img src={reviewCheck} />
                    ) : (
                      // <AccessTimeFilledIcon className="pendingIcon" />
                      <Box width={"129px"} height={"90px"}>
                        <img src={hourGlass} width="129px" height="90px" />
                      </Box>
                    )}
                  </Grid>
                  <Grid container spacing={1} sx={{ justifyContent: "center" }}>
                    {!pending ? (
                      <>
                        <Grid item xs={12}>
                          <span className="paymentModalMessage">
                            {showContinue
                              ? `Thank you for one-time device payment. As per the banking guidelines, you will need to perform 3D secure authentication to setup autopay for the monthly subscription amount ${
                                  userInfo?.country === "IN" ? "INR" : "USD"
                                } ${(planData?.subscriptionFee - appliedCouponDiscount)?.toFixed(2)} ${userInfo?.country==="IN"?" + applicable GST": "+ Tax"}.`
                              : activePlan &&
                                couponDetails?.type === "freeTrial"
                              ? "Thank you for one-time device payment. Your subscription trial period has started. Please check your email for more details and remember to add your beneficiary and family member details to complete the setup!"
                              : "Thank you for your payment. Autopay for your subscription has been successfully setup. Please remember to add your beneficiary and family member details to complete the setup!"}
                          </span>
                        </Grid>
                        {/* <Grid item xs={12}>
                          <span className="paymentModalMessageConfirmation">
                            {" "}
                            A confirmation will be sent to your email (
                            {userInfo?.email}).
                          </span>
                        </Grid> */}
                        {invoiceUrl === null ? (
                          <Grid item xs={12}>
                            <p className="downloadPara">
                              (You can download invoice from billing history
                              section in <b>My Account</b>.)
                            </p>
                          </Grid>
                        ) : (
                          <></>
                        )}

                        {!showContinue ? (
                          <Grid
                            item
                            xs={10}
                            xl={4.5}
                            lg={5.5}
                            md={10}
                            sx={{ mt: 1.5 }}
                          >
                            <Buttons
                              onClick={handleClick}
                              variant="contained"
                              type="primary"
                              text="GO TO YOUR ACCOUNT"
                            />
                          </Grid>
                        ) : null}
                        {!showContinue ? (
                          invoiceUrl !== null ? (
                            <></>
                          ) : (
                            // <Grid
                            //   item
                            //   xs={10}
                            //   xl={4.5}
                            //   lg={5.5}
                            //   md={10}
                            //   sx={{ mt: 1.5 }}
                            // >
                            //   <a
                            //     href={
                            //       ordersData?.data?.result[0]?.invoiceUrl !==
                            //       null
                            //         ? `${baseURL}${apiRoutes.DOWNLOAD_ORDER_INVOICE}/${id}`
                            //         : ""
                            //     }
                            //     className="export-link"
                            //     target="_blank"
                            //     rel="noopener noreferrer"
                            //     download
                            //   >
                            //     <Buttons
                            //       variant="contained"
                            //       type="primary"
                            //       text="Download Invoice"
                            //       startIcon="download"
                            //     />
                            //   </a>
                            // </Grid>
                            <></>
                          )
                        ) : (
                          <Grid
                            item
                            xs={10}
                            xl={4.5}
                            lg={5.5}
                            md={10}
                            sx={{ mt: 1.5 }}
                          >
                            <Buttons
                              onClick={handleContinue}
                              variant="contained"
                              type="primary"
                              text="Continue"
                              // startIcon="download"
                            />
                          </Grid>
                        )}
                      </>
                    ) : (
                      <Grid item xs={12} className="pendingPadding">
                        <span className="text1 paymentPendingText">
                          Order is placed, Please wait while we get confirmation
                          from our payment partner.
                        </span>
                        <br></br>
                        <br />
                        <span className="pendingSubText">
                          (Do not close this window or click the back button)
                        </span>
                      </Grid>
                    )}
                  </Grid>
                </Card>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
