import {
  Box,
  Card,
  Divider,
  FormHelperText,
  Grid,
  InputAdornment,
  Popover,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import tickIcon from "../../../../assets/icons/tick.svg";
import Buttons from "../../../common/button";
import infoIcon from "../../../../assets/icons/info.svg";
import { Input } from "../../../common/input";
import { useDispatch, useSelector } from "react-redux";
import {
  useCreateSubscriptionMutation,
  useStripeCheckoutMutation,
} from "../../../../redux/services/orderApiSlice";
import {
  useGetOrderSummaryMutation,
  useUpdateOrderCouponMutation,
} from "../../../../redux/services/orderApiSlice";
import {
  setBeneficiaryData,
  setChange,
  setPlanDetail,
  setSelectedPlan,
} from "../../../../redux/features/user/beneficiarySlice";
import { setUserSteps } from "../../../../redux/features/userStep/userStepSlice";
import "./reviewPay.css";
import { CustomToolTip } from "../../../common/tooltip";
import {
  addressFormat,
  getCountryNameByCountryCode,
  handleErrors,
  handlePriceFormatting,
  phoneNumberAutoFormat,
} from "../../../../utils";
import { CheckOutlined } from "@mui/icons-material";
import Loader from "../../../common/loader/loader";
import { useGetCouponByIdMutation } from "../../../../redux/services/manageCouponApiSlice";
import { ConfirmModal } from "../../../common/confirmmodal";
import { clearOrderState } from "../../../../redux/features/order/orderSlice";

export const ReviewPay = (props: any) => {
  const { activeStep, completed } = props;
  const {
    beneficiaryData,
    beneficiaryCount,
    selectedPlanId,
    isChange,
    isCustomize,
  } = useSelector((state: any) => state?.beneficiary);
  const { id } = useSelector((state: any) => state.order);
  const { userInfo } = useSelector((state: any) => state.auth);

  const [orderSummary, orderSummaryData] = useGetOrderSummaryMutation();
  const [stripeCheckout, stripeCheckoutData] = useStripeCheckoutMutation();
  const [updateCoupon, updateCouponData] = useUpdateOrderCouponMutation();
  const [getCouponById, getCouponByIdInfo] = useGetCouponByIdMutation();
  const [couponCode, setCouponCode] = useState<any>("");
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [firstrender, setfirstRender] = useState(true);
  const [serror, setSerror] = useState(false);
  const [error, setError] = useState(false);
  const [trial, setTrial] = useState<any>();
  const [helperText, setHelperText] = useState(false);
  const [deleteCustomize, setDeleteCustomize] = useState(false);
  const [orderItem, setOrderItem] = useState<any>({})
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (couponCode !== "") {
      setError(false);
    } else {
      setfirstRender(true);
      setSerror(false);
    }
  }, [couponCode]);

  const [orderData, setOrderData] = useState<any>({
    address: [],
    beneficiary: [],
    orderInfo: {},
    plan: {},
    subscriber: {},
  });
  const dispatch = useDispatch();

  const handleClick = (type: any) => {
    if (type === "beneficiary") {
      if (isCustomize) {
        console.log(isCustomize, "tttttt");
        setDeleteCustomize(true);
      } else if (!isCustomize) {
        var newCompleted = { [0]: true, [1]: true };
        dispatch(
          setUserSteps({
            activeSteps: Object.keys(newCompleted)?.length,
            completedSteps: newCompleted,
            planSelected: false,
          })
        );
        dispatch(setChange(true));
      }
    } else if (type === "address") {
      var newCompleted1 = { [0]: true, [1]: true, [2]: true };
      dispatch(
        setUserSteps({
          activeSteps: Object.keys(newCompleted1)?.length,
          completedSteps: newCompleted1,
        })
      );
    }
  };

  const onConfirmCancelCustomize = () => {
    var newCompleted = { [0]: true, [1]: true };
    // dispatch(clearOrderState());
    dispatch(
      setUserSteps({
        activeSteps: Object.keys(newCompleted)?.length,
        completedSteps: newCompleted,
        planSelected: false,
      })
    );

    dispatch(setChange(true));
  };

  const onCancelCustomize = () => {
    setDeleteCustomize(false);
  };

  const handleCoupon = () => {
    updateCoupon({ orderId: id, couponCode });
  };

  const handleModify = () => {
    setShow(false);
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);


  useEffect(() => {
    if (updateCouponData?.isSuccess) {
      setSerror(false);
      setShow(true);
      orderSummary(id);
    }
  }, [updateCouponData?.isSuccess]);
  useEffect(() => {
    if (updateCouponData?.isError) {
      // handleErrors(updateCouponData, dispatch)
      setSerror(true);
    }
  }, [updateCouponData?.isError]);

  useEffect(() => {
    orderSummary(id);
  }, []);

  useEffect(() => {
    if (orderSummaryData?.isSuccess) {
      setOrderData(orderSummaryData?.data?.result);
      dispatch(setBeneficiaryData(orderSummaryData?.data?.result?.beneficiary));
      dispatch(setPlanDetail(orderSummaryData?.data?.result?.plan));
      dispatch(
        setSelectedPlan(
          orderSummaryData?.data?.result?.plan?.subscriptionPlanId
        )
      );
      const isAppliedCoupon =
        orderSummaryData?.data?.result?.orderInfo?.appliedCoupon;
      if (isAppliedCoupon !== null) {
        getCouponById(isAppliedCoupon);
      }
        setOrderItem(orderSummaryData?.data?.result?.orderItem)
    }
  }, [orderSummaryData?.isSuccess]);
  const [appliedCouponDiscount, setAppliedCouponDiscount] = useState<any>(0);

  useEffect(() => {
    if (getCouponByIdInfo?.isSuccess) {
      setTrial(getCouponByIdInfo?.data?.result?.type);
      if (getCouponByIdInfo?.data?.result?.type === "monthlyDiscount") {
        let value =
          ((orderData?.plan?.PartnerPlan?.length ? orderData?.plan?.PartnerPlan[0]?.recurringAmount + orderData?.plan?.recurringAmount :  orderData?.plan?.recurringAmount) *
            getCouponByIdInfo?.data?.result?.value) /
          100;
        setAppliedCouponDiscount(value);
      } else if (getCouponByIdInfo?.data?.result?.type === "freeTrial") {
        let value = getCouponByIdInfo?.data?.result?.value / 30;
        setAppliedCouponDiscount(value);
      }
    }
  }, [getCouponByIdInfo?.isSuccess]);

  console.log(appliedCouponDiscount, "apllllllllll");

  useEffect(() => {
    if (getCouponByIdInfo?.isError) {
      // setCouponCode("")
      setShow(false);
      setTrial(null);
      // setfirstRender(false);
      setSerror(false);
      setError(false);
    }
  }, [getCouponByIdInfo?.isError]);

  useEffect(() => {
    handleErrors(orderSummaryData, dispatch);
  }, [orderSummaryData?.isError]);

  const matches = useMediaQuery("(max-width:1300px)");

  const handleNextClick = () => {
    stripeCheckout(orderData?.orderInfo?.orderId);
  };

  useEffect(() => {
    if (stripeCheckoutData?.isSuccess) {
      setIsLoading(false);
      const { result } = stripeCheckoutData?.data;
      if (result?.links?.length != 0) {
        var redirectData = result?.links?.find(
          (data: any) => data?.rel == "approve"
        );
      }
      window.location.href = result?.url;
    }
  }, [stripeCheckoutData?.isSuccess]);

  useEffect(() => {
    if (stripeCheckoutData?.isLoading) {
      setIsLoading(true);
    }
  }, [stripeCheckoutData?.isLoading]);

  useEffect(() => {
    if (stripeCheckoutData?.isError) {
      handleErrors(stripeCheckoutData, dispatch);
      setIsLoading(false);
    }
  }, [stripeCheckoutData?.isError]);
  useEffect(() => {
    if (orderData?.orderInfo?.appliedCoupon !== null) {
      setCouponCode(orderData?.orderInfo?.appliedCoupon);
      setShow(true);
    } else {
      setShow(false);
    }
  }, [orderData]);

  useEffect(() => {
    if (serror || (!firstrender && show)) {
      setHelperText(true);
    } else if (couponCode === "") {
      setHelperText(false);
    } else setHelperText(false);
  }, [show, serror, couponCode]);

  const widthMatch = useMediaQuery("(max-width:900px)");

  const getOrderPricing = (orderData:any) => {
    const {plan, orderInfo} = orderData;
    let pricing = {
      subTotal: orderInfo?.subTotal,
      netAmount:orderInfo?.netAmount,
      totalAmount:orderInfo?.totalAmount,
      couponDiscount: orderInfo?.couponDiscount
    }

    if(orderInfo?.subTotal <= 0) {
      pricing['subTotal'] = plan?.PartnerPlan?.length ? plan?.PartnerPlan[0]?.recurringAmount + plan?.recurringAmount :  plan?.recurringAmount;
      if(trial === "monthlyDiscount"){
      pricing['couponDiscount'] = appliedCouponDiscount
      }
    }
    if(orderInfo?.netAmount <= 0) {
      pricing['netAmount'] = plan?.PartnerPlan?.length ? plan?.PartnerPlan[0]?.recurringAmount + plan?.recurringAmount : plan?.recurringAmount;
      if(trial === "monthlyDiscount"){
        pricing['netAmount'] =  pricing['subTotal'] - appliedCouponDiscount
      }
    }
    if(orderInfo?.totalAmount <= 0) {
      pricing['totalAmount'] = plan?.PartnerPlan?.length ? plan?.PartnerPlan[0]?.recurringAmount + plan?.recurringAmount :  plan?.recurringAmount;
      if(trial === "monthlyDiscount"){
        pricing['totalAmount'] =  pricing['totalAmount'] - appliedCouponDiscount
      }
    }
    return pricing;
  }

  const getBillingCycleInfo = (order:any) => {
    const {plan} = order;
    let text = "Monthly Subscription";
    let remarkText = "monthly"

    if(plan?.billingFrequency == "MONTH"){
      if(plan?.billingInterval == 3){
        text = "Quarterly Subscription"
        remarkText = "quarterly"
      } else if(plan?.billingInterval == 6){
        text = "6 Month Subscription"
        remarkText = "6 month"
      }
    } else if(plan?.billingFrequency == "YEAR"){
      text = "Yearly Subscription"
      remarkText = "yearly"
    }

    return {text, remarkText}

  }

  const getMonthlyFee =()=> {
    let subscriptionFee = orderData?.plan?.PartnerPlan?.length ?  orderData?.plan?.PartnerPlan[0]?.recurringAmount +  orderData?.plan?.recurringAmount :  orderData?.plan?.recurringAmount ;
    return subscriptionFee || 0
  }
  return (
    <>
      <Loader
        show={
          orderSummaryData?.isLoading ||
          stripeCheckoutData?.isLoading ||
          updateCouponData?.isLoading
        }
      />
      <Box marginTop={"14px"} className="mainBoxcContainer">
        <Grid container justifyContent={"center"}>
          <Grid xs={12} sm={12} md={12} lg={11.8} xl={11.8}>
            <Card className="review-card" data-testid="reviewPayCard">
              <Grid container justifyContent={"center"}>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={11}
                  lg={11.2}
                  xl={11.2}
                  className="reviewP-topBottom"
                >
                  <Grid container>
                    <Grid item xs={9.5} sm={9.5} md={9.5} lg={9.5} xl={9.5}>
                      <div className="content-title">
                        <span>
                          <img src={tickIcon} alt="TickIcon" />
                        </span>
                        <span className="plan-title">
                          Plan and Subscriber Details
                        </span>
                      </div>
                      <div className="select-plan">
                        <span className="faded-text">Selected Plan: </span>{" "}
                        <span className="text-premium">
                          {orderData?.plan?.name}{" "}
                          {isCustomize ? "- Customized" : ""}
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={2.5} sm={2.5} md={2.5} lg={2.5} xl={2.5}>
                      <span className="changeBtn">
                        <Buttons
                          variant="contained"
                          type="secondary"
                          text="CHANGE PLAN"
                          onClick={() => handleClick("beneficiary")}
                        />
                      </span>
                    </Grid>
                  </Grid>
                  <Grid container className="subscriberM-top">
                    <Grid
                      item
                      xs={11}
                      sm={11}
                      md={12}
                      lg={orderData?.beneficiary.length < 2 ? 6 : 3}
                      xl={orderData?.beneficiary.length < 2 ? 6 : 4}
                      className="benf-info"
                    >
                      <div className="pd">
                        <span className="faded-text"> Subscriber:</span>&nbsp;
                        <span className="text-premium">
                          {orderData?.subscriber?.name}
                        </span>
                      </div>
                      <div className="pd widthPara">
                        <CustomToolTip
                          title={orderData?.subscriber?.email}
                          placement="top"
                        >
                          <p className="tooltipParagrah slice">
                            <span className="faded-text">Email:</span> &nbsp;{" "}
                            <span className="text-color">
                              {orderData?.subscriber?.email}
                            </span>
                          </p>{" "}
                        </CustomToolTip>
                      </div>
                      <div className="pd">
                        <span className="faded-text">Billing country:</span>
                        &nbsp;
                        <span className="text-color">
                          {getCountryNameByCountryCode(
                            orderData?.subscriber?.country
                          )}
                        </span>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={orderData?.beneficiary.length < 2 ? 6 : 9}
                      xl={orderData?.beneficiary.length < 2 ? 6 : 8}
                      className="paddingtop-10"
                    >
                      <Grid container rowSpacing={1}>
                        {orderData?.beneficiary?.map(
                          (beneficiaryData: any, index: any) => {
                            return (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={orderData?.beneficiary.length < 2 ? 12 : 6}
                                xl={orderData?.beneficiary.length < 2 ? 12 : 6}
                                // lg={6}
                                // xl={6}
                                className="benf-info"
                              >
                                <div>
                                  <span className="faded-text">
                                    Beneficiary {index + 1}:
                                  </span>
                                  <span className="text-premium">
                                    {" "}
                                    {beneficiaryData?.fullName}
                                  </span>
                                </div>
                                <div>
                                  <span className="faded-text">
                                    Mobile&nbsp;Number:
                                  </span>
                                  <span className="text-color">
                                    &nbsp;{beneficiaryData?.countryCode}&nbsp;
                                    {phoneNumberAutoFormat(
                                      beneficiaryData?.mobileNumber
                                    )}
                                  </span>
                                </div>
                                {beneficiaryData?.dob ? (
                                  <div>
                                    <span className="faded-text">DOB:</span>{" "}
                                    <span className="text-color">
                                      {beneficiaryData?.dob}
                                    </span>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Divider className="margin-lr"></Divider>
                  {/* -------------------------Second Container------------------------- */}
                  <Grid container>
                    <Grid xs={9.5} sm={9.5} md={9.5} lg={9.5} xl={9.5}>
                      <Grid container className="pt-10">
                        <Grid item xs={12} sm={12} md={12} lg={5} xl={6}>
                          <div className="content-title">
                            <span>
                              <img src={tickIcon} alt="TickIcon" />
                            </span>
                            <span className="plan-title">Billing Address</span>
                          </div>
                          {orderData?.address?.map((data: any, index: any) => {
                            if (data.addressType == "billing") {
                              return (
                                <div className="billing-Address-review">
                                  <span className="text-premium">
                                    {orderData?.subscriber?.name} |{" "}
                                  </span>
                                  <span className="faded-text">
                                    Mobile&nbsp;Number:
                                  </span>
                                  <span className="text-color">
                                    &nbsp;{orderData?.subscriber?.countryCode}
                                    &nbsp;
                                    {phoneNumberAutoFormat(
                                      orderData?.subscriber?.mobileNumber
                                    )}
                                  </span>
                                  <br />
                                  <span>
                                    {addressFormat(data)}
                                    {/* {data?.address1}, {data?.address2},{" "}
                                    {data?.city}, {data?.state}, {data?.zipCode}
                                    , {data?.country} */}
                                  </span>
                                </div>
                              );
                            }
                          })}
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                          <div className="content-title">
                            <span>
                              <img src={tickIcon} alt="TickIcon" />
                            </span>
                            <span className="plan-title">{orderData?.orderInfo?.totalAmount ? "Shipping Address": "Beneficiary (Elder) Address"}</span>
                          </div>
                          {orderData?.address?.map((data: any, index: any) => {
                            if (data?.addressType == "shipping") {
                              return (
                                <div className="billing-Address-review">
                                  <span className="text-premium">
                                    {data?.fullName} |{" "}
                                  </span>
                                  <span className="faded-text">
                                    Mobile&nbsp;Number:
                                  </span>
                                  <span className="text-color">
                                    &nbsp;{data?.countryCode}&nbsp;
                                    {phoneNumberAutoFormat(data?.mobileNumber)}
                                    <br />
                                  </span>
                                  <span>
                                    {addressFormat(data)}
                                    {data?.address1}, {data?.address2},{" "}
                                    {data?.city}, {data?.state}, {data?.zipCode}
                                    , {data?.country}
                                  </span>
                                </div>
                              );
                            }
                          })}
                        </Grid> */}
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={2.5}
                      sm={2.5}
                      md={2.5}
                      lg={2.5}
                      xl={2.5}
                      className="last-col"
                    >
                      <span>
                        <Buttons
                          variant="contained"
                          type="secondary"
                          text="CHANGE ADDRESS"
                          onClick={() => handleClick("address")}
                        />
                      </span>
                    </Grid>
                  </Grid>
                  <Divider className="margin-lr"></Divider>
                  {/* ---------------------Third Container---------------------------------------- */}

                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                      <div className="content-title">
                        <span>
                          <img src={tickIcon} alt="TickIcon" />
                        </span>
                        <span className="plan-title">Payment Details</span>
                      </div>

                      <Grid
                        container
                        justifyContent={"center"}
                        className="secondInnerCard"
                      >
                        <Grid item xs={11} sm={11} md={11} lg={11.4} xl={11.4}>
                          <div>
                            <Card className="pay-card">
                              <Grid
                                container
                                justifyContent={"center"}
                                sx={{ pb: 3 }}
                              >
                                <Grid
                                  item
                                  xs={11}
                                  sm={11}
                                  md={11}
                                  lg={11.5}
                                  xl={11.5}
                                >
                                  <Grid container sx={{ mt: 2, mb: 1 }}>
                                    <Grid
                                      item
                                      xs={3}
                                      sm={3}
                                      md={3}
                                      lg={4}
                                      xl={4}
                                    >
                                      <span className="product-text">
                                        PRODUCT
                                      </span>
                                    </Grid>
                                    {!widthMatch ? (
                                      <Grid
                                        item
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        lg={4}
                                        xl={4}
                                      >
                                        <span className="product-text">
                                          REMARK
                                        </span>
                                      </Grid>
                                    ) : (
                                      <Grid
                                        item
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        lg={4}
                                        xl={4}
                                      ></Grid>
                                    )}
                                    <Grid
                                      item
                                      xs={6}
                                      sm={6}
                                      md={6}
                                      lg={4}
                                      xl={4}
                                      className="product-text priceLine"
                                    >
                                      <div>
                                        PRICE
                                        <span className="usd">
                                          &nbsp;(
                                          {userInfo?.country === "IN"
                                            ? "INR"
                                            : "USD"}
                                          )
                                        </span>
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Divider className="inerHrDivider" />
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={9}
                                      sm={9}
                                      md={3}
                                      lg={4}
                                      xl={4}
                                    >
                                      <p className="pr-text marginDefineTop">
                                        {getBillingCycleInfo(orderData)?.text}
                                        {/* Monthly Subscription */}
                                      </p>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={0}
                                      sm={0}
                                      md={6}
                                      lg={5}
                                      xl={5}
                                    >
                                      {!widthMatch ? (
                                        trial === "freeTrial" ? (
                                          <span className="reviewpr-text ">
                                            Your {appliedCouponDiscount} {(appliedCouponDiscount == 1) ? 'month' : 'months'} trial period will commence immediately. After the trial period
                                            your {getBillingCycleInfo(orderData)?.remarkText} subscription fee will
                                            be{" "}
                                            <span className="price-text">
                                              <b>
                                              {userInfo?.country === "IN"
                                                ? "INR"
                                                : "USD"}
                                              &nbsp;
                                              {handlePriceFormatting(
                                                getMonthlyFee(),
                                                userInfo?.country === "IN"
                                                  ? "IN"
                                                  : "US"
                                                  )} {" "}
                                            {userInfo?.country === "IN"
                                              ? " + applicable GST"
                                              : " + Tax"}{" "}
                                              </b>
                                              </span>
                                            . The subscription will continue to
                                            renew until you cancel.
                                          </span>
                                        ) : trial === "monthlyDiscount" ? (
                                          <span className="reviewpr-text ">
                                            Your {getBillingCycleInfo(orderData)?.remarkText} subscription fee will
                                            be{" "}
                                            {
                                              <span className="price-text">
                                                <b>
                                                {userInfo?.country === "IN"
                                                  ? "INR"
                                                  : "USD"}
                                                &nbsp;
                                                {handlePriceFormatting(
                                                  getMonthlyFee() -
                                                    appliedCouponDiscount,
                                                  userInfo?.country === "IN"
                                                    ? "IN"
                                                    : "US"
                                                )}
                                                {userInfo?.country === "IN"
                                              ? " + applicable GST "
                                              : " + Tax"} {" "}
                                              </b>
                                              </span>
                                            }{" "}
                                            after applying the discount,
                                            your subscription will continue to
                                            renew until you cancel.
                                          </span>
                                        ) : (
                                          <span className="reviewpr-text ">
                                            Your {getBillingCycleInfo(orderData)?.remarkText} subscription fee will
                                            be{" "}
                                            {
                                              <span className="price-text">
                                                <b>
                                                {userInfo?.country === "IN"
                                                  ? "INR"
                                                  : "USD"}
                                                &nbsp;
                                                {handlePriceFormatting(
                                                  getMonthlyFee(),
                                                  userInfo?.country === "IN"
                                                    ? "IN"
                                                    : "US"
                                                )}{" "}
                                            {userInfo?.country === "IN"
                                              ? " + applicable GST "
                                              : " + Tax"} {" "}
                                              </b>
                                              </span>
                                            }
                                            and will continue to renew until you
                                            cancel.
                                          </span>
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      sm={3}
                                      md={3}
                                      lg={3}
                                      xl={3}
                                      className="reviewpr-text priceLine"
                                    >
                                      <div className="price-text">
                                        {/* $ {orderData?.plan?.recurringAmount} */}
                                      </div>
                                    </Grid>
                                    {widthMatch ? (
                                      <>
                                        <Grid
                                          item
                                          xs={8}
                                          sm={8}
                                          md={8}
                                          lg={8}
                                          xl={8}
                                        >
                                          <span className="reviewpr-text ">
                                            <span className="fw-700 subscriberColumn2">
                                              Remark:&nbsp;
                                            </span>
                                            {trial === "freeTrial" ? (
                                              <span className="reviewpr-text ">
                                                Your {appliedCouponDiscount} {(appliedCouponDiscount == 1) ? 'month' : 'months'} trial period will commence
                                                immediately. After the trial
                                                period your monthly subscription
                                                fee will be{" "}
                                                <b>
                                                <span className="price-text">
                                                  {userInfo?.country === "IN"
                                                    ? "INR"
                                                    : "USD"}
                                                  &nbsp;
                                                  {handlePriceFormatting(
                                                    getMonthlyFee(),
                                                    userInfo?.country === "IN"
                                                      ? "IN"
                                                      : "US"
                                                  )}
                                                </span>{" "}
                                                {userInfo?.country === "IN"
                                                  ? " + applicable GST"
                                                  : " + Tax"} </b> {" "}
                                                .The subscription will continue
                                                to renew until you cancel.
                                              </span>
                                            ) : trial === "monthlyDiscount" ? (
                                              <span className="reviewpr-text ">
                                                Your {getBillingCycleInfo(orderData)?.remarkText} subscription fee
                                                will be{" "}
                                                {
                                                  <span className="price-text">
                                                    {userInfo?.country === "IN"
                                                      ? "INR"
                                                      : "USD"}
                                                    &nbsp;
                                                    {handlePriceFormatting(
                                                      getMonthlyFee() -
                                                        appliedCouponDiscount,
                                                      userInfo?.country === "IN"
                                                        ? "IN"
                                                        : "US"
                                                    )}
                                                  </span>
                                                }{" "}
                                                {userInfo?.country === "IN"
                                                  ? "+ applicable GST "
                                                  : "+ Tax"}{" "}
                                                after applying the discount,
                                                your subscription will continue
                                                to renew until you cancel.
                                              </span>
                                            ) : (
                                              <span className="reviewpr-text ">
                                                Your {getBillingCycleInfo(orderData)?.remarkText} subscription fee
                                                will be{" "}
                                                {
                                                  <span className="price-text">
                                                    {userInfo?.country === "IN"
                                                      ? "INR"
                                                      : "USD"}
                                                    &nbsp;
                                                    {handlePriceFormatting(
                                                      getMonthlyFee(),
                                                      userInfo?.country === "IN"
                                                        ? "IN"
                                                        : "US"
                                                    )}
                                                  </span>
                                                }{" "}
                                                {userInfo?.country === "IN"
                                                  ? "+ applicable GST "
                                                  : "+ Tax"}{" "}
                                                and will continue to renew until
                                                you cancel."
                                              </span>
                                            )}
                                          </span>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={4}
                                          sm={4}
                                          md={4}
                                          lg={4}
                                          xl={4}
                                        ></Grid>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {
                                      (orderData?.orderInfo?.totalAmount > 0) ? <>
                                      <Grid
                                      item
                                      xs={9}
                                      sm={9}
                                      md={3}
                                      lg={4}
                                      xl={4}
                                    >
                                      <div className="content-inner-title">
                                        <p className="pr-text marginDefineTop ">
                                          {orderData?.plan?.name} device bundle{" "}
                                          {/* <CustomToolTip
                                            title={orderData?.plan?.remarks}
                                            placement="top"
                                          > */}
                                          <img
                                            src={infoIcon}
                                            className="icon"
                                            alt="f"
                                            onMouseEnter={handlePopoverOpen}
                                            onMouseLeave={handlePopoverClose}
                                          />{" "}
                                            <Popover
                                              id="mouse-over-popover"
                                              sx={{
                                                pointerEvents: 'none',
                                              }}
                                              open={open}
                                              anchorEl={anchorEl}
                                              anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                              }}
                                              transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                              }}
                                              onClose={handlePopoverClose}
                                              disableRestoreFocus
                                            >{<div style={{padding:12, fontSize:12}} dangerouslySetInnerHTML={{__html: orderData?.plan?.remarks}}></div>}</Popover>
                                            
                                          {/* </CustomToolTip> */}
                                        </p>
                                        &nbsp;
                                      </div>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={0}
                                      sm={0}
                                      md={6}
                                      lg={4}
                                      xl={4}
                                    >
                                      {!widthMatch ? (
                                        <span className="reviewpr-text ">
                                          One-time fee  {userInfo?.country === "IN"
                                                  ? "including GST "
                                                  : null}
                                        </span>
                                      ) : (
                                        <></>
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      sm={3}
                                      md={3}
                                      lg={4}
                                      xl={4}
                                      className="reviewpr-text priceLine"
                                    >
                                      <div className="price-text alignCenter">
                                        {""}
                                        {handlePriceFormatting(
                                          orderData?.orderInfo?.subTotal,
                                          userInfo?.country === "IN"
                                            ? "IN"
                                            : "US"
                                        )}
                                      </div>
                                    </Grid>
                                    {widthMatch ? (
                                      <>
                                        <Grid
                                          item
                                          xs={8}
                                          sm={8}
                                          md={8}
                                          lg={8}
                                          xl={8}
                                        >
                                          <span className="reviewpr-text ">
                                            <span className="fw-700 subscriberColumn2">
                                              Remark:&nbsp;
                                            </span>{" "}
                                            One-time fee  {userInfo?.country === "IN"
                                                  ? "including GST "
                                                  : null}
                                          </span>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={4}
                                          sm={4}
                                          md={4}
                                          lg={4}
                                          xl={4}
                                        ></Grid>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                      </> : null
                                    }
                                    
                                  </Grid>
                                  <Divider className="inerHrDivider" />

                                  <Grid
                                    container
                                    justifyContent={"space-between"}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={5}
                                      xl={4}
                                      sx={{ mt: 3 }}
                                      className="reviewpr-text flex-dr"
                                    >
                                      <Grid
                                        className="couponAlign"
                                        container
                                        spacing={1}
                                        display={"flex"}
                                        justifyContent={"center"}
                                      >
                                        <Grid
                                          item
                                          xs={6}
                                          sm={6}
                                          md={7}
                                          lg={7.2}
                                          xl={7.2}
                                        >
                                          <Input
                                            placeholder="Have a Coupon Code?"
                                            label="COUPON"
                                            value={couponCode}
                                            error={serror || error}
                                            helperText={
                                              couponCode === "" &&
                                              firstrender === false ? (
                                                <FormHelperText className="couponErrorText slice">
                                                  <span>Enter Coupon Code</span>
                                                </FormHelperText>
                                              ) : updateCouponData?.isError &&
                                                firstrender === false &&
                                                couponCode !== "" &&
                                                serror === true ? (
                                                <FormHelperText className="couponErrorText slice">
                                                  <span>
                                                    Invalid Coupon Code
                                                  </span>
                                                </FormHelperText>
                                              ) : updateCouponData?.isSuccess &&
                                                show ? (
                                                <FormHelperText className="couponSuccessText slice">
                                                  <span>
                                                    Coupon Applied Successfully
                                                  </span>
                                                </FormHelperText>
                                              ) : (
                                                // setHelperText(false)
                                                ""
                                              )
                                            }
                                            disabled={show}
                                            customInput={
                                              show
                                                ? "width-50 couponInput"
                                                : "width-50"
                                            }
                                            labelClassName="inputInputBoxes"
                                            onChange={(e: any) => {
                                              setCouponCode(e?.target?.value);
                                            }}
                                            endAdornment={
                                              <InputAdornment
                                                className="checkIcon"
                                                position="end"
                                                sx={{
                                                  marginLeft: "-24px",
                                                  position: "absolute",
                                                  right: "12px",
                                                }}
                                              >
                                                {show ? (
                                                  <CheckOutlined className="checkIconDisable" />
                                                ) : (
                                                  ""
                                                )}
                                              </InputAdornment>
                                            }
                                          />
                                        </Grid>
                                        {show ? (
                                          <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={5}
                                            lg={4.8}
                                            xl={4}
                                          >
                                            <Buttons
                                              onClick={handleModify}
                                              variant="text"
                                              text="MODIFY"
                                              // className="modifyMarginTop"
                                              className={
                                                helperText
                                                  ? `modifyMarginTopAlign`
                                                  : `modifyMarginTop`
                                              }
                                              textClassName="modifyButton"
                                            />
                                          </Grid>
                                        ) : (
                                          <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={5}
                                            lg={4.8}
                                            xl={4}
                                          >
                                            <Buttons
                                              onClick={() => {
                                                if (couponCode === "") {
                                                  setHelperText(true);
                                                  setError(true);
                                                  setSerror(false);
                                                }
                                                setfirstRender(false);
                                                if (couponCode != "") {
                                                  handleCoupon();
                                                }
                                              }}
                                              variant="text"
                                              text="APPLY"
                                              // className="modifyMarginTop"
                                              className={
                                                helperText
                                                  ? `modifyMarginTopAlign`
                                                  : `modifyMarginTop`
                                              }
                                              textClassName="modifyButton"
                                            />
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Grid>
                                    {/* <Grid item xs={0} sm={0} md={0} lg={3} xl={4}></Grid> */}
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={5}
                                      xl={4}
                                      className="last-col"
                                    >
                                      <Grid container className="total-list">
                                        <Grid
                                          item
                                          xs={8}
                                          sm={9}
                                          md={8.5}
                                          lg={7}
                                          xl={7}
                                          className="price-label-list"
                                        >
                                          <span className="text-color">
                                            Subtotal:{" "}
                                          </span>
                                          <span className="text-color">
                                            Discount:
                                          </span>
                                          <span className="text-color">
                                            Net Amount:
                                          </span>

                                          {/* <span className="text-color">
                                            Tax{" "}
                                            <CustomToolTip
                                              title={
                                                "The tax is for the subscription only and is based on your billing location"
                                              }
                                              placement="top"
                                            >
                                              <img
                                                className="taxImg"
                                                src={infoIcon}
                                              />
                                            </CustomToolTip>{" "}
                                            :
                                          </span> */}
                                          <span className="text-color">
                                            Shipping Charges:
                                          </span>
                                          {
                                            (trial === 'freeTrial') ?
                                              (<>
                                                <span>
                                                  <b className="text-color">Total after trial:</b>
                                                </span>
                                                <span>
                                                  <b className="text-color">Total due today:</b>
                                                </span>
                                              </>) :
                                              (<span>
                                                <b className="text-color">Total:</b>
                                              </span>)
                                          }
                                        </Grid>
                                        <Grid
                                          item
                                          xs={4}
                                          sm={3}
                                          md={3.5}
                                          lg={5}
                                          xl={5}
                                          className="price-list"
                                        >
                                          <span>
                                            {handlePriceFormatting(
                                              getOrderPricing(orderData)?.subTotal,
                                              userInfo?.country === "IN"
                                                ? "IN"
                                                : "US"
                                            )}
                                          </span>
                                          <span>
                                            {" "}
                                            {handlePriceFormatting(
                                              getOrderPricing(orderData)?.couponDiscount,
                                              userInfo?.country === "IN"
                                                ? "IN"
                                                : "US"
                                            )}
                                          </span>
                                          <span>
                                            {handlePriceFormatting(
                                              getOrderPricing(orderData)?.netAmount,
                                              userInfo?.country === "IN"
                                                ? "IN"
                                                : "US"
                                            )}
                                          </span>
                                          {/* <span>
                                            {handlePriceFormatting(
                                              orderData?.orderInfo?.taxAmount,
                                              userInfo?.country === "IN"
                                                ? "IN"
                                                : "US"
                                            )}
                                          </span> */}
                                          <span>
                                            {handlePriceFormatting(
                                              orderData?.orderInfo
                                                ?.shippingCharges,
                                              userInfo?.country === "IN"
                                                ? "IN"
                                                : "US"
                                            )}
                                          </span>
                                          <span>
                                            <b>
                                              {" "}
                                              {handlePriceFormatting(
                                                getOrderPricing(orderData)?.totalAmount,
                                                userInfo?.country === "IN"
                                                  ? "IN"
                                                  : "US"
                                              )}
                                            </b>
                                          </span>
                                          {(trial === 'freeTrial' &&
                                            <span>
                                              <b>
                                                {handlePriceFormatting(
                                                  0,
                                                  userInfo?.country === "IN"
                                                    ? "IN"
                                                    : "US"
                                                )}
                                              </b>
                                            </span>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <span className="next-btn">
                                    <Buttons
                                      onClick={handleNextClick}
                                      disabled={isLoading}
                                      variant="contained"
                                      type="primary"
                                      text="Pay"
                                      className="nxt_Btn"
                                    />
                                  </span>
                                </Grid>
                              </Grid>
                            </Card>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ConfirmModal
        className="confirmModal"
        key="updatePlan"
        open={deleteCustomize}
        primaryText="This is Customize plan, if you change this then all data will be deleted."
        cancelButton="NO"
        confirmButton="YES"
        cancelCallback={onCancelCustomize}
        confirmCallback={onConfirmCancelCustomize}
      />
    </>
  );
};
