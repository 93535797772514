import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
  styled,
  Grid,
} from "@mui/material";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ModalProps } from "../../../models/common";
import "./style.css";
import Buttons from "../button";
import { Input } from "../input";
import cancelIcon from "../../../assets/icons/cancelIcon.svg"


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(4),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(3),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="modalCloseIcon"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon className="closeIcon"/>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export const Modal = (props: ModalProps) => {
  const { primaryText, open, handleClose,showDivider=true, children, className, modalContent,xs, sm, md, lg, xl} =
    props;

  return (
    <div>
      <BootstrapDialog
        // onClose={handleClose} ///clicking outside for modal close
        aria-labelledby="customized-dialog-title"
        open={open}
        className={className? `${className} input-padding modal-title`:"input-padding modal-title" }
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <Grid container alignItems="center" justifyContent="center">
            <Grid
              item
              xs={xs ? xs : 10}
              sm={sm ? sm : 10}
              md={md ? md : 10}
              lg={lg ? lg : 10}
              xl={xl ? xl : 8.7}
            >
              {primaryText}
            </Grid>
          </Grid>
        </BootstrapDialogTitle>
        {showDivider?<Grid container alignItems="center" justifyContent="center">
          <Grid
            item
            xs={xs ? xs : 10}
            sm={sm ? sm : 10}
            md={md ? md : 10}
            lg={lg ? lg : 10}
            xl={xl ? xl : 8.7}
          >
            <Divider className="modalDivider" />
          </Grid>
        </Grid>:null}
        <DialogContent className={modalContent}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid
              item
              xs={xs ? xs : 10}
              sm={sm ? sm : 10}
              md={md ? md : 10}
              lg={lg ? lg : 10}
              xl={xl ? xl : 8.7}
            >
              <span>{children}</span>
            </Grid>
          </Grid>
        </DialogContent>
            {/* <DialogActions>
            <Buttons type="secondary" variant="contained" onClick={cancelCallback} text={cancelButton}></Buttons>
            <Buttons type="primary" variant="contained" buttonType='submit' text={confirmButton}></Buttons>
          </DialogActions> */}
            {/* </Grid>
          </Grid> */}
      </BootstrapDialog>
    </div>
  );
};
