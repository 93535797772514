import { useLocation, useNavigate } from "react-router-dom";
import { backIcon } from "../../../../assets/icons";
import { Grid } from "@mui/material";
import { Table } from "../../../common/table";
import { useGetAllUsersofSubscriberMutation } from "../../../../redux/services/userApiSlice";
import { useEffect, useState } from "react";
import { UserRoles } from "../../../../constants/enum";
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { handleErrors, phoneNumberAutoFormat } from "../../../../utils";
import Loader from "../../../common/loader/loader";

export const ViewBeneficiary = () => {
  const navigate = useNavigate();
  const [rowData, setRowData] = useState<any>([]);
  const [rowCount, setRowCount] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const { state } = useLocation();
  const subscriberId = state?.userData?.User?.userId;
  const dispatch = useDispatch();

  //Api Integration
  const [getBeneficiary, getBeneficiaryInfo] =
    useGetAllUsersofSubscriberMutation();

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Name",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding topAlign",
      flex: 1,
      renderCell: (params) => {
        return <div> {params?.row?.User?.fullName}</div>;
      },
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      align: "left",
      cellClassName: "cell-padding topAlign",
      headerAlign: "left",
      minWidth: 180,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {" "}
            {params?.row?.User?.countryCode}&nbsp;
            {phoneNumberAutoFormat(params?.row?.User?.mobileNumber)}
          </div>
        );
      },
    },
    // {
    //   field: "email",
    //   cellClassName: "cell-padding topAlign",
    //   headerName: "Email Address",
    //   minWidth: 200,
    //   headerAlign: "left",
    //   align: "left",
    //   sortable: false,
    //   flex: 2,
    //   renderCell: (params) => {
    //     return <div> {params?.row?.User?.email}</div>;
    //   },
    // },
    {
      field: "createdAt",
      headerName: "Create Date",
      cellClassName: "cell-padding topAlign",
      headerAlign: "left",
      align: "left",
      headerClassName: "lineBreak",
      type: "number",
      minWidth: 200,
      sortable: false,
      flex: 1,
    },
    {
      field: "roleName",
      cellClassName: "cell-padding topAlign",
      headerName: "User Type",
      headerAlign: "left",
      align: "left",
      type: "number",
      minWidth: 250,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return <div> {params?.row?.roleName}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      cellClassName: "cell-padding topAlign",
      align: "center",
      type: "number",
      minWidth: 110,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.User?.status.toUpperCase() === "INVITED" ? (
              <span className="draftClass">INVITED</span>
            ) : params?.row?.User?.status.toUpperCase() === "ACTIVE" ? (
              <span className="activeClass">ACTIVE</span>
            ) : (
              <span className="DeactiveClass">Deactivated</span>
            )}
          </>
        );
      },
    },
  ];
  useEffect(() => {
    getBeneficiary({
      subscriberId: subscriberId,
      // page: paginationModel?.page + 1,
      // limit: paginationModel?.pageSize,
    });
  }, []);
  useEffect(() => {
    if (getBeneficiaryInfo?.isSuccess) {
      var data: any;
      if (state?.type === UserRoles?.BENEFICIARY) {
        data = getBeneficiaryInfo?.data?.result?.users?.filter((x: any) => {
          return x?.roleName === UserRoles.BENEFICIARY;
        });
      } else {
        data = getBeneficiaryInfo?.data?.result?.users?.filter((x: any) => {
          return x?.roleName === UserRoles.FAMILY;
        });
      }
      setRowData(
        data?.map((row: any, index: any) => ({
          id: index,
          createdAt: dayjs(row?.User?.createdAt)?.format("DD MMM, YYYY"),
          ...row,
        }))
      );
      setRowCount(data?.length);
    }
  }, [getBeneficiaryInfo?.isSuccess]);

  useEffect(() => {
    if (getBeneficiaryInfo?.isError) {
      handleErrors(getBeneficiaryInfo, dispatch);
    }
  }, [getBeneficiaryInfo?.isError]);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
  };

  const calHeight = () => {
    let targetCompo,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetCompo =
      window.document.querySelector<HTMLElement>(".viewBeneficiary");
    targetElement = targetCompo?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 25);
    setTableHeight(tableHeight);
  };
  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <>
      <Loader show={getBeneficiaryInfo?.isLoading} />
      <div className="history-main-title">
        <span className="history-backIcon-pd">
          <img src={backIcon} alt="backIcon" onClick={() => navigate(-1)} />
        </span>
        <span className="capitalise">
          {state?.userData?.User?.fullName} /{" "}
          <span className="sub-name">
            {" "}
            View{" "}
            {state?.type === UserRoles?.BENEFICIARY ? "Beneficiary" : "Family"}
          </span>
        </span>
      </div>
      <Grid marginTop="2.8vh">
        <div className="viewBeneficiary">
          <Table
            rows={rowData}
            columns={columns}
            rowCount={rowCount}
            isPagination={true}
            onPaginationModelChange={handlePaginationModelChange}
            // pagination={paginationModel}
            tableHeight={tableHeight}
          />
        </div>
      </Grid>
    </>
  );
};
