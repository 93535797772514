import { Country } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";


export const CONSTANT = "constant";
export const privacySteps = [
  { label: "Create Account", description: "" },
  {
    label: "Privacy\u00a0Policy, Terms\u00a0of\u00a0Use",
    description: "Please view and accept our Privacy Policy and TOU",
  },
  { label: "Select\u00a0Plan", description: "" },
  { label: "Add\u00a0Addresses", description: "" },
  { label: "Review\u00a0and\u00a0Pay", description: "" },
];

//dropdown

const countryCodes = Country.getAllCountries().sort((a, b) =>
  a?.name.localeCompare(b?.name)
);
console.log(countryCodes, "countryCodes");

const cNames = countryCodes.map((country: any) => {
  return {
    text: country?.name,
    value: country?.isoCode,
  };
});
console.log(cNames, "namess")


const cCodes = countryCodes.map((country: any) => {
  return {
    countryCode: country.isoCode,
    text:
      country?.phonecode[0] == "+"
        ? `${country?.name} (${country?.phonecode} ${country?.isoCode})`
        : `${country?.name} (+${country?.phonecode} ${country?.isoCode})`,
    value:
      country?.phonecode[0] == "+"
        ? `${country?.phonecode} ${country?.isoCode}`
        : `+${country?.phonecode} ${country?.isoCode}`,
  };
});

const BILLING_FREQUENCY = {
  MONTHLY: "month",
  YEARLY: "year",
  QUARTERLY: "quarter",
  HALFYEAR: "half_year"
}

const BILLING_FREQUENCY_MAP = [
  {
    value: BILLING_FREQUENCY.MONTHLY,
    frequency: "month",
    interval: 1
  },
  {
    value: BILLING_FREQUENCY.QUARTERLY,
    frequency: "month",
    interval: 3
  },
  {
    value: BILLING_FREQUENCY.HALFYEAR,
    frequency: "month",
    interval: 6
  },
  {
    value: BILLING_FREQUENCY.YEARLY,
    frequency: "year",
    interval: 1
  }
  

]


const PLAN_TYPES = {
  inHouse: "INHOUSE",
  partner: "PARTNER"
}
console.log(cCodes, "cCodes")

export { cCodes, cNames, BILLING_FREQUENCY, PLAN_TYPES, BILLING_FREQUENCY_MAP };
