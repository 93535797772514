import { apiSlice } from "./apiSlice"
import { apiRoutes } from "../../config/api"

export const notificationApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getNotifications: builder.query({
            query: ({userId,...data}) => ({
                url: `${apiRoutes.NOTIFICATION}/${userId}`,
                method: 'GET',
                params:data
            })
        }),
        updateNotifications: builder.mutation({
            query: ({userId,...data}) => ({
                url: `${apiRoutes.UPDATENOTIFICATION}/${userId}`,
                method: 'PUT',
                body:data
            })
        }),
    })
})

export const {useGetNotificationsQuery,useUpdateNotificationsMutation} = notificationApiSlice;