import { apiSlice } from "./apiSlice";
import { apiRoutes } from "../../config/api";

export const manageCouponApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createNewCoupons: builder.mutation({
      query: (formData: any) => ({
        url: apiRoutes.CREATENEWCOUPON,
        method: "POST",
        body: formData,
      }),
    }),
    getAllCoupons: builder.query({
      query: (params: any) => ({
        url: apiRoutes.GETALLCOUPONS,
        params: params,
        method: "GET",
      }),
    }),
    getCouponById: builder.mutation({
      query: (couponCode) => ({
        url: `${apiRoutes.CREATENEWCOUPON}/${couponCode}`,
        method: "GET",
      }),
    }),
    updateCouponById: builder.mutation({
      query: ({couponCode, data}) => {
        return {
          url: `${apiRoutes.CREATENEWCOUPON}/${couponCode}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    deleteCouponById: builder.mutation({
      query: (couponCode) => ({
        url: `${apiRoutes.CREATENEWCOUPON}/${couponCode}`,
        method: "DELETE",
      }),
    }),
  }),
});
export const {
  useCreateNewCouponsMutation,
  useGetAllCouponsQuery,
  useGetCouponByIdMutation,
  useUpdateCouponByIdMutation,
  useDeleteCouponByIdMutation
} = manageCouponApiSlice;
