import Buttons from "../../common/button";
import { Input } from "../../common/input";
import { Modal } from "../../common/modal/modal";
import { SelectInput } from "../../common/selectinput";
import { Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { RegExpressions } from "../../../constants/regExp";
import { BILLING_FREQUENCY } from "../../../constants/constant";

export const CreatePartnerModal = (props: any) => {
  const { open, onClose, billingCountry, setOpen, setPartnerDetails, setPartnerOptions, partnerDetails, setPartnerValue, partnerOptions, billingFrequency } = props;
  const { handleSubmit, control } = useForm({
    mode: "onChange" || "onSubmit",
    defaultValues: {
      billingCountry: billingCountry,
      name: partnerDetails?.name || "",
      unitAmount: partnerDetails?.unitAmount || "0",
      recurringAmount:partnerDetails?.recurringAmount ||  "0",
      fixedAmount: partnerDetails?.fixedAmount ||  "0",
      billingFrequency: billingFrequency
    },
  });

  const countryOptions = [
    { text: "India", value: "IN" },
    { text: "United States", value: "US" },
  ];


  const handlePartnerSubmit = (data:any) => {
    setPartnerDetails(data)
    setPartnerValue(JSON.stringify(data))
    setPartnerOptions((prevState:any)=>{
        let x = prevState?.filter((partner:any)=>(partner?.text != data?.name && partner?.text != partnerDetails?.name))
        return [...x,  {text: data?.name, value:JSON.stringify(data)}]
        })
    setOpen(false)
 }


  return (
    <Modal
      open={open}
      handleClose={onClose}
      showDivider={true}
      primaryText="Create Partner Service"
      xs={12}
      className={"modal-title outer-padding newPartnerCreate"}
    >
      <form onSubmit={handleSubmit(handlePartnerSubmit)}>
        <Grid container columnSpacing={4} sx={{mt:4}}>
          <Grid
            item
            sx={{ alignSelf: "self-end" }}
            xs={6}
            className="selectInput-title"
          >
            <Controller
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <SelectInput
                  {...field}
                  label="Select Billing Country"
                  required={true}
                  value={field.value ? `${field.value}` : ""}
                  placeholder="Select country"
                  error={Boolean(error)}
                  helperText={error?.message ? error?.message : " "}
                  onChange={(e: any) => {
                    field.onChange(e);
                  }}
                  inputRef={ref}
                  disabled={true}
                  optionValue={countryOptions}
                />
              )}
              name="billingCountry"
              control={control}
              rules={{
                required: "Please select billing country",
              }}
            />
          </Grid>
          <Grid item xs={6} className="selectInput-title">
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <SelectInput
                        {...field}
                        label="Billing Frequency"
                        value={field?.value}
                        placeholder="Select billing frequency"
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={(e: any) => {
                          field.onChange(e);
                        }}
                        inputRef={ref}
                        optionValue={[
                          { text: "1 Month", value: BILLING_FREQUENCY.MONTHLY },
                          {
                            text: "3 Months",
                            value: BILLING_FREQUENCY.QUARTERLY,
                          },
                          {
                            text: "6 Months",
                            value: BILLING_FREQUENCY.HALFYEAR,
                          },
                          {
                            text: "12 Months",
                            value: BILLING_FREQUENCY.YEARLY,
                          },
                        ]}
                        disabled={true}
                      />
                    )}
                    name="billingFrequency"
                    control={control}
                    rules={{
                      required: "Please select billing frequency.",
                    }}
                  />
                </Grid>
          <Grid item xs={6} sx={{ mt: 2 }} className="input-title">
            <Controller
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <Input
                  {...field}
                  label="Partner Offering Name"
                  required={true}
                  placeholder="Enter offering name name"
                  value={field.value}
                  error={Boolean(error)}
                  helperText={error?.message ? error?.message : " "}
                  onChange={field.onChange}
                />
              )}
              name="name"
              rules={{
                required: "Please enter offering name ",
                pattern: {
                  value:RegExpressions.noSpaceatBeginningAndEnd,
                  message: "Name can not start/end with spaces."
                }
              }}
              control={control}
            />
          </Grid>
          <Grid item xs={6} sx={{ mt: 2 }} className="input-title">
            <Controller
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <Input
                  {...field}
                  label="Unit Price"
                  required={true}
                  placeholder="Enter unit price of this offering"
                  value={field.value}
                  error={Boolean(error)}
                  helperText={error?.message ? error?.message : " "}
                  onChange={field.onChange}
                />
              )}
              name="unitAmount"
              rules={{
                required: "Please enter unit price",
                pattern: {
                  value: RegExpressions?.Zero,
                  message:
                    "Please enter valid unit price upto two decimals",
                },
              }
              
            }
              control={control}
            />
          </Grid>
          <Grid item xs={6} sx={{ mt: 2 }} className="input-title">
            <Controller
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <Input
                  {...field}
                  label="Subscription Price"
                  required={true}
                  placeholder="Enter subscription price of this offering"
                  value={field.value}
                  error={Boolean(error)}
                  helperText={error?.message ? error?.message : " "}
                  onChange={field.onChange}
                />
              )}
              name="recurringAmount"
              rules={{
                required: "Please enter offering name ",
                pattern: {
                  value: RegExpressions?.Zero,
                  message:
                    "Please enter valid subscription price upto two decimals",
                },
              }}
              control={control}
            />
          </Grid>
          <Grid item xs={6} sx={{ mt: 2 }} className="input-title">
            <Controller
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <Input
                  {...field}
                  label="Setup Fee"
                  required={true}
                  placeholder="Enter setup fee for this offering"
                  value={field.value}
                  error={Boolean(error)}
                  helperText={error?.message ? error?.message : " "}
                  onChange={field.onChange}
                />
              )}
              name="fixedAmount"
              rules={{}}
              control={control}
            />
          </Grid>
        </Grid>
        <Grid container columnSpacing={4} justifyContent={"center"}>
        <Grid item xs={4.5} sx={{ mr: 4 }}>
            <Buttons
              type="secondary"
              variant="contained"
              text="Back"
              onClick={()=>setOpen(false)}
            ></Buttons>
          </Grid>

          <Grid item xs={4.5}>
            <Buttons
              type="primary"
              variant="contained"
              buttonType="submit"
              text="Continue"
            ></Buttons>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};
