import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import "./accountSubscriptionPlan.css";
import backIcon from "../../../../assets/icons/backIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Table } from "../../../common/table";
import {
  GridColDef,
  GridMoreVertIcon,
  GridPaginationModel,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import {
  useActivateSubscriptionMutation,
  useCancelSubscriptionPlanMutation,
  useGetSubscriptionPlanQuery,
} from "../../../../redux/services/planApiSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { ConfirmModal } from "../../../common/confirmmodal";
import { Messages } from "../../../../constants/messages";
import { handleErrors, handlePriceFormatting } from "../../../../utils";
import { SelectPlanModal } from "./modal/selectPlanModal";
import Loader from "../../../common/loader/loader";
import { setPaginationFilter } from "../../../../redux/features/filters/filtersSlice";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import {
  Roles,
  SubscriptionChanges,
  UserRoles,
} from "../../../../constants/enum";
import {
  setCurrentPlanData,
  setSubscriptionReviewModal,
} from "../../../../redux/features/changeSubscription/changeSubscriptionSlice";
import { Page } from "../../../../routes/config";
import { ChangeSubscriptionModal } from "./modal/changeSubscriptionModal";
import { SubscriptionDeviceModal } from "./modal/subscriptionDeviceModal";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { setBeneficiaryCount } from "../../../../redux/features/user/beneficiarySlice";
import Buttons from "../../../common/button";
import { DeviceOrderConfirmationModal } from "./modal/deviceOrderConfirmationModal";

export const ManageSubscriptionPlan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElv, setAnchorElv] = useState(null);
  const [rowData, setRowData] = useState<any>([]);
  const [rowCount, setRowCount] = useState(0);
  const [selectedChangeType, setSelectedChangeType] = useState<any>(null);
  const [selectedSubscription, setSelectedSubscription] = useState<any>(null);
  const [changeSubscriptionModal, setChangeSubscriptionModal] = useState(false);
  const [changeDeviceTypeModal, setChangeDeviceTypeModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>();
  const [openModal, setOpenModal] = useState<any>(false);
  const [upgradePlanModal, setUpgradePlanModal] = useState<any>(false);
  const [subscriberId, setSubscriberId] = useState();
  const [tableHeight, setTableHeight] = useState(0);
  const { state, pathname } = useLocation();
  const [openActvateModal, setOpenActivateModal] = useState(false);
  const filtersData = useSelector((state: any) => state.filters);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: filtersData?.pageSize,
    page: filtersData?.page,
  });
  const [benCount, setBenCount] = useState<any>();
  const { id } = useSelector((state: any) => state.order);

  const [userId, setUserId] = useState();
  const { userInfo } = useSelector((state: any) => state?.auth);
  useEffect(() => {
    if (state?.view) {
      setUserId(state?.userData?.User?.userId);
    } else {
      setUserId(userInfo?.userId);
    }
  }, [state?.userData?.User?.userId, state?.view, userInfo?.userId]);

  // API INTIGRATION
  const [cancelPlan, cancelPlanInfo] = useCancelSubscriptionPlanMutation();
  const [activateSubscription, activateSubscriptionInfo] =
    useActivateSubscriptionMutation();

  const {
    data,
    isSuccess,
    refetch,
    isError,
    fulfilledTimeStamp,
    error,
    isLoading,
  } = useGetSubscriptionPlanQuery(
    {
      limit: paginationModel?.pageSize,
      page: paginationModel?.page + 1,
      id: userId,
    },
    {
      skip: userId == undefined ? true : false,
    }
  );
  console.log(data, "datalogs");

  const [currentPlan, setCurrentPlan] = useState<any>();

  useEffect(() => {
    if (isSuccess) {
      if (data && data?.result && data?.result?.length > 0) {
        setRowData(
          data?.result?.map((row: any, index: any) => ({
            id: index,
            startdate: dayjs(row.createdAt)?.format("DD MMM, YYYY"),
            lastUpdate: dayjs(row.updatedAt)?.format("DD MMM, YYYY"),
            appliedCoupo: "Count",
            ...row,
          }))
        );
        setRowCount(data?.count);
        let currPlanData = data?.result.filter(
          (row: any, index: any) => row?.status?.toLowerCase() === "active"
        );
        console.log(currPlanData, "currrrrrrrrrrrrrrr");
        if (currPlanData && currPlanData.length) {
          setCurrentPlan(currPlanData[0]);
          dispatch(
            setCurrentPlanData({
              currentPlanData: currPlanData[0],
            })
          );
        }
      }
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (cancelPlanInfo?.isSuccess) {
      setOpenModal(false);
      refetch();
    }
  }, [cancelPlanInfo?.isSuccess]);

  const [paymentModal, setPaymentModal] = useState<any>(false);

  useEffect(() => {
    if (pathname === Page.PAYMENTSUBSCRIPTIONPLAN) {
      dispatch(
        setSubscriptionReviewModal({
          subscriptionDeviceReview: false,
        })
      );
      setPaymentModal(true);
    }
  }, []);

  useEffect(() => {
    if (cancelPlanInfo?.isError) {
      setOpenModal(false);
      handleErrors(cancelPlanInfo, dispatch);
    }
  }, [cancelPlanInfo?.isError]);

  const cancelSubscriptionModal = (row: any) => {
    setSelectedRowData(rowData[row]);
    setOpenModal(true);
  };
  const activateSubscriptionModal = (row: any) => {
    setSelectedRowData(rowData[row]);
    setOpenActivateModal(true);
  };

  const labels: any = {
    "Blood Glucose": "Glucometer",
    "Blood Pressure": "BP Monitor",
    "Temperature": "Thermometer",
    "PulseOx": "Pulse Oximeter",
    "Weight": "Weighing Scale",
  };
  
  const columns: GridColDef[] = [
    {
      field: "startdate",
      headerName: "Start Date",
      minWidth: 200,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
    },
    {
      field: "lastUpdate",
      headerName: "Last update date",
      minWidth: 200,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Subscription Plan",
      minWidth: 200,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        return <div>{params?.row?.SubscriptionPlan?.name}</div>;
      },
    },
    {
      field: "recurringAmount",
      headerName:"Monthly Amount",
      minWidth: 250,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderHeader(params) {
        return <div className="specificheader">{params.colDef.headerName} <br/> (Before Taxes)</div>
      },
      renderCell: (params) => {
        console.log(params, "params")
        return (handlePriceFormatting( params?.row?.subscriptionFee, params?.row?.SubscriptionPlan?.currency) + " " + params?.row?.SubscriptionPlan?.currency)   
        // return (
        //   <div>
        //     {params?.row?.SubscriptionPlan?.currency}{" "}
        //     {params?.row?.subscriptionFee}
        //   </div>
        // );
      },
    },
    {
      field: "supportedDevices",
      headerName: "Supported Devices",
      minWidth: 200,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 3,
      renderCell: (params) => {
        let deviceString = params?.row?.devices
          ?.map((x: any) => labels[x?.DeviceType?.biometricType])
          .join(", ");
        return deviceString;
      },
    },

    {
      field: "allowedBeneficiary",
      headerName: "Supported Beneficiaries",
      minWidth: 200,
      headerAlign: "center",
      align: "center",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
    },

    {
      field: "appliedCoupon",
      headerName: "Applied Coupon",
      minWidth: 200,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
    },

    {
      field: "description",
      headerName: "Description",
      minWidth: 300,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        let planDetail = params?.row?.SubscriptionPlan?.description;
        let CounponDetails = params?.row?.couponDetails?.description;
        return (
        <div>
          {/* {planDetail && CounponDetails ? 
          `${planDetail} ${CounponDetails}` :
          planDetail ?
          (planDetail) :
          CounponDetails ?
          (CounponDetails):
          <span>-</span>
          } */}
          
          {params?.row?.SubscriptionPlan?.description}  <br/>
          {params?.row?.couponDetails?.description}
        </div>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.status === "CANCELLED" ? (
              <span className="draftClass">CANCELLED</span>
            ) : params?.row?.status === "APPROVAL_PENDING" ? (
              <span className="statusConfirmed">APPROVAL PENDING</span>
            ) : params?.row?.status.toUpperCase() === "ACTIVE" ? (
              <span className="activeClass">ACTIVE</span>
            ) : params?.row?.status === "trialing" ?
            (<span className="activeClass">ACTIVE</span>
            ) : params?.row?.status === "incomplete" ?
            (<span className="DeactiveClass"> Incomplete</span>
            ):
             (
              <span className="DeactiveClass">SUSPENDED</span>
            )
          }
          </>
        );
      },
    },
    // {
    //   field: "action",
    //   sortable: false,
    //   align: "left",
    //   cellClassName: state?.view ? "cell-padding" : "stickyCell cell-padding",
    //   headerAlign: "left",
    //   headerClassName: state?.view ? "" : "stickyColumn",
    //   headerName: "",
    //   width: state?.view ? 180 : 0.5,
    //   renderCell: (params) =>
    //     userInfo?.roleName !== UserRoles?.PLATFORM_SUPPORT ? (
    //       state?.view &&
    //         params?.row?.status !== ("SUSPENDED" || "CANCELLED") ? (
    //         <span
    //           className="cancelSubscription"
    //           onClick={(event: any) => cancelSubscriptionModal(params?.row?.id)}
    //         >
    //           Cancel Subscription
    //         </span>
    //       ) : state?.view && params?.row?.status === "SUSPENDED" ? (
    //         <span
    //           className="cancelSubscription"
    //           onClick={(event: any) =>
    //             activateSubscriptionModal(params?.row?.id)
    //           }
    //         >
    //           Activate Subscription
    //         </span>
    //       ) : params?.row?.status === "CANCELLED" ? (
    //         <></>
    //       ) : (
    //         <IconButton
    //           onClick={(event: any) => handleMenuClick(event, params?.row?.id)}
    //         >
    //           <GridMoreVertIcon />
    //         </IconButton>
    //       )
    //     ) : (
    //       <></>
    //     ),
    // },
  ];

  const handleMenuClick = (event: any, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowData(rowData[row]);
  };

  const handleClose = (event: any) => {
    setAnchorEl(null);
  };

  const cancelModal = () => {
    setOpenModal(true);
    setAnchorEl(null);
  };

  const chnageSubscriptionModal = () => {
    setChangeSubscriptionModal(true);
    setAnchorEl(null);
  };

  const changeDeviceModal = () => {
    setChangeDeviceTypeModal(true);
    setAnchorEl(null);
  };

  const handleChangeSubscription = (changeType: any) => {
    setSelectedChangeType(changeType);
    setUpgradePlanModal(true);
    setAnchorEl(null);
  };

  const handleUpgradeBeneficiary = (changeType: any) => {
    setSelectedChangeType(changeType);
    setUpgradePlanModal(true);
    setAnchorEl(null);
  };

  const handleDeviceIncrese = (changeType: any) => {
    setSelectedChangeType(changeType);
    setUpgradePlanModal(true);
    setAnchorEl(null);
  };

  const handledowngradeBeneficiary = (changeType: any) => {
    setSelectedChangeType(changeType);
    setUpgradePlanModal(true);
    setAnchorEl(null);
  };

  const onCancelcallBack = () => {
    setOpenModal(false);
  };

  const onConfirmCallBack = () => {
    const id = data?.result[0]?.userSubscriptionId;
    console.log(data?.result[0]?.userSubscriptionId, "uuuseeeerrridid");
    cancelPlan({
      userId: id,
      remarks: "items out of stock",
      status: "cancel",
    });
  };

  const calHeight = () => {
    let targetCompo,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetCompo = window.document.querySelector<HTMLElement>(
      ".subscriptionPlanTable"
    );
    targetElement = targetCompo?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 25);
    setTableHeight(tableHeight);
  };

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
    dispatch(setPaginationFilter(newPaginationModel));
  };

  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  const handleActivateSubscription = () => {
    setOpenActivateModal(true);
    setAnchorEl(null);
  };
  const onCancelActivatecallBack = () => {
    setOpenActivateModal(false);
  };
  const onConfirmActivateCallBack = () => {
    const id = data?.result[0]?.userSubscriptionId;
    activateSubscription({
      userId: id,
      remarks: "items out of stock",
      status: "active",
    });
  };
  useEffect(() => {
    if (activateSubscriptionInfo?.isSuccess) {
      setOpenActivateModal(false);
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: activateSubscriptionInfo?.data?.message,
        })
      );
      refetch();
    }
  }, [activateSubscriptionInfo?.isSuccess]);
  useEffect(() => {
    if (activateSubscriptionInfo?.isError) {
      handleErrors(activateSubscriptionInfo, dispatch);
    }
  }, [activateSubscriptionInfo?.isError]);

  const handleClick = (event: any) => {
    setAnchorElv(event.currentTarget);
  };

  const handleOff = (event: any) => {
    setAnchorElv(null);
  };

  const openAddBeneficiary = (type: any) => {
    setSelectedSubscription(type);
    setChangeDeviceTypeModal(true);
    setAnchorElv(null);
  };

  const openAddDevice = (type: any) => {
    setSelectedSubscription(type);
    console.log(type, "rrrrrrr");
    setChangeDeviceTypeModal(true);
  };

  const [existingUser, setExistingUser] = useState<any>(false);
  const exixstingSubscription = () => {
    setExistingUser(true);
  };

  const existingModalClose = () => {
    setExistingUser(false);
  };

  return (
    <>
      <Loader
        show={
          cancelPlanInfo?.isLoading ||
          isLoading ||
          activateSubscriptionInfo?.isLoading
        }
      />
      <Grid
        container
        className="topHeadingBorder"
        rowSpacing={1}
        columnSpacing={2}
        // justifyContent={"right"}
        data-testid="container1"
      >
        <Grid item xs={12} sm={12} md={12} lg={6.9} xl={7.9}>
          <div className="subscriptionHeading">
            {userInfo?.roleName === "Subscriber" ? (
              <img
                src={backIcon}
                alt="backIcon"
                className="backIcon-pd"
                onClick={() => navigate(Page.ACCOUNTSETTING)}
              />
            ) : (
              <img
                src={backIcon}
                alt="backIcon"
                className="backIcon-pd"
                onClick={() => navigate(-1)}
              />
            )}
            <span
              className="manageSubscriptionText capitalise"
              data-testid="myaccount"
            >
              {state?.view ? state?.userData?.User?.fullName : "My Account"}{" "}
              &nbsp; / &nbsp;{" "}
              <span className="manageBolo">Manage Subscription</span>
            </span>
          </div>
        </Grid>

        {data &&
          data?.result?.length > 0 &&
          userInfo?.roleName === UserRoles?.SUBSCRIBER && data?.result[0]?.status !== "incomplete" ? (
          data?.result[0]?.stripeSubscriptionId ? (
            <Grid item xs={12} sm={6} md={4} lg={2.6} xl={2.1}
              justifyContent={"flex-end"}
            >
              {/* <Button
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                className="updateButton"
              >
                Update Subscription
              </Button>
              <div>
                <Menu
                  anchorEl={anchorElv}
                  open={Boolean(anchorElv)}
                  onClose={handleOff}
                  className="optionButton"
                >
                  <MenuItem
                    onClick={() => {
                      openAddBeneficiary("Beneficiary");
                    }}
                  >
                    Add/Remove Beneficiaries
                  </MenuItem>
                  <Divider className="divider-mp" />
                  <MenuItem
                    onClick={() => {
                      openAddDevice("Devices");
                    }}
                  >
                    Add Device Type
                  </MenuItem>
                </Menu>
              </div> */}
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} md={4} lg={2.6} xl={2.1}
              justifyContent={"flex-end"}
            >
              {/* <Buttons
                variant="contained"
                type="primary"
                onClick={exixstingSubscription}
                text="Update Subscription"
              /> */}
            </Grid>
          )
        ) : null}

{data &&
          data?.result?.length > 0 &&
          userInfo?.roleName === UserRoles?.SUBSCRIBER ? (
          data?.result[0]?.status?.toLowerCase() === "active" || data?.result[0]?.status?.toLowerCase() === "trialing" ? (
            <Grid item xs={12} sm={5} md={3.6} lg={2.5} xl={2} >
              <Buttons
                variant="contained"
                type="secondary"
                className="subsctionCancelBtn"
                text="Cancel Subscription"
                onClick={cancelModal}
              />
            </Grid>
          ) : data?.result[0]?.status?.toLowerCase() === "suspended" || data?.result[0]?.status?.toLowerCase() === "canceled" ? (
            <Grid item xs={12} sm={5} md={3.6} lg={2.5} xl={2} >
              <Buttons
                variant="contained"
                type="secondary"
                className="subsctionCancelBtn"
                text="Reactivate Subscription"
                onClick={activateSubscriptionModal}
              />
            </Grid>
          ) : (
            <></>
          )
        ) : null}

      </Grid>

      <Grid container data-testid="container2" className="container2">
        <Grid item xs={12}>
          <div
            className="manageOrder subscriptionPlanTable"
            data-testid="manage-table"
          >
            <Table
              columns={columns}
              isPagination={true}
              rows={rowData}
              // columnVisibilityObject={{
              //   appliedCoupon:rowData[0]?.appliedCoupo?true:false
              // }}
              rowCount={rowCount}
              tableHeight={tableHeight}
              onPaginationModelChange={handlePaginationModelChange}
              pagination={paginationModel}
            />
          </div>
        </Grid>
      </Grid>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ width: "4000px" }}
      >
        {selectedRowData?.status === "SUSPENDED" ? (
          <span>
            <MenuItem onClick={handleActivateSubscription}>
              Activate Subscription
            </MenuItem>
          </span>
        ) : (
          <span>
            {" "}
            {/* <MenuItem onClick={()=>handleChangeSubscription("")}>
              Change Subscription
            </MenuItem>
            <Divider className="divider-mp" /> */}
            <MenuItem
              onClick={() =>
                handleUpgradeBeneficiary(
                  SubscriptionChanges.BENEFICIARY_INCREASE
                )
              }
            >
              Add Beneficiary
            </MenuItem>
            <Divider className="divider-mp" />
            <MenuItem
              onClick={() =>
                handleDeviceIncrese(SubscriptionChanges.DEVICE_INCREASE)
              }
            >
              Add Device Type
            </MenuItem>
            <Divider className="divider-mp" />
            <MenuItem
              onClick={() =>
                handledowngradeBeneficiary(
                  SubscriptionChanges.BENEFICIARY_DECREASE
                )
              }
            >
              Remove Beneficiary
            </MenuItem>
            <Divider className="divider-mp" />
            <MenuItem onClick={chnageSubscriptionModal}>
              Change Beneficiaries
            </MenuItem>
            <Divider className="divider-mp" />
            <MenuItem onClick={changeDeviceModal}>
              Change Subscription Plan
            </MenuItem>
            <Divider className="divider-mp" />
            <MenuItem onClick={cancelModal}>Cancel Subscription</MenuItem>
          </span>
        )}
      </Menu>

      <div className="modelModal" data-testid="confirm-modal">
        <ConfirmModal
          className="confirmModal"
          key="cancel"
          open={openModal}
          primaryText={
            "Are you sure ? Clicking on the confirm button will cancel your subscription. You may however use the App until the end of this bill cycle."
          }
          cancelButton="NO"
          confirmButton="YES"
          cancelCallback={onCancelcallBack}
          confirmCallback={onConfirmCallBack}
        />
      </div>
      {/* <SelectPlanModal
        currentPlan={currentPlan}
        selectedChangeType={selectedChangeType}
        showModal={upgradePlanModal}
        selectedRowData={selectedRowData}
        setShowModal={setUpgradePlanModal}
        refetchPlans={refetch}
      /> */}

      {/* <ChangeSubscriptionModal
        changeSubscriptionModal={changeSubscriptionModal}
        setChangeSubscriptionModal={setChangeSubscriptionModal}
      /> */}

      <SubscriptionDeviceModal
        refetchPlans={refetch}
        changeDeviceTypeModal={changeDeviceTypeModal}
        setChangeDeviceTypeModal={setChangeDeviceTypeModal}
        selectedSubscription={selectedSubscription}
        userSubscriptionData={data?.result}
      />

      <div className="modelModal" data-testid="confirm-modal">
        <ConfirmModal
          className="confirmModal"
          key="activate"
          open={openActvateModal}
          primaryText={"Are you sure you want to activate?"}
          cancelButton="NO"
          confirmButton="YES"
          cancelCallback={onCancelActivatecallBack}
          confirmCallback={onConfirmActivateCallBack}
        />
      </div>

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={existingUser}
        className="PaymentModal"
      >
        <div className="existingUserModal">
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <div className="supportModalConfirmation">
              <span className="existingMessage">
                Upgrade subscription functionality is not supported for your
                account. Please contact our support by email or phone mentioned
                below.{" "}
              </span>
              <div className="support">
              <span> Email Id: customercare@familyprohealth.com</span>
                <span>Technical support: +91 91089 45797</span>
                <span>Customer care: +1 833 776 4584</span>
              </div>
            </div>

            <Buttons
              onClick={existingModalClose}
              variant="contained"
              type="primary"
              text="OK"
              className="existingBtn"
            />
          </Grid>
        </div>
      </Dialog>
      <DeviceOrderConfirmationModal
        deviceOrderId={id}
        open={paymentModal}
        setOpen={setPaymentModal}
      />
    </>
  );
};
