import { createSlice } from "@reduxjs/toolkit";

interface orderState {
  id: string;
  date: string;
  type: string;
  orderStatus: string;
  invoiceUrl: null | string;
}

const initialState = {
  id: "",
  date: "",
  type: "",
  orderStatus: "",
  invoiceUrl: null,
} as orderState;

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrder(state, action) {
      state.id = action?.payload?.id;
      state.date = action?.payload?.date;
      state.type = action?.payload?.type;
      state.orderStatus = action?.payload?.orderStatus;
      state.invoiceUrl = action?.payload?.invoiceUrl;
    },
    clearOrderState: (state) => (state = initialState),
  },
});

export const { setOrder, clearOrderState } = orderSlice.actions;
export default orderSlice.reducer;
export const getOrderInfo = (state: any) => state.order;
