import { Grid } from "@mui/material";
import { Modal } from "../../../common/modal/modal";
import { CustomToolTip } from "../../../common/tooltip";
import { check } from "../../../../assets/icons";
import Buttons from "../../../common/button";
import { useDispatch, useSelector } from "react-redux";
import {
  handleErrors,
  handlePriceFormatting,
  updateSteps,
} from "../../../../utils";
import {
  setBeneficiaryCount,
  setIsCustomized,
  setSelectedPlan,
} from "../../../../redux/features/user/beneficiarySlice";
import { JourneyStatus } from "../../../../constants/enum";
import { useEffect, useState } from "react";
import { setUserSteps } from "../../../../redux/features/userStep/userStepSlice";
import { DataGrid, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { Controller, useForm } from "react-hook-form";
import { RegExpressions } from "../../../../constants/regExp";
import {
  useCustomisationPlanMutation,
  useCustomizePlanMutation,
} from "../../../../redux/services/planApiSlice";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import { setOrder } from "../../../../redux/features/order/orderSlice";

export const CustomePreviewModal = (props: any) => {
  const {
    previewModal,
    setPreviewModal,
    recuAmount,
    subscriptionPlanId,
    beneficiaryAmount,
    tableData,
    setCustomiseModalOpen,
    totalSum,
    customPlanData,
    benTableData,
  } = props;

  const { handleSubmit, control, setValue, getValues, reset } = useForm({
    mode: "onChange" || "onSubmit",
    defaultValues: {
      beneficiaryCount: "",
      recurringAmount: "",
      fixedAmount: "",
      planDetails: "",
      devices: [],
      deviceTableData: [],
    },
  });

  console.log(customPlanData, "kkkk");
  const userId = useSelector((state: any) => state?.auth?.userId);
  const orderId = useSelector((state: any) => state?.order?.id);
  const [recurringAmount, setRecurringAmount] = useState<any>();
  const [benCount, setBenCount] = useState<any>();

  const { beneficiaryData, beneficiaryCount, selectedPlanId, isChange } =
    useSelector((state: any) => state?.beneficiary);
  const { activeSteps, completedSteps, planSelected } = useSelector(
    (state: any) => state?.userSteps
  );
  const [updatePlanId, setUpdatePlanId] = useState("");
  const [updateBeneficiaryCount, setUpdateBeneficiaryCount] = useState(0);
  const [customizePlan, customizePlanData] = useCustomizePlanMutation();

  const dispatch = useDispatch();

  const closeModal = () => {
    setPreviewModal(false);
  };

  const handleSelectPlan = () => {
    let data = {
      orderId: orderId,
      userId: userId,
      plan: {
        id: subscriptionPlanId?.id,
        quantity: benCount,
      },
      devices: tableData?.map((item: any) => ({
        id: item?.deviceTypeId,
        quantity: item?.quantity,
      })),
    };
    customizePlan(data);
  };

  useEffect(() => {
    if (customizePlanData?.isSuccess) {
      if (customizePlanData?.isSuccess) {
        const orderData = customizePlanData?.data?.result;
        dispatch(
          setAlert({
            open: true,
            alertType: "success",
            message: "Plan is selected successfully",
          })
        );
        dispatch(
          setOrder({
            id: orderData?.orderId,
            date: orderData?.orderDate,
            type: orderData?.orderType,
          })
        );
        dispatch(setBeneficiaryCount(benCount));
        dispatch(setIsCustomized(true));
        if (isChange) {
          updateSteps(JourneyStatus?.ADD_ADDRESS, completedSteps, dispatch);
        } else {
          updateSteps(JourneyStatus?.ADD_BENEFICIARY, completedSteps, dispatch);
        }
        setPreviewModal(false);
        setCustomiseModalOpen(false);
      }
    }
  }, [customizePlanData?.isSuccess]);

  useEffect(() => {
    if (customizePlanData?.isError) {
      handleErrors(customizePlanData, dispatch);
    }
  }, [customizePlanData?.isError]);

  useEffect(() => {
    if (benTableData && benTableData?.length) {
      let data = benTableData[0]?.recurring + benTableData[1]?.recurring;
      let benCount =
        parseInt(benTableData[0]?.beneficiaryCount) +
        parseInt(benTableData[1]?.beneficiaryCount);
      setBenCount(benCount);
      setRecurringAmount(data);
    }
  }, [benTableData]);

  const labels: any = {
    "Blood Glucose": "Glucometer",
    "Blood Pressure": "BP Monitor",
    "Temperature": "Thermometer",
    "PulseOx": "Pulse Oximeter",
    "Weight": "Weighing Scale",
  };

  const columns1: GridColDef[] = [
    {
      field: "text",
      headerName: "Device Type",
      renderHeader: (params: GridColumnHeaderParams) => <b>Device Type</b>,
      minWidth: 100,
      headerAlign: "left",
      align: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        return(
          <>
          <span> {labels[params?.row?.biometricType]}</span>
          </>
        )
         
      },
    },
    // {
    //   field: "description",
    //   headerName: "Model No.",
    //   minWidth: 160,
    //   headerAlign: "left",
    //   align: "left",
    //   sortable: false,
    //   cellClassName: "cell-padding",
    //   flex: 4,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         <CustomToolTip title={params?.row?.model} placement="top">
    //           <p className="textSlice"> {params?.row?.model} </p>
    //         </CustomToolTip>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "qty",
      headerName: "Quantity",
      renderHeader: (params: GridColumnHeaderParams) => <b>Quantity</b>,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        console.log(params?.row?.quantity, "quantity");
        return params?.row?.quantity;
        // <div>
        //   <Controller
        //     render={({
        //       field: { ref, ...field },
        //       fieldState: { invalid, error },
        //     }) => (
        //       <input
        //         defaultValue={1}
        //         value={params?.row?.quantity}
        //         className="inputBox inputDisabled"
        //         type="number"
        //         name="quantity"
        //         disabled
        //         min={1}
        //         max={500}
        //         required
        //       />
        //     )}
        //     name="deviceTableData"
        //     rules={{
        //       pattern: {
        //         value: RegExpressions?.five,
        //         message: "not allowed",
        //       },
        //     }}
        //     control={control}
        //   />
        // </div>
      },
    },
  ];

  return (
    <>
      <Modal
        handleClose={closeModal}
        open={previewModal}
        className="input-padding modal-title outer-pd-device reviewOrderModal"
        primaryText={`${customPlanData?.data?.result?.name} - Customized`}
      >
        <Grid container justifyContent={"center"} sx={{ mt: 2 }}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="plan-box previewPlanBox"
          >
            <Grid container sx={{ mt: 5, mb: 5 }} justifyContent="center">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {/* ------------------------------------------ */}
                <Grid container justifyContent={"center"}>
                  <Grid item lg={10} md={11} sm={11} xs={11}>
                    <Grid container>
                      <Grid item lg={7} md={7} sm={5.9} xs={5}>
                        <span
                          // className="fontWeight color"
                          className="fontWeight planCardHeading"
                        >
                          Monthly Subscription - Customized
                        </span>
                        <br />
                        <span className="normal-font textCapital">
                          <CustomToolTip
                            title={`Supports upto
                                ${benCount}
                                beneficiaries`}
                            placement="top"
                          >
                            <p className="slice m-2">
                              includes{" "}
                              <span className="supportBen">
                                {benCount} beneficiaries
                              </span>
                            </p>
                          </CustomToolTip>
                        </span>
                      </Grid>
                      <Grid
                        item
                        lg={5}
                        md={5}
                        sm={6.1}
                        xs={7}
                        className="plansAmount"
                      >
                        <span>
                          <sup className="currency">
                            {customPlanData?.data?.result?.currency}
                          </sup>
                          <span className="recurringAmount color1">
                            {handlePriceFormatting(
                              recurringAmount,
                              customPlanData?.data?.result?.currency
                            )}
                          </span>
                          <sub className="month">per month</sub>
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* -------------------------------------- */}
                <Grid container justifyContent={"center"} sx={{ mt: 0 }}>
                  <Grid item lg={10} md={10.8} sm={10.8} xs={10.8}>
                    <Grid container>
                      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                        <Grid container>
                          <Grid item lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                            <span>
                              <img src={check}></img>
                            </span>
                          </Grid>
                          {/* <Grid item lg={0.5} md={0.5} sm={0.5} xs={0.5}></Grid> */}
                          <Grid item lg={11.5} md={11.5} sm={11.5} xs={11.5}>
                            <div>
                              <span className=" fontWeight capitalise radioPlanName">
                                {customPlanData?.data?.result?.name}
                              </span>
                              {" - "}
                              <span>
                                <span className="">
                                  {customPlanData?.data?.result?.currency}{" "}
                                  {handlePriceFormatting(
                                    totalSum,
                                    customPlanData?.data?.result?.currency
                                  )}
                                </span>{" "}
                                one-time fee
                              </span>
                              <br />
                              <span className="deviceLine">
                                (includes{" "}
                                {customPlanData?.data?.result?.devices
                                  ?.map((device: any, index: any) => {
                                    return labels[device?.biometricType];
                                  })
                                  ?.join(", ")}
                                )
                              </span>
                            </div>
                            {/* <span className="fontWeight capitalise">
                              {customPlanData?.data?.result?.name}
                            </span>{" "}
                            <span>
                              (
                              {customPlanData?.data?.result?.devices
                                ?.map((device: any, index: any) => {
                                  return device?.biometricType;
                                })
                                ?.join(",")}
                              )&nbsp;-&nbsp;
                            </span>
                            <span>
                              one-time cost of{" "}
                              <span className="text-premium-h">
                                {customPlanData?.data?.result?.currency}{" "}
                                {totalSum}.
                              </span>{" "}
                            </span> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      justifyContent={"center"}
                      className="secondInnerCard"
                    >
                      <Grid item xs={10} sx={{ mt: 2 }}>
                        {/* <p>Device </p> */}
                        <div className="createPlanTable ">
                          <div style={{ width: "100%" }} className="totalData">
                            <DataGrid
                              // paginationMode="server"
                              // autoPageSize
                              columns={columns1}
                              hideFooterPagination
                              hideFooter
                              disableRowSelectionOnClick
                              disableColumnFilter
                              disableColumnSelector
                              disableColumnMenu
                              sx={{
                                "& .MuiDataGrid-row:hover": {
                                  backgroundColor: "white",
                                },
                              }}
                              autoHeight
                              rows={tableData}
                              getRowId={(device) => device?.id}
                            />
                            {/* <div className="total">
                            <span>TOTAL &nbsp;: &nbsp;</span>
                            <span>{totalSum}</span>
                          </div> */}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={2} sx={{ mt: 2 }}></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 5 }} justifyContent={"flex-end"}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={6} xs={6}></Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Buttons
                  type="secondary"
                  variant="contained"
                  text="CANCEL"
                  onClick={closeModal}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={3} xs={3}>
                <Buttons
                  type="primary"
                  variant="contained"
                  text="NEXT"
                  onClick={handleSelectPlan}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};
