import {
  Grid,
  Card,
  Box,
  FormControlLabel,
  FormGroup,
  Radio,
  Tabs,
  Tab,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  useGetAllPlansQuery,
  useSelectPlansMutation,
} from "../../../../redux/services/planApiSlice";
import Buttons from "../../../common/button";
import { check } from "../../../../assets/icons";
import { useEffect, useState } from "react";
import { setOrder } from "../../../../redux/features/order/orderSlice";
import {
  setBeneficiaryCount,
  setBeneficiaryData,
  setIsCustomized,
  setSelectedPlan,
} from "../../../../redux/features/user/beneficiarySlice";
import { setUserSteps } from "../../../../redux/features/userStep/userStepSlice";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import "./selectPlan.css";
import { handleErrors, updateSteps } from "../../../../utils";
import Loader from "../../../common/loader/loader";
import { Messages } from "../../../../constants/messages";
import { ConfirmModal } from "../../../common/confirmmodal";
import { JourneyStatus } from "../../../../constants/enum";
import { CustomiseModal } from "./customiseModal";
import PlanDisplayCard from "../../manageplans/planDisplayCard";
import { BILLING_FREQUENCY } from "../../../../constants/constant";

export const ChoosePlan = (props: any) => {
  const { completed, activeStep } = props;
  const userId = useSelector((state: any) => state?.auth?.userId);
  const { activeSteps, completedSteps, planSelected } = useSelector(
    (state: any) => state?.userSteps
  );

  const {
    beneficiaryData,
    beneficiaryCount,
    selectedPlanId,
    isChange,
    isCustomize,
  } = useSelector((state: any) => state?.beneficiary);
  console.log(isCustomize, "isCustomize");
  const orderId = useSelector((state: any) => state?.order?.id);
  const { userInfo } = useSelector((state: any) => state?.auth);
  const dispatch = useDispatch();
  const [updatePlanId, setUpdatePlanId] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [updateBeneficiaryCount, setUpdateBeneficiaryCount] = useState(0);
  const [customiseModalOpen, setCustomiseModalOpen] = useState(false);
  const [deleteCustomize, setDeleteCustomize] = useState(false);
  const [choosedPlan, setChoosedPlan] = useState<any>();
  const [selectedFrequency, setSelectedFrequency] = useState(BILLING_FREQUENCY.QUARTERLY)
  const [availableFequencies, setFrequencies] = useState<any[]>([])
  const [displayPlans, setDisplayPlans] = useState<any[]>([])

  //API Integration
  const {
    data,
    isSuccess,
    refetch,
    isError,
    fulfilledTimeStamp,
    error,
    isLoading,
  } = useGetAllPlansQuery({
    isDraft: false,
    planType: "INHOUSE",
    isActive: true,
    // groupPlans: true,
    billingCountry: userInfo?.country === "IN" ? "IN" : "US",
  });
  const [selectPlan, selectPlanData] = useSelectPlansMutation();




  const handleSelectPlan = (planId: any, count: any) => {
    if (selectedPlanId !== planId && isChange) {
      // if (beneficiaryData?.length) {
      //   setConfirmModal(true);
      // } else {
      const plandata = {
        planId: planId,
        userId: userId,
        orderId: orderId,
      };
      dispatch(setBeneficiaryCount(count));
      selectPlan(plandata);
      dispatch(setSelectedPlan(planId));
      setConfirmModal(false);
      // }
      setUpdatePlanId(planId);
      setUpdateBeneficiaryCount(count);
    } else if (selectedPlanId === planId && isChange) {
      // dispatch(
      //   setUserSteps({
      //     activeSteps: activeSteps,
      //     completedSteps: completedSteps,
      //     planSelected: true,
      //   })
      // );
      if (isCustomize) {
        const plandata = {
          planId: planId,
          userId: userId,
          orderId: orderId,
        };
        dispatch(setBeneficiaryCount(count));
        selectPlan(plandata);
        dispatch(setSelectedPlan(planId));
      } else {
        updateSteps(JourneyStatus?.ADD_ADDRESS, completedSteps, dispatch);
      }
    } else {
      const plandata = {
        planId: planId,
        userId: userId,
        orderId: orderId,
      };
      dispatch(setBeneficiaryCount(count));
      selectPlan(plandata);
      dispatch(setSelectedPlan(planId));
    }
  };
  useEffect(() => {
    if (selectPlanData?.isSuccess) {
      const orderData = selectPlanData?.data?.result;
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Plan is selected successfully",
        })
      );
      dispatch(
        setOrder({
          id: orderData?.orderId,
          date: orderData?.orderDate,
          type: orderData?.orderType,
        })
      );
      // dispatch(
      //   setUserSteps({
      //     activeSteps: activeSteps,
      //     completedSteps: completedSteps,
      //     planSelected: true,
      //   })
      // );
      dispatch(setIsCustomized(false));
      if (isChange) {
        updateSteps(JourneyStatus?.ADD_ADDRESS, completedSteps, dispatch);
      } else {
        updateSteps(JourneyStatus?.ADD_BENEFICIARY, completedSteps, dispatch);
      }
    }
  }, [selectPlanData?.isSuccess]);

  useEffect(() => {
    handleErrors({ error, isError }, dispatch);
  }, [isError]);

  useEffect(() => {
    handleErrors(selectPlanData, dispatch);
  }, [selectPlanData?.isError]);
  const onCancelUpdate = () => {
    setConfirmModal(false);
  };
  const onConfirmUpdate = () => {
    dispatch(
      setUserSteps({
        activeSteps: activeSteps,
        completedSteps: completedSteps,
        planSelected: true,
      })
    );
    dispatch(setBeneficiaryCount(updateBeneficiaryCount));
    dispatch(setSelectedPlan(updatePlanId));
    dispatch(setBeneficiaryData([]));
  };
  useEffect(() => {
    if (planSelected) {
      setConfirmModal(false);
    }
  }, [planSelected]);

  const [beneficiaryTotal, setBeneficiaryTotal] = useState<any>();
  const [id, setId] = useState<any>();

  const handleCostomiseButton = (planId: any, count: any) => {
    // if(isCustomize){
    //   console.log(isCustomize, "tttttt")
    //   setDeleteCustomize(true);
    // } else if(!isCustomize){
    //   setCustomiseModalOpen(true)
    // }
    setCustomiseModalOpen(true);

    setId({ id: planId });
    setBeneficiaryTotal(count);
  };

  const onConfirmCancelCustomize = () => {
    setCustomiseModalOpen(true);
  };

  const onCancelCustomize = () => {
    setDeleteCustomize(false);
  };



  const handleFrequencySelect = (e:any,v:any) => {
    setSelectedFrequency(v)
  }

const sorterFunction = ( a:any, b:any ) => {
  if ( a.recurringAmount+ (a?.partnerOffering?.recurringAmount || 0) < b.recurringAmount+ (b?.partnerOffering?.recurringAmount || 0) ){
    return -1;
  }
  if ( a.recurringAmount+ (a?.partnerOffering?.recurringAmount || 0) > b.recurringAmount+ (b?.partnerOffering?.recurringAmount || 0) ){
    return 1;
  }
  return 0;
}

  useEffect(()=>{
    if(data?.result?.length){
      let monthlyPlans = data?.result?.filter((pl:any)=>pl?.billingFrequency == BILLING_FREQUENCY.MONTHLY)?.sort(sorterFunction)
      let quarterlyPlans = data?.result?.filter((pl:any)=>pl?.billingFrequency ==  BILLING_FREQUENCY.QUARTERLY)?.sort(sorterFunction)
      let halfYearPlans = data?.result?.filter((pl:any)=>pl?.billingFrequency ==  BILLING_FREQUENCY.HALFYEAR)?.sort(sorterFunction)
      let annualPlans = data?.result?.filter((pl:any)=>pl?.billingFrequency ==  BILLING_FREQUENCY.YEARLY)?.sort(sorterFunction)

      let filteredPlans = [
        ...monthlyPlans,
        ...quarterlyPlans,
        ...halfYearPlans,
        ...annualPlans
      ]
      setDisplayPlans(filteredPlans)
    }

  },[data, selectedFrequency])
  return (
    <>
      <Loader show={selectPlanData?.isLoading || isLoading} />

        {/* <Grid container className="BillingSchemeSelection">
          <Grid item  xl={1.8} md={4} lg={3} sm={4} xs={5} style={{display:"flex",paddingTop:"10px"}}>

            <span style={{alignSelf:"center"}}>Choose Bill Cycle: </span>
          </Grid>
          <Grid item xl={6} md={6} lg={6} sm={6} xs={6}>
      
      <Tabs value={selectedFrequency} onChange={handleFrequencySelect} sx={{width:"100%"}} className="chosePlanTabs" >
        {
          availableFequencies?.map((freq:any)=>{
            return( <Tab label={tabLabels[freq]?.label} value={freq} />)
          })
        }
      </Tabs>
      </Grid>
        </Grid> */}
        <Grid
          container
          spacing={1}
          className="basicAndPremiumPlans"
          data-testid="Select-plancard"
          sx={{mt:0.5}}
        >
         
          {displayPlans?.length ? (
           displayPlans?.map((x: any, index: any) => (
              <Grid
                item
                xs={12}
                sm={7}
                md={6}
                lg={5}
                xl={4}
                className="selectPlan-1200"
              >
                {/* <PlanCard
                  data={x}
                  handleCostomiseButton={handleCostomiseButton}
                  handleSelectPlan={handleSelectPlan}
                /> */}

                <PlanDisplayCard 
                data={x}
                handleCostomiseButton={handleCostomiseButton}
                handleSelectPlan={handleSelectPlan}
                isActual={true}
              />
              </Grid>
            ))
          ) : (
            <div className="noData">No data found</div>
          )}
        </Grid>
        {/* </Card> */}
      {/* </Box> */}
      <div className="modelModal" data-testid="confirm-modal">
        <ConfirmModal
          className="confirmModal"
          key="updatePlan"
          open={confirmModal}
          primaryText={Messages.UPDATEPLAN}
          cancelButton="NO"
          confirmButton="YES"
          cancelCallback={onCancelUpdate}
          confirmCallback={onConfirmUpdate}
        />
        <ConfirmModal
          className="confirmModal"
          key="updatePlan"
          open={deleteCustomize}
          primaryText="This is Customize plan, if you change this then all data will be deleted."
          cancelButton="NO"
          confirmButton="YES"
          cancelCallback={onCancelCustomize}
          confirmCallback={onConfirmCancelCustomize}
        />
      </div>
      {customiseModalOpen && (
        <CustomiseModal
          customiseModalOpen={customiseModalOpen}
          setCustomiseModalOpen={setCustomiseModalOpen}
          beneficiaryTotal={beneficiaryTotal}
          id={id}
        />
      )}
      <div className="bottomNote">
        Note: You can enter any coupon code you have on the payment screen.
      </div>
    </>
  );
};
