import { Box, Card, Divider, Grid, styled, useMediaQuery } from "@mui/material";
import Buttons from "../../common/button";
import tickIcon from "../../../assets/icons/tick.svg";
import backIcon from "../../../assets/icons/backIcon.svg";
import "./style.css";
import "./viewOrderstyle.css";
import { SelectInput } from "../../common/selectinput";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetOrderByOrderIdQuery,
  useUpdateOrderStatusMutation,
} from "../../../redux/services/orderApiSlice";
import { useEffect, useState } from "react";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { useDispatch } from "react-redux";
import { OrderStatus } from "../../../constants/enum";
import {
  addressFormat,
  handleErrors,
  handlePriceFormatting,
  phoneNumberAutoFormat,
} from "../../../utils";
import dayjs from "dayjs";
import Loader from "../../common/loader/loader";
import { ConfirmModal } from "../../common/confirmmodal";
import { Messages } from "../../../constants/messages";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../common/input";
import { RegExpressions } from "../../../constants/regExp";

export const ViewOrderCard = styled((props: any) => {
  const { children, ...rest } = props;
  return <Card {...rest}>{children}</Card>;
})`
  ${(props) => props.cardHeight && `height:${props.cardHeight}px !important`}
`;

export const ViewOrder = () => {
  const match = useMediaQuery("(max-width:899px)");
  const navigate = useNavigate();
  const { state } = useLocation();
  const isEdit = state?.edit;
  const orderData = state?.orderData;
  const [ordersData, setOrdersData] = useState<any>();
  const [cardHeight, setCardHeight] = useState(0);
  const [updateStatusModal, setUpdateStatusModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [cancelDisabled, setCancelDisabled] = useState(true);
  const { handleSubmit, control, setValue,getValues, reset } = useForm({
    mode: "onChange" || "onSubmit",
  });

  // API INTIGRATION

  const [updatestatus, updateStatusData] = useUpdateOrderStatusMutation();
  const [orderStatusData, setOrderStatusData] = useState(
    orderData?.orderStatus
  );

  const { data, isSuccess, refetch, isError, error, isLoading } =
    useGetOrderByOrderIdQuery(orderData?.orderId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (updateStatusData?.isSuccess) {
      refetch();
      setCancelDisabled(true);
      setUpdateStatusModal(false);
      if (orderStatusData === OrderStatus?.DELIVERED) {
        setDisabled(true);
      }
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Order status has been updated successfully",
        })
      );
    }
  }, [updateStatusData?.isSuccess]);

  useEffect(() => {
    handleErrors(updateStatusData, dispatch);
  }, [updateStatusData?.isError]);

  useEffect(() => {
    handleErrors({ error, isError }, dispatch);
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setOrdersData(data?.result);
      setOrderStatusData(data?.result?.orderStatus);
      setValue("trackingUrl", data?.result?.trackingUrl);
      setValue("trackingNumber", data?.result?.trackingNumber);
    }
  }, [isSuccess, data]);

  const handleUpdate = () => {
    if (orderStatusData === OrderStatus.DELIVERED) {
      setUpdateStatusModal(true);
    } else {
      const trackingNumber = getValues("trackingNumber");
      const trackingUrl = getValues("trackingUrl")
      updatestatus({
        orderStatus: orderStatusData,
        orderId: orderData?.orderId,
        trackingNumber: trackingNumber,
        trackingUrl: trackingUrl
      });
    }
  };

  const [optionsValue, setOption] = useState([
    { text: "Confirmed", value: OrderStatus.CONFIRMED, disabled: false },
    { text: "Delivered", value: OrderStatus.DELIVERED, disabled: false },
    { text: "InTransit", value: OrderStatus.INTRANSIT, disabled: false },
    {
      text: "Partially Delivered",
      value: OrderStatus.PARTIALLY_DELIVERED,
      disabled: false,
    },
    { text: "Pending", value: OrderStatus.PAYMENT_PENDING, disabled: false },
  ]);

  const handleSelect = (e: any) => {
    setOrderStatusData(e?.target?.value);
  };

  useEffect(() => {
    var array = [...optionsValue];
    if (orderStatusData === OrderStatus.PAYMENT_PENDING) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].value !== "PaymentPending") {
          array[i].disabled = true;
        }
      }
    }
    if (
      orderStatusData === OrderStatus.CONFIRMED ||
      orderStatusData === OrderStatus.INTRANSIT ||
      orderStatusData === OrderStatus.PARTIALLY_DELIVERED
    ) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].value === "PaymentPending") {
          array[i].disabled = true;
        }
      }
    }
    setOption(array);
  }, []);
  const onCancelcallBack = () => {
    setUpdateStatusModal(false);
  };
  const onConfirmCallBack = () => {
    const trackingNumber = getValues("trackingNumber");
      const trackingUrl = getValues("trackingUrl")
    updatestatus({ 
      orderStatus: orderStatusData, 
      orderId: orderData?.orderId,
      trackingNumber: trackingNumber,
      trackingUrl: trackingUrl,
     });
  };

  const handleCancel = () => {
    setOrderStatusData(data?.result?.orderStatus);
  };
  useEffect(() => {
    if (orderStatusData !== orderData?.orderStatus) {
      setCancelDisabled(false);
    }
  }, [orderStatusData]);

  const handleGetDeviceCost = (deviceData: any, quantity: any) => {
    console.log(orderData?.plan?.billingCountry, "sjjjjj");
    let data = deviceData?.DeviceCostings?.filter((item: any) => {
      return item?.country === (ordersData?.currency === "INR" ? "IN" : "US");
    });
    console.log(data, "poooooooooooooooooooo");
    if (data && data?.length > 0) {
      return handlePriceFormatting(
        data[0]?.value * quantity,
        ordersData?.currency
      );
    }
  };

  const labels: any = {
    ["Blood Glucose"]: "Glucometer",
    ["Blood Pressure"]: "BP Monitor",
    ["Temperature"]: "Thermometer",
    ["PulseOx"]: "Pulse Oximeter",
    ["Weight"]: "Weighing Scale",
  };



  return (
    <>
      <Loader show={updateStatusData?.isLoading || isLoading} />
      <Box marginBottom="150px">
        <Grid container className="mainHeading" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="main-title">
              <span className="backIcon-pd">
                <img
                  src={backIcon}
                  alt="backIcon"
                  onClick={() => navigate(-1)}
                />
              </span>
              <span>
                {isEdit ? "Edit Order" : "View Order"} /{" "}
                <span className="sub-name textCapital">
                  {orderData?.fullName}
                </span>
              </span>
            </div>
          </Grid>
        </Grid>

        <Grid container justifyContent={"center"}>
          <Grid xs={12} sm={12} md={12} lg={11.8} xl={11.9}>
            <div className="viewOrderCard">
              <ViewOrderCard cardHeight={cardHeight} className="innerMainCard">
                <Grid container justifyContent={"center"}>
                  <Grid
                    item
                    xs={11}
                    sm={11}
                    md={11}
                    lg={11.2}
                    xl={11.2}
                    marginBottom={match ? "1.5vh" : ""}
                    className="reviewP-topBottom"
                  >
                    {/* order Information  */}
                    <Grid container>
                      <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                        <div className="content-title">
                          <span>
                            <img src={tickIcon} alt="TickIcon" />
                          </span>
                          <span className="plan-title">Order Information</span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container className="subscriberM-top benf-info">
                      <Grid item xl={6} lg={6} md={12} sm={12}>
                        <Grid container>
                          <Grid item>
                            <span className="faded-text"> Order No&nbsp;:</span>
                            &nbsp;
                          </Grid>
                          <Grid item>
                            <span className="text-pr">
                              {ordersData?.orderNumber}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        lg={6}
                        md={12}
                        sm={12}
                        className="paddingtop-10"
                      >
                        <div>
                          <Grid container>
                            <Grid item>
                              <span className="faded-text">Placed On :</span>
                              &nbsp;
                            </Grid>
                            <Grid item>
                              <span className="text-pr">
                                {dayjs(ordersData?.createdAt)?.format(
                                  "DD MMM, YYYY HH:MM A"
                                )}
                              </span>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                      {/* <Grid item xl={4} lg={5} md={12} sm={12} className="paddingtop-10">
                    <div>
                      <Grid container>
                        <Grid item>
                          <span className="faded-text">Payment Mode : </span>
                        </Grid>
                        <Grid item>
                          <span className="text-pr">
                            Credit Card *** *** *** 6874
                          </span>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid> */}
                    </Grid>
                    {/* -------------------------Second Container------------------------- */}
                    <Grid container>
                      <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
                        <Grid container sx={{ mt: 4 }}>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={4.7}>
                            <div className="content-title">
                              <span>
                                <img src={tickIcon} alt="TickIcon" />
                              </span>
                              <span className="plan-title">
                                Billing Address
                              </span>
                            </div>
                            {ordersData?.orderaddresses?.map(
                              (addressData: any, index: any) => {
                                if (addressData?.addressType === "billing") {
                                  return (
                                    <div className="billing-Address-review">
                                      <span className="text-pr">
                                        {orderData?.fullName} |{" "}
                                      </span>
                                      <span className="faded-text">
                                        Mobile&nbsp;Number:
                                      </span>
                                      &nbsp;+91&nbsp;
                                      {phoneNumberAutoFormat(
                                        orderData?.mobileNumber
                                      )}
                                      <br />
                                      <span>
                                        {addressFormat(addressData)}
                                        {/* {addressData?.address1}, {addressData?.address2}, {addressData?.city}, {addressData?.state}, {addressData?.zipCode}, {addressData?.country} */}
                                      </span>
                                    </div>
                                  );
                                }
                              }
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={4.7}>
                            <div className="content-title">
                              <span>
                                <img src={tickIcon} alt="TickIcon" />
                              </span>
                              <span className="plan-title">
                                Shipping Address
                              </span>
                            </div>
                            {ordersData?.orderaddresses?.map(
                              (addressData: any, index: any) => {
                                if (addressData?.addressType === "shipping") {
                                  return (
                                    <div className="billing-Address-review">
                                      <span className="text-premium">
                                        {addressData?.fullName} |{" "}
                                      </span>
                                      <span className="faded-text">
                                        Mobile&nbsp;Number:
                                      </span>
                                      &nbsp;+91&nbsp;
                                      {phoneNumberAutoFormat(
                                        addressData?.mobileNumber
                                      )}
                                      <br />
                                      <span>
                                        {addressFormat(addressData)}
                                        {/* {addressData?.address1}, {addressData?.address2}, {addressData?.city}, {addressData?.state}, {addressData?.zipCode}, {addressData?.country} */}
                                      </span>
                                    </div>
                                  );
                                }
                              }
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* ---------------------Third Container---------------------------------------- */}

                    <Grid container sx={{ mt: 4 }}>
                      <Grid item xs={12} sm={12} md={12} xl={11.7} lg={11.7}>
                        <div className="content-title">
                          <span>
                            <img src={tickIcon} alt="TickIcon" />
                          </span>
                          <span className="plan-title">Payment Details</span>
                        </div>

                        {/* <Grid container className="second-Inner-Card" justifyContent={"center"}>
                      <Grid item xs={7} sm={8.4} md={9} lg={9.2} xl={9.5}> */}
                        <Grid container className="Last-Inner-Card">
                          <Grid
                            item
                            xs={1.2}
                            sm={0.7}
                            md={0.4}
                            lg={0.3}
                            xl={0.28}
                          ></Grid>
                          <Grid item xs={10} sm={11} md={11} lg={10} xl={10}>
                            <div>
                              <Card className="pay-card">
                                <Grid
                                  container
                                  justifyContent="center"
                                  sx={{ pb: 3 }}
                                >
                                  <Grid
                                    item
                                    xs={11}
                                    sm={11}
                                    md={11}
                                    lg={11.5}
                                    xl={11.5}
                                  >
                                    <Grid container sx={{ mt: 2, mb: 1 }}>
                                      <Grid
                                        item
                                        xs={4}
                                        sm={4}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                      >
                                        <span className="product-text">
                                          PRODUCT
                                        </span>
                                      </Grid>
                                      {!match ? (
                                        <Grid
                                          item
                                          xs={2}
                                          sm={2}
                                          md={3}
                                          lg={3}
                                          xl={3}
                                        >
                                          <span className="product-text">
                                            Quantity
                                          </span>
                                        </Grid>
                                      ) : (
                                        <Grid
                                          item
                                          xs={2}
                                          sm={2}
                                          md={3}
                                          lg={4}
                                          xl={4}
                                        ></Grid>
                                      )}
                                      <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                        className="product-text priceLine"
                                      >
                                        <div>
                                          PRICE &nbsp;
                                          <span className="usd">
                                            ({ordersData?.currency})
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                    <Divider className="inerHrDivider" />
                                    {orderData?.orderType === "Subscription" ? 
                                    (
                                      <Grid container>
                                        <Grid
                                          item
                                          xs={9}
                                          sm={9}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                        >
                                          <span className="content-inner-title">
                                            {ordersData?.remarks
                                              ?.substring(0, 1)
                                              .toUpperCase() +
                                              ordersData?.remarks?.substring(
                                                1
                                              )}
                                          </span>
                                        </Grid>

                                        <Grid
                                          item
                                          xs={0}
                                          sm={0}
                                          md={3}
                                          lg={3}
                                          xl={3}
                                        >
                                          {!match ? (
                                            <span className="pr-text ">1</span>
                                          ) : (
                                            <></>
                                          )}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={3}
                                          sm={3}
                                          md={3}
                                          lg={3}
                                          xl={3}
                                          className="pr-text priceLine"
                                        >
                                          <div className="price-text">
                                            {handlePriceFormatting(
                                              ordersData?.plan?.recurringAmount,
                                              orderData?.currency
                                            )}
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={9}
                                          sm={9}
                                          md={6}
                                          lg={6}
                                          xl={6}
                                          className="pr-text "
                                          sx={{ mt: "13px", mb: 1 }}
                                        >
                                          <div className="content-inner-title">
                                            <span>
                                              {ordersData?.plan?.name
                                                ?.substring(0, 1)
                                                .toUpperCase() +
                                                ordersData?.plan?.name?.substring(
                                                  1
                                                )}{" "}
                                              device bundle
                                            </span>
                                          </div>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={0}
                                          sm={0}
                                          md={3}
                                          lg={3}
                                          xl={3}
                                          className="pr-text "
                                          sx={{ mt: "13px", mb: 1 }}
                                        >
                                          {!match ? (
                                            <span className="pr-text">1</span>
                                          ) : (
                                            <></>
                                          )}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={3}
                                          sm={3}
                                          md={3}
                                          lg={3}
                                          xl={3}
                                          className="pr-text priceLine"
                                          sx={{ mt: "13px", mb: 1 }}
                                        >
                                          <div className="price-text alignCenter">
                                            {handlePriceFormatting(
                                              ordersData?.subTotal,
                                              orderData?.currency
                                            )}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    ) :
                                    orderData?.orderType === "Upgrade"? 
                                    (
                                      <Grid container>
                                      <Grid
                                        item
                                        xs={9}
                                        sm={9}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                      >
                                        <span className="content-inner-title">
                                          {ordersData?.remarks
                                            ?.substring(0, 1)
                                            .toUpperCase() +
                                            ordersData?.remarks?.substring(
                                              1
                                            )}
                                        </span>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={0}
                                        sm={0}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                      >
                                        {/* {!match ? (
                                          <span className="pr-text ">1</span>
                                        ) : (
                                          <></>
                                        )} */}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                        className="pr-text priceLine"
                                      >
                                        <div className="price-text">
                                          {handlePriceFormatting(
                                            ordersData?.plan?.recurringAmount,
                                            orderData?.currency
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={9}
                                        sm={9}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                        className="pr-text "
                                        sx={{ mt: "13px", mb: 1 }}
                                      >
                                        {/* <div className="content-inner-title">
                                          <span>
                                            {ordersData?.plan?.name
                                              ?.substring(0, 1)
                                              .toUpperCase() +
                                              ordersData?.plan?.name?.substring(
                                                1
                                              )}{" "}
                                            device bundle
                                          </span>
                                        </div> */}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={0}
                                        sm={0}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                        className="pr-text "
                                        sx={{ mt: "13px", mb: 1 }}
                                      >
                                        {/* {!match ? (
                                          <span className="pr-text">1</span>
                                        ) : (
                                          <></>
                                        )} */}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                        className="pr-text priceLine"
                                        sx={{ mt: "13px", mb: 1 }}
                                      >
                                        <div className="price-text alignCenter">
                                          {handlePriceFormatting(
                                            ordersData?.subTotal,
                                            orderData?.currency
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                    ):
                                     (
                                      <Grid container>
                                        {ordersData?.devices?.map(
                                          (device: any, index: any) => {
                                            return (
                                              <>
                                                <Grid
                                                  item
                                                  xs={9}
                                                  sm={9}
                                                  md={6}
                                                  lg={6}
                                                  xl={6}
                                                >
                                                  <div className="content-inner-title">
                                                    <p className="pr-text marginDefineTop ">
                                                      {labels[device?.biometricType]}/
                                                      {device?.model}
                                                    </p>
                                                    &nbsp;
                                                  </div>
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={0}
                                                  sm={0}
                                                  md={3}
                                                  lg={3}
                                                  xl={3}
                                                >
                                                  {!match ? (
                                                    <span className="pr-text ">
                                                      {device?.quantity}
                                                    </span>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </Grid>
                                                <Grid
                                                  item
                                                  xs={3}
                                                  sm={3}
                                                  md={3}
                                                  lg={3}
                                                  xl={3}
                                                  className="pr-text priceLine"
                                                >
                                                  <div className="price-text alignCenter">
                                                    {handleGetDeviceCost(
                                                      device,
                                                      device?.quantity
                                                    )}
                                                  </div>
                                                </Grid>
                                                {match ? (
                                                  <>
                                                    <Grid
                                                      item
                                                      xs={8}
                                                      sm={8}
                                                      md={8}
                                                      lg={8}
                                                      xl={8}
                                                    >
                                                      <span className="pr-text ">
                                                        <span className="fw-700 subscriberColumn2">
                                                          Quantity:&nbsp;
                                                        </span>{" "}
                                                        {device?.quantity}
                                                      </span>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={4}
                                                      sm={4}
                                                      md={4}
                                                      lg={4}
                                                      xl={4}
                                                    ></Grid>
                                                  </>
                                                ) : (
                                                  <></>
                                                )}
                                              </>
                                            );
                                          }
                                        )}
                                      </Grid>
                                    )
                                    }
                                    <Divider className="inerHrDivider" />
                                    <Grid container sx={{ mt: 1, mb: 1 }}>
                                      <Grid
                                        item
                                        xs={0}
                                        sm={0}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                      ></Grid>

                                      <Grid
                                        item
                                        xs={11}
                                        sm={12}
                                        md={8}
                                        lg={8}
                                        xl={8}
                                      >
                                        <Grid container className="price-line">
                                          <Grid
                                            item
                                            xs={10}
                                            sm={10}
                                            md={10}
                                            lg={10}
                                            xl={10}
                                            className="price-list pr-list"
                                          >
                                            <span> Subtotal:</span>
                                            <span>Coupon Discount:</span>
                                            <span>Net Amount:</span>
                                            <span>Tax:</span>
                                            <span>Shipping Charges:</span>
                                            <span className="text-pr">
                                              Total:
                                            </span>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={2}
                                            sm={2}
                                            md={2}
                                            lg={2}
                                            xl={2}
                                            className="price-list pr-list"
                                          >
                                            <span>
                                              {handlePriceFormatting(
                                                ordersData?.subTotal,
                                                orderData?.currency
                                              )}
                                            </span>
                                            <span>
                                              {handlePriceFormatting(
                                                ordersData?.couponDiscount,
                                                orderData?.currency
                                              )}
                                            </span>
                                            <span>
                                              {handlePriceFormatting(
                                                ordersData?.netAmount,
                                                orderData?.currency
                                              )}
                                            </span>
                                            <span>
                                              {handlePriceFormatting(
                                                ordersData?.taxAmount,
                                                orderData?.currency
                                              )}
                                            </span>
                                            <span>
                                              {handlePriceFormatting(
                                                ordersData?.shippingCharges
                                                  ? ordersData?.shippingCharges
                                                  : 0,
                                                orderData?.currency
                                              )}
                                            </span>
                                            <span className="text-pr">
                                              {handlePriceFormatting(
                                                ordersData?.totalAmount,
                                                orderData?.currency
                                              )}
                                            </span>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Card>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* ----------Fourth Container------------- */}
                    <Grid container className="Last-Inner-Card">
                      <Grid
                        item
                        xs={1.5}
                        sm={0.8}
                        md={0.5}
                        lg={0.4}
                        xl={0.3}
                      ></Grid>
                      <Grid item xs={10} sm={10.9} md={11} lg={10} xl={10} >
                        <form>
                          <Grid container columnSpacing={4} sx={{ mt: 2 }}>
                            <Grid item xs={6}>
                              <Controller
                                render={({
                                  field: { ref, ...field },
                                  fieldState: { invalid, error },
                                }) => (
                                  <Input
                                    {...field}
                                    label="Tracking Number"
                                    value={field.value}
                                    required={false}
                                    disabled={!isEdit || disabled}
                                    error={Boolean(error)}
                                    helperText={error?.message ? error?.message : " "}
                                    onChange={field.onChange}
                                    inputRef={ref}
                                    placeholder="Enter tracking number"
                                  />
                                )}
                                name="trackingNumber"
                                control={control}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Controller
                                render={({
                                  field: { ref, ...field },
                                  fieldState: { invalid, error },
                                }) => (
                                  <Input
                                    {...field}
                                    label="Tracking URL"
                                    value={field.value}
                                    required={false}
                                    disabled={!isEdit || disabled}
                                    error={Boolean(error)}
                                    helperText={error?.message ? error?.message : " "}
                                    onChange={field.onChange}
                                    inputRef={ref}
                                    placeholder="Enter tracking url"
                                  />
                                )}
                                name="trackingUrl"
                                control={control}
                               
                              />
                            </Grid>
                          </Grid>


                          <Grid container sx={{ mt: 4 }}>
                            <Grid item xs={12} sm={12} lg={5} md={4} xl={5.1}>
                              <SelectInput
                                labelClassName="changeOrder"
                                placeholder="Select order status"
                                disabled={!isEdit || disabled}
                                value={orderStatusData}
                                onChange={handleSelect}
                                optionValue={optionsValue}
                                label="Change order status"
                                required
                              />
                            </Grid>
                            <Grid
                              item
                              xs={0.6}
                              sm={0.6}
                              lg={0.6}
                              md={0.6}
                              xl={0.6}
                            ></Grid>
                            <Grid item xs={12} sm={12} lg={6} md={7} xl={5}>
                              {/* <Grid container alignItems="center">
                                <Grid
                                  item
                                  xs={4}
                                  sm={5}
                                  md={4.7}
                                  xl={4}
                                  lg={4.5}
                                >
                                  <span className="text-pr">
                                    Notify Customer:
                                  </span>
                                </Grid>
                                <Grid
                                  item
                                  xs={0.6}
                                  sm={0.6}
                                  lg={0.6}
                                  md={0.6}
                                  xl={0.6}
                                ></Grid>
                                <Grid item xs={7} sm={6} md={6} xl={6} lg={6}>
                                  <Grid container>
                                    <Grid item xs={2} xl={3} md={3.1}>
                                      <FormControlLabel
                                        control={<Radio disabled />}
                                        label="No"
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      xl={0.9}
                                      lg={0.9}
                                      md={1}
                                    ></Grid>
                                    <Grid item xs={2} xl={3} md={3.1}>
                                      <FormControlLabel
                                        control={<Radio disabled />}
                                        label="Yes"
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid> */}
                            </Grid>
                          </Grid>
                        </form>
                        <Grid container sx={{ mt: 4 }}>
                          <Grid item xs={12} lg={4} md={4} xl={1.9}>
                            <Buttons
                              variant="contained"
                              type="secondary"
                              text="CANCEL"
                              disabled={!isEdit || cancelDisabled}
                              onClick={handleCancel}
                            />
                          </Grid>
                          <Grid item lg={0.3} xs={0} md={0.3} xl={0.3}></Grid>
                          <Grid
                            item
                            xs={12}
                            lg={4}
                            md={4}
                            xl={1.9}
                            className="button-margin"
                          >
                            <Buttons
                              variant="contained"
                              type="primary"
                              text="UPDATE STATUS"
                              disabled={!isEdit || disabled}
                              onClick={handleUpdate}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ViewOrderCard>
            </div>
          </Grid>
        </Grid>
        <div className="modelModal">
          <ConfirmModal
            className="confirmModal"
            key="signOut"
            open={updateStatusModal}
            primaryText={Messages.UPDATESTATUS}
            cancelButton="NO"
            confirmButton="YES"
            cancelCallback={onCancelcallBack}
            confirmCallback={onConfirmCallBack}
          />
        </div>
      </Box>
    </>
  );
};
