import { createSlice } from "@reduxjs/toolkit";

interface deviceOrderState {
  orderId: string,
  orderStatus:string
}

const initialState = { orderId:  "",orderStatus:""} as deviceOrderState

const devicerOrderSlice = createSlice({
  name: 'deviceOrder',
  initialState,
  reducers: {
    setdeviceOrder(state, {payload}) {
      state.orderId = payload?.orderId;
      state.orderStatus=payload?.orderStatus;
    },
    clearDeviceOrderState:(state)=> state=initialState,
  },
})

export const { setdeviceOrder,clearDeviceOrderState } = devicerOrderSlice.actions
export default devicerOrderSlice.reducer
export const getDeviceOrderInfo=(state:any)=>state.deviceOrder;