export const RegExpressions = {
  Email: new RegExp(
    "^[\\w!#$%&'*+/=?`{|}~^-]+(?:\\.[\\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,6}$"
  ),
  FullName: new RegExp(/^[^0-9\s].*/),
  MobileNumber: new RegExp(
    /^[\+]?[(]?[1-9]{1}[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/
  ),
  InternationalMobileNumber: new RegExp(
    /^\b[\d\-\/@#$]{6,12}\b$/
  ),
  Password: new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#%*?&])[A-Za-z\d@$!#%*?&]{8,}$/
  ),
  EmailOrMobile: new RegExp(
    /^([0-9]{10})|([A-Za-z0-9._%\+\-]+@[a-z0-9.\-]+\.[a-z]{2,3})$/
  ),
  Price: new RegExp(/^\d{0,5}(\.\d{1,2})?$/),
  PinCode: new RegExp(/^\d{6}$/),
  twoDigitRegex: new RegExp(/^\d{6}$/),
  ZipCode: new RegExp(/^[A-Za-z0-9 ]{3,15}$/),
  OtpCode: new RegExp(/^\d{1,4}$/),
  decimalAndIntegerValue: new RegExp(/^[-+]?\d+(\.\d+)?$/),
  onlyIntegerValue: new RegExp(/^[-+]?\d+$/),
  discoutValue: new RegExp(/^[]?\d+(\.\d+)?$/),
  noSpaceatBeginningAndEnd: new RegExp(/^[^\s]+(\s+[^\s]+)*$/),
  nodecimalOnlyNumber: new RegExp(/^\d+$/),
  discountPercent: new RegExp(/^(?:100(?:\.0+)?|\d{0,2}(?:\.\d+)?)$/),
  emailornumber: new RegExp(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}|[0-9]{6,12})$/),
  Zero: new RegExp(/^(?!0*(\.0{1,2})?$)\d{1,6}(\.\d{1,2})?$/),
  ZeroWithNoDecimal: new RegExp(/^(?!0*(\.0{1,2})?$)\d{1,10}$/),
  discountDecimal: new RegExp(/^(?!0+(\.0*)?$)(90(\.\d{1,2})?|0\.\d{1,2}|[1-8]?[0-9](\.\d{1,2})?)$/),
  totalDiscountDecimal: new RegExp(/^(?!0+(\.0*)?$)(100(\.\d{1,2})?|0\.\d{1,2}|[1-9]?[0-9](\.\d{1,2})?)$/),
  five: new RegExp(/^(1|[1-9][0-9]?|500)$/),
  NoSpace: new RegExp(/^[^-\s][a-zA-Z0-9_\s-]+$/)
  //discountdecimal: new RegExp(/^(?!0+(\.0*)?$)(90(\.(\d{1,2}))?|0\.[0-9]*[1-9][0-9]*|[1-8]?[0-9](\.\d{1,2})?)$/)
};
