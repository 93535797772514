export enum UserRoles {
  PLATFORM_SUPER_ADMIN = "Platform Super Admin",
  PLATFORM_ADMIN = "Platform Admin",
  PLATFORM_SUPPORT = "Platform Support",
  SUBSCRIBER = "Subscriber",
  BENEFICIARY = "Beneficiary",
  FAMILY = "Family",
}

export enum PageTitle {
  BENEFICIARYDASHBOARD = "Benificiary Dashboard",
  MANAGEUSERS = "Manage Users",
  MANAGEPLANS = "Manage Plans",
  MANAGECOUPONS = "Manage Coupons",
  NOTIFICATION = "Notification",
  MANAGEDEVICES = "Manage Devices",
  MANAGEORDERS = "Manage Orders",
  REPORTS = "Reports",
  DASHBOARD = "Dashboard",
  ROLESPERMISSION = "Roles / Permissions",
  ACCOUNTSETTING = "My Account",
  HELPCENTRE = "Help Center",
  LOGOUT = "Logout",
  DUEPAYMENTREPORT = "Past-due Payments",
  SUBSCRIPTIONCANCELLATION = "Subscriptions Cancellation",
  SUBSCRIPTIONPENDING = "Subscriptions Pending",
  REVENUESUMMARY = "Revenue Summary",
  REVENUEDETAIL = "Revenue Detail",
  SEVENDAYNOREADINGS = "7D No Readings",
}
export enum ModalButton {
  CANCEL = "CANCEL",
  CONFIRM = "YES",
  OK = "OK",
  SAVE = "SAVE",
  SUBMIT = "SUBMIT",
  OTP = "SEND OTP",
}

export enum deviceType {
  BLOODPRESSURE = "Blood Pressure Monitor",
  PULSEOXYMETER = "Pulse Oximeter",
  TEMPERATURE = "Infrared Thermometer",
  GLUCOSE = "Glucose",
  WEIGHT = "Multi Para Weighing Scale",
}

export enum deviceTypeValue {
  BLOODPRESSURE = "Blood Pressure",
  PULSEOXYMETER = "PulseOx",
  GLUCOSE = "Blood Glucose",
  WEIGHT = "Weight",
  TEMPERATURE = "Temperature",
  spirometer = "Spirometer",
}

export enum ModalPrimaryText {
  ACTIVATE = "Are you sure you want to activate %s?",
  DELETE = "Are you sure you want to delete %s?",
  DEACTIVATE = "Are you sure you want to deactivate %s?",
}

export enum Roles {
  SUBSCRIBER = "Subscriber",
  PLATFORMADMIN = "Platform Admin",
  PLATFORMSUPPORT = "Platform Support",
}

export enum ModalTitle {
  CHANGENAME = "Enter New Name",
  CHANGEEMAIL = "Enter New Email",
  CHANGEMOBILENUMBER = "Enter New Mobile Number",
  CHANGEPASSWORD = "Change Password",
}
export enum JourneyStatus {
  SELECT_PLAN = "selectPlan",
  PRIVACYPOLICY = "privacyPolicy",
  ADD_BENEFICIARY = "addBeneficiary",
  ADD_ADDRESS = "addAddress",
  COMPLETED = "completed",
}

export enum OrderStatus {
  PAYMENT_PENDING = "PaymentPending",
  CONFIRMED = "Confirmed",
  INTRANSIT = "InTransit",
  PARTIALLY_DELIVERED = "PartiallyDelivered",
  DELIVERED = "Delivered",
  ACTIVATE = "Activate",
  DEACTIVATED = "Deactivated",
  APPROVAL_PENDING = "Approval Pending",
  CANCELLED = "Cancelled",
}
export enum PaymentStatus {
  PAYMENT_PENDING = "PaymentPending",
  PAYMENT_COMPLETED = "PaymentCompleted",
}

export enum AccountSettingLinks {
  ORDER_HISTORY = "orderhistory",
}

export enum UserStatus {
  ACTIVE = "active",
  DEACTIVATED = "inactive",
  INVITED = "invited",
}

export enum UserType {
  BENEFICIARY = "Beneficiary",
  FAMILY = "Family",
  SUBSCRIBER = "Subscriber",
  PLATFORM_ADMIN = "Platform Admin",
  PLATFORM_SUPPORT = "Platform Support",
}

export enum DiscountType {
  PRODUCT_DISCOUNT = "productDiscount",
  TRIAL_PERIOD = "freeTrial",
  SUBSCRIPTION_DISCOUNT = "monthlyDiscount"
}


export enum SubscriptionChanges {
  BENEFICIARY_INCREASE = "beneficiaryIncreasing",
  DEVICE_INCREASE = "deviceIncreasing",
  BENEFICIARY_DECREASE = "beneficiaryDecreasing",
  DEVICE_DECREASE = "deviceDecreasing"
 }
