import {
  Grid,
  Box,
  FormControlLabel,
  Radio,
  Divider,
  FormGroup,
  Card,
  styled,
} from "@mui/material";
import "./style.css";
import { useGetRolePermissionMutation } from "../../../redux/services/roleApiSlice";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { backIcon } from "../../../assets/icons";
import Loader from "../../common/loader/loader";
import { handleErrors } from "../../../utils";
import { useDispatch } from "react-redux";

export const RolesPermissionCard = styled((props: any) => {
  const { children, ...rest } = props;
  return <Card {...rest}>{children}</Card>;
})`
  ${(props) => props.cardHeight && `height:${props.cardHeight}px !important`}
`;

export const ViewRole = () => {
  const [cardHeight, setCardHeight] = useState(0);
  const [rolePermission, rolePermissionInfo] = useGetRolePermissionMutation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const roleId = state;
  const dispatch = useDispatch();
  useEffect(() => {
    rolePermission({ roleId });
  }, []);

  const permission = rolePermissionInfo?.data?.result?.status;
  const description = rolePermissionInfo?.data?.result?.description;

  const calHeight = () => {
    let targetCompo,
      targetElement: any,
      elementHeight: number,
      cardHeight: number;
    targetCompo =
      window.document.querySelector<HTMLElement>(".rolesPermission");
    targetElement = targetCompo?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    cardHeight = elementHeight - (targetElement + 25);
    setCardHeight(cardHeight);
  };

  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  useEffect(() => {
    handleErrors(rolePermissionInfo, dispatch);
  }, [rolePermissionInfo?.isError]);
  return (
    <>
      <Loader show={rolePermissionInfo?.isLoading} />
      <Box marginBottom="150px">
        <Grid
          container
          className="mainHeading rolesHide"
          spacing={1}
          alignItems={"center"}
        >
          <span className="backIcon-pd">
            <img src={backIcon} alt="backIcon" onClick={() => navigate(-1)} />
          </span>
          <span className="accountHeading" data-testid="my-account">
            Role / Permission&nbsp;
          </span>
        </Grid>

        <div className="rolesPermission">
          <Card className=" roleCard innerMainCard">
            <Grid container>
              <Grid item xs={12} lg={12}>
                <div className={"role-descript"}>
                  <div className="roles-title">
                    {rolePermissionInfo?.data?.result?.roleName}
                  </div>
                  <div className="roles-text">{description}</div>

                  {rolePermissionInfo?.data?.result?.permissions.map(
                    (x: any, index: any) => {
                      return (
                        <>
                          <Grid container sx={{ mt: 2 }} alignItems={"center"}>
                            <Grid item xs={6} sm={6} md={5}>
                              <div className="row-name">
                                <span>{x?.permissionName}</span>
                              </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={5}>
                              <FormGroup>
                                <Grid container spacing={1}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6.5}
                                    md={4}
                                    lg={3}
                                    xl={2}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          disabled
                                          checked={x?.isView}
                                          className="radioButton"
                                        />
                                      }
                                      label="View"
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                  >
                                    <>
                                      <FormControlLabel
                                        control={
                                          <Radio
                                            disabled
                                            checked={x?.isEdit}
                                            className="radioButton"
                                          />
                                        }
                                        label="Edit"
                                      />
                                    </>
                                  </Grid>
                                </Grid>
                              </FormGroup>
                            </Grid>
                          </Grid>
                          <Divider sx={{ mt: 1 }} />
                        </>
                      );
                    }
                  )}

                  {/* <Grid container alignItems={"center"} spacing={2} sx={{ mt: 4 }} >
                                    <Grid item xs={0} lg={8} md={4} xl={8}></Grid>
                                    <Grid item xs={12} lg={4} md={8} xl={4}>
                                        <Grid container spacing={4} justifyContent={"end"} >
                                            <Grid item xs={5} lg={5} md={5} sm={5}>
                                                <Buttons
                                                    buttonType="submit"
                                                    variant="contained"
                                                    text="CANCEL"
                                                    type="secondary"
                                                />
                                            </Grid>
                                            <Grid item xs={5} lg={5} md={5} sm={5}>
                                                <Buttons
                                                    buttonType="submit"
                                                    variant="contained"
                                                    text="EDIT"
                                                    type="primary"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </Box>
    </>
  );
};
