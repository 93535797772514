/*add beneficiary object to array*/
import dayjs from "dayjs";
import { JourneyStatus } from "../constants/enum";
import {
  clearUserStepState,
  setUserSteps,
} from "../redux/features/userStep/userStepSlice";
import { clearAlertState, setAlert } from "../redux/features/alert/alertSlice";
import { clearAuthState } from "../redux/features/auth/authSlice";
import { clearOrderState } from "../redux/features/order/orderSlice";
import { clearBeneficiaryState } from "../redux/features/user/beneficiarySlice";
import { clearUserState } from "../redux/features/user/userSlice";
import { clearRoleState } from "../redux/features/role/roleSlice";
import { clearDeviceOrderState } from "../redux/features/deviceOrder/deviceOrderSlice";
import { clearFilterState } from "../redux/features/filters/filtersSlice";
import { Country } from "country-state-city";
import { clearCountryState } from "../redux/features/country/countrySlice";

// interface InputObject {
//     [key: string]: Array<any>;
// }
interface OutputObject {
  [key: string]: any;
}

export const flattenedObjectToArray = (
  data: any,
  length: any,
  update: any,
  beneficiaryData: any
): Array<OutputObject> => {
  const flattenedArray: Array<OutputObject> = [];

  for (var i = 0; i < length; i++) {
    if (!update) {
      flattenedArray.push({
        fullName: data[`fullName${i}`],
        mobileNumber: data[`mobileNumber${i}`]?.replaceAll("-", ""),
        dob: data[`dob${i}`]
          ? dayjs(data[`dob${i}`])?.format("DD/MM/YYYY")
          : "",
        countryCode: data[`countryCode${i}`]?.split(" ")[0]
          ? data[`countryCode${i}`]?.split(" ")[0]
          : "",
      });
    } else {
      flattenedArray.push({
        userId: beneficiaryData[i].userId,
        fullName: data[`fullName${i}`],
        mobileNumber: data[`mobileNumber${i}`]?.replaceAll("-", ""),
        dob: data[`dob${i}`]
          ? dayjs(data[`dob${i}`])?.format("DD/MM/YYYY")
          : "",
        countryCode: data[`countryCode${i}`]?.split(" ")[0]
          ? data[`countryCode${i}`]?.split(" ")[0]
          : "",
      });
    }
  }
  return flattenedArray;
};
/*add beneficiary object to array*/

/*Phone Number Formatter Function*/

export const phoneNumberAutoFormat = (phoneNumber: string): string => {
  const number = phoneNumber?.trim()?.replace(/[^0-9]/g, "");

  if (number?.length < 4) return number;
  if (number?.length < 7) return number?.replace(/(\d{3})(\d{1})/, "$1-$2");
  if (number?.length < 11)
    return number?.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
  return number?.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
};

/*Phone Number Formatter Function*/

/*Function Control Input field Focus*/

export const handleInputFocus = (e: any, key: any) => {
  const element = e?.target;
  let caret = element?.selectionStart;
  let value = element?.value?.split("");

  if ((caret === 4 || caret === 8) && key !== "Delete" && key !== "Backspace") {
    caret++;
  } else if ((caret === 3 || caret === 7) && key === "Backspace") {
    value?.splice(caret - 1, 1);
    caret--;
  } else if ((caret === 3 || caret === 7) && key === "Delete") {
    value?.splice(caret, 1);
  }

  // update caret for non-digits
  if (key.length === 1 && /[^0-9]/.test(key)) caret--;

  value = value
    ?.join("")
    // remove everithing except digits
    ?.replace(/[^0-9]+/g, "")
    // limit input to 10 digits
    ?.replace(/(.{10}).*$/, "$1")
    // insert "-" between groups of digits
    ?.replace(/^(.?.?.?)(.?.?.?)(.?.?.?.?)$/, "$1-$2-$3")
    // remove exescive "-" at the end
    ?.replace(/-*$/, "");

  window.requestAnimationFrame(() => {
    element?.setSelectionRange(caret, caret);
  });

  return value;
};

/*Function Control Input field Focus*/

/*Clear State Function starts*/

export const clearAllStates = (dispatch: any) => {
  localStorage.clear();
  dispatch(clearAlertState());
  dispatch(clearAuthState());
  dispatch(clearOrderState());
  dispatch(clearBeneficiaryState());
  dispatch(clearUserState());
  dispatch(clearUserStepState());
  dispatch(clearRoleState());
  dispatch(clearDeviceOrderState());
  dispatch(clearFilterState());
  dispatch(clearCountryState());
};

/*Api Error Handiling Starts*/

export const handleErrors = (apiData: any, dispatch: any) => {
  //   if (apiData?.error?.status == 500) {
  //     dispatch(setAlert({ open: true, alertType: "error", message: "Something Went Wrong" }));
  //  }
  //  else
  if (apiData?.isError) {
    if (
      apiData?.error?.data?.error &&
      Array.isArray(apiData?.error?.data?.error?.message) &&
      apiData?.error?.data?.error?.message.length !== 0
    ) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: apiData?.error?.data?.error?.message[0]?.message,
        })
      );
    } else if (
      apiData?.error?.data?.error &&
      apiData?.error?.data?.error?.message
    ) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: apiData?.error?.data?.error?.message,
        })
      );
    } else if (
      apiData?.error?.data?.error &&
      apiData?.error?.data?.error?.error &&
      apiData?.error?.data?.error?.error?.message
    ) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: Array.isArray(apiData?.error?.data?.error?.error?.message)
            ? apiData?.error?.data?.error?.error?.message[0]?.message
            : apiData?.error?.data?.error?.error?.message,
        })
      );
    } else if (
      apiData?.error?.data?.error?.error &&
      apiData?.error?.data?.error?.error?.error_description
    ) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: apiData?.error?.data?.error?.error?.error_description,
        })
      );
    } else if (
      apiData?.error?.data?.error &&
      apiData?.error?.data?.error?.error_description
    ) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: apiData?.error?.data?.error?.error_description,
        })
      );
    } else if (
      apiData?.error?.data &&
      Array.isArray(apiData?.error?.data) &&
      apiData?.error?.data?.length !== 0
    ) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: apiData?.error?.data[0]?.error?.message,
        })
      );
    } else if (
      apiData?.error?.data?.error &&
      Array.isArray(apiData?.error?.data?.error?.errors) &&
      apiData?.error?.data?.error?.errors?.length !== 0
    ) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: apiData?.error?.data?.error?.errors[0]?.msg
            ? apiData?.error?.data?.error?.errors[0]?.msg
            : apiData?.error?.data?.error?.errors[0]?.message,
        })
      );
    } else if (
      apiData?.error?.data?.errors &&
      Array.isArray(apiData?.error?.data?.errors) &&
      Array.isArray(apiData?.error?.data?.errors[0]?.nestedErrors) &&
      apiData?.error?.data?.errors[0]?.nestedErrors?.length !== 0
    ) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: apiData?.error?.data?.errors[0]?.nestedErrors[0]?.msg,
        })
      );
    } else if (
      apiData?.error?.data?.errors &&
      Array.isArray(apiData?.error?.data?.errors) &&
      apiData?.error?.data?.errors?.length !== 0
    ) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: apiData?.error?.data?.errors[0]?.msg,
        })
      );
    } else if (
      apiData?.error?.data?.error?.error &&
      apiData?.error?.data?.error?.error?.detail?.includes("to_zip") &&
      apiData?.error?.data?.error?.error?.status === 400
    ) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: "Entered zip code is not valid for selected state.",
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: "Something Went Wrong!!",
        })
      );
    }
  }
};

/*Api Error Handiling Ends*/

/*update Steps states according to journey status*/

export const updateSteps = (
  journeyStatus: any,
  completedSteps: any,
  dispatch: any
) => {
  if (journeyStatus === JourneyStatus.PRIVACYPOLICY) {
    var newCompleted = {};
    Object.assign(newCompleted, completedSteps);
    for (let i = 1; i < 2; i++) {
      Object.assign(newCompleted, { [i]: true });
    }
    dispatch(
      setUserSteps({
        activeSteps: Object.keys(newCompleted).length,
        completedSteps: newCompleted,
        planSelected: false,
      })
    );
  } else if (journeyStatus === JourneyStatus.SELECT_PLAN) {
    let newCompleted = {};
    Object.assign(newCompleted, completedSteps);
    for (let i = 1; i < 2; i++) {
      Object.assign(newCompleted, { [i]: true });
    }
    dispatch(
      setUserSteps({
        activeSteps: Object.keys(newCompleted).length,
        completedSteps: newCompleted,
        planSelected: true,
      })
    );
  } else if (journeyStatus === JourneyStatus.ADD_BENEFICIARY) {
    let newCompleted = {};
    Object.assign(newCompleted, completedSteps);
    for (let i = 1; i < 3; i++) {
      Object.assign(newCompleted, { [i]: true });
    }
    dispatch(
      setUserSteps({
        activeSteps: Object.keys(newCompleted).length,
        completedSteps: newCompleted,
      })
    );
  } else if (journeyStatus === JourneyStatus.ADD_ADDRESS) {
    let newCompleted = {};
    Object.assign(newCompleted, completedSteps);
    for (let i = 1; i < 4; i++) {
      Object.assign(newCompleted, { [i]: true });
    }
    dispatch(
      setUserSteps({
        activeSteps: Object.keys(newCompleted).length,
        completedSteps: newCompleted,
      })
    );
  } else if (journeyStatus === JourneyStatus.COMPLETED) {
    let newCompleted = {};
    Object.assign(newCompleted, completedSteps);
    for (let i = 1; i < 5; i++) {
      Object.assign(newCompleted, { [i]: true });
    }
    dispatch(
      setUserSteps({
        activeSteps: Object.keys(newCompleted).length,
        completedSteps: newCompleted,
      })
    );
  }
};

/*update Steps states according to journey status*/

// adress format
export const addressFormat = (address: any) => {
  if (address !== undefined) {
    return `${address?.address1 !== null && address?.address1}${
      address?.address2 ? ", " : ""
    }${address?.address2 == null ? "" : address?.address2}${
      address?.city ? ", " : ""
    }${address?.city == null ? "" : address?.city}${
      address?.state ? ", " : ""
    } ${address?.state == null ? "" : address?.state}${
      address?.zipCode ? ", " : ""
    } ${address?.zipCode == null ? "" : address?.zipCode}${
      address?.country ? ", " : ""
    } ${
      address?.country == null
        ? ""
        : getCountryNameByCountryCode(address?.country)
        ? getCountryNameByCountryCode(address?.country)
        : address?.country
    }`;
  } else {
    return "NA";
  }
};

export const getCountryNameByCountryCode = (countryCode: any) => {
  const countryCodes = Country.getAllCountries().sort((a, b) =>
    a.isoCode.localeCompare(b.isoCode)
  );
  const countryName = countryCodes.filter((item, index) => {
    return item.isoCode === countryCode;
  });
  return countryName?.length !== 0 ? countryName[0]?.name : countryCode;
};

export const getCountryCodeByCountryName = (countryName: any) => {
  const countryCodes = Country.getAllCountries().sort((a, b) =>
    a.isoCode.localeCompare(b.isoCode)
  );
  const countryCode = countryCodes.filter((item, index) => {
    return item.name === countryName;
  });
  return countryCode?.length !== 0 ? countryCode[0]?.isoCode : countryName;
};

export const handlePriceFormatting = (price: any, currency: any) => {
  if (currency?.toLowerCase() === "INR"?.toLowerCase() || currency?.toLowerCase()==="IN"?.toLowerCase()) {
    return price?.toLocaleString("en-IN",{minimumFractionDigits: 0,maximumFractionDigits: 2
    });
  } else {
    return price?.toLocaleString("en-US",{minimumFractionDigits: 0,maximumFractionDigits: 2});
  }
};

export const getCountryByPhoneCode = (phoneCode:any) => {
  phoneCode = phoneCode.replace('+', '');
  let countryCode = Country.getAllCountries()?.find((con)=>con?.phonecode == phoneCode);
  return countryCode;
}