import { useEffect, useState } from "react";
import "./manageUserHeader.css";
import { UserRoles, UserStatus } from "../../../../constants/enum";
import { Grid } from "@mui/material";
import { SEARCHBAR } from "../../../common/search/searchbar";
import { SelectInput } from "../../../common/selectinput";
import Buttons from "../../../common/button";
import { useSelector } from "react-redux";
import { AddPlatformModal } from "../modal/addPlatformModal";
import { cNames } from "../../../../constants/constant";

export const PlatformAdminHeader = (props: any) => {
  const {
    screenWidth,
    filtersData,
    filterData,
    setFilterData,
    refetchPlatformUser,
    rowCount,
  } = props;
  const [searchInput, setSerchInput] = useState<string>(
    filtersData?.manageUserSearch
  );
  const [disabled, setDisabled] = useState<boolean>(true);
  const { roleData } = useSelector((state: any) => state?.role);
  const [addPlatformModal, setAddPlatformModal] = useState(false);
  const {countriesNames } = useSelector(
    (state: any) => state?.countries
  );

  const userStatusOptions = [
    { text: "All", value: null },
    { text: "Active", value: UserStatus.ACTIVE },
    { text: "Deactivated", value: UserStatus.DEACTIVATED },
    { text: "Invited", value: UserStatus.INVITED },
  ];

  let userTypeOptions: any[] = [{ text: "All", value: null }];

  if (Array.isArray(roleData)) {
    roleData?.map((x: any) => {
      userTypeOptions.push({
        text: x?.roleName,
        value: x?.roleId,
      });
    });
  }

  const handleReset = () => {
    setFilterData({});
    setSerchInput("");
  };
  const countryOptions = [
    { text: "All", value: null },
    { text: "India", value: "India" },
    { text: "US", value: "US" },
  ];

  const handleChange = (e: any, { type }: any) => {
    if (type === "search") {
      if (e?.target?.value === null || e?.target?.value === "") {
        setSerchInput(e?.target?.value);
        setDisabled(true);
        setFilterData({ ...filterData, ["search"]: "" });
      } else {
        setSerchInput(e?.target?.value);
      }
    } else {
      const { name, value } = e?.target;
      setFilterData({ ...filterData, [name]: value });
    }
  };

  // const handleSearch = (e: any) => {
  //   e?.preventDefault();
  //   // handle search call by using searchInput value
  //   setFilterData({ ...filterData, ["search"]: searchInput?.toLowerCase() });
  // };

  const handleSearch = (e: any) => {
    e?.preventDefault();
    var mobileNumber = "";
    var searchNumberInput = "";
    if (searchInput.match(/[a-z]/i)) {
      searchNumberInput = searchInput;
    } else {
      searchNumberInput = searchInput
        ?.replace(/-/g, "")
        ?.split(" ")
        ?.filter(Boolean)
        ?.join("");
    }
    // const numericSearchInput = searchNumberInput?.replace(/\D/g, '');
    if (
      searchNumberInput?.startsWith("+91") ||
      searchNumberInput?.startsWith("+1")
    ) {
      if (searchNumberInput?.startsWith("+91")) {
        mobileNumber = searchNumberInput.slice(3);
      } else if (searchNumberInput?.startsWith("+1")) {
        mobileNumber = searchNumberInput.slice(2);
      }
      setFilterData({
        ...filterData,
        ["search"]: mobileNumber,
      });
    } else {
      setFilterData({
        ...filterData,
        ["search"]: searchNumberInput?.toLowerCase(),
      });
    }
  };

  useEffect(() => {
    if (searchInput) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [searchInput]);

  const addPlatformUser = () => {
    setAddPlatformModal(true);
  };

  return (
    <>
      <Grid
        container
        rowSpacing={1.2}
        columnSpacing={3.76}
        marginBottom="3vh"
        justifyContent={"right"}
      >
        <Grid item xl={2} lg={2.8} md={4} sm={7.2} xs={12}>
          <span className="manageOrderHeading" data-testid="manage-title">
            Manage Users 
          </span>
          <span className="countFonts">({rowCount})</span>
        </Grid>
        <Grid item xl={2.5} lg={2.9} md={3.5} sm={4.8} xs={12}>
          <SEARCHBAR
            data-testid="search-bar"
            key="search-bar"
            value={searchInput}
            onChange={(e: any) => {
              handleChange(e, { type: "search" });
            }}
            setValue={setSerchInput}
            handleSearchClick={(e: any) => handleSearch(e)}
            disabled={disabled}
            placeholder="Search name, Mobile number"
          />
        </Grid>

        {/* status  */}
        <Grid
          item
          xl={1.5}
          lg={1.8}
          md={3.2}
          sm={3.5}
          xs={6}
          order={{ lg: 1, xl: 2, md: 4, sm: 3 }}
        >
          <SelectInput
            value={filterData?.status ? filterData?.status : ""}
            className="filterStyle "
            placeholder="Status"
            onChange={handleChange}
            name="status"
            optionValue={userStatusOptions}
          />
        </Grid>

        {/* location  */}
        <Grid
          item
          xl={1.5}
          lg={1.8}
          md={3.2}
          sm={3.5}
          xs={6}
          order={{ md: 2, sm: 2 }}
        >
          <SelectInput
            value={filterData?.country ? filterData?.country : ""}
            className="filterStyle "
            placeholder="Location"
            onChange={handleChange}
            name="country"
            // optionValue={countryOptions}
            optionValue={countriesNames}
          />
        </Grid>

        {/* user type  */}
        <Grid
          item
          xl={1.2}
          lg={2.7}
          md={3.2}
          sm={5}
          xs={6}
          order={{ lg: 2, xl: 1, md: 3, sm: 1 }}
        >
          <SelectInput
            value={filterData?.roleId ? filterData?.roleId : ""}
            className="filterStyle"
            placeholder="User Type"
            onChange={handleChange}
            name="roleId"
            optionValue={userTypeOptions}
          />
        </Grid>
        {/*  */}

        <Grid
          item
          xl={1.1}
          lg={1.7}
          md={2.4}
          sm={4}
          xs={6}
          order={{ lg: 3, xl: 3, md: 5, sm: 4 }}
        >
          <Buttons
            startIcon="reset"
            variant="contained"
            type="primary"
            className="resetButton"
            text="Reset"
            onClick={handleReset}
          />
        </Grid>
        <Grid
          item
          xl={2.1}
          lg={2.78}
          md={4.5}
          sm={8}
          xs={12}
          order={{ lg: 4, xl: 4, md: 1, sm: 5 }}
        >
          <Buttons
            buttonType="button"
            variant="contained"
            text="Add Platform User"
            type="primary"
            onClick={addPlatformUser}
          />
        </Grid>
      </Grid>

      <AddPlatformModal
        addPlatformModal={addPlatformModal}
        setAddPlatformModal={setAddPlatformModal}
        refetchData={refetchPlatformUser}
      />
    </>
  );
};
