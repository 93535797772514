import { Divider, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import Buttons from "../../common/button";
import "./style.css";
import { Table } from "../../common/table";
import { useEffect, useState } from "react";
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  useActivatePlanMutation,
  useDeactivatePlansMutation,
  useGetAllPlansQuery,
  usePlanDetailsMutation,
  useSaveActivatePlanMutation,
} from "../../../redux/services/planApiSlice";
import { CreatePlan } from "./createPlanModal";
import { ConfirmModal } from "../../common/confirmmodal";
import { Messages } from "../../../constants/messages";
import { PreviewPlan } from "./previewPlan";
import { handleErrors, handlePriceFormatting } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/loader/loader";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { setManagePlanFilters, setPaginationFilter } from "../../../redux/features/filters/filtersSlice";
import { SelectInput } from "../../common/selectinput";
import { SEARCHBAR } from "../../common/search/searchbar";
import { UserStatus } from "../../../constants/enum";
import { PLAN_TYPES } from "../../../constants/constant";

export const ManagePlans = () => {
  const [planRows, setPlanRows] = useState<any>([]);
  const [rowCount, setRowCount] = useState(0);
  const [createPlan, setCreatePlan] = useState(false);
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [isUpgradeEdit, setIsUpgradeEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const filtersData = useSelector((state: any) => state.filters);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: filtersData?.pageSize,
    page: filtersData?.page,
  });
  const [editPlan, setEditPlan] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [planId, setPlanId] = useState<any>();
  const [selectedRow, setSelectedRow] = useState();
  const [rowData, setRowData] = useState<any>();
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);
  const [isSaveActivateModalOpen, setIsSaveActivateModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [tableHeight, setTableHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [searchInput, setSerchInput] = useState<string>(
    filtersData?.managePlanSearch
  );
  const [filterData, setFilterData] = useState<any>({
    search: filtersData?.managePlanSearch,
    isActive: filtersData?.planStatus,
  });
  console.log(filtersData?.planStatus, "status");
 
  const getFilteredParams = () => {
    var data = {};
    console.log(Object.entries(filterData),"entryobject")
    data = Object.fromEntries(
      Object.entries(filterData).filter(([_, v]) => v != "" && v != null || v===false)
    );
    console.log(data,"checData")
    return data;
  };  

  //API Integration
  const {
    data,
    isSuccess,
    refetch,
    isError,
    fulfilledTimeStamp,
    isLoading,
    error,
  } = useGetAllPlansQuery({
    page: paginationModel?.page + 1,
    limit: paginationModel?.pageSize,
    planType: PLAN_TYPES.inHouse,
    ...getFilteredParams(),
  });
  const [deactivatePlan, deactivatePlanInfo] = useDeactivatePlansMutation();
  const [plan, planInfo] = usePlanDetailsMutation();
  const [activatePlan, activatePlanInfo] = useActivatePlanMutation();
  const [saveActivatePlan, saveActivatePlanInfo] =
    useSaveActivatePlanMutation();


  const handleEdit = () => {
    if (planRows[rowData?.id]?.planType?.toLowerCase() === "upgrade") {
      setIsUpgradeEdit(true);
      setAnchorEl(null);
    } else {
      setEditPlan(true);
      setAnchorEl(null);
    }
  };

  const handleCreateUpgrade = () => {
    setIsUpgrade(true);
    setAnchorEl(null);
  };

  const handleDeactivate = () => {
    setIsDeactivateModalOpen(true);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
    setAnchorEl(null);
  };

  const handleMenuClick = (event: any, row: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setPlanId(planRows[row?.id]?.subscriptionPlanId);
    setRowData(row);
  };

  const handleClose = (event: any) => {
    setAnchorEl(null);
  };

  const onCreatePlanClick = () => {
    setCreatePlan(true);
  };
  const handleActivate = () => {
    setIsActivateModalOpen(true);
    setAnchorEl(null);
  };
  const handleSaveActivate = () => {
    setIsSaveActivateModalOpen(true);
    setAnchorEl(null);
  };


    const getPlanTotal = (data:any) => {
      let total = 0
      if(data?.partnerOffering && data?.partnerOffering?.recurringAmount){
 
            total = parseFloat(data?.recurringAmount) + parseFloat(data?.partnerOffering?.recurringAmount)
      } else {
      
          total = data?.recurringAmount
        
      }
      return total
    }

  const columns: GridColDef[] = [
    {
      field: "planType",
      headerName: "Plan Type",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding topAlign",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.planType?.substring(0, 1) +
              params?.row?.planType?.substring(1).toLowerCase()}
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Plan Name",
      align: "left",
      cellClassName: "cell-padding topAlign textCapital",
      headerAlign: "left",
      minWidth: 180,
      sortable: false,
      flex: 1,
    },
    {
      field: "description",
      cellClassName: "cell-padding topAlign textCapital",
      headerName: "Sub Details",
      minWidth: 200,
      headerAlign: "left",
      align: "left",
      sortable: false,
      flex: 2,
    },
    {
      field: "price",
      headerName: "Price",
      cellClassName: "cell-padding topAlign",
      headerAlign: "left",
      align: "left",
      headerClassName: "lineBreak",
      type: "number",
      minWidth: 200,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.currency} {handlePriceFormatting(getPlanTotal(params?.row),params?.row?.currency)} per{" "}
            {params?.row?.billingFrequency.toLowerCase()}
          </>
        );
      },
    },
    {
      field: "planDetails",
      cellClassName: "cell-padding topAlign",
      headerName: "Plan Details",
      headerAlign: "left",
      align: "left",
      type: "number",
      minWidth: 250,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{width:"100%"}}dangerouslySetInnerHTML={{__html: params?.row?.remarks}}>
            {/* <span>
              {params?.row?.currency} {handlePriceFormatting(params?.row?.fixedAmount,params?.row?.currency)} One-time device
              cost
              <br />
              <br />
              Supports&nbsp;upto&nbsp;{params?.row?.beneficiaryCount}
              &nbsp;beneficiaries
              <br />
              <br />
              {params?.row?.description}
            </span> */}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      cellClassName: "cell-padding topAlign",
      align: "center",
      type: "number",
      minWidth: 110,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.draft ? (
              <span className="draftClass">DRAFT</span>
            ) : params?.row?.isActive ? (
              <span className="activeClass">ACTIVE</span>
            ) : (
              <span className="DeactiveClass">Deactivated</span>
            )}
          </>
        );
      },
    },
    {
      field: "action",
      sortable: false,
      align: "left",
      headerAlign: "left",
      cellClassName: "cell-padding topAlign stickyCell",
      headerClassName: "",
      headerName: "",
      width: 10,
      renderCell: (params) => (
        <IconButton
          onClick={(event: any) => handleMenuClick(event, params?.row)}
        >
          <MoreVertIcon />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    if (isSuccess) {
      const rowData = data?.result.map((row: any, index: any) => ({
        id: index,
        ...row,
      }));
      setRowCount(data?.count);
      setPlanRows(rowData);
    }
  }, [data, isSuccess]);
  useEffect(() => {
    handleErrors({ error, isError }, dispatch);
  }, [isError]);

  useEffect(() => {
    dispatch(setManagePlanFilters(filterData));
  }, [filterData]);


  useEffect(() => {
    if (deactivatePlanInfo?.isSuccess) {
      setIsDeactivateModalOpen(false);
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: deactivatePlanInfo?.data?.message,
        })
      );
      refetch();
    }
  }, [deactivatePlanInfo?.isSuccess]);

  useEffect(() => {
    handleErrors(deactivatePlanInfo, dispatch);
  }, [deactivatePlanInfo?.isError]);

  useEffect(() => {
    if (planInfo?.isSuccess) {
      setSelectedRow(planInfo?.data?.result);
    }
  }, [planInfo?.isSuccess]);

  useEffect(() => {
    handleErrors(planInfo, dispatch);
  }, [planInfo?.isError]);

  useEffect(() => {
    if (activatePlanInfo?.isSuccess) {
      setIsActivateModalOpen(false);
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: activatePlanInfo?.data?.message,
        })
      );
      refetch();
    }
  }, [activatePlanInfo?.isSuccess]);

  useEffect(() => {
    handleErrors(activatePlanInfo, dispatch);
  }, [activatePlanInfo?.isError]);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
    dispatch(setPaginationFilter(newPaginationModel));
  };

  const onConfirmCallBack = () => {
    deactivatePlan({ id: planId });
  };

  const onCancelcallBack = () => {
    setIsDeactivateModalOpen(false);
  };
  const onDeleteConfirm = () => {
    setIsDeleteModalOpen(false);
  };
  const handleView = () => {
    plan({ id: planId });
    setViewModal(true);
    setAnchorEl(null);
  };
  const handleRowClick = (planId:any) => {
    plan({ id: planId });
    setViewModal(true);
    setAnchorEl(null);
  };
  const onActivateConfirm = () => {
    activatePlan({ id: planId });
  };
  const onSaveActivateConfirm = () => {
    saveActivatePlan({ id: planId });
  };

  const calHeight = () => {
    let targetCompo,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetCompo = window.document.querySelector<HTMLElement>(".managePlan");
    targetElement = targetCompo?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 25);
    setTableHeight(tableHeight);
  };

  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
      setScreenWidth(window.innerWidth);
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  useEffect(() => {
    if (saveActivatePlanInfo?.isSuccess) {
      setIsSaveActivateModalOpen(false);
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: saveActivatePlanInfo?.data?.message,
        })
      );
      refetch();
    }
  }, [saveActivatePlanInfo?.isSuccess]);
  useEffect(() => {
    if (saveActivatePlanInfo?.isError) {
      handleErrors(saveActivatePlanInfo, dispatch);
    }
  }, [saveActivatePlanInfo?.isError]);

  const userStatusOptions = [
    { text: "All", value: null },
    { text: "Active", value: UserStatus.ACTIVE },
    { text: "Deactivated", value: UserStatus.DEACTIVATED },
  ];
  
  console.log(filterData, "filter");
  

  const handleChange = (e: any, { type }: any) => {
    if (type === "search") {
      if (e?.target?.value === null || e?.target?.value === "") {
        setSerchInput(e?.target?.value);
        setDisabled(true);
        setFilterData({ ...filterData, ["search"]: "" });
      } else {
        setSerchInput(e?.target?.value);
      }
    }
    else if(type === "status"){
      console.log(e.target.value,"lop")
      if(e?.target?.value === "active"){
        setFilterData({...filterData, ["isActive"]: true})
      } else if(e?.target?.value === "inactive"){
        setFilterData({...filterData, ["isActive"]: false})
      }
      else{
        setFilterData({...filterData, ["isActive"]: null})

      }
    }
    else {
      const { name, value } = e?.target;
      setFilterData({ ...filterData, [name]: value });
    }
  };
  console.log(filterData,"filterData1")


  const handleSearch = (e: any) => {
    e?.preventDefault();
    // handle search call by using searchInput value
    setFilterData({ ...filterData, ["search"]: searchInput?.toLowerCase() });
  }

    const handleReset = () => {
      setFilterData({});
      setSerchInput("");
    };

    useEffect(() => {
      if (searchInput) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }, [searchInput]);
    console.log(isLoading,"isLoading")

  return (
    <>
      <Loader
        show={
          isLoading ||
          deactivatePlanInfo?.isLoading ||
          planInfo?.isLoading ||
          saveActivatePlanInfo?.isLoading ||
          activatePlanInfo?.isLoading
        }
      />
      <Grid container rowSpacing={1.2} columnSpacing={3} marginBottom={2}>
        <Grid item xl={4.8} lg={3.4} md={4.5} sm={7} xs={12}>
          <span className="subscriberColumn1">Manage Plans</span>
          <span className="countFonts">({rowCount})</span>
        </Grid>
        <Grid item xl={2.5} lg={2.9} md={3} sm={5} xs={12}>
          <SEARCHBAR
            data-testid="search-bar"
            key="search-bar"
            value={searchInput}
            onChange={(e: any) => {
              handleChange(e, { type: "search" });
            }}
            setValue={setSerchInput}
            handleSearchClick={(e: any) => handleSearch(e)}
            disabled={disabled}
            placeholder="Search plan name"
          />
        </Grid>
        <Grid
          item
          xl={1.5}
          lg={1.5}
          md={2.5}
          sm={3.3}
          xs={6}
        >
          <SelectInput
            value={filterData?.isActive!=null?filterData?.isActive ? "active":"inactive" : ""}
            className="filterStyle"
            placeholder="Status"
            onChange={(e: any) => {
              handleChange(e, { type: "status" });
            }}            
            name="status"
            optionValue={userStatusOptions}
          />
        </Grid>
        <Grid
          item
          xl={1.1}
          lg={1.4}
          md={2}
          sm={3.5}
          xs={6}
        >
          <Buttons
            startIcon="reset"
            variant="contained"
            type="primary"
            className="resetButton"
            text="Reset"
            onClick={handleReset}
          />
        </Grid>
        <Grid item xl={2.1} lg={2.7} md={3.7} sm={5.2} xs={12}>
          <Buttons
            type="primary"
            variant="contained"
            startIcon="add"
            text={"Create Plan"}
            onClick={onCreatePlanClick}
            primaryBtnTextClass={"createCouponBtnText"}
          ></Buttons>
        </Grid> 

        {/* <Grid item xs={12} sm={7} md={9} xl={10.5}>
          <span className="subscriberColumn1">Manage Plans</span>
        </Grid>
        <Grid item xs={12} sm={5} md={3} xl={1.5}>
          <Buttons
            type="primary"
            variant="contained"
            startIcon="add"
            text={"Create Plan"}
            onClick={onCreatePlanClick}
            primaryBtnTextClass={"createCouponBtnText"}
          ></Buttons>
        </Grid> */}
      </Grid>

      <div className="managePlan">
        <Table
          rows={planRows}
          columns={columns}
          rowCount={rowCount}
          isPagination={true}
          onPaginationModelChange={handlePaginationModelChange}
          pagination={paginationModel}
          showHover={true}
          onRowClick={(params: any) => {
            handleRowClick(planRows[params?.row?.id]?.subscriptionPlanId);
          }}
          tableHeight={tableHeight}
          data-testid={"managePlanCol"}
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ width: "4000px" }}
      >
        <MenuItem onClick={handleView}>View</MenuItem>
        <Divider className="divider-mp" />
        {rowData?.isActive === true ? (
          <>
            <MenuItem onClick={handleEdit}>Edit</MenuItem>
            <Divider className="divider-mp" />
          </>
        ) : (
          <></>
        )}

        {rowData?.draft ? (
          <MenuItem onClick={handleSaveActivate}>Save & Activate</MenuItem>
        ) : !rowData?.isActive ? (
          <MenuItem onClick={handleActivate}>Activate</MenuItem>
        ) : (
          <MenuItem onClick={handleDeactivate}>Deactivate</MenuItem>
        )}

        {/* {rowData?.planType?.toLowerCase() == "base" && !rowData?.draft ? (
          <span>
            <Divider className="divider-mp" />
            <MenuItem onClick={handleCreateUpgrade}>
              Create Upgrade Plan
            </MenuItem>
          </span>
        ) : null} */}

        {/* <Divider className="divider-mp" />
        <MenuItem onClick={handleDelete}>Delete</MenuItem> */}
      </Menu>
      {createPlan && (
        <CreatePlan
          editPlan={editPlan}
          createPlan={createPlan}
          setCreatePlan={setCreatePlan}
          refetch={refetch}
        />
      )}
      {editPlan && (
        <CreatePlan
          editPlan={editPlan}
          createPlan={editPlan}
          setCreatePlan={setEditPlan}
          refetch={refetch}
          planInfo={planInfo}
          planId={planId}
        />
      )}
      {isUpgrade && (
        <CreatePlan
          editPlan={editPlan}
          isUpgrade={isUpgrade}
          createPlan={isUpgrade}
          setCreatePlan={setIsUpgrade}
          refetch={refetch}
          planInfo={planInfo}
          planId={planId}
        />
      )}
      {isUpgradeEdit && (
        <CreatePlan
          editPlan={editPlan}
          isUpgrade={isUpgrade}
          isUpgradeEdit={isUpgradeEdit}
          createPlan={isUpgradeEdit}
          setCreatePlan={setIsUpgradeEdit}
          refetch={refetch}
          planInfo={planInfo}
          planId={planId}
        />
      )}
      {viewModal && (
        <PreviewPlan
          selectedRow={selectedRow}
          isModalOpen={viewModal}
          setModalOpen={setViewModal}
          isView={true}
        />
      )}
      <ConfirmModal
        className="confirmModal"
        key="deactivateModal"
        open={isDeactivateModalOpen}
        primaryText={Messages.DEACTIVATEPLAN}
        cancelButton="NO"
        confirmButton="YES"
        cancelCallback={onCancelcallBack}
        confirmCallback={onConfirmCallBack}
      />
      <ConfirmModal
        className="confirmModal"
        key="deleteModal"
        open={isDeleteModalOpen}
        primaryText={Messages.DELETEPLAN}
        cancelButton="NO"
        confirmButton="YES"
        cancelCallback={() => {
          setIsDeleteModalOpen(false);
        }}
        confirmCallback={onDeleteConfirm}
      />
      <ConfirmModal
        className="confirmModal"
        key="activateModal"
        open={isActivateModalOpen}
        primaryText={Messages.ACTIVATEPLAN}
        cancelButton="NO"
        confirmButton="YES"
        cancelCallback={() => {
          setIsActivateModalOpen(false);
        }}
        confirmCallback={onActivateConfirm}
      />
      <ConfirmModal
        className="confirmModal"
        key="saveactivateModal"
        open={isSaveActivateModalOpen}
        primaryText={Messages.SAVEACTIVATEPLAN}
        cancelButton="NO"
        confirmButton="YES"
        cancelCallback={() => {
          setIsSaveActivateModalOpen(false);
        }}
        confirmCallback={onSaveActivateConfirm}
      />
    </>
  );
};
