import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, IconButton, Menu, MenuItem } from "@mui/material";
import backIcon from "../../../../assets/icons/backIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import "./accoutManageDevice.css";
import { Table } from "../../../common/table";
import { GridColDef, GridMoreVertIcon, GridPaginationModel } from "@mui/x-data-grid";
import { OrderNewDevice } from "./orderNewDeviceModal";
import { useGetAllDevicesByUserIdQuery } from "../../../../redux/services/devicesApiSlice";
import { getLoggedInUser } from "../../../../redux/features/auth/authSlice";
import { handleErrors } from "../../../../utils";
import Loader from "../../../common/loader/loader";
import { getDeviceOrderInfo } from "../../../../redux/features/deviceOrder/deviceOrderSlice";
import { Page } from "../../../../routes/config";
import { OrderConformationModal } from "./orderConfirmationModal";
import dayjs from "dayjs";
import { setPaginationFilter } from "../../../../redux/features/filters/filtersSlice";
import Buttons from "../../../common/button";
import { PurchaseNewDevice } from "./purchaseNewDevice";
import { setSubscriptionReviewModal } from "../../../../redux/features/changeSubscription/changeSubscriptionSlice";
import { DeviceOrderConfirmationModal } from "../acoountSubscription/modal/deviceOrderConfirmationModal";
import { EditDeviceOrderModal } from "./editViewDevices";

export const AccountManageDevice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deviceOrderData = useSelector((state: any) => state?.order);
  const [tableHeight, setTableHeight] = useState(0);
  const [reviewOrderOpen, setReviewOrderOpen] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [deviceOrderId, setDeviceOrderId] = useState<string>("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const filtersData = useSelector((state: any) => state.filters);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: filtersData?.pageSize,
    page: filtersData?.page,
  });
  const [openOrderDeviceModal, setOpenOrderDeviceModal] = useState(false);
  const [purchaseDevice, setPurchaseDevice] = useState<any>(false);
  const [rowData, setRowData] = useState<any>([]);
  const [rowCount, setRowCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deviceId, setDeviceId] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);

  const userId = useSelector(getLoggedInUser);
  const { state, pathname } = useLocation();
  const [id, setId] = useState();

  useEffect(() => {
    if (state?.view) {
      setId(state?.userData?.User?.userId);
    } else {
      setId(userId);
    }
  }, []);
  const {
    data,
    isSuccess,
    refetch,
    isError,
    fulfilledTimeStamp,
    error,
    isLoading,
  } = useGetAllDevicesByUserIdQuery(
    {
      limit: paginationModel?.pageSize,
      page: paginationModel?.page + 1,
      id: id,
    },
    {
      skip: id == undefined ? true : false,
    }
  );

  const calHeight = () => {
    let targetComp,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetComp = window.document.querySelector<HTMLElement>(
      ".accountManageDevice"
    );
    targetElement = targetComp?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 25);
    setTableHeight(tableHeight);
  };

  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
      setScreenWidth(window.innerWidth);
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
    dispatch(setPaginationFilter(newPaginationModel));
  };

  useEffect(() => {
    if (isSuccess) {
      setRowData(
        data?.result?.map((row: any, index: any) => ({ id: index, ...row }))
      );
      setRowCount(data?.count);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      handleErrors({ error, isError }, dispatch);
    }
  }, [isError]);

  useEffect(() => {
    if (deviceOrderData?.id) {
      setDeviceOrderId(deviceOrderData?.id);
      setReviewOrderOpen(true);
    }
  }, []);

  useEffect(() => {
    if (pathname == Page.PAYMENTMANAGEDEVICES) {
      dispatch(
        setSubscriptionReviewModal({
          subscriptionDeviceReview: false,
        })
      );
      setPaymentModal(true);
    }
  }, []);

  const handleEdit = (event: any) => {
    setAnchorEl(null);
    setIsEdit(true);
    setOpen(true);
  };

  const handleMenuClick = (event: any, id: any) => {
    setAnchorEl(event?.currentTarget);
    setDeviceId(rowData[id]);
    console.log(rowData[id], "devicetypeid")
  };

  const handleClose = (event: any) => {
    setAnchorEl(null);
  };


  const labels: any = {
    ["Blood Glucose"]: "Glucometer",
    ["Blood Pressure"]: "BP Monitor",
    ["Temperature"]: "Thermometer",
    ["PulseOx"]: "Pulse Oximeter",
    ["Weight"]: "Weighing Scale",
  };

  const columns: GridColDef[] = [
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding topAlign",
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="tableCellDataStyle">
            {params?.row?.DeviceType?.manufacturer}
          </span>
        );
      },
    },
    {
      field: "biometricType",
      headerName: "Type",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "cell-padding topAlign",
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <span className="tableCellDataStyle">
            {labels[params?.row?.DeviceType?.biometricType]}
          </span>
        );
      },
    },
    {
      field: "model",
      headerName: "Model No",
      minWidth: 220,
      headerAlign: "left",
      cellClassName: "cell-padding topAlign",
      align: "left",
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <span className="tableCellDataStyle textWordBreak">
            {params?.row?.DeviceType?.model}
          </span>
        );
      },
    },
    {
      field: "serialNumber",
      headerName: "Serial No",
      headerAlign: "left",
      align: "left",
      headerClassName: "lineBreak",
      cellClassName: "cell-padding topAlign",
      minWidth: 200,
      sortable: false,
      flex: 1,
    },
    {
      field: "orderNumber",
      headerName: "Order No",
      headerAlign: "left",
      cellClassName: "cell-padding topAlign",
      align: "left",
      minWidth: 200,
      sortable: false,
      flex: 1,
    },
    {
      field: "shipsDate",
      headerName: "Ship Date",
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-padding topAlign",
      minWidth: 120,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return <>{dayjs(params?.row?.shipDate)?.format("DD MMM, YYYY")}</>;
      },
    },
    {
      field: "LastReadingDate",
      headerName: "Last Reading Date",
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-padding topAlign",
      minWidth: 160,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span>
              {params?.row?.androidId?.lastReading
                ? dayjs(params?.row?.androidId?.lastReading)?.format(
                    "DD MMM, YYYY"
                  )
                : ""}
            </span>
          </>
        );
      },
    },
    {
      field: "MAC",
      headerName: "Device MAC",
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-padding topAlign",
      minWidth: 150,
      sortable: false,
      flex: 1,
    },
    {
      field: "IPAddress",
      headerName: "IP Address",
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-padding topAlign",
      minWidth: 180,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span className="wordBreak">
              {params?.row?.androidId?.ipAddress}
            </span>
          </>
        );
      },
    },
    {
      field: "expiryDate",
      headerName: "Warranty Expiry Date",
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-padding topAlign",
      minWidth: 200,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.shipDate && params?.row?.DeviceType?.warrantyPeriod
              ? dayjs(params?.row?.shipDate)
                  .add(params?.row?.DeviceType?.warrantyPeriod, "year")
                  .format("DD MMM, YYYY")
              : " "}
          </>
        );
      },
    },
    {
      field: "isActive",
      headerName: "Status",
      headerAlign: "left",
      cellClassName: "cell-padding topAlign topAlign",
      align: "left",
      type: "number",
      minWidth: 110,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.DeviceType?.isActive ? (
              <span className="activeClass">ACTIVE</span>
            ) : (
              <span className="DeactiveClass">INACTIVE</span>
            )}
          </>
        );
      },
    },
    {
      field: "action",
      sortable: false,
      align: "left",
      cellClassName: "stickyCell cell-padding",
      headerAlign: "left",
      headerClassName: "stickyColumn",
      headerName: "",
      width: 0.5,
      renderCell: (params) => (
        <>
        {state?.view ? 
        (<IconButton
          onClick={(event: any) => handleMenuClick(event, params?.row?.id)}
        >
          <GridMoreVertIcon />
        </IconButton>)
        : (<></>)
        }
        </>
      ),
    },
  ];

  const handleNavigate=()=>{
    if(state?.view){
      navigate(-1)
    } else{
      navigate(Page?.ACCOUNTSETTING)
    }
  }

  return (
    <>
      <Loader show={isLoading} />
      <PurchaseNewDevice
      setPurchaseDevice={setPurchaseDevice}
      purchaseDevice={purchaseDevice}
      />

      <OrderNewDevice
        open={openOrderDeviceModal}
        setOpen={setOpenOrderDeviceModal}
        reviewOrderOpen={reviewOrderOpen}
        setReviewOrderOpen={setReviewOrderOpen}
        deviceOrderId={deviceOrderId}
        setDeviceOrderId={setDeviceOrderId}
      />
      {/* <OrderConformationModal
        deviceOrderId={deviceOrderId}
        open={paymentModal}
        setOpen={setPaymentModal}
      /> */}
      <DeviceOrderConfirmationModal
        deviceOrderId={deviceOrderId}
        open={paymentModal}
        setOpen={setPaymentModal}
      />
      <Grid container marginBottom={"20px"}>
        <Grid item className="headingContainer">
          <span className="history-backIcon-pd">
            <img
              src={backIcon}
              className="iconHeight"
              alt="backIcon"
              onClick={handleNavigate}
            />
          </span>
          <span className="headerTextSpan">
            <span className="headingDarkText headingLightText capitalise">
              {state?.view ? state?.userData?.User?.fullName : "My Account"}
            </span>
            <span className="headingDarkText"> / Manage Devices</span>
          </span>
          
        </Grid>
        {/* {!state?.view && (
          <Grid
            item
            xs={7.4}
            sm={screenWidth > 611 ? 3.86 : 7.4}
            md={3}
            lg={2.29}
            xl={1.8}
          >
            <Buttons
              // disabled
              buttonType="button"
              variant="contained"
              text="Order New Device"
              type="primary"
              primaryBtnTextClass="orderDeviceBtnText"
              className="orderDeviceBtn"
              onClick={()=> setPurchaseDevice(true)}
              // onClick={() => setOpenOrderDeviceModal(true)}
            />
          </Grid>
        )} */}
      </Grid>
      <Grid item xs={12}>
        <div className="accountManageDevice">
          <Table
            rowCount={rowCount}
            rows={rowData}
            columns={columns}
            isPagination={true}
            onPaginationModelChange={handlePaginationModelChange}
            pagination={paginationModel}
            tableHeight={tableHeight}
          />
        </div>
        <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ width: "4000px" }}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        {/* <Divider/> */}
        {/* <MenuItem onClick={handleClose}>Delete</MenuItem>    */}
      </Menu>
      </Grid>
      <EditDeviceOrderModal
      setIsEdit={setIsEdit}
      isEdit={isEdit}
      deviceId={deviceId}
      rowData={rowData}
      refetch={()=>refetch()}
      />
    </>
  );
};
