import { apiSlice } from "./apiSlice";
import { apiRoutes } from "../../config/api";
import data from "../../types/response.json";
export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: apiRoutes.LOGIN,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    forgotPassword: builder.mutation({
      query: (params) => ({
        url: apiRoutes.FORGOT_PASSWORD,
        method: "POST",
        body: { ...params },
      }),
    }),
    getPrivacy: builder.query({
      query: (params: any) => ({
        url: apiRoutes.GETPRIVACYPOLICY,
        method: "GET",
        params: params,
      }),
    }),
    registerUser: builder.mutation({
      query: (params) => ({
        url: apiRoutes.REGISTER_USER,
        method: "POST",
        body: { ...params },
      }),
    }),
    resetPassword: builder.mutation({
      query: (params) => ({
        url: apiRoutes.CREATE_PASSWORD,
        method: "POST",
        body: { ...params },
      }),
    }),
    logOut: builder.mutation({
      query: ({ email }) => ({
        url: apiRoutes.LOGOUT,
        method: "POST",
        body: { userName: email },
      }),
    }),
    getTermsOfUse: builder.mutation({
      query: (params: any) => {
        return {
          url: apiRoutes.TERMSOFUSE,
          method: "GET",
          params: params,
        };
      },
    }),
    forgotPasscode: builder.mutation({
      query: (params) => ({
        url: apiRoutes.FORGOT_PASSCODE,
        method: "POST",
        body: { ...params },
      }),
    }),
    resetPasscode: builder.mutation({
      query: ({ userId, ...params }) => ({
        url: `${apiRoutes.RESET_PASSCODE}/${userId}`,
        method: "POST",
        body: { ...params },
      }),
    }),
    getIp: builder.query({
      query: (params: any) => ({
        url: "",
        baseUrl: null,
        method: "GET",
      }),
    }),
    getSupportedCountries: builder.query({
      query: (params: any) => {
        return {
          url: `${apiRoutes.SUPPORTED_COUNTRIES}`,
          method: "GET",
          params: params,
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useLogOutMutation,
  useRegisterUserMutation,
  useResetPasscodeMutation,
  useGetPrivacyQuery,
  useGetTermsOfUseMutation,
  useForgotPasscodeMutation,
  useGetIpQuery,
  useGetSupportedCountriesQuery,
} = authApiSlice;
