import {
  Box,
  Card,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { MessageAlert } from "../../common/alert/alert";
import { logo } from "../../../assets/images";
import "./style.css";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../common/input";
import { RegExpressions } from "../../../constants/regExp";
import Buttons from "../../common/button";
import { useForgotPasswordMutation } from "../../../redux/services/authApiSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { handleErrors } from "../../../utils";
import { PrivacyPolicy } from "./userRegistration/privacyPolicy";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../common/loader/loader";
import { useNavigate } from "react-router-dom";
import { Page } from "../../../routes/config";

export const ForgetPassword = () => {
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange" || "onSubmit",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [termOfUse, setTermsofUse] = useState(false);
  const [height, setHeight] = useState(0);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  //API Integration
  const [forgetPassword, forgetPasswordInfo] = useForgotPasswordMutation();
  const onSubmit = (data: any) => {
    forgetPassword({ email: data?.email });
  };
  useEffect(() => {
    if (forgetPasswordInfo?.isSuccess) {
      navigate(Page.LOGIN);
      dispatch(
        setAlert({
          alertType: "success",
          open: true,
          message: forgetPasswordInfo?.data?.message,
        })
      );
    }
  }, [forgetPasswordInfo?.isSuccess]);
  useEffect(() => {
    handleErrors(forgetPasswordInfo, dispatch);
  }, [forgetPasswordInfo?.isError]);

  const onPrivacyPolicyClick = () => {
    setShowPrivacyModal(true);
  };
  const onTermsofuseClick = () => {
    setTermsofUse(true);
    setShowPrivacyModal(true);
  };
  const onModalClose = () => {
    setShowPrivacyModal(false);
    setTermsofUse(false);
  };

  useEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return (
    <>
      <Loader show={forgetPasswordInfo?.isLoading} />
      <Box height={`${height}px`} className="container-body">
        <Grid container justifyContent={"center"} height={"13vh"}>
          <Grid item xs={12}>
            <MessageAlert></MessageAlert>
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"}>
          <Grid item xs={11} sm={11} md={9} lg={5.6} xl={4.4}>
            <Card className="container-card">
              <Grid container justifyContent={"center"}>
                <Grid item xs={10} xl={8.6}>
                  <Box className="logo">
                    <img src={logo} className="loginLogo"></img>
                  </Box>
                  <Typography className="forgetPassword-title">
                    Forgot Password
                  </Typography>
                  <p className="linkEmail-text">
                    Enter the email address associated with your account, and we
                    will email you a link to reset your password.
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Controller
                          render={({
                            field: { ref, ...field },
                            fieldState: { invalid, error },
                          }) => (
                            <Input
                              label="Email"
                              {...field}
                              value={field.value}
                              placeholder="Enter email"
                              error={Boolean(error)}
                              helperText={error?.message ? error?.message : " "}
                              onChange={field.onChange}
                              inputRef={ref}
                              required={true}
                            />
                          )}
                          name="email"
                          control={control}
                          rules={{
                            required: "Please enter email",
                            pattern: {
                              value: RegExpressions?.Email,
                              message: "Please enter valid email",
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container className="mt-44">
                      <Grid item xs={12}>
                        <Buttons
                          type="primary"
                          text="SEND"
                          variant="contained"
                          buttonType="submit"
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} className="policy-textfp grid-item">
                        <span>
                          Click to view{" "}
                          <a href="#" onClick={onPrivacyPolicyClick}>
                            Privacy Policy
                          </a>{" "}
                          and{" "}
                          <a href="#" onClick={onTermsofuseClick}>
                            Terms of Use
                          </a>
                        </span>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"} className="helpContact">
          <Grid item xs={11} sm={11} md={9} lg={5.6} xl={4.4}>
            <Box className="bottom-text">
              <Box>
                <span className="textbtm text-font">
                  <a
                    href="https://familyprohealth.com/faq"
                    className="addunderline"
                  >
                    Help
                  </a>{" "}
                  &nbsp;|&nbsp;{" "}
                  <a
                    href="https://familyprohealth.com/contact-us"
                    className="addunderline"
                  >
                    Contact us
                  </a>
                </span>
              </Box>
              <Box>
                <span className="textbtm lastText">
                  @{new Date().getFullYear()} familyprohealth Inc. All rights
                  reserved.
                </span>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Dialog open={showPrivacyModal} className="PrivacyPolicyModal">
          <DialogTitle className="privacyCardText">
            {termOfUse ? "Terms Of Use" : "Privacy Policy"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <PrivacyPolicy
            modal={true}
            termofUseModal={termOfUse}
            setTermofUseModal={setTermsofUse}
          />
        </Dialog>
      </Box>
    </>
  );
};
