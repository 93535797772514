import {
  Divider,
  FormHelperText,
  Grid,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tickIcon, infoIcon } from "../../../../../assets/icons";
import { clearDeviceOrderState } from "../../../../../redux/features/deviceOrder/deviceOrderSlice";
import {
  useGetOrderSummaryMutation,
  useCreateSubscriptionMutation,
  useDeviceStripeCheckoutMutation,
  useUpdateOrderCouponMutation,
} from "../../../../../redux/services/orderApiSlice";
import {
  handleErrors,
  phoneNumberAutoFormat,
  addressFormat,
  handlePriceFormatting,
} from "../../../../../utils";
import Buttons from "../../../../common/button";
import Loader from "../../../../common/loader/loader";
import { CustomToolTip } from "../../../../common/tooltip";
import { OrderConformationModal } from "../../accoutManageDevice/orderConfirmationModal";
import { Modal } from "../../../../common/modal/modal";
import { setSubscriptionReviewModal } from "../../../../../redux/features/changeSubscription/changeSubscriptionSlice";
import { Input } from "../../../../common/input";
import { CheckOutlined } from "@mui/icons-material";
import { error } from "console";
import { useGetCouponByIdMutation } from "../../../../../redux/services/manageCouponApiSlice";
import { useDeleteOrderMutation, useGetSubscriptionPlanQuery } from "../../../../../redux/services/planApiSlice";
import { clearOrderState, setOrder } from "../../../../../redux/features/order/orderSlice";
import { setChange } from "../../../../../redux/features/user/beneficiarySlice";
import { ConfirmModal } from "../../../../common/confirmmodal";

export const DeviceReviewOrderModal = (props: any) => {
  const dispatch = useDispatch();
  const { id } = useSelector((state: any) => state.order);
  const userId = useSelector((state: any) => state?.auth?.userId);
  const { isChange } = useSelector((state: any) => state?.beneficiary);
  console.log(isChange, "iuiuiui")
  const [couponCode, setCouponCode] = useState<any>("");
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [firstrender, setfirstRender] = useState(true);
  const [serror, setSerror] = useState(false);
  const [error, setError] = useState(false);
  const [trial, setTrial] = useState<any>();
  const [helperText, setHelperText] = useState(false);
  const [subscriptionPlan, setSubscfriptionPlan] = useState<any>([]);
  console.log(subscriptionPlan, "planDatataaa")
  const [remarks, setRemarks] = useState<any>();
  const [cancelConfirmModal, setCancelConfirmModal] = useState<any>();
  const {
    data,
    isSuccess,
    refetch,
    isError,
    fulfilledTimeStamp,
  } = useGetSubscriptionPlanQuery(
    {
      id: userId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: userId === undefined ? true : false,
    }
  );
  console.log(data, "dataaaaa");
  const [updateCoupon, updateCouponData] = useUpdateOrderCouponMutation();
  const [getCouponById, getCouponByIdInfo] = useGetCouponByIdMutation();
  const handleCoupon = () => {
    updateCoupon({ orderId: id, couponCode, planId: (planData && planData?.length > 0 ? planData[0]?.id : subscriptionPlan) });
  };

  const handleModify = () => {
    setShow(false);
  };

  useEffect(()=>{
    if(isSuccess){
      if(data && data?.result && data?.result?.length > 0){
        const subsPlanData = data?.result[0]?.subscriptionPlanId;
        setSubscfriptionPlan(subsPlanData)
        console.log(subsPlanData, "planddaddat")
      }
    }
  },[])

  useEffect(() => {
    if (updateCouponData?.isSuccess) {
      setSerror(false);
      setShow(true);
      orderSummary(id);
    }
  }, [updateCouponData?.isSuccess]);
  useEffect(() => {
    if (updateCouponData?.isError) {
      setSerror(true);
    }
  }, [updateCouponData?.isError]);

  useEffect(() => {
    if (serror || (!firstrender && show)) {
      setHelperText(true);
    } else if (couponCode === "") {
      setHelperText(false);
    } else setHelperText(false);
  }, [show, serror, couponCode]);

  const [orderSummary, orderSummaryData] = useGetOrderSummaryMutation();
  const [deviceOrderCheckout, deviceOrderCheckoutData] =
    useCreateSubscriptionMutation();
  const [deviceStripeCheckout, deviceStripeCheckoutData] = useDeviceStripeCheckoutMutation();
  const [deleteOrder, deleteOrderData] = useDeleteOrderMutation();
  
  console.log(deviceStripeCheckoutData, "devicestripe")
  const {
    setReview,
    review,
    setShippingAdressModal,
    selectedSubscription,
    planData,
    totalSum,
  } = props;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [orderData, setOrderData] = useState<any>({
    address: [],
    beneficiary: [],
    orderInfo: {},
    plan: {},
    subscriber: {},
  });
  console.log(orderData?.orderInfo, "remarking")

  const onCancelcallBack = () => {
    setCancelConfirmModal(false);
  };

  const onConfirmCallBack = () => {
    dispatch(setSubscriptionReviewModal({
      subscriptionDeviceReview: false
    }))
    dispatch(setChange(false));
    dispatch(setOrder({ id: null }))
    setSerror(false)
    setShippingAdressModal(false)
    setShow(false);
    setError(false);
    setHelperText(false);
    setfirstRender(true)
    setCouponCode("")
    if(orderData?.orderInfo?.orderId){
      deleteOrder(orderData?.orderInfo?.orderId)
      console.log()
    }else{
      dispatch(clearDeviceOrderState());
    }
  };

  const handleCancel = () => {
    setCancelConfirmModal(true)
  };

  useEffect(()=>{
    if(deleteOrderData?.isSuccess){
      console.log("inside Deleeeeeeeeeeee")
      dispatch(clearOrderState())
      setReview(false);
      dispatch(clearDeviceOrderState())
      dispatch(setChange(false));
      dispatch(setOrder({ id: null }))
    }
  },[deleteOrderData?.isSuccess])

  useEffect(() => {
    if (deleteOrderData?.isError) {
      handleErrors(deleteOrderData, dispatch);
    }
  }, [deleteOrderData?.isError]);

  useEffect(() => {
    if (review) {
      orderSummary(id);
    }
  }, [review]);

  useEffect(() => {
    if (orderSummaryData?.isSuccess) {
      setOrderData(orderSummaryData?.data?.result);
      setRemarks(orderSummaryData?.data?.result?.orderInfo.remarks)
    }
  }, [orderSummaryData?.isSuccess]);

  useEffect(() => {
    handleErrors(orderSummaryData, dispatch);
  }, [orderSummaryData?.isError]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setScreenWidth(window.innerWidth);
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);


  useEffect(() => {
    if (deviceStripeCheckoutData?.isSuccess) {
      const { result } = deviceStripeCheckoutData?.data
      window.location.href = result?.url;
    }

  }, [deviceStripeCheckoutData?.isSuccess])

  const handleNext = () => {
    deviceStripeCheckout(orderData?.orderInfo?.orderId)
    console.log(orderData?.orderInfo?.orderId, "ooorrder")
  };

  const { userInfo } = useSelector((state: any) => state?.auth);

  useEffect(() => {
    if (deviceOrderCheckoutData?.isSuccess) {
      const { result } = deviceOrderCheckoutData?.data;
      if (result?.links?.length != 0) {
        var redirectData = result?.links?.find(
          (data: any) => data?.rel == "approve"
        );
        window.location.href = redirectData?.href;
      }
    }
  }, [deviceOrderCheckoutData?.isSuccess]);

  useEffect(() => {
    if (deviceOrderCheckoutData?.isError) {
      handleErrors(deviceOrderCheckoutData, dispatch);
    }
  }, [deviceOrderCheckoutData?.isError]);

  const widthMatch = useMediaQuery("(max-width:1000px)");
  console.log(planData,"plandRrrrrrrrrrrrr")

  const handleGetDeviceCost = (deviceData: any, quantity: any) => {
    console.log(userInfo?.country, "sjjjjj");
    let data = deviceData?.DeviceCostings?.filter((item: any) => {
      return item?.country === userInfo?.country;
    });
    if (data && data?.length > 0) {
      return handlePriceFormatting(
        data[0]?.value * quantity,
        userInfo?.country === "IN" ? "IN" : "US"
      );
    }
  };

  const labels: any = {
    "Blood Glucose": "Glucometer",
    "Blood Pressure": "BP Monitor",
    "Temperature": "Thermometer",
    "PulseOx": "Pulse Oximeter",
    "Weight": "Weighing Scale",
  };

  const handleAddressModalOpen=()=>{
    setShippingAdressModal(true)
    dispatch(setChange(true));
    console.log(setChange, "addreesschange")
    dispatch(setSubscriptionReviewModal({
      subscriptionDeviceReview: false
    }))
  }

  return (
    <>
      <Loader
        show={orderSummaryData?.isLoading || deviceOrderCheckoutData?.isLoading || deleteOrderData?.isLoading}
      />
      {/* <OrderConformationModal
        open={paymentModalOpen}
        setReview={setPaymentModalOpen}
      /> */}
      <Modal
        handleClose={handleCancel}
        primaryText={"Review and Pay"}
        open={review}
        xs={10.3}
        sm={10.3}
        md={10.3}
        lg={10.3}
        xl={10.3}
        className="input-padding modal-title outer-pd-device reviewOrderModal"
      >
        <Grid>
          <Grid container marginTop={"10px"} columnSpacing={4} rowSpacing={2}>
            <Grid item xs={remarks === "device purchased" ? 10 : 12} sm={remarks === "device purchased" ? 4.5 : 6} md={remarks === "device purchased" ?4 :6} lg={remarks === "device purchased" ?4.5:5} xl={remarks === "device purchased" ?4.5:5}>
              <div className="content-title orderAddressTextP">
                <span>
                  <img src={tickIcon} alt="TickIcon" />
                </span>
                <span className="orderAddressStyle">Billing Address</span>
              </div>
              {orderData?.address?.map((data: any, index: any) => {
                if (data.addressType == "billing") {
                  return (
                    <div className="orderAddressDetailStyle">
                      <span className="text-premium">
                        {orderData?.subscriber?.name} |{" "}
                      </span>
                      <span className="faded-text">Mobile&nbsp;Number:</span>
                      <span>
                        &nbsp;+91&nbsp;
                        {phoneNumberAutoFormat(
                          orderData?.subscriber?.mobileNumber
                        )}
                      </span>
                      <br />
                      <span>
                        {addressFormat(data)}
                        {/* {data.address1}, {data.address2}, {data.city},{" "}
                        {data.state}, {data.zipCode}, {data.country} */}
                      </span>
                    </div>
                  );
                }
              })}
            </Grid>
            <Grid item xs={remarks === "device purchased" ?10: 12} sm={remarks === "device purchased" ? 4.5: 6} md={remarks === "device purchased" ?5:6} lg={remarks === "device purchased" ?4.5:5} xl={remarks === "device purchased" ?5:5}>
              <div className="content-title orderAddressTextP">
                <span>
                  <img src={tickIcon} alt="TickIcon" />
                </span>
                <span className="orderAddressStyle">Shipping Address</span>
              </div>
              {orderData?.address?.map((data: any, index: any) => {
                if (data.addressType == "shipping") {
                  return (
                    <div className="orderAddressDetailStyle">
                      <span className="text-premium">{data.fullName} | </span>
                      <span className="faded-text">Mobile&nbsp;Number:</span>
                      <span>
                        &nbsp;+91&nbsp;
                        {phoneNumberAutoFormat(data.mobileNumber)}
                      </span>
                      <br />
                      <span>
                        {addressFormat(data)}
                        {/* {data.address1}, {data.address2}, {data.city},{" "}
                        {data.state}, {data.zipCode}, {data.country} */}
                      </span>
                    </div>
                  );
                }
              })}
            </Grid>
            {remarks === "device purchased" ?
              (<Grid item xs={2} sm={3} md={3} lg={3} xl={2.5}>
              <span>
                <Buttons
                  variant="contained"
                  type="secondary"
                  text="CHANGE ADDRESS"
                  onClick={handleAddressModalOpen}
                />
              </span>
            </Grid>) : 
            (<></>)
            }
          </Grid>
          <Divider className="PaymentMethodCardDivider"></Divider>
          <Grid item>
            <div className="content-title orderAddressTextP">
              <span>
                <img src={tickIcon} alt="TickIcon" />
              </span>
              <span className="orderAddressStyle">Payment Method</span>
            </div>
            <div className="PaymentMethodCardContainer">
              {/* <Card className="PaymentMethodCard"> */}
              <Grid
                container
                justifyContent={"center"}
                className="PaymentMethodCard"
              >
                <Grid item xs={11} sm={11} md={11} lg={11.4} xl={11.4}>
                  <div>
                    {/* <Card className="pay-card"> */}
                    <Grid container justifyContent={"center"} sx={{ pb: 3 }}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container sx={{ mt: 2, mb: 1 }}>
                          <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>
                            <span className="product-text">PRODUCT</span>
                          </Grid>
                          {!widthMatch ? (
                            <Grid item xs={2} sm={2} md={3} lg={3} xl={3}>
                              <span className="product-text">Quantity</span>
                            </Grid>
                          ) : (
                            <Grid
                              item
                              xs={2}
                              sm={2}
                              md={3}
                              lg={4}
                              xl={4}
                            ></Grid>
                          )}
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            md={3}
                            lg={3}
                            xl={3}
                            className="product-text priceLine"
                          >
                            <div>
                              PRICE
                              <span className="usd">
                                &nbsp;({orderData?.orderInfo?.currency})
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                        <Divider className="inerHrDivider" />
                        <Grid container>
                          {orderData?.devices?.map(
                            (device: any, index: any) => {
                              return (
                                <>
                                  <Grid item xs={9} sm={9} md={6} lg={6} xl={6}>
                                    <div className="content-inner-title">
                                      <p className="pr-text marginDefineTop ">
                                        {labels[device?.biometricType]}/{device?.model}
                                      </p>
                                      &nbsp;
                                    </div>
                                  </Grid>
                                  <Grid item xs={0} sm={0} md={3} lg={3} xl={3}>
                                    {!widthMatch ? (
                                      <span className="pr-text ">
                                        {device?.quantity}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    className="pr-text priceLine"
                                  >
                                    <div className="price-text alignCenter">
                                      {handleGetDeviceCost(
                                        device,
                                        device?.quantity
                                      )}
                                    </div>
                                  </Grid>
                                  {widthMatch ? (
                                    <>
                                      <Grid
                                        item
                                        xs={8}
                                        sm={8}
                                        md={8}
                                        lg={8}
                                        xl={8}
                                      >
                                        <span className="pr-text ">
                                          <span className="fw-700 subscriberColumn2">
                                            Quantity:&nbsp;
                                          </span>{" "}
                                          {device?.quantity}
                                        </span>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                      ></Grid>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            }
                          )}
                        </Grid>
                        <Divider className="inerHrDivider" />
                        <Grid container justifyContent={"space-between"}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={5}
                            xl={4}
                            sx={{ mt: 3 }}
                            className="reviewpr-text flex-dr"
                          >
                            <Grid
                              className="couponAlign"
                              container
                              spacing={1}
                              display={"flex"}
                              justifyContent={"center"}
                            >
                              <Grid item xs={6} sm={6} md={7} lg={7.2} xl={7.2}>
                                <Input
                                  placeholder="Have a Coupon Code?"
                                  label="COUPON"
                                  value={couponCode}
                                  error={serror || error}
                                  helperText={
                                    couponCode === "" &&
                                    firstrender === false ? (
                                      <FormHelperText className="couponErrorText slice">
                                        <span>Enter Coupon Code</span>
                                      </FormHelperText>
                                    ) : updateCouponData?.isError &&
                                      firstrender === false &&
                                      couponCode !== "" &&
                                      serror === true ? (
                                      <FormHelperText className="couponErrorText slice">
                                        <span>Invalid Coupon Code</span>
                                      </FormHelperText>
                                    ) : updateCouponData?.isSuccess && show ? (
                                      <FormHelperText className="couponSuccessText slice">
                                        <span>Coupon Applied Successfully</span>
                                      </FormHelperText>
                                    ) : (
                                      // setHelperText(false)
                                      ""
                                    )
                                  }
                                  disabled={show}
                                  customInput={
                                    show ? "width-50 couponInput" : "width-50"
                                  }
                                  labelClassName="inputInputBoxes"
                                  onChange={(e: any) => {
                                    setCouponCode(e?.target?.value);
                                  }}
                                  endAdornment={
                                    <InputAdornment
                                      className="checkIcon"
                                      position="end"
                                      sx={{
                                        marginLeft: "-24px",
                                        position: "absolute",
                                        right: "12px",
                                      }}
                                    >
                                      {show ? (
                                        <CheckOutlined className="checkIconDisable" />
                                      ) : (
                                        ""
                                      )}
                                    </InputAdornment>
                                  }
                                />
                              </Grid>
                              {show ? (
                                <Grid item xs={6} sm={6} md={5} lg={4.8} xl={4}>
                                  <Buttons
                                    onClick={handleModify}
                                    variant="text"
                                    text="MODIFY"
                                    // className="modifyMarginTop"
                                    className={
                                      helperText
                                        ? `modifyMarginTopAlign`
                                        : `modifyMarginTop`
                                    }
                                    textClassName="modifyButton"
                                  />
                                </Grid>
                              ) : (
                                <Grid item xs={6} sm={6} md={5} lg={4.8} xl={4}>
                                  <Buttons
                                    onClick={() => {
                                      if (couponCode === "") {
                                        setHelperText(true);
                                        setError(true);
                                        setSerror(false);
                                      }
                                      setfirstRender(false);
                                      if (couponCode != "") {
                                        handleCoupon();
                                      }
                                    }}
                                    variant="text"
                                    text="APPLY"
                                    // className="modifyMarginTop"
                                    className={
                                      helperText
                                        ? `modifyMarginTopAlign`
                                        : `modifyMarginTop`
                                    }
                                    textClassName="modifyButton"
                                  />
                                </Grid>
                              )}
                            </Grid>
                          </Grid>

                          {/* <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={5}
                            xl={4}
                            sx={{ mt: 3 }}
                            className="pr-text flex-dr"
                          >
                            <Grid className="couponAlign" container spacing={1}>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                md={6}
                                lg={7.2}
                                xl={7.2}
                              ></Grid>
                            </Grid>
                          </Grid> */}
                          {/* <Grid item xs={0} sm={0} md={0} lg={3} xl={4}></Grid> */}
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={5}
                            xl={4}
                            className="last-col"
                          >
                            <Grid container className="total-list">
                              <Grid
                                item
                                xs={8}
                                sm={9}
                                md={8.5}
                                lg={7}
                                xl={8}
                                className="price-label-list"
                              >
                                <span>Subtotal: </span>
                                <span className="text-color">
                                  Coupon Discount:
                                </span>
                                <span>Net Amount:</span>
                                <span>
                                  Tax{" "}
                                  {/* <CustomToolTip
                                    title={
                                      "The tax is for the subscription only and is based on your billing location"
                                    }
                                    placement="top"
                                  >
                                    <img className="taxImg" src={infoIcon} />
                                  </CustomToolTip>{" "} */}
                                  :
                                </span>
                                <span>
                                  <b>Total:</b>
                                </span>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sm={3}
                                md={3.5}
                                lg={5}
                                xl={4}
                                className="price-list"
                              >
                                <span>
                                  {handlePriceFormatting(
                                    orderData?.orderInfo?.subTotal,
                                    orderData?.orderInfo?.currency
                                  )}
                                </span>
                                <span>
                                  {" "}
                                  {handlePriceFormatting(
                                    orderData?.orderInfo?.couponDiscount,
                                    orderData?.orderInfo?.currency
                                  )}
                                </span>
                                <span>
                                  {handlePriceFormatting(
                                    orderData?.orderInfo?.netAmount,
                                    orderData?.orderInfo?.currency
                                  )}
                                </span>
                                <span>
                                  {handlePriceFormatting(
                                    orderData?.orderInfo?.taxAmount,
                                    orderData?.orderInfo?.currency
                                  )}
                                </span>
                                <span>
                                  <b>
                                    {handlePriceFormatting(
                                      orderData?.orderInfo?.totalAmount,
                                      orderData?.orderInfo?.currency
                                    )}
                                  </b>
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid container columnSpacing={2} sx={{ mt: 3 }}>
                          <Grid item xs={12} sm={6} md={6} lg={8.5} xl={8.5}></Grid>
                          <Grid item xs={6} sm={3} md={3} lg={2} xl={2}>
                          <Buttons
                              onClick={handleCancel}
                              // disabled={isLoading}
                              // isLoading={isLoading}
                              variant="contained"
                              type="secondary"
                              text="cancel"
                              // className="nxtBtn"
                            />
                          </Grid>
                          <Grid item xs={6} sm={3} md={3} lg={1.5} xl={1.5}>
                          <Buttons
                              onClick={handleNext}
                              // disabled={isLoading}
                              // isLoading={isLoading}
                              variant="contained"
                              type="primary"
                              text="PAY"
                              // className="nxtBtn"
                            />
                          </Grid>
                        </Grid>

                      </Grid>
                    </Grid>
                    {/* </Card> */}
                  </div>
                </Grid>
              </Grid>
              {/* </Card> */}
            </div>
          </Grid>
        </Grid>
      </Modal>

      <div className="modelModal">
          <ConfirmModal
            className="confirmModal"
            key="signOut"
            open={cancelConfirmModal}
            primaryText={"Are you sure you want to cancel the order?"}
            cancelButton="NO"
            confirmButton="YES"
            cancelCallback={onCancelcallBack}
            confirmCallback={onConfirmCallBack}
          />
        </div>
    </>
  );
};
