import React from 'react';
import './Famhealth.css';

function Famhealth() {
  return (
    <span>
        <span className='fam'>
            fam
        </span>
        <span className='health' >
            health
        </span>
        <sup className='r'>
            ®
        </sup>
    </span>
  )
}

export default Famhealth;