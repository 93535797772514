import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiRoutes, baseURL } from "../../config/api";
import { store } from "../store";
import { clearAllStates } from "../../utils";
import {
  getAuthTokens,
  setAuthInfo,
  setAuthTokens,
  setRefreshedAuthTokens,
  setSessionExpiredCheck,
} from "../features/auth/authSlice";
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
var token: any;

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  // credentials: 'include',
  prepareHeaders: (headers, { getState }: any) => {
    // Get auth tokens from auth state
    const {
      auth: { accessToken, idToken, refreshToken, tokenScope, ipAddress },
    }: any = getState();
    
    if (
      accessToken != undefined ||
      idToken != undefined ||
      refreshToken != undefined ||
      tokenScope != null ||
      ipAddress != undefined
    ) {
      token = idToken;
      headers.set("access-token", accessToken);
      headers.set("id-token", idToken);
      headers.set("refresh-token", refreshToken);
      headers.set("token-scope", tokenScope);
      headers.set("ip-address", ipAddress);
    }
    return headers;
  },
});
const ipBaseQuery = fetchBaseQuery({
  baseUrl: "https://api.db-ip.com/v2/free/self",
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result: any;
  if (args?.baseUrl === null) {
    result = await ipBaseQuery(args, api, extraOptions);
  } else {
    if (token) {
      var refreshTime = process.env.REACT_APP_REFRESH_TIME || "5";
      let decodedToken: any = jwt_decode(token);
      console.log(decodedToken?.exp,"decodedToken?.exp")
      
      // let currentTime=new Date("22 Aug, 2023 12:02:27").getTime() / 1000;
      let currentTime = new Date().getTime() / 1000; //current date timestamp
      console.log(decodedToken?.exp - parseInt(currentTime.toString()),"decodedToken?.exp")

      if (
        (decodedToken?.exp - parseInt(currentTime.toString())) / 60 <=
          parseInt(refreshTime?.toString())
      ) {
        if((decodedToken?.exp - parseInt(currentTime.toString())) / 60 > 0){
          const refreshResult: any = await baseQuery(
            { url: `${baseURL}${apiRoutes.REFRESH_TOKEN}`, method: "POST" },
            api,
            extraOptions
          ); //api to get the new tokens using refresh token
          if (refreshResult?.data) {
            const { idToken, accessToken } =
              refreshResult?.data?.result;
            // store the new token
            token = idToken;
            api.dispatch(setRefreshedAuthTokens({ idToken, accessToken }));
            // retry the original query with new access token
            result = await baseQuery(args, api, extraOptions);
          } else {
            token=null;
            api.dispatch(setSessionExpiredCheck(true));
          }
        }
        else{
          token=null;
          api.dispatch(setSessionExpiredCheck(true));
        }
      } else {
        console.log("check1 else");
        result = await baseQuery(args, api, extraOptions);
      }
    } else {
      console.log("check1 else");
      result = await baseQuery(args, api, extraOptions);
    }
  }

  if (
    result?.error?.data?.error?.statusCode === 500 &&
    result?.error?.data?.error?.name == "TokenExpiredError"
  ) {
    api.dispatch(setSessionExpiredCheck(true));
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 1,
});
