import { Grid, Step, StepLabel, Stepper } from "@mui/material";
import { privacySteps } from "../../../../constants/constant";
import { useSelector } from "react-redux";
import { PrivacyPolicy } from "./privacyPolicy";
import { SelectPlan } from "./selectPlan";
import { ReviewPay } from "./reviewPay";
import { BillingShippingAddress } from "./billingShippingAddress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ChoosePlan } from "./choosePlan";

export const UserRegistration = () => {
  const activeStep = useSelector((state: any) => state.userSteps.activeSteps);
  const completedSteps = useSelector(
    (state: any) => state.userSteps.completedSteps
  );

  const getStepContent = (stepIndex: any) => {
    switch (stepIndex) {
      case 1:
        return (
          <PrivacyPolicy activeStep={activeStep} completed={completedSteps} />
        );
      case 2:
        return (
          <ChoosePlan activeStep={activeStep} completed={completedSteps} />
        );
      case 3:
        return (
          <BillingShippingAddress
            activeStep={activeStep}
            completed={completedSteps}
          />
        );
      case 4:
        return <ReviewPay activeStep={activeStep} completed={completedSteps} />;
      default:
        return "Unknown stepIndex";
    }
  };

  return (
    <>
      {/* <Header /> */}

      <div className="sideStepper">
        <Grid container className="stepperGridCotainer">
          <Grid item xs={3.15} sm={3.15} md={3.15} lg={3.15} xl={3.15}>
            <Grid container className="stepperItem">
              <Grid item xs={12} className="privacyPadding">
                <Stepper
                  nonLinear
                  activeStep={activeStep}
                  orientation="vertical"
                  className="steeperLineHeight"
                >
                  {privacySteps.map((step, index) => (
                    <Step key={step.label} completed={completedSteps[index]}>
                      <StepLabel
                        StepIconComponent={
                          !completedSteps[index]
                            ? CheckCircleOutlineIcon
                            : CheckCircleIcon
                        }
                        color="inherit"
                      >
                        <div className="textLabel">
                          <span>{step.label}</span>
                        </div>
                        {activeStep < 2 ? (
                          <span className="stepDescription">
                            {step.description}
                          </span>
                        ) : (
                          <></>
                        )}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item xs={12} className="sidebarHelpContent">
                <div className="stepperSidebarBottomContent">
                  <span className="helpContact">
                    <a
                      href="https://familyprohealth.com/faq"
                      className="addunderline"
                    >
                      Help
                    </a>{" "}
                    &nbsp;|&nbsp;{" "}
                    <a
                      href="https://familyprohealth.com/contact-us"
                      className="addunderline"
                    >
                      Contact us
                    </a>
                  </span>
                  <center className="fphOrigin">
                    ©{new Date().getFullYear()} familyprohealth Inc. All rights
                    reserved.
                  </center>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8.85} sm={8.85} md={8.85} lg={8.85} xl={8.85}>
            <Grid
              item
              xs={12}
              className={
                activeStep != 4 ? "boxConatiner" : "boxContainerWoPadding"
              }
            >
              {getStepContent(activeStep)}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
