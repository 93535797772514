import { Controller, useForm } from "react-hook-form";
import { Modal } from "../../../common/modal/modal";
import { Grid } from "@mui/material";
import { Input } from "../../../common/input";
import Buttons from "../../../common/button";
import { RegExpressions } from "../../../../constants/regExp";
import { useUpdateUserMutation } from "../../../../redux/services/userApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import { setUserName } from "../../../../redux/features/auth/authSlice";
import { handleErrors } from "./../../../../utils";
import Loader from "../../../common/loader/loader";

export const NameModal = (props: any) => {
  const { changeName, setChangeName, refetch, name } = props;
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" || "onSubmit" });
  const userId = useSelector((state: any) => state?.auth?.userId);
  const dispatch = useDispatch();
  const [updateUser, updateUserInfo] = useUpdateUserMutation();
  const onNameCancelcallBack = () => {
    setChangeName(false);
    reset({
      fullName: "",
    });
  };
  const onConfirmNameCallBack = (data: any) => {
    updateUser({ userId: userId, fullName: data?.fullName });
  };
  useEffect(() => {
    if (updateUserInfo?.isSuccess) {
      if (changeName) {
        setChangeName(false);
        dispatch(
          setAlert({
            open: true,
            alertType: "success",
            message: updateUserInfo?.data?.message,
          })
        );
        dispatch(
          setUserName({
            fullName: updateUserInfo?.data?.result?.fullName,
          })
        );
        setValue("fullName", "");
      }
      refetch();
    }
  }, [updateUserInfo?.isSuccess]);
  useEffect(() => {
    handleErrors(updateUserInfo, dispatch);
  }, [updateUserInfo?.isError]);

  useEffect(() => {
    if (changeName) {
      setValue("fullName", name);
    }
  }, [changeName]);

  return (
    <>
      <Loader show={updateUserInfo?.isLoading} />
      <Modal
        key="changeName"
        open={changeName}
        handleClose={() => {
          setChangeName(false);
          reset({
            fullName: "",
          });
        }}
        primaryText="Enter New Name"
        className="accountSetingModal"
      >
        <form onSubmit={handleSubmit(onConfirmNameCallBack)} data-testid="form">
          <Grid container sx={{ marginTop: "40px" }}>
            <Grid item xs={11.9}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Full Name"
                    placeholder="Enter new name"
                    value={field.value}
                    required={true}
                    onChange={field.onChange}
                    helperText={error?.message ? error?.message : " "}
                    error={Boolean(error)}
                    inputRef={ref}
                    autoFocus={true}
                  />
                )}
                name="fullName"
                control={control}
                rules={{
                  required: "Please enter full name",
                  pattern: {
                    value: RegExpressions?.FullName,
                    message:
                      "Enter a valid name with alphanumeric characters only.",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="modalBtn">
            <Grid item xs={6} md={5} lg={4.6}>
              <Buttons
                type="secondary"
                variant="contained"
                onClick={onNameCancelcallBack}
                text="CANCEL"
              ></Buttons>
            </Grid>
            <Grid item xs={6} md={5} lg={4.6}>
              <Buttons
                type="primary"
                variant="contained"
                buttonType="submit"
                text="SUBMIT"
                data-testid="submit-btn"
              ></Buttons>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
};
