export enum Page {
  HOME = "/",
  CREATEACCOUNT = "/createaccount",
  LOGIN = "/",
  DASHBOARD = "/dashboard",
  REPORTS = "/reports",
  MANAGEUSERS = "/manageuser",
  MANAGEPLANS = "/manageplans",
  MANAGECOUPONS = "/managecoupons",
  MANAGEORDERS = "/manageorders",
  NOTIFICATION = "/notification",
  MANAGEUSER_NOTIFICATION = "/manageuser/notification",
  ACCOUNTSETTING = "/accountsetting",
  ACCOUNTSETTING_MANAGESUBSCRIPTIONPLAN = "/accountsetting/subscriptionplan",
  MANAGEUSER_SUBSCRIPTIONPLAN = "/manageuser/view/subscriptionplan",
  MANAGEUSER_ACCOUNTSETTINGS = "/manageuser/accountsetting",
  ORDERHISTORY = "/accountsetting/orderhistory",
  MANAGEUSER_ORDERHISTORY = "/manageuser/view/orderhistory",
  SETTINGSMANAGEDEVICES = "/accountsetting/managedevice",
  PAYMENTMANAGEDEVICES = "/accountsetting/managedevice/paymentstatus",
  PAYMENTSUBSCRIPTIONPLAN = "/accountsetting/subscriptionplan/paymentstatus",
  MANAGEUSER_MANAGEDEVICES = "/manageuser/view/managedevice",
  BILLINGHISTORY = "/accountsetting/billinghistory",
  MANAGEUSER_BILLINGHISTORY = "/manageuser/view/billinghistory",
  MANAGEDEVICES = "/managedevices",
  ROLESPERMISSION = "/rolespermission",
  HELPCENTRE = "/helpcenter",
  LOGOUT = "/logout",
  PRIVACYPOLICY = "/privacypolicy",
  REGISTERATION = "/",
  VIEWORDER = "/manageorders/vieworder",
  FORGETPASSWORD = "/forgetpassword",
  RESETPASSWORD = "/resetpassword",
  CREATEPASSWORD = "/createpassword",
  PAYMENTSTATUS = "/paymentstatus",
  MANAGEUSERVIEWSUBSCRIBER = "/manageuser/view/subscriber",
  MANAGEUSERVIEWBENEFICIARY = "/manageuser/view/beneficiary",
  MANAGEUSERVIEWFAMILY = "/manageuser/view/family",
  MANAGEUSERVIEWPLATFORMADMIN = "/manageuser/view/platformadmin",
  MANAGEUSERVIEWPLATEFORMSUPERADMIN = "/manageuser/view/platformsuperadmin",
  MANAGEUSERVIEWPLATFORMSUPPORT = "/manageuser/view/platformsupport",
  VIEWBENEFICIARY = "/manageuser/viewbeneficiary",
  DUEPAYMENT = "/reports",
  SUBSCRIPTIONCANCELLATION = "/reports/subscriptioncancellation",
  SUBSCRIPTIONPENDING = "/reports/subscriptionpending",
  REVENUESUMMARY = "/reports/revenuesummary",
  REVENUEDETAIL = "/reports/revenuedetail",
  SEVENDAYNOREADINGS = "/reports/noreadings",
  VIEWROLE = "/rolespermission/view",
  EDITROLE = "/rolespermission/edit",
  MANAGEUSERS_AUDITLOGS = "/manageuser/view/auditlogs",
  ACCOUNTSETTINGS_AUDITLOGS = "/accountsetting/auditlogs",
}
