import { useEffect, useState } from "react";
import { useDeactivateUserMutation, useGetAllUsersofSubscriberMutation } from "../../../../../redux/services/userApiSlice";
import { Modal } from "../../../../common/modal/modal";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, IconButton, Menu, MenuItem } from "@mui/material";
import { DataGrid, GridColDef, GridMoreVertIcon } from "@mui/x-data-grid";
import { UserRoles, UserStatus } from "../../../../../constants/enum";
import { ConfirmModal } from "../../../../common/confirmmodal";
import { Messages } from "../../../../../constants/messages";
import { setAlert } from "../../../../../redux/features/alert/alertSlice";
import Buttons from "../../../../common/button";
import { handleErrors } from "../../../../../utils";
import Loader from "../../../../common/loader/loader";
import { setBeneficaryAccountsCount } from "../../../../../redux/features/user/beneficiarySlice";
import { PaymentStatusModal } from "./subscriptionPaymentModal";

export const DowngradeBeneficiaryModal = (props: any) => {
    const { downgrade, setDowngrade, benCounting,setReview } = props;
    console.log(benCounting, "allowedBen")
    const dispatch = useDispatch();
    const { userInfo } = useSelector((state: any) => state?.auth);
    const { beneficiaryAccounts } = useSelector((state: any) => state?.beneficiary);
    const [tableData, setTableData] = useState<any>([]);
    const [rowData, setRowData] = useState<any>([]);
    const [paymentModal, setPaymentModal] = useState<any>(false)

    // API INTEGRATION 
    const [getAllUsersofSubscriber, AllUsersofSubscriberData] =
        useGetAllUsersofSubscriberMutation();
    console.log(AllUsersofSubscriberData, "kkkk")
    const [deactivateUser, deactivateUserInfo] = useDeactivateUserMutation();


    useEffect(() => {
        if (downgrade) {
            getAllUsersofSubscriber({
                subscriberId: userInfo?.userId,
            }
            );
        }
    }, [downgrade]);

    useEffect(() => {
        if (AllUsersofSubscriberData?.isSuccess) {
            const filteredData = AllUsersofSubscriberData?.data?.result?.users
                .filter((row: any) => {
                    return row?.roleName !== UserRoles?.FAMILY;
                })
                .map((row: any, index: any) => ({
                    id: index,
                    ...row
                }));

            dispatch(
                setBeneficaryAccountsCount(
                    AllUsersofSubscriberData?.data?.result?.beneficiaryTypeCount
                )
            );
            setTableData(filteredData)
            setRowData(filteredData)
        }
    }, [AllUsersofSubscriberData?.isSuccess]);


    const column: GridColDef[] = [
        {
            field: "fullName",
            headerName: "Beneficiary Name",
            minWidth: 50,
            headerAlign: "left",
            sortable: false,
            cellClassName: "cell-padding",
            flex: 1,
            renderCell: (params: any) => {
                return (
                    <div className="textWordBreak tableRenderCellText textCapital">
                        {params?.row?.User?.fullName}
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            headerAlign: "left",
            align: "left",
            cellClassName: "cell-padding",
            minWidth: 50,
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return manageOrderStatus(params?.row?.User?.status);
            },
        },
        {
            field: "deactivate",
            headerName: "",
            headerAlign: "left",
            align: "center",
            cellClassName: "cell-padding",
            minWidth: 110,
            sortable: false,
            flex: 1,
            renderCell: (params) => (
                <>

                    {params?.row?.User?.status === "active" || params?.row?.User?.status === "invited" ? (
                        <Button variant="outlined" className="deactivateButton" onClick={(event: any) => handleDeactivate(event, params?.row)}>Deactivate</Button>
                    ) : (
                        <Button variant="outlined" className="deactivateButton" onClick={(event: any) => handleActivate(event, params?.row)}>ACTIVATE</Button>
                    )}

                </>

            ),
        },

    ];

    const manageOrderStatus = (status: string) => {
        return (
            <>
                {status === UserStatus.ACTIVE ? (
                    <label className="userStatusActive">Active</label>
                ) : status === UserStatus.DEACTIVATED ? (
                    <label className="userStatusDeactive">Deactivated</label>
                ) : status === UserStatus.INVITED ? (
                    <label className="userStatusInvited">Invited</label>
                ) : (
                    <label className="">{status}</label>
                )}
            </>
        );
    };

    const handleDeactivate = (event: any, row: any) => {
        console.log(row, "rowe")
        deactivateUser({
            id: row?.User?.userId,
            // subscriberId: selectedRowData?.User?.userId,
            status: "inactive",
        });
    };

    const handleActivate = (event: any, row: any) => {
        deactivateUser({
            id: row?.User?.userId,
            status: "active",
        });

    }

    useEffect(() => {
        if (deactivateUserInfo?.isSuccess) {
            dispatch(
                setAlert({
                    open: true,
                    alertType: "success",
                    message: deactivateUserInfo?.data?.message,
                })
            );
            getAllUsersofSubscriber({
                subscriberId: userInfo?.userId,
            }
            );
        }
    }, [deactivateUserInfo?.isSuccess]);

    useEffect(() => {
        if (deactivateUserInfo?.isError) {
            handleErrors(deactivateUserInfo, dispatch);
        }
    }, [deactivateUserInfo?.isError]);



    const handleCancel = () => {
        setDowngrade(false)
    }


    const handleContinueClick = () => {
        if (beneficiaryAccounts?.activeBeneficiaries + beneficiaryAccounts?.invitedBeneficiaries === benCounting) {
            setReview(true);
            // setPaymentModal(true)
            // // setDowngrade(false)
            // dispatch(
            //     setAlert({
            //         open: true,
            //         alertType: "success",
            //         message: "Your subscription has been updated successfully.",
            //     })
            // );
        } else {
            setDowngrade(true)
            dispatch(
                setAlert({
                    open: true,
                    alertType: "error",
                    message: "Please deactivate the additional beneficiaries to continue.",
                })
            );
        }
    }

    const closeModal = () => {
        setDowngrade(false)
    }

    return (
        <>
            <Loader show={AllUsersofSubscriberData?.isLoading || deactivateUserInfo?.isLoading} />
            <Modal
                handleClose={handleCancel}
                primaryText="Deactivate additional beneficiaries"
                open={downgrade}
                xs={10.3}
                sm={10.3}
                md={10.3}
                lg={10.3}
                xl={10.3}
                className="input-padding modal-title outer-pd-device reviewOrderModal"
            >
                <Grid container>

                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <div className="createPlanTable">
                            <div className="footerNote">
                                <DataGrid
                                    // paginationMode="server"
                                    // autoPageSize
                                    columns={column}
                                    hideFooterPagination
                                    disableRowSelectionOnClick
                                    disableColumnFilter
                                    disableColumnSelector
                                    disableColumnMenu
                                    sx={{
                                        "& .MuiDataGrid-row:hover": {
                                            backgroundColor: "white",
                                        },
                                    }}
                                    autoHeight
                                    rows={tableData}
                                // getRowId={(id) => id.userId}
                                />
                                <div className="note">
                                    <span>Note: Deactivated beneficiaries will no longer have access to our services through the app</span>
                                </div>

                            </div>
                        </div>
                    </Grid>



                    {/* <div >
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleOff}
                            style={{ width: "2000px" }}
                        >
                            <MenuItem onClick={handleDeactivate} >Deactivate</MenuItem>
                        </Menu>
                    </div> */}

                </Grid>

                <Grid container >
                    <Grid item xs={12} sx={{ mt: 4 }}>
                        <Grid container spacing={4} justifyContent={"flex-end"}>
                            <Grid item xs={3}>
                                <Buttons
                                    type="secondary"
                                    text="CANCEL"
                                    variant="contained"
                                    onClick={closeModal}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Buttons
                                    type="primary"
                                    text="CONTINUE"
                                    variant="contained"
                                    buttonType="submit"
                                    onClick={handleContinueClick}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Modal>

            <PaymentStatusModal paymentModal={paymentModal} setPaymentModal={setPaymentModal} />

            {/* <div className="modelModal" data-testid="confirm-modal">
                <ConfirmModal
                    className="confirmModal"
                    key="deactivate"
                    open={deactivateModal}
                    primaryText="Deactivated beneficiaries will no longer have access to our services through the app."
                    cancelButton="NO"
                    confirmButton="YES"
                    cancelCallback={onCancelDeactivate}
                    confirmCallback={onConfirmDeactivate}
                />
            </div> */}

        </>


    )
}