import { Checkbox, Grid, ListItemText, MenuItem } from "@mui/material";
import { Modal } from "../../../common/modal/modal";
import { CheckboxSelectInput } from "../../../common/selectinput";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useGetDevicesMutation } from "../../../../redux/services/devicesApiSlice";
import { DataGrid, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { CustomToolTip } from "../../../common/tooltip";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { RegExpressions } from "../../../../constants/regExp";
import { handlePriceFormatting } from "../../../../utils";
import { deviceTypeValue } from "../../../../constants/enum";
import { useDispatch, useSelector } from "react-redux";
import { useGetSubscriptionPlanQuery } from "../../../../redux/services/planApiSlice";
import { useLocation } from "react-router-dom";
import { AnyAaaaRecord } from "dns";
import Buttons from "../../../common/button";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import { NewPurchaseShippingAddress } from "./newPurchaseShippingAddress";

export const PurchaseNewDevice = (props: any) => {
    const { purchaseDevice, setPurchaseDevice } = props;
    const { handleSubmit, control, setValue, getValues, reset } = useForm({
        mode: "onChange" || "onSubmit",
    });

    const dispatch = useDispatch();
    const { state } = useLocation();
    const { userInfo } = useSelector((state: any) => state?.auth);
    const [selectedPlanData, setSelectedPlanData] = useState<any>([]);
    const [tableData, setTableData] = useState<any>([]);
    console.log(tableData, "dataaaa");
    const [deviceName, setDeviceName] = useState<any>([]);
    const [allCheck, setAllCheck] = useState(false);
    const [device, setDevice] = useState<any>([]);
    const [deviceTypes, setDeviceTypes] = useState<any>([]);
    const [deviceTypesName, setDeviceTypesName] = useState<any>([]);
    const [userId, setUserId] = useState();
    const [deviceDetails, setDeviceDetails] = useState<any>([]);
    const [shippingAddressModal, setShippingAdressModal] = useState<any>(false);
    const [review, setReview] = useState<any>(false);

    useEffect(() => {
        if (state?.view) {
            setUserId(state?.userData?.User?.userId);
        } else {
            setUserId(userInfo?.userId);
        }
    }, [state]);

    // API INTEGRATION 
    const [getDevices, getDevicesData] = useGetDevicesMutation();
    const {
        data,
        isSuccess,
        refetch,
        isError,
        fulfilledTimeStamp,
        error,
        isLoading,
    } = useGetSubscriptionPlanQuery(
        {
            id: userId,
        },
        {
            refetchOnMountOrArgChange: true,
            skip: userId === undefined ? true : false,
        }
    );

    const labels: any = {
        ["Blood Glucose"]: "Glucometer",
        ["Blood Pressure"]: "BP Monitor",
        ["Temperature"]: "Thermometer",
        ["PulseOx"]: "Pulse Oximeter",
        ["Weight"]: "Weighing Scale",
    };

    useEffect(() => {
        getDevices({ refetchOnMountOrArgChange: true });
    }, [purchaseDevice])

    const [allDeviceData, setAllDeviceData] = useState<any>([]);
    useEffect(() => {
        if (getDevicesData?.isSuccess) {
            let data: any = [];
            getDevicesData?.data?.result?.map((x: any) => {
                data.push({
                    text: x?.biometricType,
                    description: x?.model,
                    value: x?.deviceTypeId,
                    currency: x?.currency,
                    quantity: 1,
                    deviceCost: x?.cost,
                    deviceCostings: x?.DeviceCostings,
                    igst: x?.igst,
                });
            });
            // setDeviceName(data);
            setAllDeviceData(data);
        }
    }, [getDevicesData?.isSuccess]);

    useEffect(() => {
        if (isSuccess) {
            if (data && data?.result && data?.result?.length > 0) {
                setSelectedPlanData(data?.result[0]);
                console.log(data?.result[0], "d7777777777");


            }
        }
    }, [isSuccess, data]);

   
    const columns: GridColDef[] = [
        {
            field: "text",
            headerName: "Device Type",
            minWidth: 120,
            headerAlign: "left",
            sortable: false,
            cellClassName: "cell-padding",
            flex: 1,
            renderCell: (params) => {
                console.log(params, "ptaa")
                return (
                    <>
                        {labels[params?.row?.text]}
                    </>
                )
            }
        },
        {
            field: "description",
            headerName: "Model No.",
            minWidth: 100,
            headerAlign: "left",
            sortable: false,
            cellClassName: "cell-padding",
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="sliceWidth">
                        <CustomToolTip title={params?.row?.description} placement="top">
                            <p className="sliceWidth"> {params?.row?.description} </p>
                        </CustomToolTip>
                    </div>
                );
            },
        },
        {
            field: "qty",
            headerName: "Quantity",
            minWidth: 100,
            headerAlign: "center",
            sortable: false,
            align: "center",
            cellClassName: "cell-padding",
            flex: 1,
            renderCell: (params) => {
                console.log(params?.row?.quantity, "quantity");
                return (
                    <div className="customizeInput">
                        <div
                            className="minusSign"
                            onClick={() =>
                                handleQtyChange(
                                    {
                                        target: {
                                            value: parseInt(params?.row?.quantity) - 1,
                                        },
                                    },
                                    params?.row
                                )
                            }
                        >
                            <div className="fontIcon">
                                <RemoveIcon />
                            </div>
                            {/* - */}
                        </div>
                        <Controller
                            render={({
                                field: { ref, ...field },
                                fieldState: { invalid, error },
                            }) => (
                                <input
                                    defaultValue={1}
                                    value={params?.row?.quantity}
                                    className="inputBox"
                                    onChange={(e: any) => handleQtyChange(e, params?.row)}
                                    type="number"
                                    name="quantity"
                                    min={1}
                                    max={500}
                                    required
                                />
                            )}
                            name="deviceTableData"
                            rules={{
                                pattern: {
                                    value: RegExpressions?.five,
                                    message: "not allowed",
                                },
                            }}
                            control={control}
                        />
                        <div
                            className="addSign"
                            onClick={() =>
                                handleQtyChange(
                                    {
                                        target: {
                                            value: parseInt(params?.row?.quantity) + 1,
                                        },
                                    },
                                    params?.row
                                )
                            }
                        >
                            <div className="fontIcon">
                                <AddIcon />
                            </div>
                            {/* + */}
                        </div>
                    </div>
                );
            },
        },
        {
            field: "deviceCost",
            renderHeader: (params: GridColumnHeaderParams) => (
                <b>
                    Unit Price
                    <br />
                    <center>({userInfo?.country === "IN" ? "INR" : "USD"})</center>
                </b>
            ),
            minWidth: 100,
            headerAlign: "right",
            align: "right",
            sortable: false,
            cellClassName: "cell-padding",
            flex: 1,
            renderCell: (params) => {
                let data = params?.row?.deviceCostings?.filter((item: any) => {
                    return item?.country === userInfo?.country;
                });
                console.log(params?.row, userInfo?.country + "countyrrrrrrrrrrr");
                if (data && data?.length > 0) {
                    return handlePriceFormatting(
                        data[0]?.value,
                        userInfo?.country === "IN" ? "IN" : "US"
                    );
                }
            },
        },
        {
            field: "tax",
            renderHeader: (params: GridColumnHeaderParams) => (
                <b>
                    GST
                    <br />
                    <center>({userInfo?.country === "IN" ? "INR" : "USD"})</center>
                </b>
            ),
            minWidth: 100,
            headerAlign: "right",
            sortable: false,
            align: "right",
            cellClassName: "cell-padding",
            flex: 1,
            renderCell: (params) => {
                let data = params?.row?.deviceCostings?.filter((item: any) => {
                    return item?.country === userInfo?.country;
                });
                if (data && data?.length > 0) {
                    const quantity = params?.row?.quantity;
                    const deviceCost = params?.row?.deviceCost;
                    const totalTax =
                        (quantity * data[0]?.value * params?.row?.igst) / 100;
                    // setTotalSum(totalSum+totalCost);
                    return handlePriceFormatting(
                        totalTax,
                        userInfo?.country === "IN" ? "IN" : "US"
                    );
                }
                return 0;
            },
        },
        {
            field: "finalCost",
            renderHeader: (params: GridColumnHeaderParams) => (
                <b>
                    Total Price
                    <br />
                    <center>({userInfo?.country === "IN" ? "INR" : "USD"})</center>
                </b>
            ),
            minWidth: 120,
            headerAlign: "right",
            align: "right",
            sortable: false,
            cellClassName: "cell-padding",
            flex: 1,
            renderCell: (params) => {
                if (userInfo?.country === "IN") {
                    let data = params?.row?.deviceCostings?.filter((item: any) => {
                        return item?.country === userInfo?.country;
                    });
                    if (data && data?.length > 0) {
                        const quantity = params?.row?.quantity;
                        const deviceCost = params?.row?.deviceCost;
                        const totalCost =
                            quantity * data[0]?.value +
                            (quantity * data[0]?.value * params?.row?.igst) / 100;
                        // setTotalSum(totalSum+totalCost);
                        return handlePriceFormatting(
                            totalCost,
                            userInfo?.country === "IN" ? "IN" : "US"
                        );
                    } else {
                        return 0;
                    }
                } else {
                    let data = params?.row?.deviceCostings?.filter((item: any) => {
                        return item?.country === userInfo?.country;
                    });
                    console.log(params?.row, "dataaaaaaaaadddd");
                    if (data && data?.length > 0) {
                        const quantity = params?.row?.quantity;
                        const deviceCost = params?.row?.deviceCost;
                        const totalCost = quantity * data[0]?.value;
                        // setTotalSum(totalSum+totalCost);
                        return handlePriceFormatting(
                            totalCost,
                            userInfo?.country === "IN" ? "IN" : "US"
                        );
                    } else {
                        return 0;
                    }
                }
            },
        },
    ];


    const onProductBundleChange = (e: any, field: any) => {
        const { name, value } = e?.target;
        var check = value.indexOf("all");
        if (check !== -1 && allCheck === false) {
            let data: any = [];
            deviceName?.map((x: any, index: any) => {
                data.push({
                    text: x?.text,
                    description: x?.description,
                    value: x?.value,
                    quantity: x?.quantity,
                });
            });
            setTableData(deviceName);
            setDevice(data);
            setAllCheck(true);
        } else if (check !== -1 && allCheck === true) {
            setDevice([]);
            setAllCheck(false);
            setTableData([]);
        } else {
            if (allCheck === true) {
                setTableData((filterTableData: any) =>
                    filterTableData.filter(
                        (device: any) => device?.value !== value[value?.length - 1]?.value
                    )
                );
                let data = deviceName?.filter((x: any) => {
                    return x?.value !== value[value?.length - 1]?.value;
                });
                setAllCheck(false);
                setDevice(data);
            } else {
                setDevice(value);
                setTableData(value);
            }
        }
    };

    const [deviceTypeAllCheck, setDeviceTypeAllCheck] = useState<any>(false);
    const onDeviceTypesChange = (e: any, field: any) => {
        setDevice([])
        setTableData([]);
        setAllCheck(false)
        const { name, value } = e?.target;
        handleGetDevices(value);
        console.log(e?.target, "targetrrrrA");
        var check = value.indexOf("all");
        if (check !== -1 && deviceTypeAllCheck === false) {
            let data: any = [];
            deviceTypesName?.map((x: any, index: any) => {
                data.push({
                    text: x?.text,
                    description: x?.description,
                    value: x?.value,
                });
            });
            setDeviceTypes(data);
            handleGetDevices(data);
            setDeviceTypeAllCheck(true);
        } else if (check !== -1 && deviceTypeAllCheck === true) {
            setDeviceTypes([]);
            handleGetDevices([]);
            setDeviceTypeAllCheck(false);
        } else {
            if (deviceTypeAllCheck === true) {
                let data = deviceTypesName?.filter((x: any) => {
                    return x?.value !== value[value?.length - 1]?.value;
                });
                setDeviceTypeAllCheck(false);
                setDeviceTypes(data);
            } else {
                setDeviceTypes(value);
            }
        }
    };

    const handleGetDevices = (input: any) => {
        let data = allDeviceData?.reduce((acc: any, item: any) => {
            for (let i = 0; i < input.length; i++) {
                if (input[i]?.value === item?.text) {
                    acc?.push(item);
                }
            }
            return acc;
        }, []);
        console.log(data, "datttttttttttttttttt");
        setDeviceName(data);
    };

    // useEffect(() => {
    //     const remainDevices = selectedPlanData?.devices?.map((x: any) => {
    //         return deviceOptions?.filter((y: any) => {
    //             console.log(y?.value !== x?.DeviceType?.biometricType, "seecteddevices")
    //             return y?.value !== x?.DeviceType?.biometricType;
    //         });
    //     });
    //     const upgradeDeviceArray = remainDevices?.reduce((x: any, y: any) =>
    //         x.filter((z: any) => y.includes(z))
    //     );
    //     console.log(data, "datttttttttttttttttt");
    //     setDeviceTypesName(upgradeDeviceArray);
    // }, [selectedPlanData]);

    useEffect(() => {
        const remainDevices = selectedPlanData?.devices?.flatMap((x: any) => {
            return deviceOptions?.filter((y: any) => {
                return y?.value === x?.DeviceType?.biometricType;
            });
        });
        console.log(remainDevices, "remainDevices")
        const upgradeDeviceArray = remainDevices?.filter((x: any, index: number, self: any[]) => {
            return self.indexOf(x) === index;
        });
    
        console.log(data, "datttttttttttttttttt");
        setDeviceTypesName(upgradeDeviceArray);
        console.log(upgradeDeviceArray, "upgradeDeviceArray")
    }, [selectedPlanData]);
    

    const deviceOptions = [
        { text: "BP Monitor", value: deviceTypeValue.BLOODPRESSURE },
        { text: "Pulse Oximeter", value: deviceTypeValue.PULSEOXYMETER },
        { text: "Weighing Scale", value: deviceTypeValue.WEIGHT },
        { text: "Thermometer", value: deviceTypeValue.TEMPERATURE },
        { text: "Glucometer", value: deviceTypeValue.GLUCOSE },
    ];

    const handleQtyChange = (e: any, rowData: any) => {
        let dataArray = [];
        const newQuantity = Math.max(1, parseInt(e?.target?.value || 0));
        if (e?.target?.value >= 0) {
            for (let i = 0; i < device?.length; i++) {
                if (rowData?.value === tableData[i]?.value) {
                    // tableData[i].quantity = e?.target?.value;
                    tableData[i].quantity = newQuantity;
                    dataArray.push(tableData[i]);
                } else {
                    dataArray.push(tableData[i]);
                }
            }
            setDevice(dataArray);
            setTableData(dataArray);
        }
    };

    useEffect(() => {
        if (device && device?.length > 0) {
            let selectedDevice = [];
            for (var i = 0; i < device?.length; i++) {
                selectedDevice.push({
                    deviceTypeId: device[i].value,
                    quantity: device[i]?.quantity,
                });
            }
            setDeviceDetails(selectedDevice);
        }
    }, [device]);


    const handleNextClick = () => {
        let deviceQty = tableData?.reduce((acc: any, item: any) => {
            if (item?.quantity < 1 || item?.quantity % 1 !== 0 || item.quantity > 99) {
                acc = true;
            }
            return acc;
        }, false);
        if (tableData && tableData?.length === 0) {
            dispatch(
                setAlert({
                    open: true,
                    alertType: "error",
                    message: "Please select devices to continue.",
                })
            );
        } else if (deviceQty) {
            return dispatch(
                setAlert({
                    open: true,
                    alertType: "error",
                    message: "Enter device quantity between 1-99",
                })
            );
        } else {
            console.log(tableData?.length, "length");
            dispatch(
                setAlert({
                    open: true,
                    alertType: "success",
                    message: "Device type selected successfully.",
                })
            );
            setShippingAdressModal(true);
        }
    }

    const [deviceTotalSum, setDeviceTotalSum] = useState<any>(0);
  useEffect(() => {
    const sum = tableData.reduce((acc: any, row: any) => {
      let data = row?.deviceCostings?.filter((item: any) => {
        return item?.country === userInfo?.country;
      });
      if (data && data?.length > 0) {
        if (userInfo?.country === "IN") {
          return (
            acc +
            data[0]?.value * row?.quantity +
            (data[0]?.value * row?.quantity * row?.igst) / 100
          );
        } else {
          return acc + data[0]?.value * row?.quantity;
        }
      } else {
        return 0;
      }
    }, 0);
    setDeviceTotalSum(sum);
  }, [tableData, userInfo]);



    const handleCancel = () => {
        setPurchaseDevice(false)
        setDevice([]);
        setTableData([]);
        setDeviceTypes([]);
        // setDeviceTypesName([]);
        setAllCheck(false);
        setDeviceTypeAllCheck(false);
    }

    const customLocaleText = {
        noRowsLabel: 'No data found',
      };

    return (
        <>

            <Modal
                handleClose={handleCancel}
                primaryText={"Order New Device"}
                open={purchaseDevice}
                className="input-padding modal-title outer-pd-device reviewOrderModal color"
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Grid container columnSpacing={4}>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ mt: 4 }}
                                        className="selectInput-title"
                                    >
                                        <Controller
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { invalid, error },
                                            }) => (
                                                <CheckboxSelectInput
                                                    {...field}
                                                    label="Select Device Type"
                                                    required={true}
                                                    placeholder={
                                                        deviceTypesName?.length
                                                            ? "Select device types"
                                                            : " No devices available"
                                                    }
                                                    value={deviceTypes}
                                                    error={Boolean(error)}
                                                    helperText={
                                                        error?.message ? error?.message : " "
                                                    }
                                                    onChange={(e: any) => {
                                                        field.onChange(e);
                                                        onDeviceTypesChange(e, field);
                                                    }}
                                                    inputRef={ref}
                                                    multiple={true}
                                                >
                                                    {deviceTypesName &&
                                                        deviceTypesName?.length > 0 ? (
                                                        <MenuItem key="all" value="all">
                                                            <div>
                                                                <ListItemText primary="All" />
                                                            </div>
                                                            <Checkbox
                                                                className="checkboxBox"
                                                                checked={deviceTypeAllCheck}
                                                            />
                                                        </MenuItem>
                                                    ) : null}
                                                    {deviceTypesName?.map((option: any) => {
                                                        return (
                                                            <MenuItem
                                                                key={option?.value}
                                                                value={option}
                                                            >
                                                                <div>
                                                                    <ListItemText primary={option?.text} />
                                                                    <CustomToolTip
                                                                        title={option?.description}
                                                                        placement="top"
                                                                    >
                                                                        <ListItemText
                                                                            className="sliceWidth"
                                                                            secondary={option?.description}
                                                                        />
                                                                    </CustomToolTip>
                                                                </div>
                                                                <Checkbox
                                                                    className="checkboxBox"
                                                                    checked={
                                                                        deviceTypes?.findIndex(
                                                                            (item: any) =>
                                                                                item?.value === option?.value
                                                                        ) >= 0
                                                                    }
                                                                />
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </CheckboxSelectInput>
                                            )}
                                            name="devicesTypes"
                                            control={control}
                                            rules={{
                                                required: "Please select product bundle",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container columnSpacing={4}>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ mt: 4 }}
                                        className="selectInput-title"
                                    >
                                        <Controller
                                            render={({
                                                field: { ref, ...field },
                                                fieldState: { invalid, error },
                                            }) => (
                                                <CheckboxSelectInput
                                                    {...field}
                                                    label="Select Device Model Type"
                                                    required={true}
                                                    labelArray={[labels]}
                                                    placeholder={
                                                        deviceName?.length
                                                            ? "Select device model type"
                                                            : " No devices available"
                                                    }
                                                    value={device}
                                                    error={Boolean(error)}
                                                    helperText={
                                                        error?.message ? error?.message : " "
                                                    }
                                                    onChange={(e: any) => {
                                                        field.onChange(e);
                                                        onProductBundleChange(e, field);
                                                    }}
                                                    inputRef={ref}
                                                    multiple={true}
                                                    disabled={
                                                        deviceTypes && deviceTypes?.length > 0
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    {deviceName && deviceName?.length > 0 ? (
                                                        <MenuItem key="all" value="all">
                                                            <div>
                                                                <ListItemText primary="All" />
                                                            </div>
                                                            <Checkbox
                                                                className="checkboxBox"
                                                                checked={allCheck}
                                                            />
                                                        </MenuItem>
                                                    ) : null}
                                                    {deviceName?.map((option: any) => {
                                                        console.log(option, "oopppp")
                                                        return (
                                                            <MenuItem
                                                                key={option?.value}
                                                                value={option}
                                                            >
                                                                <div>
                                                                    <ListItemText primary={labels[option?.text]} />
                                                                    <CustomToolTip
                                                                        title={option?.description}
                                                                        placement="top"
                                                                    >
                                                                        <ListItemText
                                                                            className="sliceWidth"
                                                                            secondary={option?.description}
                                                                        />
                                                                    </CustomToolTip>
                                                                </div>
                                                                <Checkbox
                                                                    className="checkboxBox"
                                                                    checked={
                                                                        device.findIndex(
                                                                            (item: any) =>
                                                                                item?.value === option?.value
                                                                        ) >= 0
                                                                    }
                                                                />
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </CheckboxSelectInput>
                                            )}
                                            name="devices"
                                            control={control}
                                            rules={{
                                                required: "Please select product bundle type",
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <div className="createPlanTable">
                            <div style={{ position: "relative", width: "100%" }}>
                                <DataGrid
                                    // paginationMode="server"
                                    // autoPageSize
                                    columns={columns}
                                    hideFooterPagination
                                    columnVisibilityModel={{
                                        tax: userInfo?.country === "IN" ? true : false,
                                    }}
                                    disableRowSelectionOnClick
                                    disableColumnFilter
                                    disableColumnSelector
                                    disableColumnMenu
                                    sx={{
                                        "& .MuiDataGrid-row:hover": {
                                            backgroundColor: "white",
                                        },
                                    }}
                                    autoHeight
                                    rows={tableData}
                                    getRowId={(id: any) => id.value}
                                    localeText={customLocaleText}
                                />
                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: 15,
                                        right: 14,
                                        fontWeight: "bold",
                                    }}
                                    className="total"
                                >
                                    One-Time &nbsp;: &nbsp;
                                    {userInfo?.country === "IN" ? "INR" : "USD"}{" "}
                                    {handlePriceFormatting(
                                        deviceTotalSum,
                                        userInfo?.country === "IN" ? "INR" : "USD"
                                    )}
                                </div>
                            </div>
                        </div>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} sx={{ mt: 4 }}>
                            <Grid container spacing={4} justifyContent={"flex-end"}>
                                <Grid item xs={2.5}>
                                    <Buttons
                                        type="secondary"
                                        text="CANCEL"
                                        variant="contained"
                                        onClick={handleCancel}
                                    />
                                </Grid>
                                <Grid item xs={2.5}>
                                    <Buttons
                                        type="primary"
                                        text="NEXT"
                                        variant="contained"
                                        buttonType="submit"
                                        onClick={handleNextClick}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>



                </Grid>
            </Modal>

            <NewPurchaseShippingAddress
                shippingAddressModal={shippingAddressModal}
                setShippingAdressModal={setShippingAdressModal}
                deviceDetails={deviceDetails}
                setReview={setReview}
            // planData={planData}
            />



        </>



    )
}