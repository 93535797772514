import * as React from "react";
import { makeStyles, Theme, useTheme } from "@mui/material/styles";
import {
  InputLabel,
  OutlinedInput,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  FormHelperText,
} from "@mui/material";
import "./style.css";
import { Selectinputprops, CountryCodeSelectinputprops } from "../../../models/common";

// const useStyles = makeStyles({
//   disabledInputLabel: {
//     color: "grey",
//   },
// });

export const SelectInput = (props: Selectinputprops) => {
  const {
    required,
    name,
    className,
    error,
    labelClassName,
    label,
    disabled,
    defaultValue,
    placeholder,
    helperText,
    optionValue,
    onChange,
    value,
    multiple,
    inputRef,
    selectInput,
    autoFocus
  } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 50,
        // border: "1px solid #8F909A",
      },
      className: props?.className === "filterStyle" ? "filterMenuStyle" : "dropdownMenuStyle"
    },
  };
  //   let sortedOptions = Array.isArray(optionValue) ? optionValue.sort((a: any, b: any) => {
  //     if (a.text < b.text) return -1
  //     return a.text > b.text ? 1 : 0
  //   }) : [];

  return (
    <div>
      <FormControl sx={{ m: 0, width: "100%" }} className="selecetBox">
        <InputLabel
          shrink
          className={
            labelClassName
              ? `${labelClassName} inputTextLabel inputTextLabelError`
              : "inputTextLabel inputTextLabelError"
          }
          htmlFor="bootstrap-input"
          required={required}
          error={error}
          disabled={disabled}
        >
          <span>{label}</span>
        </InputLabel>
        <Select
          error={error}
          multiple={multiple}
          displayEmpty
          disabled={disabled}
          defaultValue={""}
          placeholder={placeholder}
          className={`${className} selectInput`}
          value={value}
          inputRef={inputRef}
          name={name}
          onChange={onChange}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          autoFocus={autoFocus}
        // renderValue={(selected: any) => {
        //   if (selected.length == 0 || selected=="undefined") {
        //     return <span style={{ color: "#c5c5c4" }}>
        //     {placeholder}
        //   </span>
        //   }
        //   else if (selected.length){
        //     return selected;
        //   }
        //   else{
        //     return ""
        //   }
        // }}
        >
          <MenuItem className="placeholderOption" disabled value="">
            <span style={{ color: "#c5c5c4" }}>{placeholder}</span>
          </MenuItem>
          {optionValue?.map((option: any) => {
            return (
              <MenuItem key={option?.value} value={option?.value} disabled={option?.disabled}>
                {option?.text}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText
          sx={{ marginLeft: "0px" }}
          className="helperClass slice"
        >
          {helperText}
        </FormHelperText>
      </FormControl>
    </div>
  );
};


export const CheckboxSelectInput = (props: any) => {
  const {
    required,
    error,
    name,
    labelClassName,
    label,
    disabled,
    defaultValue,
    placeholder,
    helperText,
    optionValue,
    onChange,
    value,
    labelArray = {},
    multiple,
    inputRef,
    children,
  } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 50,
      },
    },
  };
  //   let sortedOptions = Array.isArray(optionValue) ? optionValue.sort((a: any, b: any) => {
  //     if (a.text < b.text) return -1
  //     return a.text > b.text ? 1 : 0
  //   }) : [];

  return (
    <div>
      <FormControl sx={{ m: 0, width: "100%" }}>
        <InputLabel
          shrink
          className={
            labelClassName
              ? `${labelClassName} inputTextLabel inputTextLabelError`
              : "inputTextLabel inputTextLabelError"
          }
          htmlFor="bootstrap-input"
          required={required}
          error={error}
          disabled={disabled}
        >
          {label}
        </InputLabel>
        <Select
          error={error}
          multiple={multiple}
          displayEmpty
          disabled={disabled}
          placeholder={placeholder}
          className="selectInput"
          value={value}
          name={name}
          inputRef={inputRef}
          onChange={onChange}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          renderValue={(selected: any) => {
            if (selected?.length == 0) {
              return <span style={{ color: "#c5c5c4" }}>
                {placeholder}
              </span>
            }
            return labelArray && labelArray?.length>0 ? selected.map((x: any) => labelArray[0][x?.text]).join(", ") : selected.map((x: any) => x?.text).join(", ");
          }}
        >
          {children}
        </Select>
        <FormHelperText
          sx={{ marginLeft: "0px" }}
          className="helperClass slice"
        >
          {helperText}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export const CountryCodeSelectInput = (props: CountryCodeSelectinputprops) => {
  const {
    required,
    name,
    className,
    error,
    labelClassName,
    label,
    disabled,
    defaultValue,
    placeholder,
    helperText,
    optionValue,
    onChange,
    value,
    multiple,
    inputRef,
    selectInput,
    autoFocus,
    isCountryCode = false

  } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 50,
        ...(isCountryCode && { minWidth: 220 })
        // border: "1px solid #8F909A",
      },
      className: props?.className === "filterStyle" ? "filterMenuStyle" : "dropdownMenuStyle"
    },
  };
  //   let sortedOptions = Array.isArray(optionValue) ? optionValue.sort((a: any, b: any) => {
  //     if (a.text < b.text) return -1
  //     return a.text > b.text ? 1 : 0
  //   }) : [];

  return (
    <div>
      <FormControl sx={{ m: 0, width: "100%" }} className="selecetBox">
        <InputLabel
          shrink
          className={
            labelClassName
              ? `${labelClassName} inputTextLabel inputTextLabelError`
              : "inputTextLabel inputTextLabelError"
          }
          htmlFor="bootstrap-input"
          required={required}
          error={error}
          disabled={disabled}
        >
          <span>{label}</span>
        </InputLabel>
        <Select
          error={error}
          multiple={multiple}
          displayEmpty
          disabled={disabled}
          defaultValue={""}
          placeholder={placeholder}
          className={`${className} selectInput`}
          value={value}
          inputRef={inputRef}
          name={name}
          onChange={onChange}
          input={<OutlinedInput />}
          MenuProps={MenuProps}
          autoFocus={autoFocus}
          renderValue={(selected: any) => {
            if (isCountryCode) {
              if (selected) {
                return selected;
              }
              else {
                return <span style={{ color: "#c5c5c4" }}>{placeholder}</span>
              }
            }
            else {
              return selected;
            }
            //   if (selected.length == 0 || selected=="undefined") {
            //     return <span style={{ color: "#c5c5c4" }}>
            //     {placeholder}
            //   </span>
            //   }
            //   else if (selected.length){
            //     return selected;
            //   }
            //   else{
            //     return ""
            //   }
          }}
        >
          <MenuItem className="placeholderOption" disabled value="">
            <span style={{ color: "#c5c5c4" }}>{placeholder}</span>
          </MenuItem>
          {optionValue?.map((option: any) => {
            return (
              <MenuItem key={option?.value} value={option?.value} disabled={option?.disabled}>
                {option?.text}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText
          sx={{ marginLeft: "0px" }}
          className="helperClass slice"
        >
          {helperText}
        </FormHelperText>
      </FormControl>
    </div>
  );
};
