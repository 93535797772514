import React from "react";
import {
  TableRow,
  Box,
  Card,
  CardContent,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Paper,
  Grid,
  Typography,
} from "@mui/material";
import Button from "@mui/material";
import "./style.css";
import UILayout from "../../layout";
import Buttons from "../../common/button";
import { Input } from "../../common/input";
import { Table } from "../../common/table";
import { CustomToolTip } from "../../common/tooltip";

export const Dashboard = () => {
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(rows.length);

  // const handleChangePage = (event: unknown, newPage: number) => {
  //     setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setRowsPerPage(+event.target.value);
  //     setPage(0);
  // };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    { field: "firstName", headerName: "First Name", width: 150 },
    { field: "lastName", headerName: "Last Name", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
  ];

  const rows = [
    { id: 1, firstName: "John", lastName: "Doe", email: "johndoe@example.com" },
    { id: 2, firstName: "Jane", lastName: "Doe", email: "janedoe@example.com" },
    {
      id: 3,
      firstName: "Bob",
      lastName: "Smith",
      email: "bobsmith@example.com",
    },
  ];

  return (
    <>
    
          <div className="workinprogress">WORK IN PROGRESS</div>
          
       

    </>
  );
};
