import { Checkbox, Grid, ListItemText, MenuItem } from "@mui/material";
import { Modal } from "../../../common/modal/modal";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../../common/input";
import Buttons from "../../../common/button";
import { RegExpressions } from "../../../../constants/regExp";
import {
  CheckboxSelectInput,
  CountryCodeSelectInput,
  SelectInput,
} from "../../../common/selectinput";
import { cCodes } from "../../../../constants/constant";
import { useEffect, useRef, useState } from "react";
import {
  handleInputFocus,
  handleErrors,
  phoneNumberAutoFormat,
} from "../../../../utils";
import {
  useCreateUserMutation,
  useGetAllUsersofSubscriberMutation,
  useUpdateFamilyDetailsMutation,
  useUpdateUserMutation,
} from "../../../../redux/services/userApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { FamilyMemberModalProps } from "../../../../models/common";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import Loader from "../../../common/loader/loader";
import { DummyBeneficiryList } from "./dummyBeneficiaryMembers";
import { UserRoles } from "../../../../constants/enum";
import { useGetAccountSettingsQuery } from "../../../../redux/services/accountsettingsApiSlice";

export const AddFamilyModal = (props: FamilyMemberModalProps) => {
  const { countriesCodes, supportedCountries,countriesNames } = useSelector(
    (state: any) => state?.countries
  );
  const {
    addFamilyModal,
    setAddFamilyModal,
    editable,
    selectedRowData,
    refetchData,
  } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [selUserData, setSelUserData] = useState<any>();
  const dispatch = useDispatch();
  const { handleSubmit, setValue, control, trigger, reset } = useForm({
    mode: "onChange" || "onSubmit",
  });
  const [key, setKey] = useState("");

  const userId = useSelector((state: any) => state?.auth?.userId);
  const [getAllUsersofSubscriber, AllUsersofSubscriberData] =
    useGetAllUsersofSubscriberMutation();
  const {
    data: selectedUserData,
    refetch,
    isError,
    error,
    isLoading,
    isSuccess,
  } = useGetAccountSettingsQuery(selectedRowData?.User?.userId, {
    refetchOnMountOrArgChange: true,
    skip: selectedRowData === undefined,
  });

  const [beneficiaryMembers, setBeneficiaryMembers] = useState<any>([]);
  console.log(beneficiaryMembers, "beneficiary");
  const [allCheck, setAllCheck] = useState(false);
  const [optionData, setOptionData] = useState<any>([]);
  const [isDisabled, setIsDisabled] = useState<any>(true)

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef?.current?.focus();
      }
    }, 0);
  }, [addFamilyModal]);

  useEffect(() => {
    if (userInfo?.roleName === UserRoles.SUBSCRIBER) {
      getAllUsersofSubscriber({
        subscriberId: userInfo?.userId,
      });
    }
  }, [addFamilyModal]);

  useEffect(()=>{
    setIsDisabled(!(optionData && optionData?.length > 0))

  },[optionData])

  useEffect(() => {
    if (isSuccess) {
      setSelUserData(selectedUserData);
    }
  }, [isSuccess, selectedUserData]);

  useEffect(() => {
    if (AllUsersofSubscriberData?.isSuccess) {
      let data: any = [];
      let filteredData = AllUsersofSubscriberData?.data?.result?.users?.filter(
        (item: any) => item?.roleName === UserRoles?.BENEFICIARY
      );

      filteredData?.map((x: any) => {
        data.push({
          text: x?.User?.fullName,
          description: x?.User?.mobileNumber,
          value: x?.User?.userId,
        });
      });

      setOptionData(data);
    }
  }, [AllUsersofSubscriberData]);

  const [addFamilyMember, addedFamilyMemberData] = useCreateUserMutation();
  const [EditFamilyMember, editedFamilyMemberData] =
  useUpdateUserMutation();
  const { userInfo } = useSelector((state: any) => state?.auth);
  const { roleData } = useSelector((state: any) => state?.role);

  var roleId: any;
  if (Array.isArray(roleData)) {
    roleId = roleData.filter((el: any) => {
      return el.roleName === "Family";
    })[0]?.roleId;
  }

  const handleMobileNumber = (e: any) => {
    var value = handleInputFocus(e, key);
    setValue("mobileNumber", value);
    trigger("mobileNumber");
  };
  const handleAddFamily = (data: any) => {
    var beneficiryarray = [];
    for (var i = 0; i < beneficiaryMembers?.length; i++) {
      beneficiryarray.push(beneficiaryMembers[i]?.value);
    }
    var requestData: any = {
      roleId: roleId,
      mobileNumber: data?.mobileNumber?.replaceAll("-", ""),
      fullName: data?.fullName,
      countryCode: data?.countryCode?.split(" ")[0]
        ? data?.countryCode?.split(" ")[0]
        : "",
      subscriberId: userInfo?.userId,
      beneficiary: beneficiryarray,
    };
    var editRequestData: any = {
      userId: selectedRowData?.User?.userId,
      mobileNumber: data?.mobileNumber?.replaceAll("-", ""),
      countryCode: data?.countryCode?.split(" ")[0]
        ? data?.countryCode?.split(" ")[0]
        : "",
      fullName: data?.fullName,
      beneficiary: beneficiryarray,
    };
    if (!editable) {
      addFamilyMember(requestData);
    } else {
      EditFamilyMember(editRequestData);
    }
  };

  const onBeneficiarySelect = (e: any, field: any) => {
    const { name, value } = e?.target;
    var check = value?.indexOf("all");
    if (check !== -1 && allCheck === false) {
      let data: any = [];
      optionData?.map((x: any) => {
        data.push({
          text: x?.text,
          value: x?.value,
        });
      });
      setBeneficiaryMembers(data);
      setAllCheck(true);
    } else if (check !== -1 && allCheck === true) {
      setBeneficiaryMembers([]);
      setAllCheck(false);
    } else {
      if (allCheck === true) {
        // let data: any = [];
        let data = optionData?.filter((x: any) => {
          return x?.value !== value[value?.length - 1]?.value;
        });
        setAllCheck(false);
        setBeneficiaryMembers(data);
      } else {
        setBeneficiaryMembers(value);
      }
    }
  };


  useEffect(() => {
    setValue("countryCode", "+91 IN");
  }, []);
  useEffect(() => {
    if (selectedRowData && selUserData) {
      var x = cCodes.filter(
        (code: any) =>
          code?.value.split(" ")[0] === selectedRowData?.User?.countryCode
      );

      const family = selUserData?.result?.beneficiary?.map((x: any) => {
        return optionData?.filter((y: any) => {
          return y?.value === x;
        });
      });
      setBeneficiaryMembers(family?.map((item: any) => item[0]));
      const selectFamily = family?.map((x: any) => {
        return x[0];
      });
      console.log(selectFamily, "selectfamily");
      setValue("countryCode", x[0]?.value);
      setValue("fullName", selectedRowData?.User?.fullName);
      setValue(
        "mobileNumber",
        selectedRowData?.User?.mobileNumber
      );
      setValue("text", selectFamily);
    }
  }, [selectedRowData, optionData]);

  useEffect(() => {
    if (addedFamilyMemberData?.isSuccess) {
      dispatch(
        setAlert({
          open: "true",
          alertType: "success",
          message: "Family added successfully",
        })
      );
      setAddFamilyModal(false);
      reset();
      setBeneficiaryMembers([]);
      refetchData();
    }
  }, [addedFamilyMemberData?.isSuccess]);

  useEffect(() => {
    handleErrors(addedFamilyMemberData, dispatch);
  }, [addedFamilyMemberData?.isError]);

  useEffect(() => {
    if (editedFamilyMemberData?.isSuccess) {
      dispatch(
        setAlert({
          open: "true",
          alertType: "success",
          message: "Family updated successfully",
        })
      );
      setAddFamilyModal(false);
      reset();
      refetchData();
    }
  }, [editedFamilyMemberData?.isSuccess]);

  useEffect(() => {
    handleErrors(editedFamilyMemberData, dispatch);
  }, [editedFamilyMemberData?.isError]);

  const onCancelcallBack = () => {
    setAddFamilyModal(false);
    reset({
      fullName: "",
      mobileNumber: "",
    });
    setBeneficiaryMembers([]);
  };

  return (
    <>
      <Loader
        show={
          addedFamilyMemberData?.isLoading || editedFamilyMemberData?.isLoading
        }
      />
      <Modal
        key="addFamily"
        open={addFamilyModal}
        handleClose={() => {
          setAddFamilyModal(false);
          reset();
          setBeneficiaryMembers([]);
        }}
        primaryText={editable ? "Edit Family Member" : "Add New Family Member"}
        className="accountSetingModal addFamilyModal"
      >
        <form data-testid="form" onSubmit={handleSubmit(handleAddFamily)}>
          <Grid container sx={{ marginTop: "40px" }}>
            <Grid item xs={12}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Full Name"
                    value={field.value}
                    required={true}
                    onChange={field.onChange}
                    helperText={error?.message ? error?.message : " "}
                    error={Boolean(error)}
                    inputRef={(e: any) => {
                      ref(e);
                      inputRef.current = e;
                    }}
                    // autoFocus={true}
                    data-testid="full-name"
                  />
                )}
                name="fullName"
                control={control}
                rules={{
                  required: "Please enter full name",
                  pattern: {
                    value: RegExpressions?.FullName,
                    message:
                      "Enter a valid name with alphanumeric characters only.",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={3} sx={{ alignSelf: "self-end" }}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <CountryCodeSelectInput
                        {...field}
                        value={field.value ? `${field.value}` : ""}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        required={true}
                        onChange={field.onChange}
                        inputRef={ref}
                        label="Country Code"
                        optionValue={countriesCodes}
                        placeholder="Select"
                        disabled={editable ? true : false}
                        isCountryCode={true}
                      />
                    )}
                    name="countryCode"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="Mobile Number"
                        value={field.value}
                        error={Boolean(error)}
                        required={true}
                        onKeyDown={(e: any) => setKey(e.key)}
                        helperText={error?.message ? error?.message : " "}
                        disabled={editable ? true : false}
                        onChange={field.onChange}
                        inputRef={ref}
                      />
                    )}
                    name="mobileNumber"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter a valid mobile number",
                      },
                      pattern: {
                        value: RegExpressions?.InternationalMobileNumber,
                        message: "Please enter a valid mobile number. ",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <CheckboxSelectInput
                    {...field}
                    label="Select Beneficiaries"
                    // placeholder="Select Beneficiary Members"
                    placeholder={isDisabled ? "No beneficiary is available for association." : "Select Beneficiary Members."}
                    value={beneficiaryMembers}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={(e: any) => {
                      field.onChange(e);
                      onBeneficiarySelect(e, field);
                    }}
                    inputRef={ref}
                    multiple={true}
                    disabled={isDisabled}
                  >
                    {optionData && optionData?.length>0?<MenuItem key="all" value="all">
                      <div>
                        <ListItemText primary="All" />
                      </div>
                      <Checkbox className="checkboxBox" checked={allCheck} />
                    </MenuItem>:null}
                    {optionData?.map((option: any) => {
                      return (
                        <MenuItem key={option?.value} value={option}>
                          <div>
                            <ListItemText primary={option?.text} />
                          </div>
                          <Checkbox
                            className="checkboxBox"
                            checked={
                              beneficiaryMembers.findIndex(
                                (item: any) => item?.value === option?.value
                              ) >= 0
                            }
                          />
                        </MenuItem>
                      );
                    })}
                  </CheckboxSelectInput>
                )}
                name="text"
                control={control}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className="modalBtn">
            <Grid item xs={6} md={5} lg={4.6}>
              <Buttons
                type="secondary"
                variant="contained"
                text="CANCEL"
                onClick={onCancelcallBack}
              ></Buttons>
            </Grid>
            <Grid item xs={6} md={5} lg={4.6}>
              <Buttons
                type="primary"
                variant="contained"
                buttonType="submit"
                text="SAVE"
                data-testid="submit-btn"
              ></Buttons>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
};
