import { Grid, IconButton, Menu, MenuItem } from "@mui/material";
import { Table } from "../../common/table";
import {
  GridColDef,
  GridMoreVertIcon,
  GridPaginationModel,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const RolesAndPermission = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowCount, setRowCount] = useState(0);
  const [selectedRowData, setSelectedRowData] = useState<any>();
  const [rowData, setRowData] = useState<any>([]);
  const roleInfo = useSelector((state: any) => state?.role);
  const [tableHeight, setTableHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 6,
    page: 0,
  });

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
  };

  // const handleEditView = () => {
  //   navigate("/rolespermission/edit")
  // }

  const handleMenuClick = (event: any, row: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedRowData(rowData[row]);
  };

  const handleView = () => {
    navigate("/rolespermission/view", {
      state: selectedRowData.roleId,
    });
  };

  const handleClose = (event: any) => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (Array.isArray(roleInfo?.roleData)) {
      setRowData(
        roleInfo?.roleData.map((row: any, index: any) => ({
          id: index,
          ...row,
        }))
      );
    }
    setRowCount(roleInfo?.roleData.length);
  }, [roleInfo]);

  const columns: GridColDef[] = [
    {
      field: "roleName",
      headerName: "Role",
      minWidth: 300,
      cellClassName: "cell-padding",
      headerAlign: "left",
      sortable: false,
      // flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 1000,
      cellClassName: "cell-padding",
      headerAlign: "left",
      sortable: false,
      flex: 1,
    },
    {
      field: "action",
      sortable: false,
      align: "left",
      cellClassName: "stickyCell cell-padding",
      headerAlign: "left",
      headerClassName: "stickyColumn",
      headerName: "",
      width: 2,
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={(event: any) => handleMenuClick(event, params?.row?.id)}
          >
            <GridMoreVertIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleRowClick=(selectedRowData:any)=>{
    navigate("/rolespermission/view", {
      state: selectedRowData.roleId,
    });
  }

  const calHeight = () => {
    let targetCompo,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetCompo = window.document.querySelector<HTMLElement>(".rolesHeight");
    targetElement = targetCompo?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 25);
    setTableHeight(tableHeight);
  };

  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <>
      <Grid container marginBottom={3.6}>
        <Grid item xs={12} sm={12} md={9} xl={10.5} sx={{ mb: 3.6 }}>
          <span className="subscriberColumn1" data-testid="title-roledesc">
            Roles / Permission
          </span>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div className="rolesHeight">
            <Table
              rows={rowData}
              columns={columns}
              rowCount={rowCount}
              isPagination={true}
              onPaginationModelChange={handlePaginationModelChange}
              pagination={paginationModel}
              tableHeight={tableHeight}
              showHover={true}
              onRowClick={(params: any) => {
                handleRowClick(params?.row);
              }}
            />
          </div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{ width: "4000px" }}
          >
            <MenuItem onClick={handleView}>View</MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </>
  );
};
