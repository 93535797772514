import React, { useEffect, useRef } from "react";
import { Modal } from "../../common/modal/modal";
import { Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../common/input";
import Buttons from "../../common/button";
import { SelectInput } from "../../common/selectinput";
import { deviceTypeValue } from "../../../constants/enum";
import {
  useCreateDeviceMutation,
  useGetDevicesByIdMutation,
  useUpdateDeviceMutation,
} from "../../../redux/services/devicesApiSlice";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleErrors } from "../../../utils";
import Loader from "../../common/loader/loader";
import { RegExpressions } from "../../../constants/regExp";

export const AddDeviceModal = (props: any) => {
  const {
    isEdit,
    open,
    setOpen,
    deviceId,
    setIsEdit,
    getDevices,
    paginationModel,
  } = props;
  const dispatch = useDispatch();
  const [deviceDataById, deviceDataByIdData] = useGetDevicesByIdMutation();
  const [addDevice, addDeviceData] = useCreateDeviceMutation();
  const [updateDevice, updateDeviceData] = useUpdateDeviceMutation();

  const { userInfo } = useSelector((state: any) => state.auth);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { handleSubmit, control, setValue, reset } = useForm({
    mode: "onChange" || "onSubmit",
  });
  // const matches = useMediaQuery("(min-width:1500px)");

  const handleAddDevice = (data: any) => {
    var requestData: any = {
      tenantId: userInfo?.tenantId,
      biometricType: data?.deviceType,
      manufacturer: data?.manufacturerName,
      model: data?.modelNumber,
      cgst: data?.cgst,
      igst: data?.igst,
      sgst: data?.sgst,
      cost: [
        {
          value: data?.inrCost,
          currency: "INR",
          country: "IN",
        },
        {
          value: data?.usdCost,
          currency: "USD",
          country: "US",
        },
      ],
      isDiscountable: false,
      isActive: true,
      currency: "INR",
      sourceState: data?.sourceState,
      hsn: data?.hsnCode,
    };
    if (!isEdit) {
      addDevice(requestData);
    } else {
      updateDevice({
        deviceData: requestData,
        id: deviceDataByIdData?.data?.result?.deviceTypeId,
      });
    }
  };

  // useEffect(()=>{
  //     reset();
  // },[])

  const deviceOptions = [
    { text: "BP Monitor", value: deviceTypeValue.BLOODPRESSURE },
    { text: "Pulse Oximeter", value: deviceTypeValue.PULSEOXYMETER },
    { text: "Weighing Scale", value: deviceTypeValue.WEIGHT },
    { text: "Thermometer", value: deviceTypeValue.TEMPERATURE },
    { text: "Glucometer", value: deviceTypeValue.GLUCOSE },
  ];

  const handleClose = () => {
    setOpen(false);
    setIsEdit(false);
    reset();
  };

  useEffect(() => {
    if (isEdit) {
      deviceDataById(deviceId);
    }
  }, [isEdit]);

  useEffect(() => {
    if (addDeviceData?.isSuccess) {
      handleClose();
      getDevices({
        page: paginationModel?.page + 1,
        limit: paginationModel?.pageSize,
      });
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "device added Successfully.",
        })
      );
    }
  }, [addDeviceData?.isSuccess]);

  useEffect(() => {
    handleErrors(addDeviceData, dispatch);
  }, [addDeviceData?.isError]);

  useEffect(() => {
    if (updateDeviceData?.isSuccess) {
      handleClose();
      getDevices({
        page: paginationModel?.page + 1,
        limit: paginationModel?.pageSize,
      });
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Device updated Successfully.",
        })
      );
    }
  }, [updateDeviceData?.isSuccess]);

  useEffect(() => {
    handleErrors(updateDeviceData, dispatch);
  }, [updateDeviceData?.isError]);

  useEffect(() => {
    if (deviceDataByIdData?.isSuccess) {
      if (deviceDataByIdData?.data && deviceDataByIdData?.data?.result) {
        const inrCostData =
          deviceDataByIdData?.data?.result?.DeviceCostings?.filter(
            (item: any) => item?.country === "IN"
          );
        const usdCostData =
          deviceDataByIdData?.data?.result?.DeviceCostings?.filter(
            (item: any) => item?.country === "US"
          );
        setValue(
          "manufacturerName",
          deviceDataByIdData?.data?.result?.manufacturer
        );
        setValue("deviceType", deviceDataByIdData?.data?.result?.biometricType);
        setValue("modelNumber", deviceDataByIdData?.data?.result?.model);
        setValue("sourceState", deviceDataByIdData?.data?.result?.sourceState);
        setValue(
          "inrCost",
          inrCostData && inrCostData?.length > 0 ? inrCostData[0]?.value : null
        );
        setValue(
          "usdCost",
          usdCostData && usdCostData?.length > 0 ? usdCostData[0]?.value : null
        );
        setValue("cgst", deviceDataByIdData?.data?.result?.cgst);
        setValue("sgst", deviceDataByIdData?.data?.result?.sgst);
        setValue("igst", deviceDataByIdData?.data?.result?.igst);
        setValue("hsnCode", deviceDataByIdData?.data?.result?.hsn);
      }
    }
  }, [deviceDataByIdData?.isSuccess]);

  useEffect(() => {
    handleErrors(deviceDataByIdData, dispatch);
  }, [deviceDataByIdData?.isError]);

  const handleCancel = () => {
    setOpen(false);
    setIsEdit(false);
    reset();
  };

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef?.current?.focus();
      }
    }, 0);
  }, [open]);

  return (
    <>
      <Loader
        show={
          deviceDataByIdData?.isLoading ||
          updateDeviceData?.isLoading ||
          addDeviceData?.isLoading
        }
      />
      <Modal
        handleClose={handleClose}
        primaryText={!isEdit ? "Add Device" : "Edit Device"}
        open={open}
        className="input-padding modal-title outer-pd-device"
      >
        <form onSubmit={handleSubmit(handleAddDevice)}>
          <Grid container>
            <Grid item xs={12} sx={{ mt: 4 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Manufacturer Name"
                    value={field.value}
                    required={true}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={(e: any) => {
                      ref(e);
                      inputRef.current = e;
                    }}
                    placeholder="Enter manufacturer name"
                    autoFocus={true}
                  />
                )}
                name="manufacturerName"
                control={control}
                rules={{
                  required: "Please enter manufacture name ",
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 4 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <SelectInput
                    {...field}
                    disabled={isEdit}
                    value={field.value ? `${field.value}` : ""}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    placeholder="Select device type"
                    required={true}
                    onChange={field.onChange}
                    inputRef={ref}
                    label="Device Type"
                    optionValue={deviceOptions}
                  />
                )}
                name="deviceType"
                control={control}
                rules={{
                  required: "Please select device type",
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Model Number"
                    value={field.value}
                    required={true}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                    placeholder="Enter model number"
                  />
                )}
                name="modelNumber"
                control={control}
                rules={{
                  required: "Please enter model number ",
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Source State"
                    value={field.value}
                    required={true}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                    placeholder="Enter source state"
                  />
                )}
                name="sourceState"
                control={control}
                rules={{
                  required: "Please enter source state",
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Cost(INR)"
                    value={field.value}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                    placeholder="Enter cost in INR"
                    required={true}
                  />
                )}
                name="inrCost"
                control={control}
                rules={{
                  required: "Please enter cost(INR)",
                  pattern: {
                    value: RegExpressions?.Zero,
                    message: "Please enter vaid cost",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Cost(USD)"
                    value={field.value}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                    placeholder="Enter cost in USD"
                    required={true}
                  />
                )}
                name="usdCost"
                control={control}
                rules={{
                  required: "Please enter cost(USD)",
                  pattern: {
                    value: RegExpressions?.Zero,
                    message: "Cost only contains numeric values",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2.5 }}>
              <Grid container columnSpacing={4}>
                <Grid item xs={6}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="CGST"
                        value={field.value}
                        required={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                        placeholder="Enter CGST"
                      />
                    )}
                    name="cgst"
                    control={control}
                    rules={{
                      required: "Please enter CGST(%) value",
                      pattern: {
                        value: RegExpressions?.Zero,
                        message: "Invalid value.",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="SGST"
                        value={field.value}
                        required={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                        placeholder="Enter SGST"
                      />
                    )}
                    name="sgst"
                    control={control}
                    rules={{
                      required: "Please enter SGST(%) value",
                      pattern: {
                        value: RegExpressions?.Zero,
                        message: "Invalid value.",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ mt: 2 }}>
              <Grid container columnSpacing={4}>
                <Grid item xs={6}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="IGST"
                        value={field.value}
                        required={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                        placeholder="Enter IGST"
                      />
                    )}
                    name="igst"
                    control={control}
                    rules={{
                      required: "Please enter IGST(%) value",
                      pattern: {
                        value: RegExpressions?.Zero,
                        message: "Invalid value",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="HSN Code"
                        value={field.value}
                        required={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                        placeholder="Enter HSN code"
                      />
                    )}
                    name="hsnCode"
                    control={control}
                    rules={{
                      required: "Please enter HSN code ",
                      pattern: {
                        value: RegExpressions?.nodecimalOnlyNumber,
                        message: "HSN code must be numeric value",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="modalBtn">
              <Grid item xs={4.5}>
                <Buttons
                  type="secondary"
                  variant="contained"
                  text="CANCEL"
                  onClick={handleCancel}
                ></Buttons>
              </Grid>
              <Grid item xs={4.5}>
                <Buttons
                  type="primary"
                  variant="contained"
                  buttonType="submit"
                  text="SAVE"
                  data-testid="submit-btn"
                ></Buttons>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
};
