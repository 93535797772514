import { useEffect, useState } from "react";
import "./style.css";
import {
  Box,
  Button,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Table } from "../../common/table";
import {
  GridColDef,
  GridMoreVertIcon,
  GridPaginationModel,
} from "@mui/x-data-grid";
import {
  getCountryNameByCountryCode,
  handleErrors,
  phoneNumberAutoFormat,
} from "../../../utils";
import { UserRoles, UserStatus } from "../../../constants/enum";
import { Page } from "../../../routes/config";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useDeactivateUserMutation,
  useDeleteUserMutation,
  useGetAllUsersMutation,
  useGetAllUsersofSubscriberMutation,
} from "../../../redux/services/userApiSlice";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { SubscriberHeader } from "./manageUserHeader/subscriberHeader";
import { PlatformSupportHeader } from "./manageUserHeader/platformSupportHeader";
import { PlatformAdminHeader } from "./manageUserHeader/platformAdminHeader";
import { Messages } from "../../../constants/messages";
import { BootstrapDialog, ConfirmModal } from "../../common/confirmmodal";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import {
  useForgotPasscodeMutation,
  useForgotPasswordMutation,
  useResetPasscodeMutation,
} from "../../../redux/services/authApiSlice";
import { AddFamilyModal } from "./modal/addFamilyModal";
import { SendMessageModal } from "./modal/sendMessageModal";
import { AddBeneficiaryModal } from "./modal/addBeneficiaryModal";
import Loader from "../../common/loader/loader";
import { useGetUserSubscriptionPlanMutation } from "../../../redux/services/planApiSlice";
import {
  setManageUsersFilters,
  setPaginationFilter,
} from "../../../redux/features/filters/filtersSlice";
import { setBeneficaryAccountsCount } from "../../../redux/features/user/beneficiarySlice";
import { CustomToolTip } from "../../common/tooltip";
import { UserGuideModal } from "./manageUserHeader/userGuideModal";

export const ManageUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showGuideModal, setShowGuideModal] = useState(false);

  const [tableHeight, setTableHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState<any>();
  const [rowData, setRowData] = useState<any>([]);
  const [rowCount, setRowCount] = useState(0);

  const filtersData = useSelector((state: any) => state.filters);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: filtersData?.pageSize,
    page: filtersData?.page,
  });
  const [filterData, setFilterData] = useState<any>({
    search: filtersData?.manageUserSearch,
    country: filtersData?.location,
    roleId: filtersData?.userType,
    status: filtersData?.userStatus,
  });
  const { userInfo } = useSelector((state: any) => state?.auth);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const [activateModal, setActivateModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [addFamilyModal, setAddFamilyModal] = useState<boolean>(false);
  const [sendMessageModal, setSendMessageModal] = useState<boolean>(false);
  const [addBeneficiaryModal, setAddBeneficiaryModal] =
    useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [benCount, setBenCount] = useState<any>();
  console.log(benCount, "beneffi")
  const userId = useSelector((state: any) => state?.auth?.userId);

  const getFilteredParams = () => {
    var data = {};
    data = Object.fromEntries(
      Object.entries(filterData).filter(([_, v]) => v !== "" && v !== null)
    );
    return data;
  };
  useEffect(() => {
    const search = location?.search;
    const params = new URLSearchParams(search);
    const checkShowGuide = params.get("showGuide");
    if (checkShowGuide) {
      setShowGuideModal(true);
    } else {
      setShowGuideModal(false);
    }
  }, [location]);

  //API Integration

  const [getAllUsers, AllUsersData] = useGetAllUsersMutation();
  const [getAllUsersofSubscriber, AllUsersofSubscriberData] =
    useGetAllUsersofSubscriberMutation();
  const [deactivateUser, deactivateUserInfo] = useDeactivateUserMutation();
  const [forgetPassword, forgetPasswordInfo] = useForgotPasswordMutation();
  const [deleteUser, deleteUserInfo] = useDeleteUserMutation();
  const [forgetPasscode, forgetPasscodeInfo] = useForgotPasscodeMutation();
  const [resetPasscode, resetPasscodeInfo] = useResetPasscodeMutation();
  const [getSubscriptionPlan, getSubscriptionPlanData] =
    useGetUserSubscriptionPlanMutation();
  useEffect(() => {
    if (userInfo?.roleName === UserRoles.SUBSCRIBER) {
      getAllUsersofSubscriber({
        subscriberId: userInfo?.userId,
        limit: paginationModel?.pageSize,
        page: paginationModel?.page + 1,
        ...getFilteredParams(),
      });
      getSubscriptionPlan(userId);
    } else {
      getAllUsers({
        limit: paginationModel?.pageSize,
        page: paginationModel?.page + 1,
        ...getFilteredParams(),
      });
    }
  }, [filterData, paginationModel]);

  useEffect(() => {
    dispatch(setManageUsersFilters(filterData));
  }, [filterData]);

  const refetchDataOnAddFamily = () => {
    getAllUsersofSubscriber({
      subscriberId: userInfo?.userId,
      limit: paginationModel?.pageSize,
      page: paginationModel?.page + 1,
      ...getFilteredParams(),
    });
  };

  const refetchPlatformUser = () => {
    getAllUsers({
      limit: paginationModel?.pageSize,
      page: paginationModel?.page + 1,
      ...getFilteredParams(),
    });
  };

  const handleMenuClick = (event: any, row: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowData(rowData[row]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = () => {
    if (selectedRowData?.roleName === UserRoles?.SUBSCRIBER) {
      navigate(Page.MANAGEUSERVIEWSUBSCRIBER, {
        state: { edit: false, userData: selectedRowData },
      });
    } else if (selectedRowData?.roleName === UserRoles?.BENEFICIARY) {
      navigate(Page.MANAGEUSERVIEWBENEFICIARY, {
        state: {
          edit: false,
          userData: selectedRowData,
          benId: selectedRowData?.User?.userId,
        },
      });
    } else if (selectedRowData?.roleName === UserRoles?.FAMILY) {
      navigate(Page.MANAGEUSERVIEWFAMILY, {
        state: { edit: false, userData: selectedRowData },
      });
    } else if (selectedRowData?.roleName === UserRoles?.PLATFORM_ADMIN) {
      if (selectedRowData?.User?.userId === userInfo?.userId) {
        navigate(Page.MANAGEUSER_ACCOUNTSETTINGS);
      } else {
        navigate(Page.MANAGEUSERVIEWPLATFORMADMIN, {
          state: { edit: false, userData: selectedRowData },
        });
      }
    } else if (selectedRowData?.roleName === UserRoles?.PLATFORM_SUPPORT) {
      navigate(Page.MANAGEUSERVIEWPLATFORMSUPPORT, {
        state: { edit: false, userData: selectedRowData },
      });
    } else if (selectedRowData?.roleName === UserRoles?.PLATFORM_SUPER_ADMIN) {
      if (selectedRowData?.User?.userId === userInfo?.userId) {
        navigate(Page.MANAGEUSER_ACCOUNTSETTINGS);
      } else {
        navigate(Page.MANAGEUSERVIEWPLATEFORMSUPERADMIN, {
          state: { edit: false, userData: selectedRowData },
        });
      }
    }
  };

  const manageOrderStatus = (status: string) => {
    return (
      <>
        {status === UserStatus.ACTIVE ? (
          <label className="userStatusActive">Active</label>
        ) : status === UserStatus.DEACTIVATED ? (
          <label className="userStatusDeactive">Deactivated</label>
        ) : status === UserStatus.INVITED ? (
          <label className="userStatusInvited">Invited</label>
        ) : (
          <label className="">{status}</label>
        )}
      </>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Name",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="textWordBreak tableRenderCellText textCapital">
            {params?.row?.User?.fullName}
          </div>
        );
      },
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      minWidth: 180,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="textWordBreak tableRenderCellText">
            {params?.row?.User?.countryCode}&nbsp;
            {phoneNumberAutoFormat(params?.row?.User?.mobileNumber)}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email Address",
      minWidth: 400,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="textWordBreak tableRenderCellText">
            <CustomToolTip title={params?.row?.User?.email} placement="top">
              <p className="textSlice"> {params?.row?.User?.email} </p>
            </CustomToolTip>
          </div>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Create Date",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,

      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="textWordBreak tableRenderCellText">
            {dayjs(params?.row?.createdAt)?.format("DD MMM, YYYY")}
          </div>
        );
      },
    },
    {
      field: "country",
      headerName: "Location",
      minWidth: 100,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="textWordBreak tableRenderCellText textCapital">
            {/* {params?.row?.User?.country?.length > 2
              ? params?.row?.User?.country?.toLowerCase()
              : params?.row?.User?.country} */}
            {getCountryNameByCountryCode(params?.row?.User?.country)}
          </div>
        );
      },
    },
    {
      field: "roleName",
      headerName: "User Type",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-padding",
      minWidth: 110,
      sortable: false,
      // flex: 1,
      renderCell: (params) => {
        return manageOrderStatus(params?.row?.User?.status);
      },
    },
    {
      field: "action",
      sortable: false,
      align: "left",
      cellClassName: "stickyCell cell-padding",
      headerAlign: "left",
      headerClassName: "stickyColumn",
      headerName: "",
      width: 0.5,
      renderCell: (params) => (
        <>
          {(userInfo?.roleName === UserRoles?.PLATFORM_ADMIN &&
            params?.row?.roleName === UserRoles.PLATFORM_SUPER_ADMIN) ||
          (userInfo?.roleName === UserRoles?.PLATFORM_SUPPORT &&
            (params?.row?.roleName === UserRoles.PLATFORM_SUPER_ADMIN ||
              params?.row?.roleName === UserRoles.PLATFORM_ADMIN ||
              params?.row?.User?.status === "invited")) ? (
            <></>
          ) : (
            <IconButton
              onClick={(event: any) => handleMenuClick(event, params?.row?.id)}
            >
              <GridMoreVertIcon />
            </IconButton>
          )}
        </>
      ),
    },
  ];

  const calHeight = () => {
    let targetComp,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetComp = window.document.querySelector<HTMLElement>(".manageUsers");
    targetElement = targetComp?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 25);
    setTableHeight(tableHeight);
  };

  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
      setScreenWidth(window.innerWidth);
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (AllUsersData?.isSuccess) {
      if (userInfo?.roleName === UserRoles?.PLATFORM_SUPPORT) {
        const filterSuperAdmin = AllUsersData?.data?.result?.filter(
          (row: any) => row?.roleName !== UserRoles?.PLATFORM_SUPER_ADMIN
        );
        setRowData(
          filterSuperAdmin.map((row: any, index: any) => ({
            id: index,
            ...row,
          }))
        );
      } else {
        setRowData(
          AllUsersData?.data?.result?.map((row: any, index: any) => ({
            id: index,
            ...row,
          }))
        );
      }
      setRowCount(AllUsersData?.data?.count);
    }
  }, [AllUsersData?.isSuccess, AllUsersData?.fulfilledTimeStamp]);

  useEffect(() => {
    handleErrors(AllUsersData, dispatch);
  }, [AllUsersData?.isError]);

  useEffect(() => {
    if (AllUsersofSubscriberData?.isSuccess) {
      setRowData(
        AllUsersofSubscriberData?.data?.result?.users?.map(
          (row: any, index: any) => ({
            id: index,
            ...row,
          })
        )
      );
      dispatch(
        setBeneficaryAccountsCount(
          AllUsersofSubscriberData?.data?.result?.beneficiaryTypeCount
        )
      );
      setRowCount(AllUsersofSubscriberData?.data?.result?.totalUserCount);
    }
  }, [AllUsersofSubscriberData?.isSuccess]);

  useEffect(() => {
    handleErrors(AllUsersofSubscriberData, dispatch);
  }, [AllUsersofSubscriberData?.isError]);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
    dispatch(setPaginationFilter(newPaginationModel));
  };

  const columnVisibilityObject = {
    country: userInfo?.roleName === UserRoles.SUBSCRIBER ? false : true,
    email: userInfo?.roleName === UserRoles.SUBSCRIBER ? false : true,
  };
  const handleDeactivate = () => {
    setDeactivateModal(true);
    setAnchorEl(null);
  };
  const onConfirmDeactivate = () => {
    deactivateUser({
      id: selectedRowData?.User?.userId,
      // subscriberId: selectedRowData?.User?.userId,
      status: "inactive",
    });
    console.log(selectedRowData?.User?.row, "oooooo");
  };
  const onCancelDeactivate = () => {
    setDeactivateModal(false);
  };
  useEffect(() => {
    if (deactivateUserInfo?.isSuccess) {
      setDeactivateModal(false);
      setActivateModal(false);
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: deactivateUserInfo?.data?.message,
        })
      );
      if (userInfo?.roleName === UserRoles.SUBSCRIBER) {
        refetchDataOnAddFamily();
      } else {
        getAllUsers({
          limit: paginationModel?.pageSize,
          page: paginationModel?.page + 1,
          ...getFilteredParams(),
        });
      }
    }
  }, [deactivateUserInfo?.isSuccess]);
  const handleActivate = () => {
    if (
      userInfo?.roleName === UserRoles?.SUBSCRIBER &&
      selectedRowData?.roleName === UserRoles.BENEFICIARY
    ) {
      const totalBeneficiary =
        AllUsersofSubscriberData?.data?.result?.beneficiaryTypeCount
          ?.activeBeneficiaries +
        AllUsersofSubscriberData?.data?.result?.beneficiaryTypeCount
          ?.invitedBeneficiaries;
      if (getSubscriptionPlanData?.data?.result[0]?.allowedBeneficiary < totalBeneficiary || getSubscriptionPlanData?.data?.result[0]?.allowedBeneficiary === totalBeneficiary) {
        setConfirmModal(true);
        setAnchorEl(null);
      } else {
        setActivateModal(true);
        setAnchorEl(null);
      }
    } else {
      setActivateModal(true);
      setAnchorEl(null);
    }
  };
  const onConfirmActivate = () => {
    deactivateUser({
      id: selectedRowData?.User?.userId,
      // subscriberId: selectedRowData?.User?.userId,
      status: "active",
    });
  };
  const onCancelActivate = () => {
    setActivateModal(false);
  };
  useEffect(() => {
    if (deactivateUserInfo?.isError) {
      handleErrors(deactivateUserInfo, dispatch);
      // setActivateModal(false);
      // setDeactivateModal(false);
    }
  }, [deactivateUserInfo?.isError]);
  const handleResetPassword = () => {
    setResetPasswordModal(true);
    setAnchorEl(null);
  };
  const onCancelResetPassword = () => {
    setResetPasswordModal(false);
  };

  const onConfirmResetPassword = () => {
    setResetPasswordModal(false);
    if (
      selectedRowData?.roleName !== UserRoles?.BENEFICIARY &&
      selectedRowData?.roleName !== UserRoles?.FAMILY
    ) {
      forgetPassword({ email: selectedRowData?.User?.email });
    } else {
      let payload = {
        userId: selectedRowData?.User?.userId,
        mobileNumber: selectedRowData?.User?.mobileNumber,
        countryCode: selectedRowData?.User?.countryCode,
        roleName: selectedRowData?.roleName,
        fullName: selectedRowData?.User?.fullName,
      };
      resetPasscode(payload);
    }
  };

  const handleDelete = () => {
    setDeleteUserModal(true);
    setAnchorEl(null);
  };
  const onCancelDelete = () => {
    setDeleteUserModal(false);
  };
  const onConfirmDelete = () => {
    deleteUser(selectedRowData?.User?.userId);
  };
  useEffect(() => {
    if (forgetPasswordInfo?.isSuccess) {
      dispatch(
        setAlert({
          open: "true",
          alertType: "success",
          message: forgetPasswordInfo?.data?.message,
        })
      );
    }
  }, [forgetPasswordInfo?.isSuccess]);
  useEffect(() => {
    if (forgetPasswordInfo?.isError) {
      handleErrors(forgetPasswordInfo, dispatch);
    }
  }, [forgetPasswordInfo?.isError]);

  useEffect(() => {
    if (resetPasscodeInfo?.isSuccess) {
      dispatch(
        setAlert({
          open: "true",
          alertType: "success",
          message: resetPasscodeInfo?.data?.result,
        })
      );
    }
  }, [resetPasscodeInfo?.isSuccess]);

  useEffect(() => {
    if (resetPasscodeInfo?.isError) {
      handleErrors(resetPasscodeInfo, dispatch);
    }
  }, [resetPasscodeInfo?.isError]);

  useEffect(() => {
    if (deleteUserInfo?.isSuccess) {
      setDeleteUserModal(false);
      refetchPlatformUser();
      dispatch(
        setAlert({
          open: "true",
          alertType: "success",
          message: deleteUserInfo?.data?.result,
        })
      );
    }
  }, [deleteUserInfo?.isSuccess]);

  useEffect(() => {
    if (deleteUserInfo?.isError) {
      handleErrors(deleteUserInfo, dispatch);
    }
  }, [deleteUserInfo?.isError]);

  const handleEditProfile = () => {
    if (selectedRowData?.roleName === "Beneficiary") {
      setAddBeneficiaryModal(true);
    } else {
      setAddFamilyModal(true);
    }
    handleClose();
  };
  useEffect(() => {
    if (getSubscriptionPlanData?.isSuccess) {
      if(getSubscriptionPlanData?.data && getSubscriptionPlanData?.data?.length > 0){
        setBenCount(getSubscriptionPlanData?.data?.result[0]?.allowedBeneficiary);
      }
    }
  }, [getSubscriptionPlanData?.isSuccess]);
  const handleConfirmModalClose = () => {
    setConfirmModal(false);
  };

  return (
    <>
      <Loader
        show={
          deactivateUserInfo?.isLoading ||
          AllUsersofSubscriberData?.isLoading ||
          AllUsersData?.isLoading ||
          deleteUserInfo?.isLoading ||
          forgetPasswordInfo?.isLoading ||
          resetPasscodeInfo?.isLoading ||
          getSubscriptionPlanData?.isLoading
        }
      />
      <Box>
        {userInfo?.roleName === UserRoles.SUBSCRIBER ? (
          <SubscriberHeader
            filtersData={filtersData}
            screenWidth={screenWidth}
            refetchDataOnAddFamily={refetchDataOnAddFamily}
            beneficiaryCount={benCount}
            AllUsersofSubscriberData={AllUsersofSubscriberData}
            getSubscriptionPlanData={getSubscriptionPlanData}
            rowCount={rowCount}

          />
        ) : userInfo?.roleName === UserRoles.PLATFORM_SUPPORT ? (
          <PlatformSupportHeader
            filtersData={filtersData}
            screenWidth={screenWidth}
            filterData={filterData}
            setFilterData={setFilterData}
            rowCount={rowCount}

          />
        ) : (
          <PlatformAdminHeader
            filtersData={filtersData}
            screenWidth={screenWidth}
            filterData={filterData}
            setFilterData={setFilterData}
            refetchPlatformUser={refetchPlatformUser}
            rowCount={rowCount}
          />
        )}
        <Grid container>
          <Grid item xs={12}>
            <div className="manageUsers">
              <Table
                rowCount={rowCount}
                rows={rowData}
                columns={columns}
                isPagination={true}
                onPaginationModelChange={handlePaginationModelChange}
                pagination={paginationModel}
                tableHeight={tableHeight}
                columnVisibilityObject={columnVisibilityObject}
              />
            </div>
          </Grid>
        </Grid>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ width: "4000px" }}
        >
          {(userInfo?.roleName === UserRoles.PLATFORM_ADMIN ||
            userInfo?.roleName === UserRoles?.PLATFORM_SUPPORT ||
            userInfo?.roleName === UserRoles.PLATFORM_SUPER_ADMIN) &&
          selectedRowData?.User?.status !== "invited" ? (
            <MenuItem onClick={handleView}>View</MenuItem>
          ) : null}
          {userInfo?.roleName === UserRoles.SUBSCRIBER ? (
            <span>
              <Divider className="divider-mp" />
              <MenuItem onClick={handleEditProfile}>Edit Profile</MenuItem>
            </span>
          ) : null}

          {userInfo?.roleName === UserRoles.PLATFORM_SUPPORT ||
          userInfo?.userId === selectedRowData?.User?.userId ||
          ((selectedRowData?.roleName === UserRoles?.PLATFORM_ADMIN ||
            selectedRowData?.roleName === UserRoles?.PLATFORM_SUPPORT ||
            selectedRowData?.roleName === UserRoles?.PLATFORM_SUPER_ADMIN) &&
            selectedRowData?.User?.status === "invited") ? null : (
            <span>
              <>
                <Divider className="divider-mp" />
                {selectedRowData?.User?.status === "inactive" ? (
                  <MenuItem onClick={handleActivate}>Activate</MenuItem>
                ) : (
                  <MenuItem onClick={handleDeactivate}>Deactivate</MenuItem>
                )}
              </>
            </span>
          )}

          {(userInfo?.roleName !== UserRoles.PLATFORM_ADMIN &&
            userInfo?.roleName !== UserRoles.PLATFORM_SUPER_ADMIN) ||
          userInfo?.userId === selectedRowData?.User?.userId ? null : (
            <span>
              <Divider className="divider-mp" />
              <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </span>
          )}

          {userInfo?.roleName !== UserRoles.SUBSCRIBER &&
          selectedRowData?.User?.status !== "invited" ? (
            <span>
              <Divider className="divider-mp" />
              <MenuItem onClick={handleResetPassword}>
                {selectedRowData?.roleName !== UserRoles?.BENEFICIARY &&
                selectedRowData?.roleName !== UserRoles?.FAMILY
                  ? "Reset Password"
                  : "Reset Passcode"}
              </MenuItem>
            </span>
          ) : null}

          {userInfo?.roleName === UserRoles.PLATFORM_SUPPORT &&
          selectedRowData?.User?.status !== "invited" &&
          selectedRowData?.roleName !== UserRoles.PLATFORM_SUPPORT ? (
            <span>
              <Divider />
              <MenuItem
                onClick={() => {
                  setSendMessageModal(true);
                  handleClose();
                }}
              >
                Send Message
              </MenuItem>
            </span>
          ) : null}
        </Menu>

        <div className="modelModal" data-testid="confirm-modal">
          <ConfirmModal
            className="confirmModal"
            key="deactivate"
            open={deactivateModal}
            primaryText={Messages.DEACTIVATE}
            cancelButton="NO"
            confirmButton="YES"
            cancelCallback={onCancelDeactivate}
            confirmCallback={onConfirmDeactivate}
          />
        </div>
        <div className="modelModal" data-testid="confirm-modal">
          <ConfirmModal
            className="confirmModal"
            key="activate"
            open={activateModal}
            primaryText={Messages.ACTIVATE}
            cancelButton="NO"
            confirmButton="YES"
            cancelCallback={onCancelActivate}
            confirmCallback={onConfirmActivate}
          />
        </div>
        <div className="modelModal" data-testid="confirm-modal">
          <ConfirmModal
            className="confirmModal"
            key="resetPassword"
            open={resetPasswordModal}
            primaryText={
              selectedRowData?.roleName !== UserRoles?.BENEFICIARY &&
              selectedRowData?.roleName !== UserRoles?.FAMILY
                ? Messages.RESET_PASSWORD
                : Messages.RESET_PASSCODE
            }
            cancelButton="NO"
            confirmButton="YES"
            cancelCallback={onCancelResetPassword}
            confirmCallback={onConfirmResetPassword}
          />
        </div>
        <div className="modelModal" data-testid="confirm-modal">
          <ConfirmModal
            className="confirmModal"
            key="DeleteUser"
            open={deleteUserModal}
            primaryText={Messages.DELETEUSER}
            cancelButton="NO"
            confirmButton="YES"
            cancelCallback={onCancelDelete}
            confirmCallback={onConfirmDelete}
          />
        </div>
        {addFamilyModal && (
          <AddFamilyModal
            addFamilyModal={addFamilyModal}
            setAddFamilyModal={setAddFamilyModal}
            editable={true}
            selectedRowData={selectedRowData}
            refetchData={refetchDataOnAddFamily}
          />
        )}
        {sendMessageModal && (
          <SendMessageModal
            open={sendMessageModal}
            setOpen={setSendMessageModal}
            selectedRowData={selectedRowData}
          />
        )}
        {showGuideModal && (
          <UserGuideModal open={showGuideModal} setOpen={setShowGuideModal} />
        )}
        {addBeneficiaryModal && (
          <AddBeneficiaryModal
            addBeneficiaryModal={addBeneficiaryModal}
            setAddBeneficiaryModal={setAddBeneficiaryModal}
            selectedRowData={selectedRowData}
            refetchData={refetchDataOnAddFamily}
            editable={true}
            getSubscriptionPlanData={getSubscriptionPlanData}
          />
        )}
        <div className="modelModal">
          <div className="ui-Confirm-Modal">
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={confirmModal}
              className="confirmModal"
            >
              {/* <img src={crossIcon}/> */}
              <div className="normalText redText">
                {Messages?.ACTIVATEBENEFICARY}
              </div>

              <Divider />

              <DialogActions className="buttonsDisplay">
                <Button
                  className="confirmbtn"
                  onClick={handleConfirmModalClose}
                >
                  EXIT
                </Button>
              </DialogActions>
            </BootstrapDialog>
          </div>
        </div>
      </Box>
    </>
  );
};
