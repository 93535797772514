import { Checkbox, Grid, ListItemText, MenuItem } from "@mui/material";
import { CheckboxSelectInput, SelectInput } from "../../common/selectinput";
import Buttons from "../../common/button";
import { Input } from "../../common/input";
import { Modal } from "../../common/modal/modal";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useGetDevicesMutation } from "../../../redux/services/devicesApiSlice";
import {
  useCreatePlanMutation,
  useGetAllPlansQuery,
  useGetAllProductMutation,
  usePlanDetailsMutation,
  useUpdatePlanMutation,
} from "../../../redux/services/planApiSlice";
import { PreviewPlan } from "./previewPlan";
import { handleErrors, handlePriceFormatting } from "../../../utils";
import { useDispatch } from "react-redux";
import { RegExpressions } from "../../../constants/regExp";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import Loader from "../../common/loader/loader";
import { CustomToolTip } from "../../common/tooltip";
import { DataGrid, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { CreatePartnerModal } from "./createPartnerModal";
import { PlanDetailsModal } from "./planDetailsModal";
import {
  BILLING_FREQUENCY,
  PLAN_TYPES,
} from "../../../constants/constant";

export const CreatePlan = (props: any) => {
  const dispatch = useDispatch();
  const {
    createPlan,
    setCreatePlan,
    editPlan,
    isUpgradeEdit,
    refetch,
    planId,
    isUpgrade,
  } = props;

  const [plan, planInfo] = usePlanDetailsMutation();

  const [getDevices, getDevicesData] = useGetDevicesMutation();
  const [createPlanData, createPlanInfoData] = useCreatePlanMutation();
  const [updatePlan, updatePlanInfo] = useUpdatePlanMutation();
  const [getProduct, getProductInfo] = useGetAllProductMutation();
  const [draft, setDraft] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [showPartnerModal, setShowPartnerModal] = useState(false);

  const { handleSubmit, control, setValue, getValues, reset } = useForm({
    mode: "onChange" || "onSubmit",
    defaultValues: {
      planType: "",
      planName: "",
      planTag: "",
      billingFrequency: "",
      subTitle: "",
      beneficiaryCount: "",
      recurringAmount: "",
      fixedAmount: "",
      planDetails: "",
      billingCountry: "",
      devices: [],
      deviceTableData: [],
      upgradeType: "",
      unitAmount: "",
      shippingCharges: "",
      partnerService: "",
    },
  });
  const [tableHeight, setTableHeight] = useState(0);
  const [allCheck, setAllCheck] = useState(false);
  const [device, setDevice] = useState<any>([]);
  const [spareDevice, setSpareDevice] = useState<any>([]);
  const [beneficiarySupportedOption, setBeneficiarySupportedOption] =
    useState<any>([
      { text: "1", value: "1" },
      { text: "2", value: 2 },
      { text: "4", value: 4 },
      { text: "6", value: 6 },
      { text: "8", value: 8 },
      { text: "10", value: "10" },
    ]);
  const [planData, setPlanData] = useState<any>();
  const [isUpdate, setIsUpdate] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [deviceName, setDeviceName] = useState<any>([]);
  const [productIdArray, setProductIdArray] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [selectCountry, setSelectCountry] = useState<any>("US");
  const [partnerDetails, setPartnerDetails] = useState<any>();
  const [partnerOptions, setPartnerOptions] = useState<any[]>([
    { text: "None", value: null },
  ]);
  const [partnerValue, setPartnerValue] = useState<any>();
  const [showPlanDetails, setShowplanDetails] = useState(false);
  const [filteredPartners, setFilteredPartners] = useState<any[]>([]);

  const countryOptions = [
    { text: "India", value: "IN" },
    { text: "United States", value: "US" },
  ];

  const [tableData, setTableData] = useState<any>([]);

  const [subscriptionLabel, setSubscriptionLabel] =
    useState("Subscription Price");

  const { data, isSuccess, isLoading, error } = useGetAllPlansQuery({
    planType: PLAN_TYPES.partner,
  });

  const onFrequencyChange = (val: any) => {
    setSubscriptionLabel(`Subscription Price (${val?.toUpperCase()})`);
  };

  useEffect(() => {
    if (createPlan) {
      getDevices({ refetchOnMountOrArgChange: true });
      getProduct({ status: "Active" });
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      let freq = getValues("billingFrequency");
      let rowData = data?.result.filter(
        (pl: any) =>
          selectCountry == pl?.billingCountry && freq == pl?.billingFrequency
      );
      setFilteredPartners(rowData);
    }
  }, [data, isSuccess, selectCountry, getValues("billingFrequency")]);

  useEffect(() => {
    let filteredBenificiaryPartners:any[] = [];

    if (filteredPartners?.length) {
      filteredBenificiaryPartners = filteredPartners
        ?.filter(
          (partner: any) =>
            partner?.beneficiaryCount == getValues("beneficiaryCount")
        )
        .map((option: any) => {
          return {
            text: `${option?.name}, Subscription Fee: ${
              selectCountry == "US" ? "USD" : "INR"
            } ${handlePriceFormatting(
              option?.recurringAmount,
              selectCountry
            )}, Subscription Cycle: ${option?.billingFrequency?.toUpperCase()}`,
            value: JSON.stringify(option),
          };
        });
    }
    setPartnerOptions([
      { text: "None", value: null },
      ...filteredBenificiaryPartners,
    ]);
  }, [filteredPartners]);

  const getPlanDetail = () => {
    if (planId) {
      reset();
      setDevice([]);
      plan({ id: planId });
    }
  };

  useEffect(() => {
    if (getDevicesData?.isSuccess) {
      let data: any = [];
      getDevicesData?.data?.result?.map((x: any) => {
        data.push({
          text: x?.biometricType,
          description: x?.model,
          value: x?.deviceTypeId,
          currency: x?.currency,
          quantity: 1,
          deviceCost: x?.cost,
          deviceCostings: x?.DeviceCostings,
          igst: x?.igst,
        });
      });
      setDeviceName(data);
      setSpareDevice(data);
      getPlanDetail();
    }
  }, [getDevicesData?.isSuccess]);

  const labels: any = {
    "Blood Glucose": "Glucometer",
    "Blood Pressure": "BP Monitor",
    "Temperature": "Thermometer",
    "PulseOx": "Pulse Oximeter",
    "Weight": "Weighing Scale",
  };

  useEffect(() => {
    handleErrors(getDevicesData, dispatch);
  }, [getDevicesData?.isError]);

  const handleCancel = () => {
    handleResetData();
  };

  useEffect(() => {
    setValue("billingCountry", "US");
    setSelectCountry("US");
  }, []);

  function getPartnerData(data: any) {
    console.log("GET PARTNER>>>>", data);
    let partner: any[] = [];
    if (data?.partnerService) {
      let partnerDetails = JSON.parse(data?.partnerService);
      if (partnerDetails?.subscriptionPlanId) {
        partner.push(partnerDetails?.subscriptionPlanId);
      } else {
        partner.push(partnerDetails);
      }
    }
    return partner;
  }

  const handleCreatePlan = (data: any) => {
    var deviceIdArray = [];
    for (var i = 0; i < device?.length; i++) {
      deviceIdArray.push({
        deviceTypeId: device[i]?.value,
        biometricType: device[i].text,
        quantity: device[i]?.quantity,
      });
    }
    let productData: any = productIdArray?.filter((item: any, index: any) => {
      return (
        item?.currency === (selectCountry === "IN" ? "INR" : "USD") &&
        item?.stripeProductId !== null
      );
    });
    if (!isUpgrade) {
      if (isUpdate) {
        const updateData = {
          name: data?.planName,
          description: data?.subTitle,
          fixedAmount: totalSum,
          recurringAmount: data?.recurringAmount,
          devices: deviceIdArray,
          beneficiaryCount: data?.beneficiaryCount,
          billingCountry: data?.billingCountry,
          remarks: data?.planDetails,
          shippingCharges: data?.shippingCharges,
          unitAmount: data?.unitAmount,
          partnerOfferings: getPartnerData(data),
          planTag: data?.planTag,
          currency: selectCountry === "IN" ? "INR" : "USD",
        };
        setPlanData({ ...updateData, id: planId });

        // updatePlan({ ...updateData, id: planId });
      } else {
        const updatedPlanData = {
          productId:
            productData && productData?.length > 0
              ? productData[0]?.productId
              : null,
          billingCountry: data?.billingCountry,
          name: data?.planName,
          // description: data?.description,
          beneficiaryCount: data?.beneficiaryCount,
          fixedAmount: totalSum,
          recurringAmount: data?.recurringAmount,
          currency: selectCountry === "IN" ? "INR" : "USD",
          devices: deviceIdArray,
          planType: PLAN_TYPES.inHouse,
          partnerOfferings: getPartnerData(data),
          planTag: data?.planTag,
          deviceCost: totalSum,
          shippingCharges: data?.shippingCharges,
          billingFrequency: data?.billingFrequency,
          billingCycles: 0,
          taxPercentage: "0",
          inclusiveTax: true,
          // remarks: data?.remarks,
          unitAmount: data?.unitAmount,
        };

        setPlanData({...planData, ...updatedPlanData});
        // setPreviewModal(true);
      }
    } else if (isUpgrade) {
      const planData = {
        parentPlanId: planId,
        productId:
          productData && productData?.length > 0
            ? productData[0]?.productId
            : null,
        billingCountry: data?.billingCountry,
        name: data?.planName,
        description: data?.subTitle,
        beneficiaryCount:
          selectedOption === "upgradeDevice" ? null : data?.beneficiaryCount,
        fixedAmount: data?.fixedAmount ? data?.fixedAmount : "0",
        recurringAmount: data?.recurringAmount ? data?.recurringAmount : null,
        currency: selectCountry === "IN" ? "INR" : "USD",
        devices: deviceIdArray,
        planType: "UPGRADE",
        deviceCost: data?.fixedAmount ? data?.fixedAmount : "0",
        shippingCharges: "0",
        billingFrequency: "MONTH",
        billingCycles: 0,
        taxPercentage: "0",
        inclusiveTax: true,
        remarks: data?.planDetails,
        // changeType: data?.upgradeType
      };
      setPlanData(planData);
      // setPreviewModal(true);
    }
  };

  const onProductBundleChange = (e: any, field: any) => {
    const { name, value } = e?.target;
    var check = value.indexOf("all");
    if (check !== -1 && allCheck === false) {
      let data: any = [];
      deviceName?.map((x: any, index: any) => {
        data.push({
          text: x?.text,
          description: x?.description,
          value: x?.value,
        });
      });
      setTableData(deviceName);
      setDevice(data);
      setAllCheck(true);
    } else if (check !== -1 && allCheck === true) {
      setDevice([]);
      setAllCheck(false);
      setTableData([]);
    } else {
      if (allCheck === true) {
        setTableData((filterTableData: any) =>
          filterTableData.filter(
            (device: any) => device?.value !== value[value?.length - 1]?.value
          )
        );

        // let data: any = [];
        let data = deviceName?.filter((x: any) => {
          return x?.value !== value[value?.length - 1]?.value;
        });
        setAllCheck(false);
        setDevice(data);
      } else {
        setDevice(value);
        setTableData(value);
      }
    }
  };

  useEffect(() => {
    if (createPlanInfoData?.isSuccess) {
      reset();
      setPreviewModal(false);
      setCreatePlan(false);
      refetch();
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: createPlanInfoData?.data?.message,
        })
      );
    }
  }, [createPlanInfoData?.isSuccess, createPlanInfoData?.fulfilledTimeStamp]);

  useEffect(() => {
    handleErrors(createPlanInfoData, dispatch);
  }, [createPlanInfoData?.isError]);

  const [baseBenCount, setBaseBenCount] = useState<any>(null);
  const [baseRecurringAmount, setBaseRecurringAmount] = useState<any>(null);
  useEffect(() => {
    if (planInfo?.isSuccess) {
      const { data } = planInfo;
      if (editPlan) {
        setIsUpdate(true);
        setDraft(planInfo?.data?.result?.draft);
        let qtyData: any = [];
        const devices = planInfo?.data?.result?.devices?.map((x: any) => {
          return deviceName?.filter((y: any) => {
            if (y?.value === x?.deviceTypeId) {
              return qtyData?.push({ ...y, quantity: x?.quantity });
            }
          });
        });

        let selectedDevice: any = devices?.map((x: any) => {
          return x[0];
        });
        // selectedDevice = planInfo?.data?.result?.devices?.map((x: any) => {
        //   return selectedDevice?.reduce((acc: any, item: any) => {
        //     if (item?.value === x?.deviceTypeId) {
        //       item.quantity = x?.quantity;
        //       return item;
        //     }
        //     return;
        //   }, {});
        // });

        // let partnerService = planInfo?.data?.result?.PartnerPlan?.length ? planInfo?.data?.result?.PartnerPlan[0] : ""

        let partnerService = planInfo?.data?.result?.partnerOffering
          ? planInfo?.data?.result?.partnerOffering
          : "";

        // let billingFrequencyMap = BILLING_FREQUENCY_MAP.find(
        //   (map) =>
        //     map?.frequency == data?.result?.billingFrequency?.toLowerCase() &&
        //     map?.interval == data?.result?.billingInterval
        // );

        // let billingFrequency = billingFrequencyMap?.value || "";
        // let billingInterval = billingFrequencyMap?.interval
        console.log(
          partnerService,
          partnerOptions?.find(
            (op) => op?.value == JSON.stringify(partnerService)
          ),
          "billing frequency"
        );
        setPartnerDetails(partnerService);
        setPartnerValue(JSON.stringify(partnerService));
        setSelectCountry(planInfo?.data?.result?.billingCountry);
        setValue("planName", planInfo?.data?.result?.name);
        setValue("subTitle", planInfo?.data?.result?.description);
        setValue("beneficiaryCount", planInfo?.data?.result?.beneficiaryCount);
        setValue("unitAmount", planInfo?.data?.result?.unitAmount);
        setValue("recurringAmount", planInfo?.data?.result?.recurringAmount);
        setValue("fixedAmount", totalSum);
        setValue("shippingCharges", planInfo?.data?.result?.shippingCharges);
        setValue("planDetails", planInfo?.data?.result?.remarks);
        setValue("billingCountry", planInfo?.data?.result?.billingCountry);
        setValue("devices", selectedDevice);
        setValue("partnerService", JSON.stringify(partnerService));
        setValue("planTag", planInfo?.data?.result?.planTag);
        setValue("planType", planInfo?.data?.result?.planType);
        setValue("billingFrequency", planInfo?.data?.result?.billingFrequency);
        setDevice(selectedDevice);
        setTableData(qtyData);
      } else if (isUpgradeEdit) {
        setIsUpdate(true);
        let qtyData: any = [];
        const devices = planInfo?.data?.result?.devices?.map((x: any) => {
          return deviceName?.filter((y: any) => {
            if (y?.value === x?.deviceTypeId) {
              return qtyData?.push({ ...y, quantity: x?.quantity });
            }
          });
        });
        setBaseBenCount(planInfo?.data?.result?.parentBeneficiaryCount);
        setBaseRecurringAmount(planInfo?.data?.result?.recurringAmount);

        const remainDevices = planInfo?.data?.result?.parentDevices?.map(
          (x: any) => {
            return deviceName?.filter((y: any) => {
              return y?.value !== x?.deviceTypeId;
            });
          }
        );
        const upgradeDeviceArray = remainDevices?.reduce((x: any, y: any) =>
          x.filter((z: any) => y.includes(z))
        );
        const selectedDeviceArray = planInfo?.data?.result?.devices?.map(
          (x: any) => {
            return upgradeDeviceArray?.filter((y: any) => {
              return y?.value === x?.deviceTypeId;
            });
          }
        );
        const selectedDevice = selectedDeviceArray?.map((x: any) => {
          return x[0];
        });
        setDeviceName(upgradeDeviceArray);
        setDevice(selectedDevice);
        setValue("planName", planInfo?.data?.result?.name);
        setValue("subTitle", planInfo?.data?.result?.description);
        setValue("beneficiaryCount", planInfo?.data?.result?.beneficiaryCount);
        setValue("recurringAmount", planInfo?.data?.result?.recurringAmount);
        setValue("fixedAmount", planInfo?.data?.result?.fixedAmount);
        setValue("planDetails", planInfo?.data?.result?.remarks);
        setValue("billingCountry", planInfo?.data?.result?.billingCountry);
        setValue("shippingCharges", planInfo?.data?.result?.shippingCharges);
        setValue("devices", selectedDevice);
        setDevice(selectedDevice);
        setTableData(qtyData);
        if (!planInfo?.data?.result?.beneficiaryCount) {
          setValue("upgradeType", "upgradeDevice");

          setSelectedOption("upgradeDevice");
        } else if (selectedDevice?.length === 0) {
          setValue("upgradeType", "upgradeBeneficiary");
          setSelectedOption("upgradeBeneficiary");
        }
      } else if (isUpgrade) {
        // setValue("beneficiaryCount", planInfo?.data?.result?.beneficiaryCount);
        setValue("beneficiaryCount", planInfo?.data?.result?.beneficiaryCount);
        setValue("recurringAmount", planInfo?.data?.result?.recurringAmount);
        setBaseBenCount(planInfo?.data?.result?.beneficiaryCount);
        setBaseRecurringAmount(planInfo?.data?.result?.recurringAmount);

        // let finalOptions = beneficiarySupportedOption?.filter((item: any) => {
        //   return item?.value >= planInfo?.data?.result?.beneficiaryCount;
        // });
        // setBeneficiarySupportedOption(finalOptions);
        const remainDevices = planInfo?.data?.result?.devices?.map((x: any) => {
          return deviceName?.filter((y: any) => {
            return y?.value !== x?.deviceTypeId;
          });
        });
        const upgradeDeviceArray = remainDevices?.reduce((x: any, y: any) =>
          x.filter((z: any) => y.includes(z))
        );
        setDeviceName(upgradeDeviceArray);
        // setValue("billingCountry", "United States of America");
      }
    }
  }, [planInfo?.isSuccess]);

  useEffect(() => {
    if (updatePlanInfo?.isSuccess) {
      refetch();
      setCreatePlan(false);
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Plan updated successfully",
        })
      );
    }
  }, [updatePlanInfo?.isSuccess]);

  useEffect(() => {
    handleErrors(updatePlanInfo, dispatch);
  }, [updatePlanInfo?.isError]);

  useEffect(() => {
    if (getProductInfo?.isSuccess) {
      setProductIdArray(getProductInfo?.data?.result);
    }
  }, [getProductInfo?.isSuccess]);

  useEffect(() => {
    handleErrors(getProductInfo, dispatch);
  }, [getProductInfo?.isError]);

  const handleResetData = () => {
    reset();
    setDevice([]);
    setDeviceName(spareDevice);
    setBeneficiarySupportedOption([
      { text: "0", value: 0 },
      { text: "1", value: 1 },
      { text: "2", value: 2 },
      { text: "4", value: 4 },
      { text: "6", value: 6 },
      { text: "8", value: 8 },
    ]);
    setCreatePlan(false);
  };

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef?.current?.focus();
      }
    }, 0);
  }, []);

  useEffect(() => {
    if (isUpdate && !isUpgradeEdit && !draft) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [isUpdate]);

  const tableRows =
    getDevicesData?.data?.result?.map((x: any, index: number) => ({
      id: x?.deviceTypeId,
      deviceName: x?.biometricType,
      modelNo: x?.model,
      qty: 1,
    })) || [];

  const columns: GridColDef[] = [
    {
      field: "text",
      headerName: "Device Type",
      minWidth: 120,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => {
        return <>{labels[params?.row?.text]}</>;
      },
    },
    {
      field: "description",
      headerName: "Model No.",
      minWidth: 100,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="sliceWidth">
            <CustomToolTip title={params?.row?.description} placement="top">
              <p className="sliceWidth"> {params?.row?.description} </p>
            </CustomToolTip>
          </div>
        );
      },
    },
    {
      field: "qty",
      headerName: "Quantity",
      minWidth: 100,
      headerAlign: "center",
      sortable: false,
      align: "center",
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        let data = params?.row?.deviceCostings?.find((item: any) => {
          return item?.country === selectCountry;
        });
        return (
          <div className="customizeInput">
            {
              data?.value > 0 && (
                <div
              className="minusSign"
              onClick={() =>
                handleQtyChange(
                  {
                    target: {
                      value: parseInt(params?.row?.quantity) - 1,
                    },
                  },
                  params?.row
                )
              }
            >
              <div className="fontIcon">
                <RemoveIcon />
              </div>
              {/* - */}
            </div>
              )
            }
            
            <Controller
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <input
                  defaultValue={1}
                  value={params?.row?.quantity}
                  className="inputBox"
                  onChange={(e: any) => handleQtyChange(e, params?.row)}
                  type="number"
                  name="quantity"
                  min={1}
                  max={500}
                  disabled={!(data?.value > 0)}
                  required
                />
              )}
              name="deviceTableData"
              rules={{
                pattern: {
                  value: RegExpressions?.five,
                  message: "not allowed",
                },
              }}
              control={control}
            />
            {
               data?.value > 0 && (<div
                className="addSign"
                onClick={() =>
                  handleQtyChange(
                    {
                      target: {
                        value: parseInt(params?.row?.quantity) + 1,
                      },
                    },
                    params?.row
                  )
                }
              >
                <div className="fontIcon">
                  <AddIcon />
                </div>
                {/* + */}
              </div>) 
            }

          </div>
        );
      },
    },

    {
      field: "deviceCost",
      renderHeader: (params: GridColumnHeaderParams) => (
        <b>
          Unit Price
          <br />
          <center>({selectCountry === "IN" ? "INR" : "USD"})</center>
        </b>
      ),
      minWidth: 100,
      headerAlign: "right",
      align: "right",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        let data = params?.row?.deviceCostings?.filter((item: any) => {
          return item?.country === selectCountry;
        });
        if (data && data?.length > 0) {
          return handlePriceFormatting(data[0]?.value, selectCountry);
        }
      },
    },
    {
      field: "tax",
      renderHeader: (params: GridColumnHeaderParams) => (
        <b>
          GST
          <br />
          <center>({selectCountry === "IN" ? "INR" : "USD"})</center>
        </b>
      ),
      minWidth: 100,
      headerAlign: "right",
      sortable: false,
      align: "right",
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        let data = params?.row?.deviceCostings?.filter((item: any) => {
          return item?.country === selectCountry;
        });
        if (data && data?.length > 0) {
          const quantity = params?.row?.quantity;
          const deviceCost = params?.row?.deviceCost;
          const totaltax =
            (quantity * data[0]?.value * params?.row?.igst) / 100;
          // setTotalSum(totalSum+totalCost);
          return handlePriceFormatting(totaltax, selectCountry);
        }
        return 0;
      },
    },
    {
      field: "finalCost",
      renderHeader: (params: GridColumnHeaderParams) => (
        <b>
          Total Price
          <br />
          <center>({selectCountry === "IN" ? "INR" : "USD"})</center>
        </b>
      ),
      minWidth: 120,
      headerAlign: "right",
      align: "right",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        if (selectCountry === "IN") {
          let data = params?.row?.deviceCostings?.filter((item: any) => {
            return item?.country === selectCountry;
          });
          if (data && data?.length > 0) {
            const quantity = params?.row?.quantity;
            const deviceCost = params?.row?.deviceCost;
            const totalCost =
              quantity * data[0]?.value +
              (quantity * data[0]?.value * params?.row?.igst) / 100;
            // setTotalSum(totalSum+totalCost);
            return handlePriceFormatting(totalCost, selectCountry);
          } else {
            return 0;
          }
        } else {
          let data = params?.row?.deviceCostings?.filter((item: any) => {
            return item?.country === selectCountry;
          });
          if (data && data?.length > 0) {
            const quantity = params?.row?.quantity;
            const deviceCost = params?.row?.deviceCost;
            const totalCost = quantity * data[0]?.value;
            // setTotalSum(totalSum+totalCost);
            return handlePriceFormatting(totalCost, selectCountry);
          } else {
            return 0;
          }
        }
      },
    },
  ];

  const [totalSum, setTotalSum] = useState<any>(0);

  useEffect(() => {
    const sum = tableData.reduce((acc: any, row: any) => {
      let data = row?.deviceCostings?.filter((item: any) => {
        return item?.country === selectCountry;
      });
      if (data && data?.length > 0) {
        if (selectCountry === "IN") {
          return (
            acc +
            data[0]?.value * row?.quantity +
            (data[0]?.value * row?.quantity * row?.igst) / 100
          );
        } else {
          return acc + data[0]?.value * row?.quantity;
        }
      } else {
        return 0;
      }
    }, 0);
    setTotalSum(sum);
  }, [tableData, selectCountry]);

  const handleQtyChange = (e: any, rowData: any) => {
    let dataArray = [];
    if (e?.target?.value >= 0) {
      for (let i = 0; i < device?.length; i++) {
        if (rowData?.value === tableData[i]?.value) {
          tableData[i].quantity = e?.target?.value;
          dataArray.push(tableData[i]);
        } else {
          dataArray.push(tableData[i]);
        }
      }
      setDevice(dataArray);
      setTableData(dataArray);
    }
  };

  const calHeight = () => {
    let targetComp,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetComp = window.document.querySelector<HTMLElement>(".createPlanTable");
    targetElement = targetComp?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 25);
    setTableHeight(tableHeight);
  };

  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
      // setScreenWidth(window.innerWidth);
    });
    resizeObserver.observe(document.body);
    // if (filtersData?.fromDate) {
    //   setDateDisabled(false);
    // }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleUpgradeTypeChange = (e: any) => {
    setSelectedOption(e.target.value);
    if (e?.target?.value === "upgradeDevice") {
      setDevice([]);
      setTableData([]);
      setValue("fixedAmount", "");
      setValue("recurringAmount", "");
      setValue("beneficiaryCount", "");
    } else if (e?.target?.value === "upgradeBeneficiary") {
      setValue("recurringAmount", baseRecurringAmount);
      setValue("beneficiaryCount", baseBenCount);
      setDevice([]);
      setTableData([]);
      setValue("fixedAmount", "");
    }
  };

  useEffect(() => {
    setValue("partnerService", partnerValue);
  }, [partnerValue]);

  const handleBillingCountryChange = (e: any) => {
    setSelectCountry(e?.target?.value);
    setValue("unitAmount", "");
    setValue("recurringAmount", "");
    setValue("beneficiaryCount", "");
  };

  const handleBeneficiaryCountChange = (e: any) => {
    const {value} = e.target
    // if (e?.target && e?.target?.value) {
    //   let totalValue: any =
    //     parseFloat(getValues("unitAmount")?.toString()) *
    //     parseInt(e?.target?.value?.toString());
    //   setValue("recurringAmount", totalValue);
    // }
    let filteredBenificiaryPartners:any[] = [];
    console.log("INSIDE USE EFFECT", filteredPartners)

    if (filteredPartners?.length && value) {
      console.log("INSIDE USE EFFECT", filteredPartners)
      filteredBenificiaryPartners = filteredPartners
        ?.filter(
          (partner: any) =>
            partner?.beneficiaryCount == value
        )
        .map((option: any) => {
          return {
            text: `${option?.name}, Subscription Fee: ${
              selectCountry == "US" ? "USD" : "INR"
            } ${handlePriceFormatting(
              option?.recurringAmount,
              selectCountry
            )}, Subscription Cycle: ${option?.billingFrequency?.toUpperCase()}`,
            value: JSON.stringify(option),
          };
        });
    }
    setPartnerOptions([
      { text: "None", value: null },
      ...filteredBenificiaryPartners,
    ]);

  };
  const handleUnitAmountChange = (e: any) => {
    // if (e?.target && e?.target?.value) {
    //   let totalValue: any =
    //     parseFloat(getValues("beneficiaryCount")?.toString()) *
    //     parseInt(e?.target?.value?.toString());
    //   setValue("recurringAmount", totalValue);
    // }
  };

  const onPartnerSelect = (partner: any) => {
    setPartnerDetails(JSON.parse(partner));
    setPartnerValue(partner);
  };

  return (
    <>
      <Loader
        show={
          planInfo?.isLoading ||
          getDevicesData?.isLoading ||
          createPlanInfoData?.isLoading ||
          updatePlanInfo?.isLoading ||
          getProductInfo?.isLoading
        }
      />
      <Modal
        key="basePlan"
        open={createPlan}
        handleClose={() => {
          handleResetData();
        }}
        primaryText={
          !isUpgrade
            ? !isUpgradeEdit
              ? isUpdate
                ? "Edit Plan"
                : "Create Plan"
              : "Edit Upgrade Plan"
            : "Create Upgrade Plan"
        }
        className="input-padding modal-title outer-padding newCreatePlanModal"
      >
        <form
          onSubmit={handleSubmit((data) => {
            handleCreatePlan(data);
            setShowplanDetails(true);
          })}
        >
          <Grid container>
            <Grid item xs={12}>
              <Grid container sx={{ mt: 2 }} columnSpacing={4}>
                <Grid
                  item
                  sx={{ alignSelf: "self-end" }}
                  xs={6}
                  className="selectInput-title"
                >
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <SelectInput
                        {...field}
                        label="Select Billing Country"
                        required={true}
                        value={field.value ? `${field.value}` : ""}
                        placeholder="Select country"
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleBillingCountryChange(e);
                        }}
                        inputRef={ref}
                        optionValue={countryOptions}
                        disabled={isUpdate}
                      />
                    )}
                    name="billingCountry"
                    control={control}
                    rules={{
                      required: "Please select billing country",
                    }}
                  />
                </Grid>
                <Grid item xs={6} sx={{ mt: 2 }} className="input-title">
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="Plan Name"
                        required={true}
                        placeholder="Enter plan name"
                        value={field.value}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                      />
                    )}
                    name="planName"
                    rules={{
                      required: "Please enter plan name ",
                    }}
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Grid container columnSpacing={4}>
                <Grid
                  item
                  xs={6}
                  sx={{ alignSelf: "self-end" }}
                  className="selectInput-title disabled-box"
                >
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <SelectInput
                        {...field}
                        label="Plan Tag"
                        value={field?.value}
                        placeholder="Select a tag for this plan"
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                        optionValue={[
                          { text: "Best Value", value: "Best Value" },
                          { text: "Most Popular", value: "Most Popular" },
                          { text: "None", value: null },
                        ]}
                      />
                    )}
                    name="planTag"
                    control={control}
                  />
                </Grid>

                {/* {isUpgrade || isUpgradeEdit ? (
                  <Grid item xs={12} sx={{ mt: 2 }} className="input-title">
                    <Controller
                      render={({
                        field: { ref, ...field },
                        fieldState: { invalid, error },
                      }) => (
                        <Input
                          {...field}
                          label="Base Plan"
                          required={true}
                          placeholder="Enter plan name"
                          value={`${field.value}`}
                          error={Boolean(error)}
                          disabled
                          helperText={error?.message ? error?.message : " "}
                          onChange={field.onChange}
                          inputRef={(e: any) => {
                            ref(e);
                            inputRef.current = e;
                          }}
                          autoFocus={true}
                        />
                      )}
                      name="basePlanName"
                      rules={{
                        required: "Please enter plan name ",
                      }}
                      control={control}
                    />
                  </Grid>
                ) : (
                  <></>
                )} */}

                {/* {isUpgrade || isUpgradeEdit ? (
                  <Grid item xs={12} sx={{ mt: 2 }} className="input-title">
                    <Controller
                      render={({
                        field: { ref, ...field },
                        fieldState: { invalid, error },
                      }) => (
                        <SelectInput
                          {...field}
                          label="Upgrade Type"
                          required={true}
                          placeholder="Select"
                          disabled={isUpgradeEdit}
                          optionValue={[
                            {
                              text: "Upgrade Beneficiary",
                              value: "upgradeBeneficiary",
                            },
                            { text: "Upgrade Device", value: "upgradeDevice" },
                          ]}
                          value={field.value}
                          error={Boolean(error)}
                          helperText={error?.message ? error?.message : " "}
                          inputRef={(e: any) => {
                            ref(e);
                            inputRef.current = e;
                          }}
                          autoFocus={true}
                          onChange={(e: any) => {
                            field.onChange(e);
                            handleUpgradeTypeChange(e);
                          }}
                        />
                      )}
                      name="upgradeType"
                      control={control}
                      rules={{
                        required:
                          "Please select upgrade beneficiary or upgrade device",
                      }}
                    />
                  </Grid>
                ) : (
                  <></>
                )} */}

                <Grid item xs={6} sx={{ mt: 2 }} className="input-title">
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <SelectInput
                        {...field}
                        label="Billing Frequency"
                        value={field?.value}
                        placeholder="Select billing frequency"
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={(e: any) => {
                          field.onChange(e);
                          onFrequencyChange(e?.target?.value);
                        }}
                        inputRef={ref}
                        optionValue={[
                          { text: "1 Month", value: BILLING_FREQUENCY.MONTHLY },
                          {
                            text: "3 Months",
                            value: BILLING_FREQUENCY.QUARTERLY,
                          },
                          {
                            text: "6 Months",
                            value: BILLING_FREQUENCY.HALFYEAR,
                          },
                          {
                            text: "12 Months",
                            value: BILLING_FREQUENCY.YEARLY,
                          },
                        ]}
                        disabled={isUpdate}
                      />
                    )}
                    name="billingFrequency"
                    control={control}
                    rules={{
                      required: "Please select billing frequency.",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/*  TO DO: Move this input for plan sub details to step 2
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Plan Sub Details"
                    required={false}
                    value={field.value}
                    error={Boolean(error)}
                    placeholder="Enter plan sub details"
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                  />
                )}
                name="subTitle"
                control={control}
              />
            </Grid> */}

            <Grid item xs={12}>
              <Grid container sx={{ mt: 2 }} columnSpacing={4}>
                <Grid
                  item
                  sx={{ alignSelf: "self-end" }}
                  xs={6}
                  className="selectInput-title"
                >
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="Unit Price per beneficiary"
                        placeholder={selectCountry === "IN" ? "INR" : "USD"}
                        value={field?.value}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleUnitAmountChange(e);
                        }}
                        // onChange={handleUnitAmountChange}
                        inputRef={ref}
                        required={true}
                        disabled={disabled}
                      />
                    )}
                    name="unitAmount"
                    control={control}
                    rules={{
                      required: "Please enter per beneficiary cost",
                      pattern: {
                        value: RegExpressions?.Zero,
                        message: "Please enter valid per beneficiary cost.",
                      },
                    }}
                  />
                </Grid>

                {selectedOption !== "upgradeDevice" ? (
                  <Grid item xs={6} className="selectInput-title">
                    <Controller
                      render={({
                        field: { ref, ...field },
                        fieldState: { invalid, error },
                      }) => (
                        <Input
                          {...field}
                          label="Beneficiaries Supported"
                          // disabled={isUpgrade || isUpgradeEdit ? true : false}
                          required={true}
                          disabled={disabled}
                          placeholder="Enter Beneficiaries Supported"
                          value={field?.value}
                          error={Boolean(error)}
                          helperText={error?.message ? error?.message : " "}
                          onChange={(e: any) => {
                            field.onChange(e);
                            handleBeneficiaryCountChange(e);
                          }}
                          inputRef={ref}
                        />
                      )}
                      name="beneficiaryCount"
                      control={control}
                      rules={{
                        required: "Please Enter beneficiaries supported",
                        validate: () => {
                          if (baseBenCount > getValues("beneficiaryCount")) {
                            return "Please enter valid beneficiary count";
                          }
                        },
                        pattern: {
                          value: RegExpressions?.ZeroWithNoDecimal,
                          message: "Please enter valid beneficiary count",
                        },
                      }}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>

            {selectedOption !== "upgradeDevice" ? (
              <Grid container columnSpacing={4}>
                <>
                  <Grid item xs={6} sx={{ mt: 2 }}>
                    <Controller
                      render={({
                        field: { ref, ...field },
                        fieldState: { invalid, error },
                      }) => (
                        <Input
                          {...field}
                          label={subscriptionLabel}
                          required={true}
                          placeholder={selectCountry === "IN" ? "INR" : "USD"}
                          value={field?.value}
                          error={Boolean(error)}
                          disabled={disabled}
                          helperText={error?.message ? error?.message : " "}
                          onChange={field.onChange}
                          inputRef={ref}
                        />
                      )}
                      name="recurringAmount"
                      control={control}
                      rules={{
                        required: "Please enter Subscription price (USD)",
                        validate: () => {
                          if (
                            baseRecurringAmount > getValues("recurringAmount")
                          ) {
                            return "Please enter the price greater than its base plan";
                          }
                        },
                        pattern: {
                          value: RegExpressions?.Zero,
                          message:
                            "Please enter valid subscription price upto two decimals",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sx={{ mt: 2 }}>
                    <Controller
                      render={({
                        field: { ref, ...field },
                        fieldState: { invalid, error },
                      }) => (
                        <Input
                          {...field}
                          label="Shipping Charges"
                          required={true}
                          // labelClassName="planDetails-title"
                          placeholder="Enter Shipping Charges"
                          value={field.value}
                          error={Boolean(error)}
                          helperText={error?.message ? error?.message : " "}
                          onChange={field.onChange}
                          inputRef={ref}
                        />
                      )}
                      name="shippingCharges"
                      control={control}
                      rules={{
                        required: "Please enter shipping charges",
                        pattern: {
                          value: RegExpressions?.Price,
                          message: "Please enter valid shipping charges.",
                        },
                      }}
                    />
                  </Grid>
                </>
              </Grid>
            ) : (
              <></>
            )}

            {selectedOption !== "upgradeBeneficiary" ? (
              <Grid item xs={12}>
                <Grid container columnSpacing={4}>
                  <Grid
                    item
                    xs={12}
                    sx={{ mt: 4 }}
                    className="selectInput-title"
                  >
                    <Controller
                      render={({
                        field: { ref, ...field },
                        fieldState: { invalid, error },
                      }) => (
                        <CheckboxSelectInput
                          {...field}
                          label="Product Bundle"
                          // disabled={isUpgrade || isUpgradeEdit ? true : false}
                          // required={isUpgrade || isUpgradeEdit ? false : true}
                          required={true}
                          labelArray={[labels]}
                          // required={
                          //   !isUpgrade ? (!isUpgradeEdit ? true : false) : false
                          // }
                          // disabled={deviceName?.length && disabled==false? false : true}
                          placeholder={
                            deviceName?.length
                              ? "Select devices to bundle"
                              : " No devices available"
                          }
                          value={device}
                          error={Boolean(error)}
                          helperText={error?.message ? error?.message : " "}
                          onChange={(e: any) => {
                            field.onChange(e);
                            onProductBundleChange(e, field);
                          }}
                          inputRef={ref}
                          multiple={true}
                        >
                          {deviceName && deviceName?.length > 0 ? (
                            <MenuItem key="all" value="all">
                              <div>
                                <ListItemText primary="All" />
                              </div>
                              <Checkbox
                                className="checkboxBox"
                                checked={allCheck}
                              />
                            </MenuItem>
                          ) : null}
                          {deviceName?.map((option: any) => {
                            return (
                              <MenuItem key={option?.value} value={option}>
                                <div>
                                  <ListItemText
                                    primary={labels[option?.text]}
                                  />
                                  <CustomToolTip
                                    title={option?.description}
                                    placement="top"
                                  >
                                    <ListItemText
                                      className="sliceWidth"
                                      secondary={option?.description}
                                    />
                                  </CustomToolTip>
                                </div>
                                <Checkbox
                                  className="checkboxBox"
                                  checked={
                                    device.findIndex(
                                      (item: any) =>
                                        item?.value === option?.value
                                    ) >= 0
                                  }
                                />
                              </MenuItem>
                            );
                          })}
                        </CheckboxSelectInput>
                      )}
                      name="devices"
                      control={control}
                      rules={{
                        required: "Please select product bundle",
                      }}
                    />
                  </Grid>

                  {/* <Grid item xs={6} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                      <Controller
                        render={({
                          field: { ref, ...field },
                          fieldState: { invalid, error },
                        }) => (
                          <Input
                            {...field}
                            label="Cost Of Per Beneficiary"
                            // disabled={isUpgrade || isUpgradeEdit ? true : false}
                            required={
                              (isUpgrade || isUpgradeEdit) && device.length <= 0
                                ? false
                                : true
                            }
                            // required={true}
                            placeholder={
                              selectCountry === "India" ? "INR" : "USD"
                            }
                            value={field.value}
                            error={Boolean(error)}
                            helperText={error?.message ? error?.message : " "}
                            onChange={field.onChange}
                            inputRef={ref}
                          />
                        )}
                        name="fixedAmount"
                        control={control}
                        rules={{
                          required: {
                            value:
                              (isUpgrade || isUpgradeEdit) && device.length <= 0
                                ? false
                                : true,
                            message: "Please enter product bundle price",
                          },
                          pattern: {
                            value: RegExpressions?.Zero,
                            message:
                              "Please enter valid bundle price upto two decimals",
                          },
                        }}
                      />
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {selectedOption !== "upgradeBeneficiary" ? (
              <Grid item xs={12} sx={{ mt: 1 }}>
                <div className="createPlanTable">
                  <div style={{ position: "relative", width: "100%" }}>
                    <DataGrid
                      // paginationMode="server"
                      // autoPageSize
                      columns={columns}
                      hideFooterPagination
                      disableRowSelectionOnClick
                      disableColumnFilter
                      disableColumnSelector
                      disableColumnMenu
                      columnVisibilityModel={{
                        tax: selectCountry === "IN" ? true : false,
                      }}
                      sx={{
                        "& .MuiDataGrid-row:hover": {
                          backgroundColor: "white",
                        },
                      }}
                      autoHeight
                      rows={tableData}
                      getRowId={(id) => id.value}
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: 15,
                        right: 14,
                        fontWeight: "bold",
                      }}
                      className="total"
                    >
                      One-Time &nbsp;: &nbsp;
                      {selectCountry === "IN" ? "INR" : "USD"}{" "}
                      {handlePriceFormatting(totalSum, selectCountry)}
                    </div>
                  </div>
                </div>
              </Grid>
            ) : (
              <></>
            )}

            <Grid item xs={12} sx={{ mt: 6 }}>
              <Grid container columnSpacing={4}>
                <Grid
                  item
                  xs={12}
                  sx={{ alignSelf: "self-end" }}
                  className="selectInput-title disabled-box"
                  key={partnerValue}
                >
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <SelectInput
                        {...field}
                        label="Select Partner Service"
                        value={partnerValue}
                        defaultValue={partnerValue}
                        placeholder="Select a partner service to associate"
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={(e: any) => {
                          field.onChange(e);
                          onPartnerSelect(e?.target?.value);
                        }}
                        inputRef={ref}
                        optionValue={partnerOptions}
                        disabled={isUpdate}
                      />
                    )}
                    name="partnerService"
                    control={control}
                  />
                  {!isUpdate && (
                    <span className="input-title extraDetailsFont">
                      <u
                        style={{ cursor: "pointer" }}
                        onClick={() => setShowPartnerModal(true)}
                      >
                        {!partnerDetails || partnerDetails?.subscriptionPlanId
                          ? "Create New Partner Service"
                          : "Edit Partner Service"}
                      </u>
                    </span>
                  )}
                </Grid>

                {/* <Grid
                  item
                  xs={6}
                  sx={{ mt: 2, textAlign:"right" }}
                  className="input-title extraDetailsFont"

                >
                 
                </Grid> */}
              </Grid>
            </Grid>
            {/*  Move this input to step 2
            <Grid item xs={12} sx={{ mt: 5 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Plan Details"
                    required={true}
                    labelClassName="planDetails-title"
                    placeholder="Add plan detail info"
                    value={field.value}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                  />
                )}
                name="planDetails"
                control={control}
                rules={{
                  required: "Please enter plan details",
                }}
              />
            </Grid> */}
            <Grid item xs={12} sx={{ mt: 3 }}>
              <Grid container className="modalButton">
                <Grid item xs={4.5} sx={{ mr: 4 }}>
                  <Buttons
                    type="secondary"
                    variant="contained"
                    text="CANCEL"
                    onClick={handleCancel}
                  ></Buttons>
                </Grid>

                <Grid item xs={4.5}>
                  {isUpdate ? (
                    <Buttons
                      type="primary"
                      variant="contained"
                      buttonType="submit"
                      text="Next"
                    ></Buttons>
                  ) : (
                    <Buttons
                      type="primary"
                      variant="contained"
                      buttonType="submit"
                      text="Continue"
                    ></Buttons>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
        {/* <RTE/> */}
      </Modal>
      {showPartnerModal && (
        <CreatePartnerModal
          open={showPartnerModal}
          billingCountry={selectCountry}
          setOpen={setShowPartnerModal}
          partnerDetails={partnerDetails}
          setPartnerDetails={setPartnerDetails}
          setPartnerOptions={setPartnerOptions}
          setPartnerValue={setPartnerValue}
          partnerOptions={partnerOptions}
          billingFrequency={getValues("billingFrequency")}
        />
      )}
      {showPlanDetails && (
        <PlanDetailsModal
          open={showPlanDetails}
          setOpen={setShowplanDetails}
          planDetails={planData}
          setPlanDetails={setPlanData}
          isUpdate={isUpdate}
          updatePlan={updatePlan}
          setReviewModal={setPreviewModal}
        />
      )}
      {previewModal && (
        <PreviewPlan
          selectedRow={planData}
          isModalOpen={previewModal}
          setModalOpen={setPreviewModal}
          baseRecurringAmount={baseRecurringAmount}
          createPlan={createPlanData}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          baseBenCount={baseBenCount}
          isUpgrade={isUpgrade}
          isUpgradeEdit={isUpgradeEdit}
          partnerDetails={partnerDetails}
          isUpdate={isUpdate}
          updatePlan={updatePlan}
        />
      )}
    </>
  );
};
