import { Button, Dialog, DialogActions, DialogTitle, Divider, IconButton, styled, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import "./style.css";
import { crossIcon } from '../../../assets/icons';
import { Confirmmodalprops } from '../../../models/common';


export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}


export const ConfirmModal = (props: Confirmmodalprops) => {
    const { className,primaryText,secondaryText,cancelButton,confirmButton,cancelCallback,confirmCallback,open,handleClose} = props

    return (
     
            <div className='ui-Confirm-Modal' data-testid="confirm-modal">
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                    className={className}
                >
                    {/* <img src={crossIcon}/> */}
                    <div className={`normalText ${secondaryText ? "normalTextWithPad":""}`}>
                        {primaryText} 
                        <br></br>
                        <br></br>
                         {secondaryText} 
                    </div>

                    <Divider />

                    <DialogActions className='buttonsDisplay'>
                        <Button onClick={cancelCallback} className='confirmbtn'>{cancelButton}</Button>
                        <Divider orientation="vertical" flexItem />
                        <Button  onClick={confirmCallback} className='confirmbtn'>{confirmButton}</Button>
                    </DialogActions>
                </BootstrapDialog>
            </div>
    );
}