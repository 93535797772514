import React from "react";
import UILayout from "../../layout";
import { Card, CardContent } from '@mui/material';
import "./style.css";
import { SelectInput } from "../../common/selectinput";


export const HELPCENTRE = () => {
    return (
        <>
             <div className="workinprogress">WORK IN PROGRESS</div>
        </>

    )
}