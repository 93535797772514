import { createSlice } from "@reduxjs/toolkit";

const initialState = { supportedCountries: [],countriesCodes:[] ,countriesNames:[] };

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    setCountry:(state, {payload}) => {
      state.supportedCountries = payload?.supportedCountries;
    },
    setSuppCountries:(state, {payload}) => {
      state.countriesCodes = payload?.countriesCodes;
    },
    setSuppCountriesNames:(state, {payload}) => {
      state.countriesNames = payload?.countriesNames;
    },
    clearCountryState: (state) => (state = initialState),
  },
});

export const {  setCountry, clearCountryState,setSuppCountries,setSuppCountriesNames } = countrySlice.actions;
export default countrySlice.reducer;
