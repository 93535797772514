import { useState } from "react";
import "./manageUserHeader.css";
import { Grid } from "@mui/material";
import Buttons from "../../../common/button";
import { useSelector } from "react-redux";
import { AddBeneficiaryModal } from "../modal/addBeneficiaryModal";
import { AddFamilyModal } from "../modal/addFamilyModal";
import { BootstrapDialog } from "../../../common/confirmmodal";
import { Button, DialogActions, Divider } from "@mui/material";
import { Messages } from "../../../../constants/messages";
import { infoIcon } from "../../../../assets/icons";
import { UserTypeModal } from "./userTypeModal";

export const SubscriberHeader = (props: any) => {
  const {
    screenWidth,
    refetchDataOnAddFamily,
    beneficiaryCount,
    AllUsersofSubscriberData,
    getSubscriptionPlanData,
    rowCount,
  } = props;

  const { roleData } = useSelector((state: any) => state?.role);
  const { userInfo } = useSelector((state: any) => state?.auth);

  // API INTEGRATION

  const [addBeneficiaryModal, setAddBeneficiaryModal] = useState(false);
  const [userModal, setUserModal] = useState<any>(false)
  const [addFamilyModal, setAddFamilyModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  if (roleData && Array.isArray(roleData)) {
    const roleInfo = roleData.filter((x: any) => {
      // filter condition
      return x?.roleName === "Beneficiary";
    });
  }

  const addFamily = () => {
    setAddFamilyModal(true);
  };

  const addBenificiary = () => {
    const totalBeneficiary =
      AllUsersofSubscriberData?.data?.result?.beneficiaryTypeCount
        ?.activeBeneficiaries +
      AllUsersofSubscriberData?.data?.result?.beneficiaryTypeCount
        ?.invitedBeneficiaries;
    if (
      getSubscriptionPlanData?.data?.result[0]?.allowedBeneficiary < totalBeneficiary ||
      getSubscriptionPlanData?.data?.result[0]?.allowedBeneficiary === totalBeneficiary
    ) {
      setConfirmModal(true);
    } else {
      setAddBeneficiaryModal(true);
    }
  };
  const handleClose = () => {
    setConfirmModal(false);
  };

  const handleUserTypeModal=()=>{
    setUserModal(true)
  }

  return (
    <>
      <div
        className={
          screenWidth > 900 ? "m-30 subscriberHeaderContainer" : "m-30"
        }
      >
        <Grid
          container
          columnSpacing={2}
          rowSpacing={1}
          justifyContent={"right"}
        >
          <Grid item xs={12} sm={12} md={4} lg={6.5} xl={7.8}>
            <span className="manageOrderHeading" data-testid="manage-title">
              Manage Users
            <img className="taxImg cursor" src={infoIcon} onClick={handleUserTypeModal}/>
            </span>
            <span className="countFonts">({rowCount})</span>
          </Grid>
          <Grid item xs={12} sm={6} md={3.8} lg={2.6} xl={2}>
            <Buttons
              buttonType="button"
              variant="contained"
              text="Add New Beneficiary"
              type="primary"
              startIcon="add"
              onClick={addBenificiary}
              primaryBtnTextClass={
                screenWidth < 710 ? "subscriberHeaderBtnText" : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4.2} lg={2.9} xl={2.2}>
            <Buttons
              buttonType="button"
              variant="contained"
              text="Add New Family Member"
              type="primary"
              startIcon="add"
              onClick={addFamily}
              primaryBtnTextClass={
                screenWidth < 710 ? "subscriberHeaderBtnText" : undefined
              }
            />
          </Grid>
        </Grid>
      </div>

      {addBeneficiaryModal && (
        <AddBeneficiaryModal
          addBeneficiaryModal={addBeneficiaryModal}
          setAddBeneficiaryModal={setAddBeneficiaryModal}
          refetchData={refetchDataOnAddFamily}
          editable={false}
          getSubscriptionPlanData={getSubscriptionPlanData}
        />
      )}
      <AddFamilyModal
        addFamilyModal={addFamilyModal}
        setAddFamilyModal={setAddFamilyModal}
        editable={false}
        refetchData={refetchDataOnAddFamily}
      />

      <UserTypeModal 
      setUserModal={setUserModal}
      userModal={userModal}
      />


      <div className="modelModal">
        <div className="ui-Confirm-Modal">
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={confirmModal}
            className="confirmModal"
          >
            {/* <img src={crossIcon}/> */}
            <div className="normalText redText">{Messages?.BENEFICIARY}</div>

            <Divider />

            <DialogActions className="buttonsDisplay">
              <Button className="confirmbtn" onClick={handleClose}>
                EXIT
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </div>
      </div>
    </>
  );
};
