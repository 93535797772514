import { Box, FormHelperText, Grid } from "@mui/material";
import { SelectInput } from "../../common/selectinput";
import Buttons from "../../common/button";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import "./reportStyle.css"
import { Table } from "../../common/table";
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid";

export const RevenueDetails  = () => {
  const [tableHeight, setTableHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const [filterData, setFilterData] = useState<any>({
    search: "",
    from: "",
    to: "",
    orderStatus: "",
  });


  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
  };


  const columns: GridColDef[] = [
    {
      field: "subscriber",
      headerName: "Plan Name(subscriber)",
      minWidth: 200,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
    },
    {
      field: "beneficiary",
      headerName: "Plan Name(beneficiary)",
      minWidth: 200,
      headerAlign: "left",
      sortable: false ,
      cellClassName: "cell-padding",
      flex:1
    },
    {
      field: "billingcountry",
      headerName: "Billing Country",
      minWidth:200,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex:1
    },
    {
      field: "billingState",
      headerName: "Billing State",
      minWidth:200,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex:1
    },
    {
      field: "lastMonthRevenue",
      headerName: "Last Month Revenue",
      minWidth:180,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex:1
    },
    {
      field: "currentMonthRevenue",
      headerName: "Current Month Revenue",
      minWidth:200,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex:1
    },
    {
      field: "rvenueOfTheYear",
      headerName: "Revenue Of The Year",
      minWidth:200,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex:1
    },
  ]

  const calHeight = () => {
    let targetComp,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetComp = window.document.querySelector<HTMLElement>(".revenueDetails");
    targetElement = targetComp?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 25);
    setTableHeight(tableHeight);
  };

  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
      setScreenWidth(window.innerWidth);
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);



  return (
    <Box>
    <Grid
      container
      rowSpacing={1.2}
      columnSpacing={3.8}
      marginBottom="3vh"
      className="manageOrderHeader"
      // columnSpacing={screenWidth > 1199 && screenWidth < 1536 ? 2 : 3.75}
    >
      <Grid item className="Manage-order-Container">
        <span className="manageOrderHeading">Revenue Details</span>
      </Grid>

      <Grid item xl={3.57} lg={3.5} md={6} sm={12} xs={12} >
        <Grid container  spacing={3.8}>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
            <SelectInput
              className="filterStyle"
              placeholder="Filter"
              name="roleId"
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
            <Buttons
              buttonType="submit"
              variant="contained"
              text="EXPORT"
              type="primary"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    
    <Grid container>
      <Grid item xs={12}>
        <div className="revenueDetails">
        <Table 
        //  rowCount={rowCount}
         rows={[]}
         columns={columns}
         isPagination={true}
         onPaginationModelChange={handlePaginationModelChange}
         pagination={paginationModel}
         tableHeight={tableHeight}
        />
        </div>
      </Grid>
    </Grid>
    </Box>
  );
};
