import { apiSlice } from "./apiSlice";
import { apiRoutes } from "../../config/api";

export const accountSettingsService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccountSettings: builder.query({
      query: (id) => ({
        url: `${apiRoutes.USER}/${id}`,
        method: "GET",
      }),
    }),
    getBillingHistory: builder.query({
      query: ({id,...data}) => {
        if (id !== undefined) {
          return {
            url: `${apiRoutes.BILLINGHISTORY}/${id}`,
            method: "GET",
            params:data
          };
        }
      },
    }),
    downloadInvoice: builder.mutation({
      query: (data) => ({
        url: apiRoutes.DOWNLOADINVOICE,
        method: "POST",
        body: { ...data },
      }),
    }),
  }),
});

export const {
  useGetAccountSettingsQuery,
  useGetBillingHistoryQuery,
  useDownloadInvoiceMutation,
} = accountSettingsService;
