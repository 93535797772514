import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  handleErrors,
  handleInputFocus,
  phoneNumberAutoFormat,
} from "../../../../utils";
import { Modal } from "../../../common/modal/modal";
import { Grid } from "@mui/material";
import { CountryCodeSelectInput, SelectInput } from "../../../common/selectinput";
import { Input } from "../../../common/input";
import { RegExpressions } from "../../../../constants/regExp";
import Buttons from "../../../common/button";
import { cCodes } from "../../../../constants/constant";
import {
  useSendOtpMutation,
  useUpdateEmailMobileMutation,
} from "../../../../redux/services/userApiSlice";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import Loader from "../../../common/loader/loader";

export const MobileNumberModal = (props: any) => {
  const {
    changeMobile,
    setChangeMobile,
    openMobileOtp,
    setOpenMobileOtp,
    refetch,
    selectedMobileNumber,
    selectedCountryCode,
    selectedCountry,
  } = props;
  const { handleSubmit, setValue, control, reset, trigger } = useForm({
    mode: "onChange" || "onSubmit",
  });
  const [mobileNumber, setMobileNumber] = useState({
    mobileNumber: "",
    countryCode: "",
  });
  const userId = useSelector((state: any) => state?.auth?.userId);
  const dispatch = useDispatch();

  const [key, setKey] = useState("");
  const [tokenId, setTokenId] = useState();

  const [updateEmail, updateEmailInfo] = useUpdateEmailMobileMutation();
  const [sendOtp, sendOtpInfo] = useSendOtpMutation();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const onMobileCancelCallBack = () => {
    setChangeMobile(false);
    reset({
      MobileNumber: "",
    });
  };
  const onMobileOtpCancel = () => {
    setOpenMobileOtp(false);
    reset({
      otp: "",
    });
  };

  const handleMobileNumber = (e: any) => {
    var value = handleInputFocus(e, key);
    setValue("MobileNumber", value);
    trigger("MobileNumber");
  };
  const onConfirmMobileCallBack = (data: any) => {
    updateEmail({
      userId: userId,
      mobileNumber: data?.MobileNumber?.replaceAll("-", ""),
      countrycode: data?.countryCode?.split(" ")[0]
        ? data?.countryCode?.split(" ")[0]
        : "",
      email: "",
    });
    setMobileNumber({
      mobileNumber: data?.MobileNumber,
      countryCode: data?.countryCode,
    });
  };
  const onResendOtpClick = () => {
    updateEmail({
      userId: userId,
      mobileNumber: mobileNumber?.mobileNumber?.replaceAll("-", ""),
      countrycode: mobileNumber?.countryCode?.split(" ")[0]
        ? mobileNumber?.countryCode?.split(" ")[0]
        : "",
      email: "",
    });
  };
  useEffect(() => {
    if (updateEmailInfo?.isSuccess) {
      if (changeMobile) {
        setOpenMobileOtp(true);
        dispatch(
          setAlert({
            alertType: "success",
            open: true,
            message: updateEmailInfo?.data?.message,
          })
        );
        setTokenId(updateEmailInfo?.data?.result?.userTokenId);
      }
    }
  }, [updateEmailInfo?.isSuccess]);

  useEffect(() => {
    if (updateEmailInfo?.isError) {
      handleErrors(updateEmailInfo, dispatch);
    }
  }, [updateEmailInfo?.isError]);
  useEffect(() => {
    if (sendOtpInfo?.isSuccess) {
      if (openMobileOtp) {
        dispatch(
          setAlert({
            open: true,
            alertType: "success",
            message: sendOtpInfo?.data?.result,
          })
        );
        setOpenMobileOtp(false);
        setChangeMobile(false);
        refetch();
        reset({
          otp: "",
          MobileNumber: "",
        });
      }
    }
  }, [sendOtpInfo?.isSuccess]);
  useEffect(() => {
    if (sendOtpInfo?.isError) {
      if (openMobileOtp) {
        handleErrors(sendOtpInfo, dispatch);
      }
    }
  }, [sendOtpInfo?.isError]);
  const onConfirmMobileOtpCallBack = (data: any) => {
    sendOtp({
      userId: userId,
      userTokenId: tokenId,
      otp: data?.otp,
      mobileNumber: data?.MobileNumber?.replaceAll("-", ""),
      countrycode: data?.countryCode?.split(" ")[0]
        ? data?.countryCode?.split(" ")[0]
        : "",
    });
  };
  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef?.current?.focus();
      }
    }, 0);
  }, [openMobileOtp]);

  useEffect(() => {
    if (changeMobile) {
      setValue("MobileNumber", selectedMobileNumber);
      // if (selectedCountryCode === "+91") {
      setValue("countryCode", `${selectedCountryCode} ${selectedCountry}`);
      // } else {
      // setValue("countryCode", `${selectedCountryCode} US`);
      // }
    }
  }, [changeMobile]);

  return (
    <>
      <Loader show={updateEmailInfo?.isLoading || sendOtpInfo?.isLoading} />
      <Modal
        className="accountSetingModal"
        key="changeMobileNumber"
        open={changeMobile}
        primaryText="Enter New Mobile Number"
        handleClose={() => {
          setChangeMobile(false);
          reset({
            MobileNumber: "",
          });
        }}
      >
        <form onSubmit={handleSubmit(onConfirmMobileCallBack)}>
          <Grid container spacing={1} sx={{ marginTop: "40px" }}>
            <Grid item xs={3} sx={{ alignSelf: "self-end" }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <CountryCodeSelectInput
                    {...field}
                    value={`${field.value}` ? `${field.value}` : ""}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    required={true}
                    onChange={field.onChange}
                    inputRef={ref}
                    label="Country Code"
                    optionValue={cCodes}
                    placeholder="Select"
                    isCountryCode={true}
                    disabled
                  />
                )}
                name="countryCode"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={9}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Mobile Number"
                    placeholder="Enter Mobile Number"
                    value={field.value}
                    required={true}
                    onKeyDown={(e: any) => setKey(e.key)}
                    onChange={field.onChange}
                    helperText={error?.message ? error?.message : " "}
                    error={Boolean(error)}
                    inputRef={(e: any) => {
                      ref(e);
                      inputRef.current = e;
                    }}
                    autoFocus={true}
                  />
                )}
                name="MobileNumber"
                control={control}
                rules={{
                  required: "Please enter your mobile number",
                  pattern: {
                    value: RegExpressions?.InternationalMobileNumber,
                    message: "Please enter your mobile number",
                  },
                }}
              />
            </Grid>
          </Grid>
          <span className="otpMessage">An OTP will be send to the entered mobile number</span>
          <Grid container spacing={2} className="modalBtn">
            <Grid item xs={6} md={5} lg={4.6}>
              <Buttons
                type="secondary"
                variant="contained"
                onClick={onMobileCancelCallBack}
                text="CANCEL"
              ></Buttons>
            </Grid>
            <Grid item xs={6} md={5} lg={4.6}>
              <Buttons
                type="primary"
                variant="contained"
                buttonType="submit"
                text="SEND OTP"
              ></Buttons>
            </Grid>
          </Grid>
        </form>
      </Modal>
      <Modal
        className="accountSetingModal"
        key="sendOtp"
        open={openMobileOtp}
        primaryText="Enter Mobile Number OTP"
        handleClose={() => {
          setOpenMobileOtp(false);
          reset({
            otp: "",
          });
        }}
      >
        <form onSubmit={handleSubmit(onConfirmMobileOtpCallBack)}>
          <Grid container sx={{ marginTop: "40px" }}>
            <Grid item xs={12}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Mobile OTP"
                    value={field.value}
                    required={true}
                    onChange={field.onChange}
                    helperText={error?.message ? error?.message : " "}
                    error={Boolean(error)}
                    inputRef={ref}
                    placeholder="Enter OTP"
                  />
                )}
                name="otp"
                control={control}
                rules={{
                  required: "Please enter OTP",
                  pattern: {
                    value: RegExpressions?.OtpCode,
                    message: "Please enter valid OTP",
                  },
                }}
              />
              <span className="resendOtp">
                {" "}
                <a href="#" onClick={onResendOtpClick}>
                  {" "}
                  Resend OTP{" "}
                </a>{" "}
              </span>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="modalBtn">
            <Grid item xs={6} md={5} lg={4.6}>
              <Buttons
                type="secondary"
                variant="contained"
                onClick={onMobileOtpCancel}
                text="CANCEL"
              ></Buttons>
            </Grid>
            <Grid item xs={6} md={5} lg={4.6}>
              <Buttons
                type="primary"
                variant="contained"
                buttonType="submit"
                text="SUBMIT"
              ></Buttons>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
};
