import { createSlice } from "@reduxjs/toolkit";

interface changeSubscriptionState {
  subsReviewModal: boolean;
  subsAddressModal: boolean;
  selectedPlan: Object;
  currentPlanData: Object;
  selectedRow: Object;
  orderData: Object;
  address: any;
  modalHeading: any;
  updateSubscription: any;
  subscriptionDeviceReview: boolean;
}

const initialState = { modalHeading: "", updateSubscription: "", currentPlanData: {}, subscriptionDeviceReview: false, subsReviewModal: false, subsAddressModal: false, selectedPlan: {}, selectedRow: {}, address: [] } as changeSubscriptionState

const changeSubscriptionSlice = createSlice({
  name: 'changeSubscription',
  initialState,
  reducers: {
    setChangeSubscriptionModal(state, { payload }) {
      state.subsReviewModal = payload?.subsReviewModal;
      state.subsAddressModal = payload?.subsAddressModal;
      state.selectedPlan = payload?.selectedPlan;
      state.selectedRow = payload?.selectedRow;
      state.orderData = payload?.orderData;
      state.modalHeading = payload?.modalHeading;
      state.updateSubscription = payload?.updateSubscription;
    },
    setChangeSubscriptionOrderAddress(state, { payload }) {
      state.address = payload?.address;
    },
    setSubscriptionReviewModal(state, { payload }) {
      state.subscriptionDeviceReview = payload?.subscriptionDeviceReview
    },
    setCurrentPlanData(state, { payload }) {
      state.currentPlanData = payload?.currentPlanData;
    },
    clearChangeSubscription: (state) => state = initialState,
  },
})

export const { setChangeSubscriptionModal, clearChangeSubscription,setSubscriptionReviewModal, setChangeSubscriptionOrderAddress, setCurrentPlanData } = changeSubscriptionSlice.actions
export default changeSubscriptionSlice.reducer
export const getChangeSubsInfo = (state: any) => state.changeSubscription;