import { apiSlice } from "./apiSlice";
import { apiRoutes } from "../../config/api";

export const tenantManagementApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTenant: builder.mutation({
      query: () => ({
        url: apiRoutes.TENANTS,
        method: "GET",
      }),
    }),
  }),
});
export const { useGetAllTenantMutation } = tenantManagementApiSlice;
