import { createSlice } from "@reduxjs/toolkit";
import { AppUSer } from "../../../models/appUser/appUser";
const initialState = {
  userInfo: {},
  fullName: null,
  accessToken: null,
  idToken: null,
  refreshToken: null,
  userId: null,
  isLoggedIn: false,
  tokenScope: null,
  tenantInfo: {},
  isPlatformRole: null,
  permissions: [],
  ipAddress: null,
  sessionExpirationCheck:false
} as AppUSer;

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthInfo: (
      state,
      {
        payload: {
          userInfo,
          accessToken,
          idToken,
          refreshToken,
          userId,
          tokenScope,
        },
      }
    ) => {
      state.userInfo = userInfo;
      state.accessToken = accessToken;
      state.idToken = idToken;
      state.refreshToken = refreshToken;
      state.userId = userId;
      state.tokenScope = tokenScope;
    },
    setUserName: (state, { payload }) => {
      state.fullName = payload?.fullName;
    },
    setAuthTokens: (state, { payload }) => {
      state.accessToken = payload?.accessToken;
      state.idToken = payload?.idToken;
      state.refreshToken = payload?.refreshToken;
    },
    setRefreshedAuthTokens: (state, { payload }) => {
      state.accessToken = payload?.accessToken;
      state.idToken = payload?.idToken;
    },
    setIpAddress: (state, { payload }) => {
      state.ipAddress = payload?.ipAddress;
    },
    setLogin: (state, { payload }) => {
      state.isLoggedIn = payload;
    },
    setSessionExpiredCheck: (state, { payload }) => {
      state.sessionExpirationCheck = payload;
    },
    logOut: (state) => initialState,
    clearAuthState: (state) => (state = initialState),
  },
  extraReducers: (builder) => {},
});

export const {
  setAuthInfo,
  setAuthTokens,
  setRefreshedAuthTokens,
  setLogin,
  setUserName,
  logOut,
  clearAuthState,
  setIpAddress,
  setSessionExpiredCheck
} = authSlice.actions;
export default authSlice.reducer;
export const getAuthState = (state: any) => state?.auth;
export const getLoggedInUser = (state: any) => state?.auth?.userId;
export const getIsLoggedIn = (state: any) => state?.auth?.isLoggedIn;
export const getAuthTokens = (state: any) => ({
  accessToken: state?.auth?.accessToken,
  idToken: state?.auth?.idToken,
  refreshToken: state?.auth?.refreshToken,
});
export const getIsPlatformRole = (state: any) => state?.auth?.isPlatformRole;
export const getTenantInfo = (state: any) => state?.auth?.tenantInfo;
