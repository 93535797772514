import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  styled,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Buttons from "../../../common/button";
import CloseIcon from "@mui/icons-material/Close";
import { handleErrors, phoneNumberAutoFormat } from "../../../../utils";
import { useSendMessageMutation } from "../../../../redux/services/userApiSlice";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import { useDispatch } from "react-redux";
import Loader from "../../../common/loader/loader";
import { Input } from "../../../common/input";

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const DialogBox = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "100%",
    maxWidth: "700px",
    padding: "50px 0px 20px 0px",
  },
}));

function ModalTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} className="sendMsgTitle" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export const SendMessageModal = (props: any) => {
  const { open, setOpen, selectedRowData } = props;
  const dispatch = useDispatch();
  const [sendMessage, sendMessageData] = useSendMessageMutation();
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" || "onSubmit" });

  const handleClose = () => {
    setOpen(false);
  };
  const handleSendMessage = (data: any) => {
    let payload = {
      userId: selectedRowData?.User?.userId,
      message: data?.message,
    };
    sendMessage(payload);
  };

  useEffect(() => {
    if (sendMessageData?.isSuccess) {
      setOpen(false);
      reset();
      dispatch(
        setAlert({
          open: "true",
          alertType: "success",
          message: "Message sent successfully",
        })
      );
    }
  }, [sendMessageData?.isSuccess]);

  useEffect(() => {
    if(sendMessageData?.error?.data?.error?.statusCode === 404){
    // handleErrors(sendMessageData, dispatch);
    dispatch(
      setAlert({
        open:"true",
        alertType: "error",
        message: "The message cannot be sent as the user need to perform one-time login into the app"
      })
    )
    } else{
      handleErrors(sendMessageData, dispatch);
    }
  }, [sendMessageData?.isError]);
  
  return (
    <>
      <Loader show={sendMessageData.isLoading} />
      <DialogBox open={open} onClose={handleClose}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className="messageBox"
        >
          <Grid item xs={10} sm={10} md={10} lg={10} xl={9}>
            <ModalTitle id="customized-dialog-title" onClose={handleClose}>
              <span className="textCapital">
                {" "}
                {selectedRowData?.User?.fullName}{" "}
              </span>
              <span className="mobileNoStyle textWordBreak">
                &nbsp;({selectedRowData?.User?.countryCode}&nbsp;
                {phoneNumberAutoFormat(selectedRowData?.User?.mobileNumber)})
              </span>
            </ModalTitle>
            <Divider className="modalDivider" />
            <DialogContent>
              <form
                data-testid="form"
                onSubmit={handleSubmit(handleSendMessage)}
              >
                <Grid item xs={12}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <FormControl
                        variant="standard"
                        sx={{ width: "100%", marginTop: "20px" }}
                      >
                        {/* <FormLabel className="labelClass">Message</FormLabel> */}
                        <Input
                          label="Message"
                          value={field.value}
                          // className="messageTextField"
                          placeholder="Enter message"
                          inputRef={ref}
                          onChange={field.onChange}
                        />
                        <FormHelperText className="helperClass slice">
                          {error?.message ? error?.message : " "}
                        </FormHelperText>
                      </FormControl>
                    )}
                    name="message"
                    control={control}
                    rules={{
                      required: "Please enter the Message",
                    }}
                  />
                </Grid>
                <Grid container spacing={2} className="modalBtn">
                  <Grid item xs={6} md={5} lg={4.6}>
                    <Buttons
                      type="secondary"
                      variant="contained"
                      text="CANCEL"
                      onClick={() => {
                        setOpen(false);
                      }}
                    ></Buttons>
                  </Grid>
                  <Grid item xs={6} md={5} lg={4.6}>
                    <Buttons
                      type="primary"
                      variant="contained"
                      buttonType="submit"
                      text="SEND MESSAGE"
                      data-testid="submit-btn"
                    ></Buttons>
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
};
