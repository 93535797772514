import { Card, Dialog, Divider, Grid, useMediaQuery } from "@mui/material";
import { Modal } from "../../../../common/modal/modal";
import Buttons from "../../../../common/button";
import { infoIcon, tickIcon } from "../../../../../assets/icons";
import {
  addressFormat,
  handleErrors,
  handlePriceFormatting,
  phoneNumberAutoFormat,
} from "../../../../../utils";
import { useEffect, useState } from "react";
import { CustomToolTip } from "../../../../common/tooltip";
import {
  setBeneficiaryData,
  setPlanDetail,
} from "../../../../../redux/features/user/beneficiarySlice";
import { useGetOrderSummaryMutation } from "../../../../../redux/services/orderApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateBeneficiariesMutation } from "../../../../../redux/services/userApiSlice";
import { useGetSubscriptionPlanQuery } from "../../../../../redux/services/planApiSlice";
import { reviewCheck } from "../../../../../assets/images";
import dayjs from "dayjs";

let couponDetails = {
  campaignId: "54604a80-5a8f-40eb-b640-46c965aec926",
  tenantId: "bad51873-b97a-4561-b45a-903e070a9a27",
  country: null,
  campaignName: "Launch offer",
  couponCode: "FPH0823",
  stripeCouponId: null,
  description: "Special offer for full launch",
  type: "monthlyDiscount",
  value: 25,
  status: "Active",
  applicableOn: "Device Cost",
  applicableCycle: 1,
  startsFrom: "2023-08-07",
  expiresOn: "2023-09-30",
  createdBy: "Harish Kakde",
  createdAt: "2023-08-04T09:15:55.000Z",
  updatedBy: null,
  updatedAt: "2023-08-07T10:02:09.000Z",
  deletedBy: null,
  deletedAt: null,
  CampaignPlans: [
    {
      campaignPlanId: "21f6fed9-6676-4753-8df9-15ed9542012a",
      campaignId: "54604a80-5a8f-40eb-b640-46c965aec926",
      subscriptionPlanId: "f53f65d0-abaf-48d9-8d8d-002ca8abf89e",
    },
    {
      campaignPlanId: "7ee095d9-05bf-4e4a-ab1f-3df63b705726",
      campaignId: "54604a80-5a8f-40eb-b640-46c965aec926",
      subscriptionPlanId: "7795dcbc-a436-4484-91fb-7dc906053f04",
    },
  ],
  couponActive: false,
};
export const SubscriptionReviewModal = (props: any) => {
  const { id } = useSelector((state: any) => state.order);
  const userId = useSelector((state: any) => state?.auth?.userId);
  const { beneficiaryAccounts } = useSelector(
    (state: any) => state?.beneficiary
  );
  console.log(beneficiaryAccounts?.activeBeneficiaries, "ppppppppp");

  const {
    setReview,
    review,
    setShippingAdressModal,
    refetchPlans,
    selectedSubscription,
    planData,
    totalSum,
    allowedBen,
    setChangeDeviceTypeModal,
  } = props;
  console.log(allowedBen, "sumsumsum");
  const {
    data,
    isSuccess,
    refetch,
    isError,
    fulfilledTimeStamp,
    error,
    isLoading,
  } = useGetSubscriptionPlanQuery(
    {
      id: userId,
    },
    {
      skip: userId == undefined ? true : false,
    }
  );
  console.log(data?.result, "subscription");
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state: any) => state?.auth);
  const [orderSummary, orderSummaryData] = useGetOrderSummaryMutation();
  console.log(orderSummaryData, "orderSummaryData");

  const [updateBeneficiary, updateBeneficiaryInfo] =
    useUpdateBeneficiariesMutation();
  console.log(updateBeneficiaryInfo, "subscriptouoiuoi");

  const handleCancel = () => {
    setReview(false);
  };
  const [orderData, setOrderData] = useState<any>({
    address: [],
    beneficiary: [],
    orderInfo: {},
    plan: {},
    subscriber: {},
  });
  const [trial, setTrial] = useState<any>();

  // useEffect(() => {
  //   if (review) {
  //     orderSummary(id);
  //   }
  // }, [review]);

  useEffect(() => {
    if (orderSummaryData?.isSuccess) {
      setOrderData(orderSummaryData?.data?.result);
      dispatch(setBeneficiaryData(orderSummaryData?.data?.result?.beneficiary));
      dispatch(setPlanDetail(orderSummaryData?.data?.result?.plan));
      console.log(orderSummaryData?.data?.result?.plan, "planData");
    }
  }, [orderSummaryData?.isSuccess, selectedSubscription === "Devices"]);

  useEffect(() => {
    handleErrors(orderSummaryData, dispatch);
  }, [orderSummaryData?.isError]);

  const handleClick = () => {
    setShippingAdressModal(true);
  };
  const widthMatch = useMediaQuery("(max-width:900px)");
  const [couponDiscount, setCouponDiscount] = useState<any>(0);
  console.log(couponDiscount, "couponcouponnn");

  useEffect(() => {
    if (planData && planData?.length > 0) {
      if (
        planData[0]?.couponDetails &&
        planData[0]?.couponDetails?.type === "monthlyDiscount"
      ) {
        let sum = (totalSum * planData[0]?.couponDetails?.value) / 100;
        setCouponDiscount(sum);
      }
      else if (planData[0]?.couponDetails &&
        planData[0]?.couponDetails?.type === "freeTrial"
      ) {
        let value = planData[0]?.couponDetails?.value / 30
        setCouponDiscount(value)
      }
      setTrial(planData[0]?.couponDetails?.type)
    }
  }, [review, planData]);

  // useEffect(()=>{
  //   const allowed = data?.result[0]?.allowedBeneficiary;
  //   const totalBen = allowedBen + allowed
  //   console.log(allowedBen, "counting")

  // },[isSuccess])

  const [updateBeneficiaryData, setUpdateBeneficiaryData] =
    useState<any>(false);
  const [updateBenData, setUpdateBenData] = useState<any>();
  console.log(updateBenData, "ddddassadas");
  const [billingCycleDate, setBillingCycleDate] = useState<any>();
  const [planName, setPlanName] = useState<any>();
  const [confirmAmount, setConfirmAmount] = useState<any>();

  useEffect(() => {
    if (updateBeneficiaryInfo?.isSuccess) {
      setUpdateBeneficiaryData(true);
      setReview(false);
    }
  }, [updateBeneficiaryInfo?.isSuccess]);

  useEffect(() => {
    if (updateBeneficiaryInfo?.isError) {
      handleErrors(updateBeneficiaryInfo, dispatch);
    }
  }, [updateBeneficiaryInfo?.isError]);

  useEffect(() => {
    if (isSuccess) {
      if (data && data?.result?.length > 0) {
        const feeData = data?.result[0]?.subscriptionFee;
        setUpdateBenData(feeData);
        const nextPaymentDate = data?.result[0]?.nextPaymentDate;
      if (nextPaymentDate) {
        const formattedDate = dayjs(nextPaymentDate, "DD-MM-YYYY")?.format("DD MMM, YYYY");
        setBillingCycleDate(formattedDate);
        console.log(formattedDate, "formattedDate")
      }
        // const date = (data?.result[0]?.nextPaymentDate);
        // setBillingCycleDate(dayjs(date)?.format("DD MMM, YYYY"));
        setPlanName(data?.result[0]?.SubscriptionPlan?.name)
        console.log(data?.result[0]?.SubscriptionPlan?.name, "billingcycle")
      }
    }
  }, [isSuccess]);

  const handleConfirmBeneficiary = () => {
    var request: any = {
      allowedBeneficiary: allowedBen,
      userSubscriptionId: data?.result[0]?.userSubscriptionId,
    };
    updateBeneficiary(request);
    console.log(request, "requestsss");
  };

  const updateBeneficiaryDataClose = () => {
    refetchPlans()
    setUpdateBeneficiaryData(false);
    setChangeDeviceTypeModal(false);
  };

  return (
    <>
      <Modal
        handleClose={handleCancel}
        primaryText={
          selectedSubscription !== "Beneficiary"
            ? "Review and pay"
            : "Review and Confirm"
        }
        open={review}
        xs={10.3}
        sm={10.3}
        md={10.3}
        lg={10.3}
        xl={10.3}
        className="input-padding modal-title outer-pd-device reviewOrderModal"
      >
        <Grid container justifyContent={"center"}>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
            {/* <Card className="review-card" data-testid="reviewPayCard"> */}
            <Grid container justifyContent={"center"}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="reviewP-topBottom"
              >
                {/* -------------------------Second Container------------------------- */}
                {selectedSubscription !== "Beneficiary" ? (
                  <>
                    <Grid container>
                      <Grid xs={10} sm={10} md={10} lg={10} xl={10}>
                        <Grid container className="pt-10">
                          <Grid item xs={12} sm={12} md={12} lg={5} xl={6}>
                            <div className="content-title">
                              <span>
                                <img src={tickIcon} alt="TickIcon" />
                              </span>
                              <span className="plan-title">
                                Billing Address
                              </span>
                            </div>
                            {orderData?.address?.map(
                              (data: any, index: any) => {
                                if (data.addressType == "billing") {
                                  return (
                                    <div className="billing-Address-review">
                                      <span className="text-premium">
                                        Sanjeev Sharma |{" "}
                                      </span>
                                      <span className="faded-text">
                                        Mobile&nbsp;Number:
                                      </span>
                                      <span className="text-color">
                                        &nbsp;
                                        {orderData?.subscriber?.countryCode}
                                        &nbsp;
                                        {phoneNumberAutoFormat(
                                          orderData?.subscriber?.mobileNumber
                                        )}
                                      </span>
                                      <br />
                                      <span>
                                        {addressFormat(data)}
                                        {/* {data?.address1}, {data?.address2},{" "}
                                    {data?.city}, {data?.state}, {data?.zipCode}
                                    , {data?.country} */}
                                      </span>
                                    </div>
                                  );
                                }
                              }
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div className="content-title">
                              <span>
                                <img src={tickIcon} alt="TickIcon" />
                              </span>
                              <span className="plan-title">
                                Shipping Address
                              </span>
                            </div>
                            {orderData?.address?.map(
                              (data: any, index: any) => {
                                if (data?.addressType == "shipping") {
                                  return (
                                    <div className="billing-Address-review">
                                      <span className="text-premium">
                                        {data?.fullName} |{" "}
                                      </span>
                                      <span className="faded-text">
                                        Mobile&nbsp;Number:
                                      </span>
                                      <span className="text-color">
                                        &nbsp;{data?.countryCode}&nbsp;
                                        {phoneNumberAutoFormat(
                                          data?.mobileNumber
                                        )}
                                        <br />
                                      </span>
                                      <span>
                                        {addressFormat(data)}
                                        {/* {data?.address1}, {data?.address2},{" "}
                                    {data?.city}, {data?.state}, {data?.zipCode}
                                    , {data?.country} */}
                                      </span>
                                    </div>
                                  );
                                }
                              }
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        lg={1.7}
                        xl={1.8}
                        className="last-col"
                      >
                        <span>
                          <Buttons
                            variant="contained"
                            type="secondary"
                            text="CHANGE"
                            onClick={handleClick}
                          />
                        </span>
                      </Grid>
                    </Grid>
                    <Divider className="margin-lr"></Divider>
                  </>
                ) : null}
                {/* ---------------------Third Container---------------------------------------- */}

                <Grid container>
                  <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
                    {selectedSubscription !== "Beneficiary" ? (
                      <div className="content-title">
                        <span>
                          <img src={tickIcon} alt="TickIcon" />
                        </span>
                        <span className="plan-title">Setup Payment Method</span>
                      </div>
                    ) : null}

                    <Grid
                      container
                      justifyContent={"center"}
                      className="secondInnerCard"
                    >
                      <Grid item xs={11} sm={11} md={11} lg={11.4} xl={11.4}>
                        <div>
                          <Card className="pay-card">
                            <Grid
                              container
                              justifyContent={"center"}
                              sx={{ pb: 3 }}
                            >
                              <Grid
                                item
                                xs={11}
                                sm={11}
                                md={11}
                                lg={11.5}
                                xl={11.5}
                              >
                                <Grid container sx={{ mt: 2, mb: 1 }}>
                                  <Grid item xs={3} sm={3} md={3} lg={4} xl={4}>
                                    <span className="product-text">
                                      PRODUCT
                                    </span>
                                  </Grid>
                                  {!widthMatch ? (
                                    <Grid
                                      item
                                      xs={3}
                                      sm={3}
                                      md={3}
                                      lg={4}
                                      xl={4}
                                    >
                                      <span className="product-text">
                                        REMARK
                                      </span>
                                    </Grid>
                                  ) : (
                                    <Grid
                                      item
                                      xs={3}
                                      sm={3}
                                      md={3}
                                      lg={4}
                                      xl={4}
                                    ></Grid>
                                  )}
                                  <Grid
                                    item
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    lg={4}
                                    xl={4}
                                    className="product-text priceLine"
                                  >
                                    <div>
                                      PRICE
                                      <span className="usd">
                                        &nbsp;(
                                        {planData && planData?.length > 0
                                          ? planData[0]?.currency === "INR"
                                            ? "INR"
                                            : "USD"
                                          : null}
                                        )
                                      </span>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Divider className="inerHrDivider" />
                                <Grid container>
                                  <Grid item xs={9} sm={9} md={3} lg={4} xl={4}>
                                    <p className="pr-text marginDefineTop">
                                      {/* {orderData?.plan?.name} Subscription */}
                                      {planName} Subscription
                                    </p>
                                  </Grid>
                                  <Grid item xs={0} sm={0} md={6} lg={5} xl={5}>
                                    {!widthMatch ? (
                                      selectedSubscription === "Beneficiary" ? (
                                        <>
                                          {trial === "freeTrial" ?
                                            (<span className="reviewpr-text ">
                                              Your monthly subscription amount will be{" "}
                                              <span className="price-text">
                                                <span className="riviewBold">
                                                  {planData && planData?.length > 0
                                                    ? planData[0]?.currency === "INR"
                                                      ? "INR"
                                                      : "USD"
                                                    : null}
                                                  &nbsp;
                                                  {planData && planData?.length > 0 ?
                                                    handlePriceFormatting(totalSum, userInfo?.country === "IN" ? "IN" : "") : <></>
                                                  }
                                                </span>&nbsp;

                                                {userInfo?.country === "IN"
                                                  ? "+ applicable GST "
                                                  : "+ Tax"} </span> {" "}
                                              from your next bill cycle starting
                                              from <b>{billingCycleDate}</b>. The payment method
                                              on your account will continue to be
                                              used.
                                            </span>)
                                            :
                                            (<span className="reviewpr-text ">
                                              Your monthly subscription amount will
                                              be{" "}
                                              <b>
                                                {planData && planData?.length > 0
                                                  ? planData[0]?.currency === "INR"
                                                    ? "INR"
                                                    : "USD"
                                                  : null}
                                                &nbsp;
                                                {handlePriceFormatting(
                                                  totalSum - couponDiscount,
                                                  userInfo?.country === "IN"
                                                    ? "IN"
                                                    : "US"
                                                )}
                                                {" "}

                                                {userInfo?.country === "IN"
                                                  ? "+ applicable GST "
                                                  : "+ Tax"}
                                              </b>{" "}
                                              from your next bill cycle starting
                                              from <b>{billingCycleDate}</b>. The payment method
                                              on your account will continue to be
                                              used.
                                            </span>
                                            )}
                                        </>
                                      ) : planData[0]?.couponDetails?.type === "freeTrial" ? (
                                        <span className="reviewpr-text ">
                                          Your monthly subscription amount will
                                          be{" "}
                                          <span className="price-text">
                                            <span className="riviewBold">
                                              {planData && planData?.length > 0
                                                ? planData[0]?.currency === "INR"
                                                  ? "INR"
                                                  : "USD"
                                                : null}
                                              &nbsp;
                                              {planData && planData?.length > 0 ?
                                                handlePriceFormatting(totalSum, userInfo?.country === "IN" ? "IN" : "") : <></>
                                              }
                                            </span>&nbsp;

                                            {userInfo?.country === "IN"
                                              ? "+ applicable GST "
                                              : "+Tax"} </span>{" "}
                                          from your next bill cycle starting
                                          from <b>{billingCycleDate}</b>. The payment method
                                          on your account will continue to be
                                          used.
                                        </span>
                                      ) : (
                                        <span className="reviewpr-text ">
                                          Your subscription will commence once
                                          the device bundle is delivered.
                                          <br />
                                          You will be charged a subscription fee
                                          of{" "}
                                          <span className="price-text">
                                            &nbsp;
                                            {orderData?.plan?.recurringAmount}
                                          </span>
                                          . This fee will be charged monthly and
                                          will continue to renew until you
                                          cancel your subscription.
                                        </span>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    className="reviewpr-text priceLine"
                                  >
                                    <div className="price-text">
                                      {/* $ {orderData?.plan?.recurringAmount} */}
                                    </div>
                                  </Grid>
                                  {widthMatch ? (
                                    <>
                                      <Grid
                                        item
                                        xs={8}
                                        sm={8}
                                        md={8}
                                        lg={8}
                                        xl={8}
                                      >
                                        <span className="reviewpr-text ">
                                          <span className="fw-700 subscriberColumn2">
                                            Remark:&nbsp;
                                          </span>
                                          {trial === "freeTrial" ? (
                                            <span className="reviewpr-text ">
                                              Your monthly subscription amount will
                                              be{" "}
                                              <span className="price-text">
                                                <span className="riviewBold">
                                                  {planData && planData?.length > 0
                                                    ? planData[0]?.currency === "INR"
                                                      ? "INR"
                                                      : "USD"
                                                    : null}
                                                  &nbsp;
                                                  {planData && planData?.length > 0 ?
                                                    handlePriceFormatting(totalSum, userInfo?.country === "IN" ? "IN" : "") : <></>
                                                  }
                                                </span>&nbsp;

                                                {userInfo?.country === "IN"
                                                  ? "+ applicable GST "
                                                  : "+ Tax"}
                                              </span>{" "}
                                              from your next bill cycle starting
                                              from <b>{billingCycleDate}</b>. The payment method
                                              on your account will continue to be
                                              used.
                                            </span>
                                          ) : (
                                            <span className="reviewpr-text ">
                                              Your monthly subscription amount will be {" "}
                                              <span className="price-text">
                                                <span className="riviewBold">
                                                  {planData && planData?.length > 0
                                                    ? planData[0]?.currency === "INR"
                                                      ? "INR"
                                                      : "USD"
                                                    : null}
                                                  &nbsp;
                                                  {planData && planData?.length > 0 ?
                                                    handlePriceFormatting(totalSum, userInfo?.country === "IN" ? "IN" : "") : <></>
                                                  }
                                                </span>&nbsp;

                                                {userInfo?.country === "IN"
                                                  ? "+ applicable GST "
                                                  : "+ Tax"}
                                              </span>{" "}
                                              from your next bill cycle starting
                                              from <b>{billingCycleDate}</b>. The payment method
                                              on your account will continue to be
                                              used.
                                            </span>
                                          )}
                                        </span>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                      ></Grid>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {selectedSubscription !== "Beneficiary" ? (
                                    <>
                                      <Grid
                                        item
                                        xs={9}
                                        sm={9}
                                        md={3}
                                        lg={4}
                                        xl={4}
                                      >
                                        <div className="content-inner-title">
                                          <p className="pr-text marginDefineTop ">
                                            {orderData?.plan?.name} device
                                            bundle{" "}
                                            <CustomToolTip
                                              title={orderData?.plan?.remarks}
                                              placement="top"
                                            >
                                              <img
                                                src={infoIcon}
                                                className="icon"
                                              />{" "}
                                            </CustomToolTip>
                                          </p>
                                          &nbsp;
                                        </div>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={0}
                                        sm={0}
                                        md={6}
                                        lg={4}
                                        xl={4}
                                      >
                                        {!widthMatch ? (
                                          <span className="reviewpr-text ">
                                            One Time Fee
                                          </span>
                                        ) : (
                                          <></>
                                        )}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        lg={4}
                                        xl={4}
                                        className="reviewpr-text priceLine"
                                      >
                                        <div className="price-text alignCenter">
                                          $ {orderData?.plan?.fixedAmount}
                                        </div>
                                      </Grid>
                                    </>
                                  ) : null}
                                  {widthMatch &&
                                    selectedSubscription !== "Beneficiary" ? (
                                    <>
                                      <Grid
                                        item
                                        xs={8}
                                        sm={8}
                                        md={8}
                                        lg={8}
                                        xl={8}
                                      >
                                        <span className="reviewpr-text ">
                                          <span className="fw-700 subscriberColumn2">
                                            Remark:&nbsp;
                                          </span>{" "}
                                          One-time fee
                                        </span>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={4}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                      ></Grid>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Grid>
                                <Divider className="inerHrDivider" />

                                <Grid
                                  container
                                  justifyContent={"space-between"}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={5}
                                    xl={7}
                                    sx={{ mt: 3 }}
                                    className="reviewpr-text flex-dr"
                                  ></Grid>
                                  {/* <Grid item xs={0} sm={0} md={0} lg={3} xl={4}></Grid> */}
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={7}
                                    xl={5}
                                    className="last-col"
                                  >
                                    {selectedSubscription !== "Beneficiary" ? (
                                      <Grid container className="total-list">
                                        <Grid
                                          item
                                          xs={8}
                                          sm={9}
                                          md={8.5}
                                          lg={7}
                                          xl={7}
                                          className="price-label-list"
                                        >
                                          <span className="text-color">
                                            Subtotal:{" "}
                                          </span>
                                          <span className="text-color">
                                            Coupon Discount:
                                          </span>
                                          <span className="text-color">
                                            Net Amount:
                                          </span>
                                          <span className="text-color">
                                            Tax{" "}
                                            <CustomToolTip
                                              title={
                                                "The tax is for the subscription only and is based on your billing location"
                                              }
                                              placement="top"
                                            >
                                              <img
                                                className="taxImg"
                                                src={infoIcon}
                                              />
                                            </CustomToolTip>{" "}
                                            :
                                          </span>
                                          <span>
                                            <b className="text-color">Total:</b>
                                          </span>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={4}
                                          sm={3}
                                          md={3.5}
                                          lg={5}
                                          xl={5}
                                          className="price-list"
                                        >
                                          <span>
                                            $ {orderData?.orderInfo?.subTotal}
                                          </span>
                                          <span>
                                            ${" "}
                                            {
                                              orderData?.orderInfo
                                                ?.couponDiscount
                                            }
                                          </span>
                                          <span>
                                            $ {orderData?.orderInfo?.netAmount}
                                          </span>
                                          <span>
                                            $ {orderData?.orderInfo?.taxAmount}
                                          </span>
                                          <span>
                                            <b>
                                              ${" "}
                                              {
                                                orderData?.orderInfo
                                                  ?.totalAmount
                                              }
                                            </b>
                                          </span>
                                        </Grid>
                                      </Grid>
                                    ) : (
                                      <Grid container className="total-list">
                                        <Grid
                                          item
                                          xs={8}
                                          sm={8}
                                          md={9}
                                          lg={10}
                                          xl={10}
                                          className="price-label-list"
                                        >
                                          {planData && planData?.length > 0 ? (
                                            planData[0]?.couponDetails?.type ===
                                              "monthlyDiscount" ? (
                                              <span className="text-color">
                                                Subscription cost:{" "}
                                              </span>
                                            ) : null
                                          ) : null}
                                          {planData && planData?.length > 0 ? (
                                            planData[0]?.couponDetails?.type ===
                                              "monthlyDiscount" ? (
                                              <span className="text-color">
                                                Coupon discount:{" "}
                                              </span>
                                            ) : null
                                          ) : null}
                                          <span className="text-color">
                                            Total subscription amount{" "}{userInfo?.country === "IN" ? "(excluding GST)" : null}:{" "}
                                          </span>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={4}
                                          sm={4}
                                          md={3}
                                          lg={2}
                                          xl={2}
                                          className="price-list"
                                        >
                                          {planData && planData?.length > 0 ? (
                                            planData[0]?.couponDetails?.type ===
                                              "monthlyDiscount" ? (
                                              <span>
                                                <b>
                                                  &nbsp;
                                                  {handlePriceFormatting(
                                                    totalSum,
                                                    userInfo?.country === "IN"
                                                      ? "IN"
                                                      : "US"
                                                  )}
                                                </b>
                                              </span>
                                            ) : null
                                          ) : null}
                                          {planData && planData?.length > 0 ? (
                                            planData[0]?.couponDetails?.type ===
                                              "monthlyDiscount" ? (
                                              <span>
                                                <b>

                                                  {handlePriceFormatting(
                                                    couponDiscount,
                                                    userInfo?.country === "IN"
                                                      ? "IN"
                                                      : "US"
                                                  )}
                                                </b>
                                              </span>
                                            ) : null
                                          ) : null}


                                          {planData && planData?.length > 0 ? (
                                            planData[0]?.couponDetails?.type ===
                                              "monthlyDiscount" ? (
                                              <span>
                                                <b>
                                                  {handlePriceFormatting(
                                                    totalSum - couponDiscount,
                                                    userInfo?.country === "IN"
                                                      ? "IN"
                                                      : "US"
                                                  )}
                                                </b>
                                              </span>
                                            ) :
                                              <span>
                                                <b>
                                                  {handlePriceFormatting(
                                                    totalSum,
                                                    userInfo?.country === "IN"
                                                      ? "IN"
                                                      : "US"
                                                  )}
                                                </b>
                                              </span>
                                          ) : null}



                                        </Grid>
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid container columnSpacing={2} sx={{ mt: 3 }}>
                                  <Grid item xs={12} sm={6} md={6} lg={8} xl={8}></Grid>
                                  <Grid item xs={6} sm={2.5} md={3} lg={2} xl={2}>
                                    <Buttons
                                      onClick={handleCancel}
                                      // disabled={isLoading}
                                      // isLoading={isLoading}
                                      variant="contained"
                                      type="secondary"
                                      text="cancel"
                                    // className="nxtBtn"
                                    />
                                  </Grid>
                                  <Grid item xs={6} sm={3.5} md={3} lg={2} xl={2}>
                                    <Buttons
                                      variant="contained"
                                      type="primary"
                                      text="Confirm"
                                      // className="nxt_Btn"
                                      onClick={handleConfirmBeneficiary}
                                    />
                                  </Grid>
                                </Grid>

                                {/* {selectedSubscription !== "Beneficiary" ? (
                                  <span className="next-btn">
                                    <Buttons
                                      variant="contained"
                                      type="primary"
                                      text="Pay"
                                      className="nxt_Btn"
                                    />
                                  </span>
                                ) : (
                                  <span className="next-btn">
                                    <Buttons
                                      variant="contained"
                                      type="primary"
                                      text="Confirm"
                                      className="nxt_Btn"
                                      onClick={handleConfirmBeneficiary}
                                    />
                                  </span>
                                )} */}
                              </Grid>
                            </Grid>
                          </Card>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* </Card> */}
          </Grid>
        </Grid>
      </Modal>

      <div className="updateDialoge">
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={updateBeneficiaryData}
          className="PaymentModal"
        >
          <div className="existingUserModal">
            <Grid container sx={{ display: "flex", justifyContent: "center" }}>
              <div className="supportModalConfirmation">
                <img className="reviwCheckImg" src={reviewCheck} />
                <span className="existingMessage marginTop">
                  Your Subscription has been updated successfully.
                </span>
              </div>
              <div className="lowerPara">
                <span>
                  Your monthly subscription amount will be{" "}
                  <span className="riviewBold">
                    {planData && planData?.length > 0
                      ? planData[0]?.currency === "INR"
                        ? "INR"
                        : "USD"
                      : null}
                    &nbsp;


                    {planData && planData?.length > 0 ? (
                      planData[0]?.couponDetails?.type ===
                        "monthlyDiscount" ? (
                        <span>
                          <b>
                            {handlePriceFormatting(
                              totalSum - couponDiscount,
                              userInfo?.country === "IN"
                                ? "IN"
                                : "US"
                            )}
                            {" "}
                    {userInfo?.country === "IN"
                      ? "+ GST "
                      : "+ Tax"}{" "}
                          </b>
                        </span>
                      ) :
                        <span>
                          <b>
                            {handlePriceFormatting(
                              totalSum,
                              userInfo?.country === "IN"
                                ? "IN"
                                : "US"
                            )}{" "}
                            {userInfo?.country === "IN"
                              ? "+ GST "
                              : "+ Tax"}{" "}
                          </b>
                        </span>
                    ) : null}



                    {/* {handlePriceFormatting(
                      totalSum - couponDiscount,
                      userInfo?.country === "IN" ? "IN" : "US"
                    )}{" "}
                    {userInfo?.country === "IN"
                      ? "+ GST "
                      : "+ Tax"}{" "} */}
                  </span>
                  &nbsp;from your next bill cycle starting from{" "}
                  <span className="riviewBold">{billingCycleDate}</span>, You now can setup {allowedBen} active
                  beneficiaries.
                </span>
              </div>

              <Buttons
                onClick={updateBeneficiaryDataClose}
                variant="contained"
                type="primary"
                text="OK"
                className="existingBtn"
              />
            </Grid>
          </div>
        </Dialog>
      </div>
    </>
  );
};
