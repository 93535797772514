import { Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { State } from "country-state-city";
import { useEffect, useState } from "react";
import { useAddOrderAddressMutation, useGetAllOrdersByUserIdMutation, useGetAllOrdersQuery, useUpdateOrderAddressMutation } from "../../../../redux/services/orderApiSlice";
import { useGetAccountSettingsQuery } from "../../../../redux/services/accountsettingsApiSlice";
import { useOrderDeviceMutation } from "../../../../redux/services/devicesApiSlice";
import { getCountryNameByCountryCode, handleErrors, handleInputFocus, phoneNumberAutoFormat } from "../../../../utils";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import { setSubscriptionReviewModal } from "../../../../redux/features/changeSubscription/changeSubscriptionSlice";
import { clearOrderState, setOrder } from "../../../../redux/features/order/orderSlice";
import { Modal } from "../../../common/modal/modal";
import { RegExpressions } from "../../../../constants/regExp";
import { Input } from "../../../common/input";
import { CountryCodeSelectInput, SelectInput } from "../../../common/selectinput";
import { cCodes } from "../../../../constants/constant";
import Buttons from "../../../common/button";
import { DeviceReviewOrderModal } from "../acoountSubscription/modal/deviceReviewModal";
import { setChange } from "../../../../redux/features/user/beneficiarySlice";
import { clearDeviceOrderState } from "../../../../redux/features/deviceOrder/deviceOrderSlice";
import { useDeleteOrderMutation } from "../../../../redux/services/planApiSlice";
import { ConfirmModal } from "../../../common/confirmmodal";

export const NewPurchaseShippingAddress = (props: any) => {
  const {
    setShippingAdressModal,
    shippingAddressModal,
    selectedSubscription,
    planData,
    deviceDetails,
  } = props;
  const userId = useSelector((state: any) => state?.auth?.userId);
  const {subscriptionDeviceReview } = useSelector(
    (state: any) => state.changeSubscription
  );
  console.log(subscriptionDeviceReview, "llllllllpppplop")
  const [getOrders, getOrdersData] = useGetAllOrdersByUserIdMutation();
  console.log(getOrdersData, "getOrerrr")
  const [orderId, setOrderId] = useState<any>();
  const [cancelConfirmModal, setCancelConfirmModal] = useState<any>();


  useEffect(() => {
    if (shippingAddressModal) {
      getOrders({
        userId: userId,
      });
    }
  }, [shippingAddressModal]);

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange" || "onSubmit",
    defaultValues: {
      fullName: "",
      mobileNumber: "",
      shippingHouseNo: "",
      shippingArea: "",
      shippingTown: "",
      shippingState: "",
      countryCode: "",
      shippingPincode: "",
      shippingCountry: "",
    },
  });
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state: any) => state?.auth);
  const { id } = useSelector((state: any) => state?.order);
  const { isChange } = useSelector((state: any) => state?.beneficiary);
  console.log(isChange, "changgggeeing")
  const indiaStateNames = State.getStatesOfCountry("IN").map((states: any) => {
    return { text: states?.name, value: `${states?.isoCode}` };
  });
  const [key, setKey] = useState("");
  const [review, setReview] = useState<any>(false);
  const [shipping, setShipping] = useState({
    fullName: "",
    countryCode: "",
    mobileNumber: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });
  const [billingData, setBillingData] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
  });

  // API INTEGRATION
  const {
    data,
    isSuccess,
    refetch,
    isError,
    fulfilledTimeStamp,
    error,
    isLoading,
  } = useGetAllOrdersQuery({
    refetchOnMountOrArgChange: true,
  });
  console.log(data?.result[0], "shipppingaddreess");
  const [deleteOrder, deleteOrderData] = useDeleteOrderMutation();

  const {
    data: data1,
    refetch: refetch1,
    isError: isError1,
    error: error1,
    isLoading: isLoading1,
    isSuccess: isSuccss1,
  } = useGetAccountSettingsQuery(userId, {
    refetchOnMountOrArgChange: true,
  });
  console.log(data1, "billingaddress");
  const [addOrderAddress, addOrderAddressData] = useAddOrderAddressMutation();
  console.log(addOrderAddressData, "addorderaddress");
  const [orderDevice, orderDeviceData] = useOrderDeviceMutation();
  console.log(orderDeviceData, "orderDeviceData")
  const [updateOrderAddress, updateOrderAddressData] =
    useUpdateOrderAddressMutation();
  const [orderIdData, setOrderIdData] = useState<any>()

  useEffect(() => {
    if (isSuccess) {
      setShipping(data?.result[0]?.orderaddresses[0]);
      console.log(data?.result[0]?.orderaddresses[0], "orderaddress");
      setOrderIdData(data?.result[0]?.orderId)
      console.log(data?.result[0]?.orderId, "localidOrdeer")
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isSuccss1) {
      setBillingData(data1?.result?.address);
      console.log(data1?.result?.address, "adressswww");
    }
  }, [data1, isSuccss1]);

  useEffect(() => {
    setValue("countryCode", shipping?.countryCode);
    setValue("shippingCountry", shipping?.country);
  }, [shippingAddressModal]);

  const handleMobileNumber = (e: any) => {
    var value = handleInputFocus(e, key);
    setValue("mobileNumber", value);
    trigger("mobileNumber");
  };

  useEffect(() => {
    if (getOrdersData?.isSuccess) {
      if (
        getOrdersData?.data &&
        getOrdersData?.data?.result &&
        getOrdersData?.data?.result?.length > 0
      ) {
        setOrderId(getOrdersData?.data?.result[0]?.orderId)
        let orderAdderess= getOrdersData?.data?.result?.filter((item:any,index:any)=>
        item?.orderaddresses?.length>0);
        let shippingOrderAddress:any=[];
        if(orderAdderess && orderAdderess?.length>0){
          shippingOrderAddress=orderAdderess[0]?.orderaddresses?.filter((item:any)=>item?.addressType==="shipping")
        }
        if(shippingOrderAddress && shippingOrderAddress?.length>0){
          setValue("fullName", shippingOrderAddress[0]?.fullName);
          setValue(
            "mobileNumber",
            phoneNumberAutoFormat(shippingOrderAddress[0]?.mobileNumber)
          );
          setValue("countryCode", "+91 IN");
          setValue("shippingHouseNo", shippingOrderAddress[0]?.address1);
          setValue("shippingArea", shippingOrderAddress[0]?.address2);
          setValue("shippingTown", shippingOrderAddress[0]?.city);
          setValue("shippingState", shippingOrderAddress[0]?.state);
          setValue("shippingPincode", shippingOrderAddress[0]?.zipCode);
          setValue("shippingCountry", shippingOrderAddress[0]?.country);
        }
        
      }
    }
  }, [getOrdersData?.isSuccess]);

  console.log(deviceDetails,"deggggggggggggggggg")

  const onSubmit = (data: any) => {
    var finalData = {
      userId: userId,
      devices: deviceDetails?.map((item:any,index:any)=>{
        return {id:item?.deviceTypeId,quantity:item?.quantity}
      }),
      currency:userInfo?.country==="IN"?"INR":"USD",
      remarks: "device purchased",
      billing: {
        houseNo: billingData?.address1,
        address2: billingData?.address2,
        city: billingData?.city,
        state: billingData?.state,
        zipCode: billingData?.zipCode,
        country: getCountryNameByCountryCode(billingData?.country),
      },
      shipping: {
        fullName: data?.fullName,
        mobileNumber: data?.mobileNumber?.replaceAll("-", ""),
        houseNo: data?.shippingHouseNo,
        locality: data?.shippingArea,
        city: data?.shippingTown,
        state: data?.shippingState,
        countryCode: data?.countryCode?.split(" ")[0]
          ? data?.countryCode?.split(" ")[0]
          : "",
        zipCode: data?.shippingPincode,
        country: data?.shippingCountry,
      },
    };

    var payload = {
      userId: userId,
      devices: deviceDetails?.map((item:any,index:any)=>{
        return {id:item?.deviceTypeId,quantity:item?.quantity}
      }),
      currency:userInfo?.country==="IN"?"INR":"USD",
      remarks: "device purchased",
      orderId: isChange ? id : null,
      billing: {
        houseNo: billingData?.address1,
        address2: billingData?.address2,
        city: billingData?.city,
        state: billingData?.state,
        zipCode: billingData?.zipCode,
        country: getCountryNameByCountryCode(billingData?.country),
      },
      shipping: {
        fullName: data?.fullName,
        mobileNumber: data?.mobileNumber?.replaceAll("-", ""),
        houseNo: data?.shippingHouseNo,
        locality: data?.shippingArea,
        city: data?.shippingTown,
        state: data?.shippingState,
        countryCode: data?.countryCode?.split(" ")[0]
          ? data?.countryCode?.split(" ")[0]
          : "",
        zipCode: data?.shippingPincode,
        country: data?.shippingCountry,
      },
    };

    if(!isChange){
      orderDevice(finalData);
      console.log(finalData, "finaldatata")
    }else if(isChange){
      updateOrderAddress(payload)
    }
  
    
   
  };
  useEffect(() => {
    if (orderDeviceData?.isSuccess) {
      dispatch(setOrder({ id: orderDeviceData?.data?.result?.orderId }));
      console.log(orderDeviceData?.data?.result, "lllllllooooo");
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Address Updated Successfully",
        })
      );
      dispatch(setSubscriptionReviewModal({
        subscriptionDeviceReview:true
      }))
      // setReview(true);
    }
  }, [orderDeviceData?.isSuccess]);

  useEffect(() => {
    handleErrors(orderDeviceData, dispatch);
  }, [orderDeviceData?.isError]);

  useEffect(() => {
    if (addOrderAddressData?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Address Saved Successfully",
        })
      );
      dispatch(setSubscriptionReviewModal({
        subscriptionDeviceReview:true
      }))
      // setReview(true)
    }
  }, [addOrderAddressData?.isSuccess]);

  useEffect(() => {
    handleErrors(addOrderAddressData, dispatch);
  }, [addOrderAddressData?.isError]);


  useEffect(() => {
    if (updateOrderAddressData?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Address Updated Successfully",
        })
      );
      dispatch(setSubscriptionReviewModal({
        subscriptionDeviceReview:true
      }))
    }
  }, [updateOrderAddressData?.isSuccess]);

  useEffect(() => {
    handleErrors(updateOrderAddressData, dispatch);
  }, [updateOrderAddressData?.isError]);

  const handleNextmodalOpen = () => {
    // setReview(true)
  };

  
  const onCancelcallBack = () => {
    setCancelConfirmModal(false);
  };

  const onConfirmCallBack = () => {
    if(isChange){
      setShippingAdressModal(false);
      setCancelConfirmModal(false);
      dispatch(setChange(false));
      console.log(setChange, "cccccchhhh")
      dispatch(setOrder({ id: null }))
      if(id){
        deleteOrder(id)
        console.log()
      }else{
        dispatch(clearDeviceOrderState());
      }
    }else if(!isChange){
      setCancelConfirmModal(false);
      setShippingAdressModal(false);
    }
  };

  const handleCancel = () => {
    setCancelConfirmModal(true)
  };


  useEffect(()=>{
    if(deleteOrderData?.isSuccess){
      console.log("inside Deleeeeeeeeeeee")
      dispatch(clearOrderState())
      setReview(false);
      dispatch(clearDeviceOrderState())
      dispatch(setChange(false));
      dispatch(setOrder({ id: null }))
    }
  },[deleteOrderData?.isSuccess])

  useEffect(() => {
    if (deleteOrderData?.isError) {
      handleErrors(deleteOrderData, dispatch);
    }
  }, [deleteOrderData?.isError]);

  return (
    <>
      <Modal
        handleClose={handleCancel}
        primaryText={"Enter Shipping Address"}
        open={shippingAddressModal}
        xs={10.3}
        sm={10.3}
        md={10.3}
        lg={10.3}
        xl={10.3}
        className="input-padding modal-title outer-pd-device reviewOrderModal"
      >
        <div className="addEditBenificiary">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="policyConditions">
              <Grid container columnSpacing={4} rowSpacing={1}>
                <Grid item xs={12} className="billingHeading">
                  <span>Enter Device Shipping Address</span>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Controller
                    name="fullName"
                    control={control}
                    rules={{
                      required: "Please enter full name",
                      pattern: {
                        value: RegExpressions?.FullName,
                        message:
                          "Enter a valid name with alphanumeric characters only.",
                      },
                    }}
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="Full Name"
                        value={field.value}
                        placeholder="Name of contact at delivery address"
                        required={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={4} lg={2} sx={{ alignSelf: "self-end" }}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <CountryCodeSelectInput
                        {...field}
                        value={`${field.value}`}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        required={true}
                        disabled
                        onChange={field.onChange}
                        labelClassName="countryCodeSelect"
                        inputRef={ref}
                        label="Country Code"
                        optionValue={cCodes}
                        placeholder="Select"
                        isCountryCode={true}
                      />
                    )}
                    name="countryCode"
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                </Grid>
                <Grid item xs={8} lg={4}>
                  <Controller
                    name="mobileNumber"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter a valid 10-digit mobile number",
                      },
                      pattern: {
                        value: RegExpressions?.MobileNumber,
                        message: "Please enter a valid 10-digit mobile number",
                      },
                    }}
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        placeholder="XXX-XXX-XXXX"
                        label="Mobile Number"
                        value={field.value}
                        required={true}
                        error={Boolean(error)}
                        onKeyDown={(e: any) => setKey(e.key)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleMobileNumber(e);
                        }}
                        inputRef={ref}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="House No, Street"
                        value={field.value}
                        required={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                      />
                    )}
                    name="shippingHouseNo"
                    control={control}
                    rules={{
                      required: "Please enter house No, street",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="Area, Locality"
                        value={field.value}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                      />
                    )}
                    name="shippingArea"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="Town/City"
                        value={field.value}
                        required={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                      />
                    )}
                    name="shippingTown"
                    control={control}
                    rules={{
                      required: "Please enter town/city",
                      pattern: {
                        value: RegExpressions?.noSpaceatBeginningAndEnd,
                        message: "Please enter valid town/city",
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={3}
                  className="selectInputAlign stateMarginTop"
                >
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <SelectInput
                        {...field}
                        value={`${field.value}`}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        required={true}
                        onChange={field.onChange}
                        inputRef={ref}
                        label="State"
                        optionValue={indiaStateNames}
                        placeholder="Select"
                      />
                    )}
                    name="shippingState"
                    control={control}
                    rules={{
                      required: "Please select state ",
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="Pincode"
                        value={field.value}
                        required={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                      />
                    )}
                    name="shippingPincode"
                    control={control}
                    rules={{
                      required: "Please enter Pincode",
                      pattern: {
                        value: RegExpressions?.PinCode,
                        message: "Please enter valid pin code",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="Country"
                        disabled
                        value={"India"}
                        required={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                      />
                    )}
                    name="shippingCountry"
                    control={control}
                    rules={{
                      required: "Please enter Country",
                    }}
                  />
                </Grid>
                <Grid container justifyContent={"flex-end"}>
                  <Grid item xs={12} sm={11.3} md={6} lg={2.6} xl={2.7}>
                    <Buttons
                      buttonType="submit"
                      variant="contained"
                      text="Next"
                      type="primary"
                      // onClick={handleNextmodalOpen}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </form>
        </div>
      </Modal>
{subscriptionDeviceReview && 
      <DeviceReviewOrderModal
      // open={subscriptionDeviceReview}
        review={subscriptionDeviceReview}
        setReview={setReview}
        setShippingAdressModal={setShippingAdressModal}
        planData={planData}
      />
}

        <div className="modelModal">
          <ConfirmModal
            className="confirmModal"
            key="signOut"
            open={cancelConfirmModal}
            primaryText={"Are you sure you want to cancel the order?"}
            cancelButton="NO"
            confirmButton="YES"
            cancelCallback={onCancelcallBack}
            confirmCallback={onConfirmCallBack}
          />
        </div>
    </>

  );
};
