import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import storage from "redux-persist/lib/storage";
import authReducer from "../redux/features/auth/authSlice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import userReducer from "./features/user/userSlice";
import countryReducer from "./features/country/countrySlice";
import userStepReducer from "./features/userStep/userStepSlice";
import orderReducer from "./features/order/orderSlice";
import alertReducer from "./features/alert/alertSlice";
import beneficiaryReducer from "./features/user/beneficiarySlice";
import roleReducer from "./features/role/roleSlice";
import deviceOrderReducer from "./features/deviceOrder/deviceOrderSlice";
import changeSubscriptionReducer from "./features/changeSubscription/changeSubscriptionSlice";
import filterReducer from "./features/filters/filtersSlice";
import { authApiSlice } from "./services/authApiSlice";
import { apiSlice } from "./services/apiSlice";
import { planService } from "./services/planApiSlice";
import { orderApiSlice } from "./services/orderApiSlice";
import { accountSettingsService } from "./services/accountsettingsApiSlice";
import { userApiSlice } from "./services/userApiSlice";
import { roleApiSlice } from "./services/roleApiSlice";
import { manageCouponApiSlice } from "./services/manageCouponApiSlice";
import { tenantManagementApiSlice } from "./services/tenantManagementApiSlice";
import { notificationApiSlice } from "./services/notificationApiSlice";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["api","alert"],
};

export const rootReducers = combineReducers({
  // Add the generated reducer as a specific top-level slice
  [apiSlice.reducerPath]: apiSlice.reducer,
  [authApiSlice.reducerPath]: authApiSlice.reducer,
  [planService.reducerPath]: planService.reducer,
  [orderApiSlice.reducerPath]: orderApiSlice.reducer,
  [accountSettingsService.reducerPath]: accountSettingsService.reducer,
  [userApiSlice.reducerPath]: userApiSlice.reducer,
  [roleApiSlice.reducerPath]:roleApiSlice.reducer,
  [manageCouponApiSlice.reducerPath]:manageCouponApiSlice.reducer,
  [tenantManagementApiSlice.reducerPath]:tenantManagementApiSlice.reducer,
  [notificationApiSlice.reducerPath]:notificationApiSlice.reducer,
  auth: authReducer,
  userSteps: userStepReducer,
  user: userReducer,
  alert: alertReducer,
  order: orderReducer,
  beneficiary: beneficiaryReducer,
  role: roleReducer,
  deviceOrder: deviceOrderReducer,
  changeSubscription: changeSubscriptionReducer,
  filters:filterReducer,
  countries:countryReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      apiSlice.middleware,
      authApiSlice.middleware,
      planService.middleware,
      orderApiSlice.middleware,
      accountSettingsService.middleware,
      userApiSlice.middleware,
      roleApiSlice.middleware,
      manageCouponApiSlice.middleware,
      tenantManagementApiSlice.middleware,
      notificationApiSlice.middleware,
    ]),
  devTools: true,
});

setupListeners(store.dispatch);
