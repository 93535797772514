import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { reviewCheck } from "../../../../../assets/images";
import { OrderStatus } from "../../../../../constants/enum";
import { clearDeviceOrderState } from "../../../../../redux/features/deviceOrder/deviceOrderSlice";
import { useGetOrderByOrderIdQuery } from "../../../../../redux/services/orderApiSlice";
import { Page } from "../../../../../routes/config";
import { handleErrors } from "../../../../../utils";
import Buttons from "../../../../common/button";
import { clearChangeSubscription, setSubscriptionReviewModal } from "../../../../../redux/features/changeSubscription/changeSubscriptionSlice";
import { setOrder } from "../../../../../redux/features/order/orderSlice";
import { setChange } from "../../../../../redux/features/user/beneficiarySlice";

var interval: any = null;

export const DeviceOrderConfirmationModal = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { open, setOpen, deviceOrderId } = props;
  const { data, refetch, isError, error, isLoading, isSuccess } =
    useGetOrderByOrderIdQuery(deviceOrderId, {
      skip: open === false,
      refetchOnMountOrArgChange: true,
    });
    console.log(data, "dddaatata")
  const [pending, setPending] = useState(true);
  const { userInfo } = useSelector((state: any) => state.auth);

  const handleClose = () => {
    setOpen(false);
    dispatch(clearDeviceOrderState());
    navigate(data?.result?.remarks === "device purchased" ? Page.SETTINGSMANAGEDEVICES : Page.ACCOUNTSETTING_MANAGESUBSCRIPTIONPLAN);
  };
  useEffect(() => {
    if (open) {
      dispatch(setSubscriptionReviewModal({
        subscriptionDeviceReview: false
      }))
    }
  }, [open]);

  const handleClick = () => {
    setOpen(false);
    dispatch(setChange(false));
    dispatch(clearDeviceOrderState());
    dispatch(clearChangeSubscription());
    dispatch(setOrder({ id: null }))
    navigate(data?.result?.remarks === "device purchased" ? Page.SETTINGSMANAGEDEVICES : Page.ACCOUNTSETTING_MANAGESUBSCRIPTIONPLAN);
    // navigate(Page.ACCOUNTSETTING_MANAGESUBSCRIPTIONPLAN);
  };

  useEffect(() => {
    if (open) {
      interval = setInterval(() => {
        refetch();
      }, 4000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [open]);

  useEffect(() => {
    if (isSuccess) {
      const { result } = data;
      if (result?.orderStatus == OrderStatus?.CONFIRMED) {
        setPending(false);
        clearInterval(interval);
      }
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      handleErrors({ error, isError }, dispatch);
    }
  }, [isError]);

  return (
    <>
      <Dialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="OrderConformationModal"
      >
        {!pending ? (
          <div className="OrderConformationContainer">
            <div className="orderConfImg">
              <img src={reviewCheck} />
            </div>
            {data?.result?.remarks === "device purchased" ?
              (<div className="orderConfPrimaryText">
                Thank you for placing the order for devices. You can check the status of the shipment on your order history page.
              </div>) :
              (<div className="orderConfPrimaryText">
                The new biometric device has been added to your subscription. You can check the status of the shipment on your order history page.
              </div>)
            }

            {/* {data?.result?.remarks === "device purchased" ?
              (<div className="orderConfSecondaryText">
                A confirmation email has been sent to your email address ({userInfo?.email})
              </div>) :
              (<div className="orderConfSecondaryText">
                A confirmation will be sent to your email ({userInfo?.email})
              </div>)
            } */}

            <div className="marginTopBtn">
              <Buttons
                onClick={handleClick}
                variant="contained"
                type="primary"
                text="OK"
                className="OrderConfBtn"
              />
            </div>

          </div>
        ) : (
          <div className="OrderConformationContainer text1">
            <div className="pendingOrderConfImg">
              <AccessTimeFilledIcon className="pendingIcon" />
            </div>
            <div className="orderConfPrimaryText">
              Order is placed, Please wait while we get confirmation from our
              payment partner.
            </div>
          </div>
        )}
      </Dialog>
    </>
  );
};
