import { Backdrop, Box } from "@mui/material";
import React, { useState } from "react";
import { logoIcon } from "../../../assets/icons";
import "./style.css"
const Loader = (props:any) => {
    const {show,showImage=true}= props;

  return (
    <Backdrop
    className="LoaderBackdrop"
      sx={{
        color: "white",
        zIndex: (theme) => theme.zIndex.drawer + 19999999,
      }}
      open={show}
    >
      {showImage?<Box className="loaderBox">
        <img className="loader" src={logoIcon} />
      </Box>:<></>}
    </Backdrop>
  );
};

export default Loader;
