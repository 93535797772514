import "./style.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import downloadIcon from "../../../assets/icons/download.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { SEARCHBAR } from "../../common/search/searchbar";
import { SelectInput } from "../../common/selectinput";
import { useForm } from "react-hook-form";
import Buttons, { UploadButtons } from "../../common/button";
import {
  GridColDef,
  GridMoreVertIcon,
  GridPaginationModel,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Table } from "../../common/table";
import {
  useGetAllOrdersQuery,
  useImportOrdersMutation,
} from "../../../redux/services/orderApiSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { Page } from "../../../routes/config";
import { OrderStatus } from "../../../constants/enum";
import { apiRoutes, baseURL } from "../../../config/api";
import {
  addressFormat,
  handleErrors,
  phoneNumberAutoFormat,
} from "../../../utils";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import Loader from "../../common/loader/loader";
import {
  setManageOrdersFilters,
  setPaginationFilter,
} from "../../../redux/features/filters/filtersSlice";
import { AnyARecord } from "dns";
import { CustomToolTip } from "../../common/tooltip";

export const ManageOrder = () => {
  const filtersData = useSelector((state: any) => state.filters);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowData, setRowData] = useState<any>([]);
  const [rowCount, setRowCount] = useState(0);
  const [selectedRowData, setSelectedRowData] = useState<any>();
  const [importOrder, importOrderData] = useImportOrdersMutation();
  const [viewOrder, setViewOrder] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({ mode: "onChange" || "onSubmit" });
  const [tableHeight, setTableHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [searchInput, setSerchInput] = useState<string>(
    filtersData?.manageOrderSearch
  );
  const { userInfo } = useSelector((state: any) => state?.auth);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [validateToDate, setValidateToDate] = useState<boolean>(false);
  const [validateFromDate, setValidateFromDate] = useState<boolean>(false);
  const [dateDisabled, setDateDisabled] = useState(true);
  const formData = new FormData();
  const dispatch = useDispatch();

  const orderStatusOptions = [
    { text: "Confirmed", value: OrderStatus.CONFIRMED },
    { text: "Delivered", value: OrderStatus.DELIVERED },
    { text: "InTransit", value: OrderStatus.INTRANSIT },
    { text: "Partially Delivered", value: OrderStatus.PARTIALLY_DELIVERED },
    { text: "Pending", value: OrderStatus.PAYMENT_PENDING },
  ];

  const [paginationModel, setPaginationModel] = useState({
    pageSize: filtersData?.pageSize,
    page: filtersData?.page,
  });

  const [filterData, setFilterData] = useState<any>({
    search: filtersData?.manageOrderSearch,
    from: filtersData?.fromDate,
    to: filtersData?.toDate,
    orderStatus: filtersData?.orderStatus,
  });

  const handleChange = (e: any, { type }: any) => {
    if (type === "fromDate") {
      if (isNaN(dayjs(e).year())) {
        setValidateFromDate(true);
      } else {
        setValidateFromDate(false);
        setFilterData({
          ...filterData,
          ["from"]: dayjs(e)?.format("YYYY-MM-DD"),
          ["to"]: null,
        });
        setDateDisabled(false);
      }
    } else if (type === "toDate") {
      if (isNaN(dayjs(e).year())) {
        setValidateToDate(true);
      } else {
        setValidateToDate(false);
        setFilterData({
          ...filterData,
          ["to"]: dayjs(e)?.format("YYYY-MM-DD"),
        });
      }
    } else if (type === "search") {
      if (e?.target?.value == null || e?.target?.value == "") {
        setSerchInput(e?.target?.value);
        setDisabled(true);
        setFilterData({ ...filterData, ["search"]: "" });
      } else {
        setSerchInput(e?.target?.value);
      }
    } else {
      const { name, value } = e?.target;
      setFilterData({ ...filterData, [name]: value });
    }
  };

  useEffect(() => {
    dispatch(setManageOrdersFilters(filterData));
  }, [filterData]);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
    dispatch(setPaginationFilter(newPaginationModel));
  };

  const manageOrderStatus = (status: string) => {
    return (
      <>
        {status === OrderStatus.CONFIRMED ? (
          <label className="statusConfirmed">Confirmed</label>
        ) : status === OrderStatus.PAYMENT_PENDING ? (
          <label className="statusPending">Pending</label>
        ) : status === OrderStatus.INTRANSIT ? (
          <label className="statusInTransit">InTransit</label>
        ) : status === OrderStatus.PARTIALLY_DELIVERED ? (
          <label className="statusPartiallyDelivered">
            Partially Delivered
          </label>
        ) : status === OrderStatus.DELIVERED ? (
          <label className="statusDelivered">Delivered</label>
        ) : (
          <label className="">{status}</label>
        )}
      </>
    );
  };

  useEffect(() => {
    if (searchInput !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [searchInput]);

  // API INTEGRATION

  const getFilteredParams = () => {
    var data = {};
    data = Object.fromEntries(
      Object.entries(filterData).filter(([_, v]) => v != "" && v != null)
    );
    return data;
  };
  const {
    data,
    isSuccess,
    refetch,
    isError,
    fulfilledTimeStamp,
    error,
    isLoading,
  } = useGetAllOrdersQuery({
    limit: paginationModel?.pageSize,
    page: paginationModel?.page + 1,
    ...getFilteredParams(),
  });

  const handleMenuClick = (event: any, row: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedRowData(rowData[row]);
  };

  const columns: GridColDef[] = [
    {
      field: "orderNumber",
      headerName: "Order No.",
      minWidth: 200,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
    },
    {
      field: "fullName",
      headerName: "Subscriber",
      headerAlign: "left",
      align: "left",
      minWidth: 350,
      cellClassName: "cell-padding topAlign",
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <div>
            <p className="boldText textWordBreak textCapital">
              {params?.row?.fullName}
            </p>
            <p className="p-0">
              {" "}
              <span className="lightText">Mobile Number: </span>
              <span className="mobileNoStyle textWordBreak">
                &nbsp;{params?.row?.countryCode}&nbsp;
                {phoneNumberAutoFormat(params?.row?.mobileNumber)}
              </span>
            </p>
            <p className="lightText textWordBreak">
              Email: {params?.row?.email}
            </p>
          </div>
        );
      },
    },
    {
      field: "orderaddresses",
      headerName: "Shipping Address",
      minWidth: 350,
      headerAlign: "left",
      cellClassName: "cell-padding",
      align: "left",
      sortable: false,
      flex: 2,
      renderCell: (params) => {
        if(params?.row?.orderType === "Upgrade"){
          return (
            <>
              <span>-</span>
            </>
          );
        } else{
        return (
          <>
            {params?.row?.orderaddresses ? (
              <div>
                <p className="boldText textWordBreak textCapital">
                  {params?.row?.orderaddresses[0]?.fullName}
                </p>
                <p className="p-0">
                  <span className="lightText">Mobile Number: </span>
                  <span className="mobileNoStyle textWordBreak">
                    &nbsp;{params?.row?.orderaddresses[0]?.countryCode}&nbsp;
                    {phoneNumberAutoFormat(
                      params?.row?.orderaddresses[0]?.mobileNumber
                    )}
                    ,&nbsp;
                  </span>
                </p>
                <p className="mobileNoStyle textWordBreak">
                  {addressFormat(params?.row?.orderaddresses[0])}
                  {/* {params?.row?.orderaddresses[0]?.address1}
              , {params?.row?.orderaddresses[0]?.address2},   {params?.row?.orderaddresses[0]?.city}, {params?.row?.orderaddresses[0]?.state},&nbsp;{params?.row?.orderaddresses[0]?.zipCode},&nbsp;{params?.row?.orderaddresses[0]?.country} */}
                </p>{" "}
              </div>
            ) : (
              <></>
            )}
          </>
        );
        }
      },
    },
    {
      field: "name",
      headerName: "Order Details",
      headerAlign: "left",
      align: "left",
      headerClassName: "lineBreak",
      cellClassName: "cell-padding textCapital",
      minWidth: 300,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return(
          <>
          {/* {params?.row?.name} {" "} */}
          {params?.row?.remarks !== null ? 
          (params?.row?.remarks):
          params?.row?.name ?
            (params?.row?.name) : 
            ( `${params?.row?.orderType} Order`  ) 
             }
          </>
        )
      }
    },
    {
      field: "orderDateTime",
      headerName: "Order Date/Time",
      headerAlign: "left",
      cellClassName: "cell-padding",
      align: "left",
      minWidth: 200,
      sortable: false,
      flex: 1,
    },
    {
      field: "orderStatus",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      cellClassName: "cell-padding",
      minWidth: 170,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        // if(params?.row?.orderStatus === "Confirmed"){
        //     return("Confirmed")
        // }else if(params?.row?.orderStatus === "PaymentPending"){
        //     return("Pending")
        // }else{
        //     return("Deliverd")
        // }

        return manageOrderStatus(params?.row?.orderStatus);
      },
    },
    {
      field: "trackingNumber",
      headerName: "Tracking Number",
      headerAlign: "left",
      cellClassName: "cell-padding",
      align: "left",
      minWidth: 220,
      sortable: false,
      flex: 1,
    },
    {
      field: "trackingUrl",
      headerName: "Tracking URL",
      headerAlign: "left",
      cellClassName: "cell-padding",
      align: "left",
      minWidth: 250,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            <div className="sliceWidth">
              <CustomToolTip title={params?.row?.trackingUrl} placement="top">
                <p className="trackingUrlColor">
                  <a
                    className="export-link"
                    onClick={(event: any) => event.stopPropagation()}
                    href={params?.row?.trackingUrl}
                    target="_blank"
                  >
                    {params?.row?.trackingUrl}
                  </a>
                </p>
              </CustomToolTip>
            </div>
          </div>
        );
      },
    },
    // {
    //   field: 'paymentmode',
    //   headerName: 'Payment Mode',
    //   headerAlign: "left",
    //   align: "left",
    //   cellClassName: "cell-padding",
    //   minWidth: 200,
    //   sortable: false, flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         <p>Credit Card<br />*** *** *** 6874</p>
    //         <p></p>
    //       </div>
    //     )
    //   }
    // },
   
    {
      field: "downloadAction",
      headerName: "Download Invoice",
      headerAlign: "center",
      cellClassName: "cell-padding ",
      align: "center",
      headerClassName: "downloadInvoiceHeader",
      minWidth: 150,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const isUpgrade = params?.row?.orderType === "Upgrade";
      
        if (isUpgrade) {
          return (
            <span>-</span>
          );
        } else if (params?.row?.orderStatus !== OrderStatus?.PAYMENT_PENDING) {
          return (
            <a
              onClick={(event: any) => event.stopPropagation()}
              href={`${baseURL}${apiRoutes.DOWNLOAD_ORDER_INVOICE}/${params?.row?.orderId}?isSystem=true`}
              className="export-link"
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              <IconButton>
                <img src={downloadIcon} />
              </IconButton>
            </a>
          );
        } else {
          return (<></>);
        }
      }
    },
    {
      field: "action",
      sortable: false,
      align: "left",
      cellClassName: "stickyCell cell-padding",
      headerAlign: "left",
      headerClassName: "stickyColumn",
      headerName: "",
      width: 0.5,
      renderCell: (params) => (
        <IconButton
          onClick={(event: any) => handleMenuClick(event, params?.row?.id)}
        >
          <GridMoreVertIcon />
        </IconButton>
      ),
    },
  ];

  const calHeight = () => {
    let targetComp,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetComp = window.document.querySelector<HTMLElement>(".manageOrder");
    targetElement = targetComp?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 25);
    setTableHeight(tableHeight);
  };

  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
      // setScreenWidth(window.innerWidth);
    });
    resizeObserver.observe(document.body);
    if (filtersData?.fromDate) {
      setDateDisabled(false);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleClose = (event: any) => {
    setAnchorEl(null);
  };

  const handleView = () => {
    navigate(Page.VIEWORDER, {
      state: { edit: false, orderData: selectedRowData },
    });
    setViewOrder(true);
    setOpen(true);
  };

  const handleRowClick = (rowData: any) => {
    navigate(Page.VIEWORDER, {
      state: { edit: false, orderData: rowData },
    });
    setViewOrder(true);
    setOpen(true);
  };
  const handleEdit = (row: any) => {
    navigate(Page.VIEWORDER, {
      state: { edit: true, orderData: selectedRowData },
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setRowData(
        data?.result.map((row: any, index: any) => ({
          id: index,
          orderDateTime: dayjs(row?.createdAt)?.format("DD MMM, YYYY HH:mm A"),
          ...row,
        }))
      );
      setRowCount(data?.count);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError) {
      handleErrors({ error, isError }, dispatch);
    }
  }, [isError]);

  const handleSearch = (e: any) => {
    e?.preventDefault();
    // handle search call by using searchInput value
    setFilterData({ ...filterData, ["search"]: searchInput.toLowerCase() });
  };

  const handleUpload = (e: any) => {
    if (e?.target?.files?.length != 0) {
      formData.append("file", e?.target?.files[0]);
      importOrder(formData);
      e.target.value = null;
    }
  };

  useEffect(() => {
    if (importOrderData?.isSuccess) {
      if (
        importOrderData?.data?.result?.failedCount &&
        importOrderData?.data?.result?.failed?.length != 0
      ) {
        dispatch(
          setAlert({
            alertType: "error",
            message: `Failed count=${importOrderData?.data?.result?.failedCount} Success count=${importOrderData?.data?.result?.successCount}   `,
            open: true,
          })
        );
        refetch();
      } else {
        dispatch(
          setAlert({
            alertType: "success",
            message: `Failed count=${importOrderData?.data?.result?.failedCount} Success Count=${importOrderData?.data?.result?.successCount}   `,
            open: true,
          })
        );
        refetch();
      }
    }
  }, [importOrderData?.isSuccess]);

  useEffect(() => {
    if (importOrderData?.isError) {
      handleErrors(importOrderData, dispatch);
    }
  }, [importOrderData?.isError]);
  const handleReset = () => {
    setFilterData({
      search: "",
      from: null,
      to: null,
      orderStatus: "",
    });
    setSerchInput("");
  };
  const location = useLocation();

  return (
    <>
      <Loader show={isLoading || importOrderData?.isLoading} />
      <Box>
        <Grid
          container
          rowSpacing={1.2}
          columnSpacing={screenWidth > 1199 && screenWidth < 1536 ? 2 : 3.75}
          marginBottom="3vh"
          justifyContent={"end"}
          className="manageOrderHeader"
        >
          <Grid item xs={12} sm={8} md={4.2} lg={3} xl={2.5}>
            <span className="manageOrderHeading" data-testid="manage-title">
              Manage Orders
            </span>
            <span className="countFonts">({rowCount})</span>
          </Grid>
          <Grid item xs={6} sm={4} md={2.85} lg={2} xl={1.5} order={{ xs: 1 }}>
            {" "}
            <SEARCHBAR
              key="search-bar"
              value={searchInput}
              onChange={(e: any) => {
                handleChange(e, { type: "search" });
              }}
              setValue={setSerchInput}
              handleSearchClick={(e: any) => handleSearch(e)}
              disabled={disabled}
              placeholder="Search order no"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={4.71}
            lg={3.51}
            xl={2.88}
            order={{ xs: 3, sm: 2 }}
          >
            <Grid
              container
              columnSpacing={
                screenWidth > 1199 && screenWidth < 1536 ? 2 : 3.75
              }
              rowSpacing={1.2}
            >
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="manageOrderDatePicker"
                    label="From"
                    format="DD/MM/YYYY"
                    closeOnSelect={true}
                    value={
                      filtersData?.fromDate
                        ? dayjs(filtersData?.fromDate, "YYYY-MM-DD")
                        : null
                    }
                    defaultValue={null}
                    disableFuture={true}
                    onChange={(e: any) => {
                      handleChange(e, { type: "fromDate" });
                    }}
                  />
                </LocalizationProvider>
                {validateFromDate ? (
                  <FormHelperText className="helper-text">
                    Enter a Valid Date
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    className="manageOrderDatePicker"
                    format="DD/MM/YYYY"
                    label="To"
                    value={
                      filtersData?.toDate
                        ? dayjs(filtersData?.toDate, "YYYY-MM-DD")
                        : null
                    }
                    closeOnSelect={true}
                    disableFuture={true}
                    defaultValue={null}
                    disabled={dateDisabled}
                    onChange={(e: any) => {
                      handleChange(e, { type: "toDate" });
                    }}
                    minDate={dayjs(filtersData?.fromDate, "YYYY-MM-DD")}
                  />
                </LocalizationProvider>
                {validateToDate ? (
                  <FormHelperText className="helper-text">
                    Enter a Valid Date
                  </FormHelperText>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            md={4.2}
            lg={2}
            xl={1.742}
            order={{ xs: 2, sm: 3 }}
          >
            <SelectInput
              value={filtersData?.orderStatus}
              className="filterStyle"
              placeholder="Select order status"
              onChange={handleChange}
              name="orderStatus"
              optionValue={orderStatusOptions}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            md={2.85}
            lg={1.4}
            xl={1.1}
            order={{ xs: 3 }}
          >
            <Buttons
              startIcon="reset"
              variant="contained"
              type="primary"
              className="resetButton"
              text="Reset"
              onClick={handleReset}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={4.71}
            lg={3.4}
            xl={2.2}
            order={{ xs: 4 }}
          >
            <Grid container columnSpacing={2.5} rowSpacing={1.2}>
              <Grid item xs={6}>
                <label htmlFor="upload-photo">
                  <input
                    onChange={handleUpload}
                    style={{ display: "none" }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    accept=".xlsx, .xls, .csv"
                  />
                  <UploadButtons
                    // buttonType="submit"
                    variant="contained"
                    text="IMPORT"
                    type="primary"
                  ></UploadButtons>
                </label>
              </Grid>
              <Grid item xs={6}>
                <a
                  href={`${baseURL}${apiRoutes.ORDER_EXPORT}/${
                    userInfo?.userId
                  }${
                    Object.keys(getFilteredParams()).length != 0 ? "?" : ""
                  }${createSearchParams(getFilteredParams())}`}
                  className="export-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <Buttons
                    buttonType="submit"
                    variant="contained"
                    text="EXPORT"
                    disabled={rowCount === 0 ? true : false}
                    type="primary"
                  />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <div className="manageOrder">
              <Table
                rowCount={rowCount}
                rows={rowData}
                columns={columns}
                isPagination={true}
                onPaginationModelChange={handlePaginationModelChange}
                pagination={paginationModel}
                tableHeight={tableHeight}
                showHover={true}
                onRowClick={(params: any) => {
                  handleRowClick(params?.row);
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ width: "4000px" }}
        >
          <MenuItem onClick={handleView}>View</MenuItem>
          {selectedRowData?.orderStatus === OrderStatus?.PAYMENT_PENDING ||
          selectedRowData?.orderStatus === OrderStatus?.DELIVERED ? (
            ""
          ) : (
            <span>
              <Divider className="divider-mp" />
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
            </span>
          )}
        </Menu>
      </Box>
    </>
  );
};
