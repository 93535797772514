import { Checkbox, Grid, ListItemText, MenuItem } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { CheckboxSelectInput, SelectInput } from "../../../common/selectinput";
import { useEffect, useRef, useState } from "react";
import { Input } from "../../../common/input";
import { RegExpressions } from "../../../../constants/regExp";
import { State } from "country-state-city";
import Buttons from "../../../common/button";
import { Modal } from "../../../common/modal/modal";
import {
  useGetDevicesMutation,
  useOrderDeviceMutation,
} from "../../../../redux/services/devicesApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleErrors, handleInputFocus } from "../../../../utils";
import { getLoggedInUser } from "../../../../redux/features/auth/authSlice";
import { ReviewOrderModal } from "./ReviewOrderModal";
import { cCodes } from "../../../../constants/constant";
import Loader from "../../../common/loader/loader";
import { setdeviceOrder } from "../../../../redux/features/deviceOrder/deviceOrderSlice";
import { useGetAccountSettingsQuery } from "../../../../redux/services/accountsettingsApiSlice";
import { setOrder } from "../../../../redux/features/order/orderSlice";

interface orderNewDevice {
  open: boolean;
  setOpen: (value: boolean) => void;
  setReviewOrderOpen: any;
  reviewOrderOpen: boolean;
  deviceOrderId: string;
  setDeviceOrderId: any;
}

export const OrderNewDevice = (props: orderNewDevice) => {
  const dispatch = useDispatch();
  const {
    open,
    setOpen,
    reviewOrderOpen,
    setReviewOrderOpen,
    deviceOrderId,
    setDeviceOrderId,
  } = props;
  const [deviceName, setDeviceName] = useState<any>([]);
  const [allCheck, setAllCheck] = useState(false);
  const [billingDetail, setBillingDetail] = useState<any>();
  const [device, setDevice] = useState<any>([]);
  const [key, setKey] = useState("");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { handleSubmit, control, setValue, reset, trigger } = useForm({
    mode: "onChange" || "onSubmit",
    defaultValues: {
      fullName: "",
      mobileNumber: "",
      houseNo: "",
      locality: "",
      city: "",
      state: "",
      pinCode: "",
      devices: [],
      country: "India",
      countryCode: "+91 IN",
    },
  });
  const userId = useSelector(getLoggedInUser);

  //Api integration
  const [getDevices, getDevicesData] = useGetDevicesMutation();
  const { data, isLoading, isSuccess } = useGetAccountSettingsQuery(userId, {
    refetchOnMountOrArgChange: true,
  });
  const [orderDevice, orderDeviceData] = useOrderDeviceMutation();

  const indiaStateNames = State.getStatesOfCountry("IN").map((states: any) => {
    return { text: states?.name, value: `${states?.isoCode}` };
  });

  useEffect(() => {
    if (open) {
      getDevices({ refetchOnMountOrArgChange: true });
    }
  }, [open]);

  useEffect(() => {
    if (isSuccess) {
      setBillingDetail(data?.result?.address);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (orderDeviceData?.isSuccess) {
      const orderData = orderDeviceData?.data?.result;
      dispatch(
        setdeviceOrder({
          orderId: orderData?.orderId,
          orderStatus: orderData?.orderStatus,
        })
      );
      setDeviceOrderId(orderData?.orderId);
      handleCancel();
      setReviewOrderOpen(true);
    }
  }, [orderDeviceData?.isSuccess]);

  useEffect(() => {
    if (getDevicesData?.isSuccess) {
      let data: any = [];
      getDevicesData?.data?.result?.map((x: any) => {
        data.push({
          text: x?.biometricType,
          description: x?.model,
          value: x?.deviceTypeId,
        });
      });
      setDeviceName(data);
    }
  }, [getDevicesData?.isSuccess]);

  useEffect(() => {
    handleErrors(getDevicesData, dispatch);
  }, [getDevicesData?.isError]);

  useEffect(() => {
    handleErrors(orderDeviceData, dispatch);
  }, [orderDeviceData?.isError]);

  const onDeviceBundleChange = (e: any, field: any) => {
    const { value } = e?.target;
    var check = value.indexOf("all");
    if (check !== -1 && allCheck === false) {
      let data: any = [];
      deviceName?.map((x: any) => {
        data.push({
          text: x?.text,
          description: x?.description,
          value: x?.value,
        });
      });
      setDevice(data);
      setAllCheck(true);
    } else if (check !== -1 && allCheck === true) {
      setDevice([]);
      setAllCheck(false);
    } else {
      if (allCheck === true) {
        let data = deviceName?.filter((x: any) => {
          return x.value !== value[value?.length - 1]?.value;
        });
        setAllCheck(false);
        setDevice(data);
      } else {
        setDevice(value);
      }
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setDevice([]);
    reset();
    dispatch(setOrder({ id: null }))
  };

  const handleOrderDevice = (data: any) => {
    let selectedDevices: any = [];
    device?.map((x: any) => {
      selectedDevices.push(x?.value);
    });

    var requestData: any = {
      userId: userId,
      devices: selectedDevices,

      billing: {
        houseNo: billingDetail?.address1,
        locality: billingDetail?.address2,
        city: billingDetail?.city,
        state: billingDetail?.state,
        zipCode: billingDetail?.zipCode,
        country: billingDetail?.country,
      },

      shipping: {
        fullName: data?.fullName,
        mobileNumber: data?.mobileNumber?.replaceAll("-", ""),
        houseNo: data?.houseNo,
        locality: data?.locality,
        city: data?.city,
        state: data?.state,
        zipCode: data?.pinCode,
        country: data?.country,
        countryCode: data?.countryCode?.split(" ")[0]
          ? data?.countryCode?.split(" ")[0]
          : "",
      },
    };
    orderDevice(requestData);
  };

  useEffect(() => {
    // setValue("country", "India");
    // setValue("countryCode", "+91 IN");
  }, []);

  const handleMobileNumber = (e: any) => {
    var value = handleInputFocus(e, key);
    setValue("mobileNumber", value);
    trigger("mobileNumber");
  };

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef?.current?.focus();
      }
    }, 0);
  }, [open]);

  return (
    <>
      <Loader
        show={
          getDevicesData?.isLoading || isLoading || orderDeviceData?.isLoading
        }
      />
     
      <Modal
        handleClose={handleCancel}
        primaryText={"Order New Device"}
        open={open}
        className="input-padding modal-title outer-pd-device"
      >
        <form onSubmit={handleSubmit(handleOrderDevice)}>
          <Grid container>
            <Grid item xs={12} className="orderDeviceModalGridItem">
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <CheckboxSelectInput
                    {...field}
                    label="Device"
                    required={true}
                    placeholder="Select devices to order"
                    value={device}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={(e: any) => {
                      field.onChange(e);
                      onDeviceBundleChange(e, field);
                    }}
                    inputRef={(e: any) => {
                      ref(e);
                      inputRef.current = e;
                    }}
                    multiple={true}
                  >
                    {deviceName && deviceName?.length>0?<MenuItem key="all" value="all">
                      <div>
                        <ListItemText primary="All" />
                      </div>
                      <Checkbox className="checkboxBox" checked={allCheck} />
                    </MenuItem>:null}
                    {deviceName?.map((option: any) => {
                      return (
                        <MenuItem key={option?.value} value={option}>
                          <div>
                            <ListItemText primary={option?.text} />
                            <ListItemText secondary={option?.description} />
                          </div>
                          <Checkbox
                            className="checkboxBox"
                            checked={
                              device.findIndex(
                                (item: any) => item.value === option.value
                              ) >= 0
                            }
                          />
                        </MenuItem>
                      );
                    })}
                  </CheckboxSelectInput>
                )}
                name="devices"
                control={control}
                rules={{
                  required: "Please select atlest one device to order ",
                }}
              />
            </Grid>
            <Grid item xs={12} className="orderDeviceModalfirstGridItem">
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Full Name"
                    value={field.value}
                    required={true}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                    placeholder="First and last name"
                  />
                )}
                name="fullName"
                control={control}
                rules={{
                  required: "Please enter full name",
                  pattern: {
                    value: RegExpressions?.FullName,
                    message:
                      "Enter a valid name with alphanumeric characters only.",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} className="orderDeviceModalfirstGridItem">
              <Grid container columnSpacing={4}>
                <Grid item xs={3} sx={{ alignSelf: "self-end" }}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <SelectInput
                        {...field}
                        value={`${field.value}`}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        required={true}
                        disabled
                        onChange={field.onChange}
                        labelClassName="countryCodeSelect"
                        inputRef={ref}
                        label="Country Code"
                        optionValue={cCodes}
                        placeholder="Select"
                      />
                    )}
                    name="countryCode"
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="Mobile Number"
                        value={field.value}
                        error={Boolean(error)}
                        required={true}
                        helperText={error?.message ? error?.message : " "}
                        onChange={(e: any) => {
                          field.onChange(e);
                          handleMobileNumber(e);
                        }}
                        placeholder="Mobile number of the delivery contact"
                        inputRef={ref}
                      />
                    )}
                    name="mobileNumber"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter a valid 10-digit mobile number",
                      },
                      pattern: {
                        value: RegExpressions?.MobileNumber,
                        message:
                          "Please enter a valid 10-digit mobile number. ",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="orderDeviceModalfirstGridItem">
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="House No, Street"
                    value={field.value}
                    required={true}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                  />
                )}
                name="houseNo"
                control={control}
                rules={{
                  required: "Please enter the house no, street",
                }}
              />
            </Grid>
            <Grid item xs={12} className="orderDeviceModalfirstGridItem">
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Area, Locality"
                    value={field.value}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                  />
                )}
                name="locality"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container columnSpacing={4}>
                <Grid item xs={6} className="orderDeviceModalfirstGridItem">
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="Town/City"
                        value={field.value}
                        required={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                        placeholder=""
                      />
                    )}
                    name="city"
                    control={control}
                    rules={{
                      required: "Please select the city",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  className="orderDeviceModalGridItem selectInputAlign"
                >
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <SelectInput
                        {...field}
                        label="State"
                        required={true}
                        value={`${field.value}`}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        optionValue={indiaStateNames}
                        inputRef={ref}
                      />
                    )}
                    name="state"
                    control={control}
                    rules={{
                      required: "Please select state ",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container columnSpacing={4}>
                <Grid item xs={6} className="orderDeviceModalfirstGridItem">
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="Pincode"
                        value={field.value}
                        required={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                        placeholder=""
                      />
                    )}
                    name="pinCode"
                    control={control}
                    rules={{
                      required: "Please enter pincode",
                      pattern: {
                        value: RegExpressions?.PinCode,
                        message: "Please enter valid pin code",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} className="orderDeviceModalfirstGridItem">
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="Country*"
                        value={field.value}
                        disabled
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                      />
                    )}
                    name="country"
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="modalBtn">
              <Grid item xs={4.5}>
                <Buttons
                  type="secondary"
                  variant="contained"
                  text="CANCEL"
                  onClick={handleCancel}
                ></Buttons>
              </Grid>
              <Grid item xs={4.5}>
                <Buttons
                  type="primary"
                  variant="contained"
                  buttonType="submit"
                  text="PLACE ORDER"
                  data-testid="submit-btn"
                ></Buttons>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
};
