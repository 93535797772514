import { Grid } from "@mui/material";
import { Modal } from "../../../common/modal/modal";
import Buttons from "../../../common/button";
import { useNavigate } from "react-router-dom";
import { Page } from "../../../../routes/config";

export const UserGuideModal = (props: any) => {
  const { open, setOpen } = props;
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate(Page.MANAGEUSERS, { replace: true });
    setOpen(false);
  };
  return (
    <Modal
      handleClose={handleCancel}
      primaryText={""}
      showDivider={false}
      open={open}
      xs={11}
      sm={11}
      md={11}
      lg={11}
      xl={11}
      className="accountSetingModal addFamilyModal"
    >
      <Grid container>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <span className="userGuideHeading">
            Complete your setup by creating accounts for beneficiaries and
            family members.
          </span>
        </Grid>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <span className="userTypes">Step 1: </span>
          <span className="subtitleUsertype">
            Click on the "ADD NEW BENEFICIARY" button seen above on this page to
            add user(s) that take biometric readings.
          </span>
        </Grid>

        <Grid item xs={12} sx={{ mt: 3 }}>
          <span className="userTypes">Step 2: </span>
          <span className="subtitleUsertype">
            Next, click on the "ADD NEW FAMILY MEMBER" button to add user(s)
            that can view the Beneficiary's readings. This step is optional.
          </span>
        </Grid>
        <Grid
          style={{ display: "flex", justifyContent: "center" }}
          item
          xs={12}
          sx={{ mt: 3 }}
        >
          <Buttons
            onClick={() => navigate(Page.MANAGEUSERS, { replace: true })}
            variant="contained"
            type="primary"
            text="OK"
            className="okkBtn"
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
