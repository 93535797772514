import React, { useEffect, useState } from "react";
import "./style.css";
import { Alert, Box, Collapse, IconButton, Snackbar, SnackbarOrigin } from "@mui/material";
import { crossIcon } from "../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../redux/features/alert/alertSlice";

export const MessageAlert = (props: any) => {
  const dispatch = useDispatch();
  const { open, message, alertType } = useSelector((state: any) => state?.alert);
  const [alertValue,setAlertValue]=useState<any>("success")

  const handleClose = () => {
    dispatch(setAlert({ open: false,alertType: "", message: message }));
  };

  useEffect(()=>{
    if(alertType){
      setAlertValue(alertType);
    }
  },[alertType])


  return (
    <>
      <Snackbar open={open} autoHideDuration={3000} onClose={()=>handleClose()} anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert className="alertClass" onClose={()=>handleClose()} severity={alertValue} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  )
}