import { useEffect, useState } from "react";
import { List, Box, MenuItem, Collapse } from "@mui/material";
import "./style.css";
import { Link, useLocation } from "react-router-dom";
import { Page } from "../../../routes/config";
import { PageTitle, UserRoles } from "../../../constants/enum";
import { useDispatch, useSelector } from "react-redux";
import { useLogOutMutation } from "../../../redux/services/authApiSlice";
import { ConfirmModal } from "../confirmmodal";
import { Messages } from "../../../constants/messages";
import { clearAllStates } from "../../../utils";
import styled from "@emotion/styled";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { CustomToolTip } from "../tooltip";
import { clearFilterState } from "../../../redux/features/filters/filtersSlice";

export const StyledSideBarContainer = styled((props: any) => {
  const { children, ...rest } = props;
  return <div {...rest}>{children}</div>;
})`
  ${(props) => props.height && `height:${props.height}px !important`};
  display: flex;
  flex-direction: column;
`;

const Sidebar = () => {
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState<number>(-1);
  const location = useLocation();
  const { userInfo } = useSelector((state: any) => state?.auth);
  const [selectedIndex, setSelectedIndex] = useState<any>();
  const [currentRoute, setCurrentRoute] = useState<any>();
  const [sidebarArray, setSidebarArray] = useState<any>([]);
  const [path, setPath] = useState<any>();
  const dispatch = useDispatch();
  const [userLogOut, userLogOutData] = useLogOutMutation();
  const [signOutModal, setSignOutModal] = useState(false);
  const [sideNavHeight, setSideNaveHeight] = useState<number>(0);
  const subscriberItems = [
    {
      key: "1",
      label: PageTitle.MANAGEUSERS,
      route: Page.MANAGEUSERS,
    },
    {
      key: "2",
      label: PageTitle.ACCOUNTSETTING,
      route: Page.ACCOUNTSETTING,
    },
  ];

  const platformAdmin = [
    // {
    //   key: "1",
    //   label: PageTitle.DASHBOARD,
    //   route: Page.DASHBOARD,
    // },
    // {
    //   key: "2",
    //   label: PageTitle.REPORTS,
    //   route: Page.DUEPAYMENT,
    //   submenu: [
    //     {
    //       key: "2.1",
    //       label: PageTitle.DUEPAYMENTREPORT,
    //       route: Page.DUEPAYMENT,
    //     },
    //     {
    //       key: "2.2",
    //       label: PageTitle.SUBSCRIPTIONCANCELLATION,
    //       route: Page.SUBSCRIPTIONCANCELLATION,
    //     },
    //     {
    //       key: "2.3",
    //       label: PageTitle.SUBSCRIPTIONPENDING,
    //       route: Page.SUBSCRIPTIONPENDING,
    //     },
    //     {
    //       key: "2.4",
    //       label: PageTitle.REVENUESUMMARY,
    //       route: Page.REVENUESUMMARY,
    //     },
    //     {
    //       key: "2.5",
    //       label: PageTitle.REVENUEDETAIL,
    //       route: Page.REVENUEDETAIL,
    //     },
    //     {
    //       key: "2.6",
    //       label: PageTitle.SEVENDAYNOREADINGS,
    //       route: Page.SEVENDAYNOREADINGS,
    //     },
    //   ],
    // },
    {
      key: "3",
      label: PageTitle.MANAGEUSERS,
      route: Page.MANAGEUSERS,
    },
    {
      key: "4",
      label: PageTitle.MANAGEPLANS,
      route: Page.MANAGEPLANS,
    },
    {
      key: "5",
      label: PageTitle.MANAGECOUPONS,
      route: Page.MANAGECOUPONS,
    },
    {
      key: "6",
      label: PageTitle.MANAGEDEVICES,
      route: Page.MANAGEDEVICES,
    },
    {
      key: "7",
      label: PageTitle.MANAGEORDERS,
      route: Page.MANAGEORDERS,
    },
    {
      key: "8",
      label: PageTitle.ROLESPERMISSION,
      route: Page.ROLESPERMISSION,
    },
  ];

  const platformSupport = [
    {
      key: "1",
      label: PageTitle.MANAGEUSERS,
      route: Page.MANAGEUSERS,
    },
    {
      key: "2",
      label: PageTitle.MANAGEORDERS,
      route: Page.MANAGEORDERS,
    },
    // {
    //   key: "3",
    //   label: PageTitle.REPORTS,
    //   route: Page.DUEPAYMENT,
    //   submenu: [
    //     {
    //       key: "2.1",
    //       label: PageTitle.DUEPAYMENTREPORT,
    //       route: Page.DUEPAYMENT,
    //     },
    //     {
    //       key: "2.2",
    //       label: PageTitle.SUBSCRIPTIONCANCELLATION,
    //       route: Page.SUBSCRIPTIONCANCELLATION,
    //     },
    //     {
    //       key: "2.3",
    //       label: PageTitle.SUBSCRIPTIONPENDING,
    //       route: Page.SUBSCRIPTIONPENDING,
    //     },
    //     {
    //       key: "2.4",
    //       label: PageTitle.REVENUESUMMARY,
    //       route: Page.REVENUESUMMARY,
    //     },
    //     {
    //       key: "2.5",
    //       label: PageTitle.REVENUEDETAIL,
    //       route: Page.REVENUEDETAIL,
    //     },
    //     {
    //       key: "2.6",
    //       label: PageTitle.SEVENDAYNOREADINGS,
    //       route: Page.SEVENDAYNOREADINGS,
    //     },
    //   ],
    // },
  ];

  useEffect(() => {
    if (userInfo?.roleName == UserRoles.SUBSCRIBER) {
      setSidebarArray(subscriberItems);
    } else if (
      userInfo?.roleName == UserRoles.PLATFORM_ADMIN ||
      userInfo?.roleName == UserRoles.PLATFORM_SUPER_ADMIN
    ) {
      setSidebarArray(platformAdmin);
    } else if (userInfo?.roleName == UserRoles.PLATFORM_SUPPORT) {
      setSidebarArray(platformSupport);
    }
  }, []);

  useEffect(() => {
    setPath(location.pathname);
  }, [location.pathname]);
  useEffect(() => {
    // var path = location.pathname;
    console.log(path?.split("/"), "path inside filter");
    for (var i = 0; i < sidebarArray.length; i++) {
      if (`/${path?.split("/")[1]}` == sidebarArray[i]?.route) {
        if (
          path?.split("/")?.length == 2 &&
          `/${path?.split("/")[1]}` == Page.ACCOUNTSETTING
        ) {
          dispatch(clearFilterState());
        }
        setSelectedIndex(i);
        return;
      } else {
        setSelectedIndex(99);
      }
    }
  }, [sidebarArray, path]);

  const handleSignOut = () => {
    setSignOutModal(true);
  };
  const onCancelcallBack = () => {
    setSignOutModal(false);
  };
  const onConfirmCallBack = () => {
    setSignOutModal(false);
    userLogOut({ email: userInfo?.email });
    clearAllStates(dispatch);
    localStorage.clear();
    // window.location.href = "/";
  };

  const calHeight = () => {
    let headerElement: any,
      headerElementHeight: number,
      elementHeight: number,
      navHeight: number;
    headerElement = window.document.querySelector<HTMLElement>(".my-header");

    headerElementHeight = headerElement?.offsetHeight;
    elementHeight = window.innerHeight;
    navHeight = elementHeight - headerElementHeight;
    setSideNaveHeight(navHeight);
  };

  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
    });
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const handleListItemClick = (index: number, route: any) => {
    dispatch(clearFilterState());
    setOpenSubmenuIndex((prevIndex) => (prevIndex === index ? -1 : index));
    setSelectedIndex(index);
    setCurrentRoute(route);
  };

  return (
    <StyledSideBarContainer height={sideNavHeight}>
      <Box className="sidebarBox" sx={{ overflow: "auto" }}>
        <List className="ulList">
          {sidebarArray.map((item: any, index: number) => (
            <>
              <Link to={item?.route}>
                <MenuItem
                  selected={selectedIndex == index ? true : false}
                  onClick={() => handleListItemClick(index, item?.route)}
                  className="liList"
                  key={item.key}
                >
                  <p>
                    {item?.label}{" "}
                    {item.submenu &&
                      (openSubmenuIndex === index ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      ))}
                  </p>
                </MenuItem>
              </Link>
              {item.submenu && (
                <Collapse
                  in={openSubmenuIndex === index}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.submenu.map((submenuItem: any) => (
                      <Link to={submenuItem.route} key={submenuItem.key}>
                        <MenuItem
                          selected={location.pathname === submenuItem.route}
                          className="submenuItem"
                        >
                          <Link to={submenuItem.route}>
                            <CustomToolTip
                              title={submenuItem.label}
                              placement="top"
                              className="tooltipWidth"
                            >
                              <p className="slice">{submenuItem.label}</p>
                            </CustomToolTip>
                          </Link>
                        </MenuItem>
                      </Link>
                    ))}
                  </List>
                </Collapse>
              )}
            </>
          ))}
          {userInfo?.roleName == UserRoles.SUBSCRIBER ? (
            <MenuItem onClick={handleSignOut} className="liList">
              <span className="logoutOption">Logout</span>
            </MenuItem>
          ) : (
            <></>
          )}
        </List>
      </Box>
      <div className="modelModal">
        <ConfirmModal
          className="confirmModal"
          key="signOut"
          open={signOutModal}
          primaryText={Messages.SIGNOUT}
          cancelButton="NO"
          confirmButton="YES"
          cancelCallback={onCancelcallBack}
          confirmCallback={onConfirmCallBack}
        />
      </div>
      <div className="sideNavFooter" style={{ marginTop: "auto" }}>
        ©{new Date().getFullYear()} familyprohealth Inc. All rights reserved.
      </div>
    </StyledSideBarContainer>
  );
};

export default Sidebar;
