import { Controller, useForm } from "react-hook-form";
import { Modal } from "../../../common/modal/modal";
import { Grid } from "@mui/material";
import { RegExpressions } from "../../../../constants/regExp";
import { PasswordInput } from "../../../common/input";
import Buttons from "../../../common/button";
import { useUpdatePasswordMutation } from "../../../../redux/services/userApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import { handleErrors } from "../../../../utils";
import Loader from "../../../common/loader/loader";
import { passwordProps } from "../../../../models/common";

export const PasswordModal = (props: passwordProps) => {
  const { changePassword, setChangePassword, refetch, modal } = props;
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" || "onSubmit" });
  const userId = useSelector((state: any) => state?.auth?.userId);
  const dispatch = useDispatch();
  const [updatePassword, updatePasswordInfo] = useUpdatePasswordMutation();
  const onPasswordCancelCallBack = () => {
    setChangePassword(false);
    reset({
      oldPassword: "",
      newPassword: "",
      AgainPassword: "",
    });
  };
  const onConfirmPaswwordCallBack = (data: any) => {
    updatePassword({
      userId: userId,
      oldPassword: data?.oldPassword,
      newPassword: data?.newPassword,
    });
  };
  useEffect(() => {
    if (updatePasswordInfo?.isSuccess) {
      if (setChangePassword) {
        setChangePassword(false);
        dispatch(
          setAlert({
            open: true,
            alertType: "success",
            message: updatePasswordInfo?.data?.message,
          })
        );
      }
      if (!modal) {
        refetch();
      }
      reset({
        oldPassword: "",
        newPassword: "",
        AgainPassword: "",
      });
    }
  }, [updatePasswordInfo?.isSuccess]);

  useEffect(() => {
    if (updatePasswordInfo?.isError) {
      handleErrors(updatePasswordInfo, dispatch);
    }
  }, [updatePasswordInfo?.isError]);

  return (
    <>
      <Loader show={updatePasswordInfo?.isLoading} />
      <Modal
        className="accountSetingModal"
        key="changePsssword"
        open={changePassword}
        primaryText="Change Password"
        handleClose={() => {
          setChangePassword(false);
          reset({
            oldPassword: "",
            newPassword: "",
            AgainPassword: "",
          });
        }}
      >
        <form
          onSubmit={handleSubmit(onConfirmPaswwordCallBack)}
          className="passwordContent"
        >
          <Grid container sx={{ marginTop: "40px" }}>
            <Grid item xs={11.9}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <PasswordInput
                    {...field}
                    label="Current Password"
                    value={field.value}
                    required={true}
                    onChange={field.onChange}
                    helperText={error?.message ? error?.message : " "}
                    error={Boolean(error)}
                    inputRef={ref}
                    autoFocus={true}
                    placeholder="Enter current password"
                  />
                )}
                name="oldPassword"
                control={control}
                rules={{
                  required: "Please enter your password",
                  pattern: {
                    value: RegExpressions?.Password,
                    message: "Please enter your password",
                  },
                }}
              />
            </Grid>
            <Grid item xs={11.9}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <PasswordInput
                    {...field}
                    label="New Password"
                    value={field.value}
                    required={true}
                    onChange={field.onChange}
                    helperText={error?.message ? error?.message : " "}
                    error={Boolean(error)}
                    inputRef={ref}
                    info={true}
                    passwordInfo="newPasswordInfo"
                    placeholder="Enter new password"
                  />
                )}
                name="newPassword"
                control={control}
                rules={{
                  required: "Please enter your new password",
                  pattern: {
                    value: RegExpressions?.Password,
                    message: "Please enter your new password",
                  },
                }}
              />
            </Grid>
            <Grid item xs={11.9}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <PasswordInput
                    {...field}
                    label="Retype Password"
                    value={field.value}
                    required={true}
                    onChange={field.onChange}
                    helperText={error?.message ? error?.message : " "}
                    error={Boolean(error)}
                    inputRef={ref}
                    placeholder="Re-enter password"
                  />
                )}
                name="AgainPassword"
                control={control}
                rules={{
                  required: "Please enter the retype password",
                  validate: () => {
                    if (
                      getValues("newPassword") != getValues("AgainPassword")
                    ) {
                      return "Password and retype password does not match.";
                    }
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="modalBtn">
            <Grid item xs={6} md={5} lg={4.5}>
              <Buttons
                type="secondary"
                variant="contained"
                onClick={onPasswordCancelCallBack}
                text="CANCEL"
              ></Buttons>
            </Grid>
            <Grid item xs={6} md={5} lg={4.5}>
              <Buttons
                type="primary"
                variant="contained"
                buttonType="submit"
                text="SUBMIT"
              ></Buttons>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
};
