import { useForm } from "react-hook-form";
import { Modal } from "../../../common/modal/modal";
import { Checkbox, Divider, Grid } from "@mui/material";
import Buttons from "../../../common/button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserRoles } from "../../../../constants/enum";
import {
  useGetprefferedCommunicationQuery,
  useUpdatePrefferedCommunicationMutation,
} from "../../../../redux/services/userApiSlice";
import { handleErrors } from "../../../../utils";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import Loader from "../../../common/loader/loader";

export const PrefferedCommunicationModal = (props: any) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state: any) => state.auth);
  const { communication, setCommunication } = props;
  const [ischecked, setIsChecked] = useState(false);
  const [responseData, setResponseData] = useState<any>();
  const [updateCommunication, updateCommunicationData] =
    useUpdatePrefferedCommunicationMutation();

  const {
    data,
    isSuccess,
    refetch,
    isError,
    fulfilledTimeStamp,
    error,
    isLoading,
  } = useGetprefferedCommunicationQuery(userInfo.userId, {
    skip: communication === false,
  });

  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" || "onSubmit" });

  const labels: any = {
    ["Health"]: "Biometric Data",
    ["Campaigns"]: "Campaigns",
    ["Schedules"]: "Schedules",
    ["Subscription & Payment"]: "Subscription & Payment",
    ["User"]: "User",
    ["Orders"]: "Orders",
    ["Product & Plan"]: "Product & Plan",
  };

  const onCommunicationCancelBack = () => {
    setCommunication(false);
  };

  const onConfirmCommuniocationCallBack = () => {
    const data = [];
    for (let i = 0; i < responseData?.result?.length; i++) {
      var obj = {
        userCommPreferenceId: responseData?.result[i]?.commPreferenceId,
        communicationType: responseData?.result[i]?.communicationType,
      };
      data.push(obj);
    }

    updateCommunication(data);
  };

  const handleChange = (e: any, commData: any) => {
    if (e?.target?.checked) {
      var array = responseData?.result?.map((item: any) => {
        if (item?.notificationType == commData?.notificationType) {
          var communicationTypeArray = [...item?.communicationType];
          communicationTypeArray?.push?.apply(communicationTypeArray, [
            e?.target?.name,
          ]);
          return { ...item, communicationType: communicationTypeArray };
        }
        return item;
      });
      setResponseData({ ...responseData, result: array });
    } else {
      var array2 = responseData?.result?.map((item: any) => {
        if (item?.notificationType == commData?.notificationType) {
          var communicationTypeArray = [...item?.communicationType];
          communicationTypeArray?.splice(
            communicationTypeArray?.indexOf(e?.target?.name),
            1
          );
          return { ...item, communicationType: communicationTypeArray };
        }
        return item;
      });
      setResponseData({ ...responseData, result: array2 });
    }
  };

  useEffect(() => {
    if (updateCommunicationData?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          // message: "Preffered communication updated successfully",
          message: updateCommunicationData?.data?.result,
        })
      );
      setCommunication(false);
    }
  }, [updateCommunicationData?.isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      const modifiedData = data?.result?.filter((x: any) => {
        return x?.notificationType !== "User";
      });
      setResponseData({ ...data, result: modifiedData });
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      handleErrors({ error, isError }, dispatch);
    }
  }, [isError]);

  useEffect(() => {
    handleErrors(updateCommunicationData, dispatch);
  }, [updateCommunicationData?.isError]);

  // useEffect(() => {
  //   if(communication){
  //     refetch();
  //     setResponseData(data);
  //   }
  // },[communication]);

  return (
    <>
      <Loader show={isLoading || updateCommunicationData?.isLoading} />
      <Modal
        key="communication"
        open={communication}
        handleClose={() => {
          setCommunication(false);
        }}
        primaryText="Preferred Communication"
        className="accountSetingModal"
      >
        {data?.result?.length ? (
          <form
            data-testid="form"
            onSubmit={handleSubmit(onConfirmCommuniocationCallBack)}
          >
            <Grid container sx={{ marginTop: "40px" }}>
              <Grid item xs={12}>
                <Grid container sx={{ mb: 2 }}>
                  <Grid
                    item
                    xs={userInfo?.roleName === UserRoles.PLATFORM_ADMIN ? 8 : 6}
                  ></Grid>
                  <Grid
                    item
                    xs={userInfo?.roleName === UserRoles.PLATFORM_ADMIN ? 4 : 6}
                    justifyContent={"flex-end"}
                  >
                    <Grid container columnSpacing={2}>
                      <Grid
                        item
                        xs={
                          userInfo?.roleName === UserRoles.PLATFORM_ADMIN
                            ? 6
                            : 4
                        }
                      >
                        Email
                      </Grid>
                      <Grid
                        item
                        xs={
                          userInfo?.roleName === UserRoles.PLATFORM_ADMIN
                            ? 6
                            : 4
                        }
                      >
                        WebApp
                      </Grid>
                      {userInfo?.roleName === UserRoles.SUBSCRIBER ? (
                        <>
                          <Grid item xs={4}>
                            MobileApp
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {responseData?.result?.map((item: any, index: any) => (
                  <Grid
                    container
                    className="communicationAlign"
                    sx={{ mb: 1.5 }}
                  >
                    <Grid
                      item
                      xs={
                        userInfo?.roleName === UserRoles.PLATFORM_ADMIN ? 8 : 6
                      }
                    >
                      {labels[item?.notificationType]}
                    </Grid>
                    <Grid
                      item
                      xs={
                        userInfo?.roleName === UserRoles.PLATFORM_ADMIN ? 4 : 6
                      }
                      justifyContent={"flex-end"}
                    >
                      <Grid container columnSpacing={2}>
                        <Grid
                          item
                          xs={
                            userInfo?.roleName === UserRoles.PLATFORM_ADMIN
                              ? 6
                              : 4
                          }
                        >
                          <Checkbox
                            name="email"
                            checked={item?.communicationType?.includes("email")}
                            onChange={(e: any) => {
                              handleChange(e, item);
                            }}
                          />
                        </Grid>
                        <Grid
                          className="checkPos"
                          item
                          xs={
                            userInfo?.roleName === UserRoles.PLATFORM_ADMIN
                              ? 6
                              : 4
                          }
                        >
                          <Checkbox
                            name="web"
                            checked={item?.communicationType?.includes("web")}
                            onChange={(e: any) => {
                              handleChange(e, item);
                            }}
                          />
                        </Grid>
                        {userInfo?.roleName === UserRoles.SUBSCRIBER ? (
                          <>
                            <Grid className="checkPos" item xs={4}>
                              <Checkbox
                                name="mobile"
                                checked={item?.communicationType?.includes(
                                  "mobile"
                                )}
                                onChange={(e: any) => {
                                  handleChange(e, item);
                                }}
                              />
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <Divider sx={{ mb: 2, mt: 2.6 }} />

              <Grid container spacing={2} className="modalBtn">
                <Grid item xs={6} md={5} lg={4.6}>
                  <Buttons
                    type="secondary"
                    variant="contained"
                    onClick={onCommunicationCancelBack}
                    text="CANCEL"
                  ></Buttons>
                </Grid>
                <Grid item xs={6} md={5} lg={4.6}>
                  <Buttons
                    type="primary"
                    variant="contained"
                    buttonType="submit"
                    text="SUBMIT"
                    data-testid="submit-btn"
                  ></Buttons>
                </Grid>
              </Grid>
            </Grid>
          </form>
        ) : (
          <div className="noCommData">No Communication Options Found</div>
        )}
      </Modal>
    </>
  );
};
