export const Messages = {
  SIGNOUT: "Are you sure you want to sign out ?",
  CONFIRM: "Are you sure ?",
  CANCEL_SUBSCRIPTION: "Are you sure you want to cancel subscription?",
  DEACTIVATEPLAN: "Are you sure you want to deactivate plan ?",
  DELETEPLAN: "Are you sure you want to delete plan ?",
  ACTIVATEPLAN: "Are you sure you want to activate plan ?",
  SAVEACTIVATEPLAN: "Are you sure you want to save & activate plan ?",
  RESET_PASSWORD: "Are you sure you want to reset password?",
  DEACTIVATE: "Are you sure you want to deactivate?",
  ACTIVATE: "Are you sure you want to activate?",
  DELETECOUPON: "Are you sure you want to delete?",
  DELETEUSER:
    "Deleting the user will permanently delete all information of the user and also its associated data. Are you sure you want to delete ?",
  RESET_PASSCODE: "Are you sure you want to reset passcode?",
  BENEFICIARY:
    "Add new beneficiary not allowed, you have reached beneficiary limit for your plan.",
  ACTIVATEBENEFICARY:
    "You have reached the active beneficiary limit as per your plan. To add and activate a new beneficiary please deactivate one of the existing beneficiary.",
  UPDATEPLAN:
    "All the beneficiary data provided will be lost. Are you sure you want to change the plan?",
  DELETEBENEFICIARY: "Are you sure you want to remove?",
  DOWNGRADEPLAN1: "Are you sure you want to change the upgrade plan?",
  DOWNGRADEPLAN2:
    "Clicking YES will require you to deactivate the additional beneficiaries and then change the upgrade plan.",
  SESSIONEXPIRED: "Your session has expired. Please login again.",
  UPDATESTATUS:
    "Once the status changes to 'Delivered', you cannot change back to any other status.",
};
