import { useEffect, useState } from "react";
import { Table } from "../../common/table";
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import "./style.css";
import { FormHelperText, Grid } from "@mui/material";
import dayjs from "dayjs";
import Loader from "../../common/loader/loader";
import "./style.css";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { backIcon } from "../../../assets/icons";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetNotificationsQuery,
  useUpdateNotificationsMutation,
} from "../../../redux/services/notificationApiSlice";
import { useDispatch, useSelector } from "react-redux";
import Buttons from "../../common/button";
import {
  setNotificationsFilters,
  setPaginationFilter,
} from "../../../redux/features/filters/filtersSlice";

export const Notification = (props: any) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [tableHeight, setTableHeight] = useState(0);
  const filtersData = useSelector((state: any) => state.filters);
  const [rowData, setRowData] = useState<any>([]);
  const [notificationsData, setNotificationsData] = useState<any>([...rowData]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [validateToDate, setValidateToDate] = useState<boolean>(false);
  const [dateDisabled, setDateDisabled] = useState(true);
  const [newPaginationModel, setNewPaginationModel] = useState<any>({
    pageSize: 5,
    page: 0,
  });
  const [validateFromDate, setValidateFromDate] = useState<boolean>(false);
  const [updateNotifications, updateNotificationsData] =
    useUpdateNotificationsMutation();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: filtersData?.pageSize,
    page: filtersData?.page,
  });
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState<any>({
    from: filtersData?.fromDate,
    to: filtersData?.toDate,
  });

  const { userInfo } = useSelector((state: any) => state?.auth);
  //  console.log(user)

  // const handlePaginationModelChange = (
  //   newPaginationModel: GridPaginationModel
  // ) => {
  //   setNewPaginationModel(newPaginationModel);
  //   handleRead();
  // };

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    console.log(newPaginationModel, "newPaginationModel");
    handleRead();
    setPaginationModel(newPaginationModel);
    dispatch(setPaginationFilter(newPaginationModel));
  };

  const handleRead = () => {
    setNotificationsData(rowData);
  };

  useEffect(() => {
    if (notificationsData?.length) {
      var notifIdArray = notificationsData?.map((item: any, index: any) => {
        if (item?.isRead === false) {
          return item?.sentNotificationId;
        }
      });
      var filteredNotArray = notifIdArray.filter((x: any) => x !== undefined);

      if (filteredNotArray?.length) {
        var notifRequest = {
          userId: state ? state?.userData?.User?.userId : userInfo?.userId,
          isRead: true,
          notificationId: filteredNotArray,
        };
        updateNotifications(notifRequest);
      }
      // else {
      //   setNotificationsData([]);
      //   if (newPaginationModel) {
      //     setPaginationModel(newPaginationModel);
      //     dispatch(setPaginationFilter(newPaginationModel));
      //   }
      // }
    }
  }, [notificationsData]);

  // useEffect(() => {
  //   if (updateNotificationsData?.isSuccess) {
  //     setPaginationModel(newPaginationModel);
  //     // dispatch(setPaginationFilter(newPaginationModel));
  //     setNotificationsData([]);
  //   }
  // }, [updateNotificationsData?.isSuccess]);

  // useEffect(() => {
  //   if (updateNotificationsData?.isError) {
  //     handleErrors(updateNotificationsData, dispatch);
  //   }
  // }, [updateNotificationsData?.isError]);

  const handleChange = (e: any, { type }: any) => {
    if (type === "fromDate") {
      if (isNaN(dayjs(e).year())) {
        setValidateFromDate(true);
      } else {
        setValidateFromDate(false);
        setFilterData({
          ...filterData,
          ["from"]: dayjs(e)?.format("YYYY-MM-DD"),
          ["to"]: null,
        });
        setDateDisabled(false);
      }
    } else if (type === "toDate") {
      if (isNaN(dayjs(e).year())) {
        setValidateToDate(true);
      } else {
        setValidateToDate(false);
        setFilterData({
          ...filterData,
          ["to"]: dayjs(e)?.format("YYYY-MM-DD"),
        });
      }
    } else {
      const { name, value } = e?.target;
      setFilterData({ ...filterData, [name]: value });
    }
  };
  console.log(
    state !== null ? state?.userData?.User?.userId : userInfo?.userId,
    "99999"
  );

  const getFilteredParams = () => {
    var data = {};
    var params = {};
    data = Object.fromEntries(
      Object.entries(filterData).filter(([_, v]) => v !== "" && v != null)
    );
    if (userInfo?.userId !== undefined) {
      params = {
        ...data,
        userId:
          state !== null ? state?.userData?.User?.userId : userInfo?.userId,
      };
    } else {
      params = data;
    }
    return params;
  };

  useEffect(() => {
    dispatch(setNotificationsFilters(filterData));
  }, [filterData]);

  const { data, isSuccess, isLoading } = useGetNotificationsQuery(
    {
      limit: paginationModel?.pageSize,
      page: paginationModel?.page + 1,
      mode: "web",
      ...getFilteredParams(),
    }
    // {
    //   skip: notificationsData?.length !== 0,
    // }
  );

  const handleReset = () => {
    setFilterData({
      fromDate: null,
      toDate: null,
    });
  };

  useEffect(() => {
    if (Array.isArray(data?.result)) {
      setRowData(
        data?.result?.map((row: any, index: any) => ({
          id: index,
          Date: dayjs(row?.createdAt)?.format("DD MMM, YYYY"),
          message: row?.content,
          ...row,
        }))
      );
    }
  }, [isSuccess, data]);

  const calHeaight = () => {
    let targetCompo,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetCompo =
      window.document.querySelector<HTMLElement>(".notificationTable");
    targetElement = targetCompo?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 25);
    setTableHeight(tableHeight);
  };

  useEffect(() => {
    calHeaight();
    const resizeObserver = new ResizeObserver(() => {
      calHeaight();
      setScreenWidth(window.innerWidth);
    });
    resizeObserver.observe(document.body);
    if (filtersData?.fromDate) {
      setDateDisabled(false);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const columns: GridColDef[] = [
    {
      field: "Date",
      headerName: "Date",
      minWidth: 150,
      align: "left",
      cellClassName: "cell-padding",
      headerAlign: "left",
      sortable: false,
      flex: 1,
    },
    {
      field: "category",
      headerName: "Category",
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-padding",
      minWidth: 150,
      sortable: false,
      flex: 1,
    },
    {
      field: "message",
      headerName: "Message",
      cellClassName: "cell-padding",
      headerAlign: "left",
      minWidth: 300,
      align: "left",
      sortable: false,
      flex: 5,
    },
  ];

  return (
    <>
      <Loader show={isLoading || updateNotificationsData?.isLoading} />
      <Grid
        container
        rowSpacing={1.5}
        columnSpacing={2}
        sx={{ mb: "3vh", justifyContent: "end" }}
      >
        <Grid
          item
          xl={7.5}
          lg={6}
          md={4.5}
          sm={12}
          xs={12}
          className="notification-Container "
        >
          {!state?.view ? (
            <>
            <span className="notificationHeading">Notifications</span>
            <span className="countFonts">{" "}({data?.count})</span>
            </>
          ) : (
            <>
              <div className="notification-main-title">
                <span className="history-backIcon-pd">
                  <img
                    src={backIcon}
                    alt="backIcon"
                    onClick={() => navigate(-1)}
                    className="notificationBackIcon"
                  />
                </span>
                <span className="capitalise">
                  {state?.userData?.User?.fullName} /{" "}
                  <span className="sub-name">Notifications</span>

                </span>
              </div>
            </>
          )}
        </Grid>
        <Grid item xl={3} lg={4} md={5} sm={8} xs={12}>
          <Grid container rowSpacing={1.5} columnSpacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="manageOrderDatePicker"
                  label="From"
                  format="DD/MM/YYYY"
                  closeOnSelect={true}
                  defaultValue={null}
                  disableFuture={true}
                  onChange={(e: any) => {
                    handleChange(e, { type: "fromDate" });
                  }}
                  value={
                    filtersData?.fromDate
                      ? dayjs(filtersData?.fromDate, "YYYY-MM-DD")
                      : null
                  }
                />
              </LocalizationProvider>
              {validateFromDate ? (
                <FormHelperText className="helper-text">
                  Enter a Valid Date
                </FormHelperText>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className="manageOrderDatePicker"
                  format="DD/MM/YYYY"
                  label="To"
                  closeOnSelect={true}
                  disableFuture={true}
                  defaultValue={null}
                  onChange={(e: any) => {
                    handleChange(e, { type: "toDate" });
                  }}
                  value={
                    filtersData?.toDate
                      ? dayjs(filtersData?.toDate, "YYYY-MM-DD")
                      : null
                  }
                  disabled={dateDisabled}
                  minDate={dayjs(filtersData?.fromDate, "YYYY-MM-DD")}
                />
              </LocalizationProvider>
              {validateToDate ? (
                <FormHelperText className="helper-text">
                  Enter a Valid Date
                </FormHelperText>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={1.5} lg={2} md={2.5} sm={4} xs={12}>
          <Buttons
            startIcon="reset"
            variant="contained"
            type="primary"
            className="resetButton"
            text="Reset"
            onClick={handleReset}
          />
        </Grid>
      </Grid>
      <div className="notificationTable">
        <Table
          rows={rowData}
          columns={columns}
          rowCount={data?.count}
          isPagination={true}
          onPaginationModelChange={handlePaginationModelChange}
          pagination={paginationModel}
          tableHeight={tableHeight}
        />
      </div>
    </>
  );
};
