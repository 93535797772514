import { apiRoutes } from "../../config/api"
import { apiSlice } from "./apiSlice"

export const deviceApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDevices:builder.mutation({
            query:(params)=>({
                url:apiRoutes.GETALLDEVICES,
                method:"GET",
                params:params,
            }),
        }),
        getDevicesById:builder.mutation({
            query:(deviceId)=>({
                url:`${apiRoutes.GETDEVICEBYID}/${deviceId}`,
                method:"GET",
            }),
        }),
        createDevice:builder.mutation({
            query:(deviceData)=>({
                url:apiRoutes.CREATEDEVICE,
                method:"POST",
                body:{...deviceData}
            }),
        }),
        updateDevice:builder.mutation({
            query:({deviceData,id})=>({
                url:`${apiRoutes.UPDATEDEVICE}/${id}`,
                method:"PATCH",
                body:{...deviceData}
            }),
        }),
        editUpdateDevice:builder.mutation({
            query:({serialNumber,id})=>({
                url:`${apiRoutes.EDITUPDATEDEVICE}/${id}`,
                method:"PATCH",
                body:{...serialNumber}
            }),
        }),
        getAllDevicesByUserId: builder.query({
            query: ({id,...data}) => ({
              url: `${apiRoutes.GETALLDEVICESBYUSERID}/${id}`,
              method: "GET",
              params:data
            }),
        }),
        orderDevice:builder.mutation({
            query:(orderDetail)=>({
                url:apiRoutes.ORDERDEVICE,
                method:"POST",
                body:{...orderDetail}
            }),
        }),
    })

})

export const {useGetDevicesMutation,useUpdateDeviceMutation, useEditUpdateDeviceMutation,  useCreateDeviceMutation,useGetDevicesByIdMutation, useGetAllDevicesByUserIdQuery,useOrderDeviceMutation} = deviceApiSlice
