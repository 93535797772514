import { createSlice } from "@reduxjs/toolkit";

const initialState = { roleData: {}, roleId: [], supportedCountries: [] };

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRole(state, action) {
      state.roleId = action?.payload?.roleId;
      state.roleData = action?.payload?.roleData;
    },
    setCountry:(state, {payload}) => {
      state.supportedCountries = payload?.supportedCountries;
    },
    clearRoleState: (state) => (state = initialState),
  },
});

export const { setRole, setCountry, clearRoleState } = roleSlice.actions;
export default roleSlice.reducer;
export const getRolesInfo = (state: any) => state.role;
