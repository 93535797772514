import { Card, Grid, FormGroup, FormControlLabel, Radio, Badge, IconButton, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import Buttons from "../../../common/button";
import { handlePriceFormatting } from "../../../../utils";
import "./style.css"
import { BILLING_FREQUENCY } from "../../../../constants/constant";

const PlanDisplayCard = ({ data, handleCostomiseButton, handleSelectPlan,isActual=false,  }: any) => {
  const [defaultPlan, setDefaultPlan] = useState<any>()
  const [choosedPlan, setChoosedPlan] = useState<any>();

  useEffect(() => {
    if (data) {
      setChoosedPlan(data);
      setDefaultPlan(data)
    }
  }, [data]);

const getPlanNameClass = () => {
  return data?.planTag ? "planNameDisplay taggedPlanName" : "planNameDisplay taggedPlanName"
}


const getPlanTagLabel = () => {
  return data?.planTag ? (
    <span className="planTag">{data?.planTag}</span>
  ) : <span className="planTag hidden"></span>;
};

const getPlanTotal = () => {
  let total = 0
  if(data?.partnerDetails && data?.partnerDetails?.recurringAmount){
    if(choosedPlan){
    total = parseFloat(choosedPlan?.recurringAmount) + parseFloat(data?.partnerDetails?.recurringAmount)
    }else{
      total = parseFloat(data?.recurringAmount) + parseFloat(data?.partnerDetails?.recurringAmount)
    }
  }else if(data?.partnerOffering && data?.partnerOffering?.recurringAmount){
    if(choosedPlan){
      total = parseFloat(choosedPlan?.recurringAmount) + parseFloat(data?.partnerOffering?.recurringAmount)
      }else{
        total = parseFloat(data?.recurringAmount) + parseFloat(data?.partnerOffering?.recurringAmount)
      }
  } else {
    if(choosedPlan){
      total = choosedPlan?.recurringAmount
    } else{
      total = data?.recurringAmount
    }
    
  }
  return total
}



const handleBillingSelect = (billingOption:any) => {
  setChoosedPlan(billingOption)
}


const getChipClass = (currentOptin:any) => {
  if(currentOptin?.planId == choosedPlan?.planId){
    return "chip highlighted"
  } else {
    return "chip"
  }
}


const handleSelect = () => {
  if(isActual){
    handleSelectPlan(choosedPlan?.subscriptionPlanId, 0)
  }
}


const getBillingInfo = () => {
  let text = ""
 
    switch (data?.billingFrequency){
      case BILLING_FREQUENCY.YEARLY:
        text = "billed annually"  ;
         break;
      case  BILLING_FREQUENCY.QUARTERLY:
        text = "billed every 3 months"
        break;
      case BILLING_FREQUENCY.HALFYEAR:
        text = "billed every 6 months"
        break;
      default:
        text = "billed monthly"
        break;
  
    }

  return text
}


const getCurrencySymbol = () => {
  if(data?.currency == "USD"){
    return "$"
  } else {
    return<span>&#8377;</span>;
  }
}
  return (
    <>
      {getPlanTagLabel()}
      <Card className={data?.planTag? "planDisplayCard highlightedCard":"planDisplayCard"}>
        <Grid container className="displaCard">
          <Grid item xs={12} className={getPlanNameClass()}>
            <span>{data?.name}</span>
          </Grid>
          {/* <Grid item xs={12} sx={{borderTop: "0.5px solid #c5c9d0"}}>
            <Grid container columnSpacing={4} justifyContent={"center"}>
              <Grid item xs={12} className="planSubDetails textAlignCenter">
                {data?.description}
              </Grid>
            </Grid>
          </Grid> */}

          <Grid item xs={12} sx={{ mt: 4, ml:3.5 }}>
            <Grid container columnSpacing={4} justifyContent={"center"} >
              <Grid item xs={12} sx={{mt:1}} className="">
                <span className="planAmount">
                <span className="planCurrency">{data?.currency} </span>
                  {getCurrencySymbol()}{handlePriceFormatting(getPlanTotal(), data?.currency)}
                </span>
              </Grid>
              <Grid item xs={12} sx={{mt:1}} className="">
                <span className="planFrequency">
                  {getBillingInfo()}
                </span>
              </Grid>
              {/* <Grid item xs={12} sx={{ mt: 2 }} className="textAlignCenter">
                <span className="planDetailsBullets planCSS textAlignCenter">
                  Select your plan:
                </span>
              </Grid> */}
              {/* <Grid item xs={12} className="textAlignCenter" sx={{ mt: 1 }}>
                {data?.billingOptions?.length
                  ? data?.billingOptions?.map((billingOption: any) => {
                      return (
                        <Chip
                          className={getChipClass(billingOption)}
                          onClick={() => handleBillingSelect(billingOption)}
                          clickable
                          label={<span className="planFrequency">{getFrequencyText(billingOption)}</span>}
                        />
                      );
                    })
                  : 
                 
                    <Chip
                      className="chip highlighted"
                      onClick={() => {}}
                      clickable
                      label={<span className="planFrequency">{getFrequencyText(data)}</span>}
                    />
                  }
                {/* <Chip
                  sx={{
                    bgcolor:
                      selectFrequency == "3 months" ? "blue" : "whitesmoke",
                  }}
                  onClick={() => setSelectedFrequency("3 months")}
                  clickable
                  label={<span className="planFrequency">{"3 M"}</span>}
                />
                <Chip
                  sx={{
                    bgcolor:
                      selectFrequency == "6 months" ? "blue" : "whitesmoke",
                    ml: 1,
                    mr: 1,
                  }}
                  onClick={() => setSelectedFrequency("6 months")}
                  clickable
                  label={<span className="planFrequency">{"6 M"}</span>}
                />
                <Chip
                  sx={{
                    bgcolor:
                      selectFrequency == "12 months" ? "blue" : "whitesmoke",
                  }}
                  onClick={() => setSelectedFrequency("12 months")}
                  clickable
                  label={<span className="planFrequency">{"12 M"}</span>}
                /> 
              </Grid> 
              */}
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 4, pr:3, pl:3}}>
            <Grid container columnSpacing={4} sx={{mt:1}} justifyContent={"center"}>
              <Grid item xs={12} className="textAlignCenter">
                <Buttons type="primary" variant="contained" text={"SUBSCRIBE"} onClick={handleSelect}>
                  Select
                </Buttons>
              </Grid>
            </Grid>
          </Grid>
{/* 
          <Grid item xs={12} sx={{ mt: 4 }}>
            <Grid container columnSpacing={4} justifyContent={"center"}>
              <Grid item xs={12} className="planSubDetails textAlignCenter">
                {data?.description}
              </Grid>
            </Grid>
          </Grid>
           */}
        </Grid>
        <Grid container className="displaCard" sx={{mt:3}}>

          <Grid item xs={12} sx={{ mt: 2, ml:3, mr: 1.5}}>
            <Grid container columnSpacing={1} justifyContent={"center"}>
              <Grid item xs={12} className="planDetailsBullets">
                {/* <div style={{ display: "flex" }}> */}
                  <div dangerouslySetInnerHTML={{ __html: data?.remarks }} />
                {/* </div> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default PlanDisplayCard;
