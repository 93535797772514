import { apiRoutes } from "../../config/api";
import { apiSlice } from "./apiSlice";

export const roleApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllRole: builder.mutation({
      query: (params: any) => {
        return {
          url: apiRoutes?.ROLES,
          method: "GET",
          params: params,
        };
      },
    }),
    getRolePermission: builder.mutation({
      query: (params:any) => {
        return {
          url: `${apiRoutes.GETROLEPERMISSION}/${params?.roleId}`,
          method: "GET",
        };
      },
    }),
  }),
});


export const { useGetAllRoleMutation , useGetRolePermissionMutation} = roleApiSlice;
