import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import UILayout from "../components/layout";
import { Login } from "../components/pages/auth";
import { CreateAccount } from "../components/pages/auth/createAccount";
import { Dashboard } from "../components/pages/dashboard";
import { Notification } from "../components/pages/notification";
import { Page } from "./config";
import { useDispatch, useSelector } from "react-redux";
import { getIsLoggedIn, setIpAddress } from "../redux/features/auth/authSlice";
import { ProtectedRoute } from "./protectedRoute";
import { UserRegistration } from "../components/pages/auth/userRegistration";
import { ManageOrder } from "../components/pages/manageOrder";
import { ForgetPassword } from "../components/pages/auth/forgetPassword";
import { ResetPassword } from "../components/pages/auth/resetPassword";
import { ManageDevice } from "../components/pages/manageDevice";
import { UserRoles } from "../constants/enum";
import { ManagePlans } from "../components/pages/manageplans";
import { ReviewAndPay } from "../components/common/paymentModal/paymentModal";
import { ViewOrder } from "../components/pages/manageOrder/viewOrder";
import { HELPCENTRE } from "../components/pages/helpcenter";
import { ManageSubscriptionPlan } from "../components/pages/settings/acoountSubscription/accountSubscriptionPlan";
import { OrderHistory } from "../components/pages/settings/orderHistory/orderHistory";
import { AccountManageDevice } from "../components/pages/settings/accoutManageDevice";
import { AccountSettings } from "../components/pages/settings/accountSetting";
import { BillingHistoy } from "../components/pages/settings/billingHistory/billingHistory";
import { ManageUsers } from "../components/pages/manageUsers";
import { ManageUserView } from "../components/pages/manageUsers/manageUserView/manageUserView";
import { ViewBeneficiary } from "../components/pages/manageUsers/manageUserView/viewBeneficiary";
import { PrivacyPolicy } from "../components/pages/auth/userRegistration/privacyPolicy";
import { DuePayment } from "../components/pages/reports/duePayment";
import { NoReadings } from "../components/pages/reports/noReadings";
import { RevenueDetails } from "../components/pages/reports/revenueDetail";
import { RevenueSummary } from "../components/pages/reports/revenueSummary";
import { SubscriptionCancellation } from "../components/pages/reports/subscriptionCancellation";
import { SubscriptionPending } from "../components/pages/reports/subscriptionPending";
import { RolesAndPermission } from "../components/pages/rolesAndPermission";
import { ViewRole } from "../components/pages/rolesAndPermission/ViewRole";
import { EditRole } from "../components/pages/rolesAndPermission/editRole";

import { ManageCoupon } from "../components/pages/manageCoupon";
import { AuditLogs } from "../components/pages/auditLog";
import { useGetIpQuery } from "../redux/services/authApiSlice";
import { useEffect } from "react";

const UIRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const steps = useSelector((state: any) => state.userSteps.activeSteps);
  const { userInfo } = useSelector((state: any) => state?.auth);
  const { orderStatus } = useSelector((state: any) => state?.order);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const dispatch = useDispatch();
  const { data, isSuccess } = useGetIpQuery({
    refetchOnMountOrArgChange: true,
  });

  const renderPaths = (paths: any, Element: any) => {
    return paths.map((path: any) => {
      return <Route key={path} path={path} element={Element} />;
    });
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setIpAddress({ ipAddress: data?.ipAddress }));
    }
  }, [isSuccess]);

  useEffect(() => {
    console.log(userInfo?.roleName == UserRoles.SUBSCRIBER &&
      location?.pathname.split("/").filter(Boolean).length === 0 &&
      isLoggedIn &&
      steps >= 5,"checkPath")
    if (
      userInfo?.roleName != UserRoles.SUBSCRIBER &&
      location?.pathname.split("/").filter(Boolean).length === 0 &&
      isLoggedIn
    ) {
      navigate(Page.MANAGEUSERS);
    } else if (
      userInfo?.roleName == UserRoles.SUBSCRIBER &&
      location?.pathname.split("/").filter(Boolean).length === 0 &&
      isLoggedIn &&
      steps >= 5
    ) {
      navigate(Page.MANAGEUSERS);
    }
  }, []);

  return (
    <Routes>
      {!isLoggedIn && <Route index element={<Login />} />}
      <Route path={Page.CREATEACCOUNT} element={<CreateAccount />}></Route>
      <Route
        path={Page.PRIVACYPOLICY}
        element={<PrivacyPolicy mobile={true} />}
      ></Route>
      <Route path={Page.FORGETPASSWORD} element={<ForgetPassword />}></Route>
      <Route>
        {renderPaths(
          [Page.RESETPASSWORD, Page.CREATEPASSWORD],
          <ResetPassword />
        )}
      </Route>
      <Route element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
        {userInfo?.roleName == UserRoles.SUBSCRIBER ? (
          <Route path={Page.PAYMENTSTATUS} element={<ReviewAndPay />} />
        ) : (
          <></>
        )}
        <Route path={Page.HOME} element={<UILayout />}>
          {userInfo?.roleName == UserRoles.SUBSCRIBER && steps < 5 ? (
            <Route path={Page.REGISTERATION} element={<UserRegistration />} />
          ) : (
            <></>
          )}
          {userInfo?.roleName != UserRoles.SUBSCRIBER ? (
            <Route path={Page.DASHBOARD} element={<Dashboard />} />
          ) : (
            <></>
          )}
          <Route path={Page.REGISTERATION} element={<Dashboard />} />
          <Route path={Page.MANAGEUSERS} element={<ManageUsers />} />
          <Route path={Page.MANAGEDEVICES} element={<ManageDevice />} />
          <Route path={Page.NOTIFICATION} element={<Notification />} />
          <Route
            path={Page.MANAGEUSER_NOTIFICATION}
            element={<Notification />}
          />

          <Route>
            {renderPaths(
              [Page.MANAGEUSERS_AUDITLOGS, Page.ACCOUNTSETTINGS_AUDITLOGS],
              <AuditLogs />
            )}
          </Route>
          <Route>
            {renderPaths(
              [Page.ACCOUNTSETTING, Page.MANAGEUSER_ACCOUNTSETTINGS],
              <AccountSettings />
            )}
          </Route>

          <Route path={Page.ROLESPERMISSION} element={<RolesAndPermission />} />
          <Route path={Page.VIEWROLE} element={<ViewRole />} />
          <Route path={Page.EDITROLE} element={<EditRole />} />
          <Route>
            {renderPaths(
              [
                Page.ACCOUNTSETTING_MANAGESUBSCRIPTIONPLAN,
                Page.MANAGEUSER_SUBSCRIPTIONPLAN,
                Page.PAYMENTSUBSCRIPTIONPLAN,
              ],
              <ManageSubscriptionPlan />
            )}
          </Route>
          <Route>
            {renderPaths(
              [Page.ORDERHISTORY, Page.MANAGEUSER_ORDERHISTORY],
              <OrderHistory />
            )}
          </Route>
          <Route>
            {renderPaths(
              [Page.BILLINGHISTORY, Page.MANAGEUSER_BILLINGHISTORY],
              <BillingHistoy />
            )}
          </Route>
          <Route>
            {renderPaths(
              [
                Page.SETTINGSMANAGEDEVICES,
                Page.MANAGEUSER_MANAGEDEVICES,
                Page.PAYMENTMANAGEDEVICES,
              ],
              <AccountManageDevice />
            )}
          </Route>
          <Route>
            {renderPaths(
              [
                Page.MANAGEUSERVIEWSUBSCRIBER,
                Page.MANAGEUSERVIEWBENEFICIARY,
                Page.MANAGEUSERVIEWFAMILY,
                Page.MANAGEUSERVIEWPLATFORMADMIN,
                Page.MANAGEUSERVIEWPLATFORMSUPPORT,
                Page.MANAGEUSERVIEWPLATEFORMSUPERADMIN,
              ],
              <ManageUserView />
            )}
          </Route>
          <Route path={Page.VIEWBENEFICIARY} element={<ViewBeneficiary />} />
          <Route path={Page.HELPCENTRE} element={<HELPCENTRE />} />
          <Route path={Page.MANAGEPLANS} element={<ManagePlans />} />
          <Route path={Page.MANAGECOUPONS} element={<ManageCoupon />} />
          <Route path={Page.MANAGEORDERS} element={<ManageOrder />} />
          <Route path={Page.VIEWORDER} element={<ViewOrder />} />
          <Route path={Page.DUEPAYMENT} element={<DuePayment />}></Route>
          <Route
            path={Page.SEVENDAYNOREADINGS}
            element={<NoReadings />}
          ></Route>
          <Route path={Page.REVENUEDETAIL} element={<RevenueDetails />}></Route>
          <Route
            path={Page.REVENUESUMMARY}
            element={<RevenueSummary />}
          ></Route>
          <Route
            path={Page.SUBSCRIPTIONCANCELLATION}
            element={<SubscriptionCancellation />}
          ></Route>
          <Route
            path={Page.SUBSCRIPTIONPENDING}
            element={<SubscriptionPending />}
          ></Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default UIRoutes;
