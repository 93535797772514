import { apiRoutes } from "../../config/api";
import { apiSlice } from "./apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateUser: builder.mutation({
      query: (formData: any) => {
        const { userId, ...data } = formData;
        return {
          url: `${apiRoutes.USER}/${userId}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    updateEmailMobile: builder.mutation({
      query: (formData: any) => {
        const { userId, ...data } = formData;
        return {
          url: `${apiRoutes.UPDATEUSER}/${userId}`,
          method: "POST",
          body: data,
        };
      },
    }),
    updatePassword: builder.mutation({
      query: (formData: any) => {
        const { userId, ...data } = formData;
        return {
          url: `${apiRoutes.CHANGE_PASSWORD}/${userId}`,
          method: "POST",
          body: data,
        };
      },
    }),
    registerUser: builder.mutation({
      query: (params) => ({
        url: apiRoutes.REGISTER_USER,
        method: "POST",
        body: { ...params },
      }),
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `${apiRoutes.USER}/${userId}`,
        method: "DELETE",
      }),
    }),
    sendOtp: builder.mutation({
      query: (formData: any) => {
        const { userId, ...data } = formData;
        return {
          url: `${apiRoutes.UPDATEUSER}/${userId}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    getUserById: builder.query({
      query: (userId: any) => ({
        url: `${apiRoutes.USER}/${userId}`,
        method: "GET",
      }),
    }),
    getAllUsers: builder.mutation({
      query: (params: any) => ({
        url: apiRoutes.USER,
        params: params,
        method: "GET",
      }),
    }),
    getAllUsersofSubscriber: builder.mutation({
      query: (formData: any) => {
        const { subscriberId, ...data } = formData;
        return {
          url: `${apiRoutes.GETALLUSEROFSUBSCRIBER}/${subscriberId}`,
          method: "GET",
          params: data,
        };
      },
    }),
    createBeneficiary: builder.mutation({
      query: (data) => {
        return {
          url: apiRoutes.UPDATEBENEFICIARY,
          method: "POST",
          body: data,
        };
      },
    }),
    createUser: builder.mutation({
      query: (formData: any) => {
        return {
          url: apiRoutes.USER,
          method: "POST",
          body: formData,
        };
      },
    }),
    deactivateUser: builder.mutation({
      query: (formData: any) => {
        const { id,status, ...data } = formData;
        return {
          url: `${apiRoutes.DEACTIVATEUSER}/${id}`,
          method: "PATCH",
          body: {status:status},
        };
      },
    }),
    updateFamilyDetails: builder.mutation({
      query: (data: any) => {
        return {
          url: `${apiRoutes.UPDATEFAMILYDATA}`,
          method: "PUT",
          body: data,
        };
      },
    }),
    sendMessage: builder.mutation({
      query: (payload: any) => {
        const {userId , ...data} = payload;
        return {
          url: `${apiRoutes.SEND_MESSAGE}/${userId}`,
          method: "POST",
          body: data,
        };
      },
    }),
    addNewBeneficary: builder.mutation({
      query: (payload:any) => {
        const { userSubscriptionId, ...data } = payload;
        return {
          url: `${apiRoutes.ADD_NEW_BENEFICIARY}/${userSubscriptionId}`,
          method: "POST",
          body: data,
        };
      },
    }),
    getAudit: builder.query({
      query: (params: any) => ({
        url: `${apiRoutes.AUDIT}`,
        method: "GET",
        params:params
      }),
    }),
    getprefferedCommunication : builder.query({
      query: (consumerId, ...params:any) => ({
          url: `${apiRoutes.GETPREFERREDCOMMUNICATION}/${consumerId}`,
          method: 'GET',
          params: params,
      })
  }),
    updatePrefferedCommunication : builder.mutation({
      query: (data:any) => ({
          url: `${apiRoutes.UPDATEPREFERREDCOMMUNICATION}`,
          method: 'PUT',
          body: {categories:data},
      })
    }),
    getPaymentMethod: builder.query({
      query: (userId: any) => ({
        url: `${apiRoutes?.PAYMENTMETHOD}/${userId}`,
        method: "GET",
      }),
    }),
    customerPaymentPortal: builder.mutation({
      query: (userId:any) => ({
        url: `${apiRoutes.CHANGE_PAYMENT_METHOD}/${userId}`,
        method: "POST",
        body: {},
      }),
    }),
    updateBeneficiaries: builder.mutation({
      query: ({allowedBeneficiary, userSubscriptionId}) => ({
        url: `${apiRoutes.UPDATE_BENEFICIARY}/${userSubscriptionId}`,
        method: "PATCH",
        body: {allowedBeneficiary},
      }),
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useUpdateUserMutation,
  useUpdatePasswordMutation,
  useDeleteUserMutation,
  useUpdateEmailMobileMutation,
  useSendOtpMutation,
  useGetAllUsersMutation,
  useGetAllUsersofSubscriberMutation,
  useGetUserByIdQuery,
  useCreateBeneficiaryMutation,
  useCreateUserMutation,
  useDeactivateUserMutation,
  useUpdateFamilyDetailsMutation,
  useSendMessageMutation,
  useAddNewBeneficaryMutation,
  useGetAuditQuery,
  useGetprefferedCommunicationQuery,
  useUpdatePrefferedCommunicationMutation,
  useGetPaymentMethodQuery,
  useCustomerPaymentPortalMutation,
  useUpdateBeneficiariesMutation,
} = userApiSlice;
