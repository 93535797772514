import { Controller, useForm } from "react-hook-form";
import { Modal } from "../../../../common/modal/modal";
import {
  Checkbox,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { DataGrid, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { RegExpressions } from "../../../../../constants/regExp";
import { row } from "../dummyrowData";
import {
  CheckboxSelectInput,
  SelectInput,
} from "../../../../common/selectinput";
import { CustomToolTip } from "../../../../common/tooltip";
import { useEffect, useState } from "react";
import {
  useGetDevicesMutation,
  useOrderDeviceMutation,
} from "../../../../../redux/services/devicesApiSlice";
import Buttons from "../../../../common/button";
import { deviceData } from "./deviceDummyData";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { DowngradeBeneficiaryModal } from "./downgradeBeneficiary";
import { setAlert } from "../../../../../redux/features/alert/alertSlice";
import { useLocation } from "react-router-dom";
import { useGetSubscriptionPlanQuery } from "../../../../../redux/services/planApiSlice";
import { handleErrors, handlePriceFormatting } from "../../../../../utils";
import { DeviceShippingAddress } from "./deviceShippingAddress";
import { setChangeSubscriptionModal } from "../../../../../redux/features/changeSubscription/changeSubscriptionSlice";
import {
  getDeviceOrderInfo,
  setdeviceOrder,
} from "../../../../../redux/features/deviceOrder/deviceOrderSlice";
import { useGetAccountSettingsQuery } from "../../../../../redux/services/accountsettingsApiSlice";
import { useGetAllOrdersQuery } from "../../../../../redux/services/orderApiSlice";
import { setOrder } from "../../../../../redux/features/order/orderSlice";
import Loader from "../../../../common/loader/loader";
import { SubscriptionReviewModal } from "./subscriptionReview";
import { deviceTypeValue } from "../../../../../constants/enum";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

export const SubscriptionDeviceModal = (props: any) => {
  const {
    refetchPlans,
    setChangeDeviceTypeModal,
    changeDeviceTypeModal,
    selectedSubscription,
  } = props;

  const { handleSubmit, control, setValue, getValues, reset } = useForm({
    mode: "onChange" || "onSubmit",
  });
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [userId, setUserId] = useState();
  const { userInfo } = useSelector((state: any) => state?.auth);
  const orderId = useSelector((state: any) => state?.order?.id);
  const [review, setReview] = useState<any>(false);

  useEffect(() => {
    if (state?.view) {
      setUserId(state?.userData?.User?.userId);
    } else {
      setUserId(userInfo?.userId);
    }
  }, [state, changeDeviceTypeModal]);
  const [tableData, setTableData] = useState<any>([]);
  console.log(tableData, "dataaaa");
  const [deviceName, setDeviceName] = useState<any>([]);
  const [allCheck, setAllCheck] = useState(false);
  const [device, setDevice] = useState<any>([]);
  const [deviceTypes, setDeviceTypes] = useState<any>([]);
  const [deviceTypesName, setDeviceTypesName] = useState<any>([]);
  const [couponDiscount, setCouponDiscount] = useState<any>(0);
  const [downgrade, setDowngrade] = useState<any>(false);
  const [planData, setPlanData] = useState<any>([]);
  console.log(planData[1]?.beneficiaryCount, "benenenen");
  const [selectedPlanData, setSelectedPlanData] = useState<any>([]);
  console.log(selectedPlanData, "selectedPlanData")
  const [allowedBen, SetAllowedBen] = useState<any>();
  const [shippingAddressModal, setShippingAdressModal] = useState<any>(false);
  const [deviceDetails, setDeviceDetails] = useState<any>([]);
  console.log(deviceDetails, "devicedetails");

  // API INTEGRATION

  const {
    data,
    isSuccess,
    refetch,
    isError,
    fulfilledTimeStamp,
    error,
    isLoading,
  } = useGetSubscriptionPlanQuery(
    {
      id: userId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: userId === undefined ? true : false,
    }
  );
  console.log(data, "data");
  const {
    data: data1,
    isLoading: isLoading1,
    isSuccess: isSuccess1,
  } = useGetAccountSettingsQuery(userId, {
    refetchOnMountOrArgChange: true,
    skip: userId === undefined ? true : false,
  });
  const [orderDevice, orderDeviceData] = useOrderDeviceMutation();
  const [getDevices, getDevicesData] = useGetDevicesMutation();
  const { beneficiaryAccounts } = useSelector(
    (state: any) => state?.beneficiary
  );
  const [totalSum, setTotalSum] = useState<any>(0);
  const [benCounting, setBenCounting] = useState<any>(
    beneficiaryAccounts?.activeBeneficiaries +
      beneficiaryAccounts?.invitedBeneficiaries
  );
  const [total, setTotal] = useState<any>();

  const [activeBenCount, setActiveBenCount] = useState<any>();

  useEffect(() => {
    getDevices({ refetchOnMountOrArgChange: true });
  }, [changeDeviceTypeModal]);

  useEffect(() => {
    if (isSuccess) {
      if (data && data?.result && data?.result?.length > 0) {
        setSelectedPlanData(data?.result[0]);
        console.log(data?.result[0], "d7777777777");
        const benData: any = [
          {
            id: data?.result[0]?.subscriptionPlanId,
            beneficiarySupported: "Beneficiaries included in your plan",
            beneficiaryCount:
              data?.result[0]?.SubscriptionPlan?.beneficiaryCount,
            unitAmount: "-",
            currency: data?.result[0]?.SubscriptionPlan?.currency,
            recurring: data?.result[0]?.SubscriptionPlan?.recurringAmount,
            couponDetails: data?.result[0]?.couponDetails,
            country: data?.result[0]?.SubscriptionPlan?.billingCountry,
          },
          {
            id: "2",
            beneficiarySupported: "Additional beneficiaries",
            beneficiaryCount:
              parseInt(data?.result[0]?.allowedBeneficiary?.toString()) -
              parseInt(
                data?.result[0]?.SubscriptionPlan?.beneficiaryCount?.toString()
              ),
            unitAmount: data?.result[0]?.SubscriptionPlan?.unitAmount || 0,
            currency: data?.result[0]?.SubscriptionPlan?.currency,
            recurring:
              (parseInt(data?.result[0]?.allowedBeneficiary?.toString()) -
                parseInt(
                  data?.result[0]?.SubscriptionPlan?.beneficiaryCount?.toString()
                )) *
              (data?.result[0]?.SubscriptionPlan?.unitAmount || 0),
            couponDetails: data?.result[0]?.couponDetails,
            country: data?.result[0]?.SubscriptionPlan?.billingCountry,
          },
        ];
        console.log(benData, "bennnnnnnnnnnnn");
        setPlanData(benData);

        SetAllowedBen(data?.result[0]?.allowedBeneficiary);
      }
    }
  }, [isSuccess, data, changeDeviceTypeModal]);

  useEffect(() => {
    if (changeDeviceTypeModal) {
      refetch();
    }
  }, [changeDeviceTypeModal]);

  const [allDeviceData, setAllDeviceData] = useState<any>([]);
  useEffect(() => {
    if (getDevicesData?.isSuccess) {
      let data: any = [];
      getDevicesData?.data?.result?.map((x: any) => {
        data.push({
          text: x?.biometricType,
          description: x?.model,
          value: x?.deviceTypeId,
          currency: x?.currency,
          quantity: 1,
          deviceCost: x?.cost,
          deviceCostings: x?.DeviceCostings,
          igst: x?.igst,
        });
      });
      // setDeviceName(data);
      setAllDeviceData(data);
    }
  }, [getDevicesData?.isSuccess]);


  const labels: any = {
    ["Blood Glucose"]: "Glucometer",
    ["Blood Pressure"]: "BP Monitor",
    ["Temperature"]: "Thermometer",
    ["PulseOx"]: "Pulse Oximeter",
    ["Weight"]: "Weighing Scale",
  };

  useEffect(() => {
    let totalValue = planData?.reduce((acc: any, item: any) => {
      acc = acc + item?.recurring;
      return acc;
    }, 0);
    console.log(totalValue, "totalllllllll");
    setTotalSum(totalValue);
  }, [planData]);

  useEffect(() => {
    handleErrors(getDevicesData, dispatch);
  }, [getDevicesData?.isError]);

  useEffect(() => {
    dispatch(
      setChangeSubscriptionModal({
        updateSubscription: selectedSubscription,
      })
    );
  }, []);

  useEffect(() => {
    let totalCount =
      beneficiaryAccounts?.activeBeneficiaries +
      beneficiaryAccounts?.invitedBeneficiaries;
    setActiveBenCount(totalCount);
  }, [beneficiaryAccounts]);

  console.log(activeBenCount, "ac8999999999999");

  const handleChange = (e: any, rowData: any) => {
    let data: any;
    const value = e.target.value;
    let totalAmount = rowData?.unitAmount * value;
    if (value >= 0) {
      data = {
        ...planData[1],
        beneficiaryCount: value,
        recurring: totalAmount,
      };
      console.log(data, "bendata");
      setPlanData([planData[0], data]);
    }
  };

  const columns2: GridColDef[] = [
    {
      field: "beneficiarySupported",
      headerName: "",
      minWidth: 80,
      headerAlign: "center",
      align: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1.5,
    },
    {
      field: "beneficiaryCount",
      renderHeader: (params: GridColumnHeaderParams) => <b>Count</b>,
      minWidth: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => {
        return (
          <div>
            {params?.row?.beneficiaryCount !== "NA" ? (
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <div className="customizeInput">
                    {params?.row?.beneficiarySupported ===
                    "Additional beneficiaries" ? (
                      <div
                        className="minusSign"
                        onClick={() =>
                          handleChange(
                            {
                              target: {
                                value:
                                  parseInt(planData[1]?.beneficiaryCount) - 1,
                              },
                            },
                            params?.row
                          )
                        }
                      >
                        -
                      </div>
                    ) : null}
                    <input
                      disabled={params?.row?.unitAmount === "-" ? true : false}
                      className="inputBox"
                      type="number"
                      min={1}
                      max={200}
                      required
                      value={params?.row?.beneficiaryCount}
                      onChange={(e: any) => {
                        field?.onChange(e);
                        handleChange(e, params?.row);
                      }}
                    />
                    {params?.row?.beneficiarySupported ===
                    "Additional beneficiaries" ? (
                      <div
                        className="addSign"
                        onClick={() =>
                          handleChange(
                            {
                              target: {
                                value:
                                  parseInt(planData[1]?.beneficiaryCount) + 1,
                              },
                            },
                            params?.row
                          )
                        }
                      >
                        +
                      </div>
                    ) : null}
                  </div>
                )}
                name="beneficiaryTableCount"
                rules={{
                  pattern: {
                    value: RegExpressions?.five,
                    message: "not allowed",
                  },
                }}
                control={control}
              />
            ) : null}
          </div>
        );
      },
    },
    {
      field: "unitAmount",
      renderHeader: (params: GridColumnHeaderParams) => (
        <b>
          Unit Price
          <br />
          <center>({userInfo?.country === "IN" ? "INR" : "USD"})</center>
        </b>
      ),
      minWidth: 50,
      headerAlign: "right",
      align: "right",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        return params?.row?.unitAmount === "-"
          ? "-"
          : handlePriceFormatting(
              params?.row?.unitAmount,
              userInfo?.country === "IN" ? "IN" : "US"
            );
      },
    },
    {
      field: "recurring",
      renderHeader: (params: GridColumnHeaderParams) => (
        <b>
          Sub Total
          <br />
          <center>({userInfo?.country === "IN" ? "INR" : "USD"})</center>
        </b>
      ),
      minWidth: 80,
      headerAlign: "right",
      align: "right",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        return handlePriceFormatting(
          params?.row?.recurring,
          userInfo?.country === "IN" ? "IN" : "US"
        );
      },
    },
  ];

  console.log(tableData, "tabbbbbbbbbbbbbbbb");

  const columns: GridColDef[] = [
    {
      field: "text",
      headerName: "Device Type",
      minWidth: 120,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell:(params) =>{
        console.log(params, "ptaa")
        return(
          <>
          {labels[params?.row?.text]}
          </>
        )
      }
    },
    {
      field: "description",
      headerName: "Model No.",
      minWidth: 100,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="sliceWidth">
            <CustomToolTip title={params?.row?.description} placement="top">
              <p className="sliceWidth"> {params?.row?.description} </p>
            </CustomToolTip>
          </div>
        );
      },
    },
    {
      field: "qty",
      headerName: "Quantity",
      minWidth: 100,
      headerAlign: "center",
      sortable: false,
      align: "center",
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        console.log(params?.row?.quantity, "quantity");
        return (
          <div className="customizeInput">
            <div
              className="minusSign"
              onClick={() =>
                handleQtyChange(
                  {
                    target: {
                      value: parseInt(params?.row?.quantity) - 1,
                    },
                  },
                  params?.row
                )
              }
            >
               <div className="fontIcon">
              <RemoveIcon/>
              </div>
              {/* - */}
            </div>
            <Controller
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <input
                  defaultValue={1}
                  value={params?.row?.quantity}
                  className="inputBox"
                  onChange={(e: any) => handleQtyChange(e, params?.row)}
                  type="number"
                  name="quantity"
                  min={1}
                  max={500}
                  required
                />
              )}
              name="deviceTableData"
              rules={{
                pattern: {
                  value: RegExpressions?.five,
                  message: "not allowed",
                },
              }}
              control={control}
            />
            <div
              className="addSign"
              onClick={() =>
                handleQtyChange(
                  {
                    target: {
                      value: parseInt(params?.row?.quantity) + 1,
                    },
                  },
                  params?.row
                )
              }
            >
              <div className="fontIcon">
              <AddIcon/>
              </div>
              {/* + */}
            </div>
          </div>
        );
      },
    },
    {
      field: "deviceCost",
      renderHeader: (params: GridColumnHeaderParams) => (
        <b>
          Unit Price
          <br />
          <center>({userInfo?.country === "IN" ? "INR" : "USD"})</center>
        </b>
      ),
      minWidth: 100,
      headerAlign: "right",
      align: "right",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        let data = params?.row?.deviceCostings?.filter((item: any) => {
          return item?.country === planData[0]?.country;
        });
        console.log(params?.row, userInfo?.country + "countyrrrrrrrrrrr");
        if (data && data?.length > 0) {
          return handlePriceFormatting(
            data[0]?.value,
            userInfo?.country === "IN" ? "IN" : "US"
          );
        }
      },
    },
    {
      field: "tax",
      renderHeader: (params: GridColumnHeaderParams) => (
        <b>
          GST
          <br />
          <center>({userInfo?.country === "IN" ? "INR" : "USD"})</center>
        </b>
      ),
      minWidth: 100,
      headerAlign: "right",
      sortable: false,
      align: "right",
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        let data = params?.row?.deviceCostings?.filter((item: any) => {
          return item?.country === planData[0]?.country;
        });
        if (data && data?.length > 0) {
          const quantity = params?.row?.quantity;
          const deviceCost = params?.row?.deviceCost;
          const totalTax =
            (quantity * data[0]?.value * params?.row?.igst) / 100;
          // setTotalSum(totalSum+totalCost);
          return handlePriceFormatting(
            totalTax,
            userInfo?.country === "IN" ? "IN" : "US"
          );
        }
        return 0;
      },
    },
    {
      field: "finalCost",
      renderHeader: (params: GridColumnHeaderParams) => (
        <b>
          Total Price
          <br />
          <center>({userInfo?.country === "IN" ? "INR" : "USD"})</center>
        </b>
      ),
      minWidth: 120,
      headerAlign: "right",
      align: "right",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params) => {
        if (userInfo?.country === "IN") {
          let data = params?.row?.deviceCostings?.filter((item: any) => {
            return item?.country === planData[0]?.country;
          });
          if (data && data?.length > 0) {
            const quantity = params?.row?.quantity;
            const deviceCost = params?.row?.deviceCost;
            const totalCost =
              quantity * data[0]?.value +
              (quantity * data[0]?.value * params?.row?.igst) / 100;
            // setTotalSum(totalSum+totalCost);
            return handlePriceFormatting(
              totalCost,
              userInfo?.country === "IN" ? "IN" : "US"
            );
          } else {
            return 0;
          }
        } else {
          let data = params?.row?.deviceCostings?.filter((item: any) => {
            return item?.country === planData[0]?.country;
          });
          console.log(params?.row, "dataaaaaaaaadddd");
          if (data && data?.length > 0) {
            const quantity = params?.row?.quantity;
            const deviceCost = params?.row?.deviceCost;
            const totalCost = quantity * data[0]?.value;
            // setTotalSum(totalSum+totalCost);
            return handlePriceFormatting(
              totalCost,
              userInfo?.country === "IN" ? "IN" : "US"
            );
          } else {
            return 0;
          }
        }
      },
    },
  ];

  console.log(selectedPlanData, "uddddjjjjjjjjjjjjj");

  useEffect(() => {
    const totalCosts = tableData?.map((row: any) => {
      const quantity = row?.quantity || 0;
      const devCost = row?.deviceCost || "NA";
      return quantity * devCost;
    });
    const sum = totalCosts?.reduce((acc: any, cv: any) => acc + cv, 0);
    setTotal(sum);
  }, [tableData]);

  const onProductBundleChange = (e: any, field: any) => {
    const { name, value } = e?.target;
    var check = value.indexOf("all");
    if (check !== -1 && allCheck === false) {
      let data: any = [];
      deviceName?.map((x: any, index: any) => {
        data.push({
          text: x?.text,
          description: x?.description,
          value: x?.value,
          quantity: x?.quantity,
        });
      });
      setTableData(deviceName);
      setDevice(data);
      setAllCheck(true);
    } else if (check !== -1 && allCheck === true) {
      setDevice([]);
      setAllCheck(false);
      setTableData([]);
    } else {
      if (allCheck === true) {
        setTableData((filterTableData: any) =>
          filterTableData.filter(
            (device: any) => device?.value !== value[value?.length - 1]?.value
          )
        );
        let data = deviceName?.filter((x: any) => {
          return x?.value !== value[value?.length - 1]?.value;
        });
        setAllCheck(false);
        setDevice(data);
      } else {
        setDevice(value);
        setTableData(value);
      }
    }
  };

  const [deviceTypeAllCheck, setDeviceTypeAllCheck] = useState<any>(false);
  const onDeviceTypesChange = (e: any, field: any) => {
    setDevice([])
    setTableData([]);
    setAllCheck(false)
    const { name, value } = e?.target;
    handleGetDevices(value);
    console.log(e?.target, "targetrrrrA");
    var check = value.indexOf("all");
    if (check !== -1 && deviceTypeAllCheck === false) {
      let data: any = [];
      deviceTypesName?.map((x: any, index: any) => {
        data.push({
          text: x?.text,
          description: x?.description,
          value: x?.value,
        });
      });
      setDeviceTypes(data);
      handleGetDevices(data);
      setDeviceTypeAllCheck(true);
    } else if (check !== -1 && deviceTypeAllCheck === true) {
      setDeviceTypes([]);
      handleGetDevices([]);
      setDeviceTypeAllCheck(false);
    } else {
      if (deviceTypeAllCheck === true) {
        let data = deviceTypesName?.filter((x: any) => {
          return x?.value !== value[value?.length - 1]?.value;
        });
        setDeviceTypeAllCheck(false);
        setDeviceTypes(data);
      } else {
        setDeviceTypes(value);
      }
    }
  };

  const handleGetDevices = (input: any) => {
    let data = allDeviceData?.reduce((acc: any, item: any) => {
      for (let i = 0; i < input.length; i++) {
        if (input[i]?.value === item?.text) {
          acc?.push(item);
        }
      }
      return acc;
    }, []);
    // if(input && input?.length>0){
    //   if(input[0]!=="all"){
    //     if (data?.length === input?.length) {
    //       setDevice(data);
    //       setAllCheck(true);
    //     }
      
    //   }
    // }
    // else{
    //   setDevice([]);
    //   setAllCheck(false);
    // }
    console.log(data, "datttttttttttttttttt");
    setDeviceName(data);
  };

  useEffect(() => {
    const remainDevices = selectedPlanData?.devices?.map((x: any) => {
      return deviceOptions?.filter((y: any) => {
        return y?.value !== x?.DeviceType?.biometricType;
      });
    });
    const upgradeDeviceArray = remainDevices?.reduce((x: any, y: any) =>
      x.filter((z: any) => y.includes(z))
    );
    console.log(data, "datttttttttttttttttt");
    setDeviceTypesName(upgradeDeviceArray);
  }, [selectedPlanData, changeDeviceTypeModal]);

  const deviceOptions = [
    { text: "BP Monitor", value: deviceTypeValue.BLOODPRESSURE },
    { text: "Pulse Oximeter", value: deviceTypeValue.PULSEOXYMETER },
    { text: "Weighing Scale", value: deviceTypeValue.WEIGHT },
    { text: "Thermometer", value: deviceTypeValue.TEMPERATURE },
    { text: "Glucometer", value: deviceTypeValue.GLUCOSE },
  ];

  const handleQtyChange = (e: any, rowData: any) => {
    let dataArray = [];
    const newQuantity = Math.max(1, parseInt(e?.target?.value || 0))
    if (e?.target?.value >= 0) {
      for (let i = 0; i < device?.length; i++) {
        if (rowData?.value === tableData[i]?.value) {
          // tableData[i].quantity = e?.target?.value;
          tableData[i].quantity = newQuantity;
          dataArray.push(tableData[i]);
        } else {
          dataArray.push(tableData[i]);
        }
      }
      setDevice(dataArray);
      setTableData(dataArray);
    }
  };

  useEffect(() => {
    if (device && device?.length > 0) {
      let selectedDevice = [];
      for (var i = 0; i < device?.length; i++) {
        selectedDevice.push({
          deviceTypeId: device[i].value,
          quantity: device[i]?.quantity,
        });
      }
      setDeviceDetails(selectedDevice);
    }
  }, [device]);

  const [totalCountBen, setTotalCountBen] = useState<any>();

  console.log(benCounting, activeBenCount + "dddddddddddd");

  const handleNextClick = () => {
    if (selectedSubscription === "Beneficiary") {
      let totalBenCount =
         parseInt(planData[0]?.beneficiaryCount) +  parseInt(planData[1]?.beneficiaryCount);
      if (
        planData[1]?.beneficiaryCount === "" ||
        planData[1]?.beneficiaryCount < 0 ||
        planData[1]?.beneficiaryCount % 1 !== 0
      ) {
        dispatch(
          setAlert({
            open: true,
            alertType: "error",
            message:
              "Additional beneficiary count cannot less than 0 or invalid number.",
          })
        );
      } else if(planData[1]?.beneficiaryCount > 99){
        console.log(planData[1]?.beneficiaryCount, "829");
        
        return dispatch(
          setAlert({
            open: true,
            alertType:"error",
            message: "Beneficiary count should not be greater than 99.",
          })
        )
      }
      else if (
        parseInt(totalBenCount?.toString()) <
        parseInt(activeBenCount?.toString())
      ) {
        setDowngrade(true);
      } else {
        setReview(true);
      }
    } else if (selectedSubscription === "Devices") {
      let deviceQty = tableData?.reduce((acc: any, item: any) => {
        if (item?.quantity < 1 || item?.quantity % 1 !== 0 || item.quantity > 99) {
          acc = true;
        }
        return acc;
      }, false);
      if (tableData && tableData?.length === 0) {
        dispatch(
          setAlert({
            open: true,
            alertType: "error",
            message: "Please select devices to continue.",
          })
        );
      } else if (deviceQty) {
        return dispatch(
          setAlert({
            open: true,
            alertType: "error",
            message: "Enter device quantity between 1-99",
          })
        );
      } else {
        console.log(tableData?.length, "length");
        dispatch(
          setAlert({
            open: true,
            alertType: "success",
            message: "Device type selected successfully.",
          })
        );
        setChangeDeviceTypeModal(true);
        setShippingAdressModal(true);
      }
    }
  };

  const closeModal = () => {
    reset();
    setChangeDeviceTypeModal(false);
    setDevice([]);
    setTableData([]);
    setDeviceTypes([]);
    setDeviceTypesName([]);
    setAllCheck(false);
    setDeviceTypeAllCheck(false);
    setBenCounting(
      beneficiaryAccounts?.activeBeneficiaries +
        beneficiaryAccounts?.invitedBeneficiaries
    );
  };

  const [deviceTotalSum, setDeviceTotalSum] = useState<any>(0);

  useEffect(() => {
    const sum = tableData.reduce((acc: any, row: any) => {
      let data = row?.deviceCostings?.filter((item: any) => {
        return item?.country === planData[0]?.country;
      });
      if (data && data?.length > 0) {
        if (userInfo?.country === "IN") {
          return (
            acc +
            data[0]?.value * row?.quantity +
            (data[0]?.value * row?.quantity * row?.igst) / 100
          );
        } else {
          return acc + data[0]?.value * row?.quantity;
        }
      } else {
        return 0;
      }
    }, 0);
    setDeviceTotalSum(sum);
  }, [tableData, userInfo]);

  const customLocaleText = {
    noRowsLabel: 'No data found'
  };

  return (
    <>
      <Loader show={isLoading || isLoading1} />
      <Modal
        handleClose={closeModal}
        open={changeDeviceTypeModal}
        primaryText={
          selectedSubscription === "Beneficiary"
            ? "Add/Remove Beneficiaries"
            : "Add Device Type"
        }
        className="input-padding modal-title outer-pd-device reviewOrderModal color"
        xs={10}
        sm={10}
        md={10}
        lg={10}
        xl={10}
      >
        {/* <form> */}
        <Grid container>
          {selectedSubscription === "Devices" ? (
            <></>
          ) : (
            <>
              <Grid item xs={12}>
                {/* <p className="tableHeading">Beneficiaries Supported </p> */}
                {/* <p className="tableHeading">Beneficiary Count</p> */}
                <div className="createPlanTable ">
                  <div style={{ width: "100%" }} className="totalData">
                    <DataGrid
                      columns={columns2}
                      hideFooterPagination
                      disableRowSelectionOnClick
                      disableColumnFilter
                      disableColumnSelector
                      disableColumnMenu
                      sx={{
                        "& .MuiDataGrid-row:hover": {
                          backgroundColor: "white",
                        },
                      }}
                      autoHeight
                      rows={planData}
                      // getRowId={(row) => row.id}
                      // getRowId={(device) => device?.id}
                    />
                    <div className="updateBentotal">
                      <span>New monthly subscription total&nbsp;: &nbsp;</span>
                      <span>
                        {planData && planData?.length > 0
                          ? planData[0]?.currency
                          : null}{" "}
                        {handlePriceFormatting(
                          totalSum,
                          userInfo?.country === "IN" ? "IN" : "US"
                        )}
                      </span>
                    </div>
                  </div>
                    <div
                      // style={{
                      //   visibility: selectedPlanData?.couponDetails?.type !== "productDiscount" || selectedPlanData?.couponDetails !== null
                      //     ? "inherit"
                      //     : "hidden",
                      // }}
                      className="updateBenNote"
                    >
                      { selectedPlanData?.couponDetails?.type === "productDiscount" || selectedPlanData?.couponDetails === null ?
                      (<span></span>) :

                      (<span>
                        Note:You have an active coupon with your subscription.
                        This coupon will also be applied to any new additions to
                        your subscription.
                      </span>
                      )}
                    </div>
                    
                </div>
              </Grid>
            </>
          )}
          {/* <Divider className="hr" /> */}




          {selectedSubscription === "Beneficiary" ? (
            <></>
          ) : (
            <>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={9.5}>
                      <p
                        className="tableHeading deviceBundleptag"
                        style={{ fontSize: "18px" }}
                      >
                        Existing Biometric Device Type
                      </p>
                    </Grid>

                    {/* <Grid item xs={2.5}>
                    {showAddDeviceButton && <Grid container>
                      <Grid item xs={12} sx={{ mt: 2 }}>
                        <Buttons type="primary" variant="contained" text="Add Device" onClick={handlAddDevice} />
                      </Grid>
                    </Grid>}
                  </Grid> */}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <div className="deviceList">
                    {/* <li className="listHeader">
                      <span className="headerItem">Device Type</span>
                  
                    </li> */}

                    {/* Dummy device data */}
                    {selectedPlanData?.devices?.map(
                      (device: any, index: any) => (
                        <span className="deviceItem" key={index}>
                          <span className="deviceInfo deviceFontSize">
                            {labels[device?.DeviceType?.biometricType]}
                            {index !== selectedPlanData?.devices?.length - 1
                              ? ","
                              : ""}
                          </span>
                          {/* <span className="deviceInfo">{device.model}</span> */}
                          {/* <span className="deviceInfo">{device.quantity}</span> */}
                        </span>
                      )
                    )}
                  </div>
                </Grid>
              </Grid>

              <br />

              {deviceTypesName && deviceTypesName?.length > 0 ? (
                <>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Grid container>
                      <Grid item xs={9}>
                        <p className="tableHeading deviceBundleptag">
                          Add Biometric Devices
                        </p>
                      </Grid>
                      {/* <Grid item xs={3} sx={{ mt: 2 }} className="justifyContent">
                      <Buttons type="secondary" variant="contained" text="Cancel" onClick={handleDeviceCancel} />
                    </Grid> */}
                    </Grid>

<Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Grid container columnSpacing={4}>
                        <Grid
                          item
                          xs={12}
                          sx={{ mt: 4 }}
                          className="selectInput-title"
                        >
                          <Controller
                            render={({
                              field: { ref, ...field },
                              fieldState: { invalid, error },
                            }) => (
                              <CheckboxSelectInput
                                {...field}
                                label="Select Device Type"
                                required={true}
                                placeholder={
                                  deviceTypesName?.length
                                    ? "Select device types"
                                    : " No devices available"
                                }
                                value={deviceTypes}
                                error={Boolean(error)}
                                helperText={
                                  error?.message ? error?.message : " "
                                }
                                onChange={(e: any) => {
                                  field.onChange(e);
                                  onDeviceTypesChange(e, field);
                                }}
                                inputRef={ref}
                                multiple={true}
                              >
                                {deviceTypesName &&
                                deviceTypesName?.length > 0 ? (
                                  <MenuItem key="all" value="all">
                                    <div>
                                      <ListItemText primary="All" />
                                    </div>
                                    <Checkbox
                                      className="checkboxBox"
                                      checked={deviceTypeAllCheck}
                                    />
                                  </MenuItem>
                                ) : null}
                                {deviceTypesName?.map((option: any) => {
                                  return (
                                    <MenuItem
                                      key={option?.value}
                                      value={option}
                                    >
                                      <div>
                                        <ListItemText primary={option?.text} />
                                        <CustomToolTip
                                          title={option?.description}
                                          placement="top"
                                        >
                                          <ListItemText
                                            className="sliceWidth"
                                            secondary={option?.description}
                                          />
                                        </CustomToolTip>
                                      </div>
                                      <Checkbox
                                        className="checkboxBox"
                                        checked={
                                          deviceTypes?.findIndex(
                                            (item: any) =>
                                              item?.value === option?.value
                                          ) >= 0
                                        }
                                      />
                                    </MenuItem>
                                  );
                                })}
                              </CheckboxSelectInput>
                            )}
                            name="devicesTypes"
                            control={control}
                            rules={{
                              required: "Please select product bundle",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container columnSpacing={4}>
                        <Grid
                          item
                          xs={12}
                          sx={{ mt: 4 }}
                          className="selectInput-title"
                        >
                          <Controller
                            render={({
                              field: { ref, ...field },
                              fieldState: { invalid, error },
                            }) => (
                              <CheckboxSelectInput
                                {...field}
                                label="Select Device Model Type"
                                required={true}
                                labelArray={[labels]}
                                placeholder={
                                  deviceName?.length
                                    ? "Select device model type"
                                    : " No devices available"
                                }
                                value={device}
                                error={Boolean(error)}
                                helperText={
                                  error?.message ? error?.message : " "
                                }
                                onChange={(e: any) => {
                                  field.onChange(e);
                                  onProductBundleChange(e, field);
                                }}
                                inputRef={ref}
                                multiple={true}
                                disabled={
                                  deviceTypes && deviceTypes?.length > 0
                                    ? false
                                    : true
                                }
                              >
                                {deviceName && deviceName?.length > 0 ? (
                                  <MenuItem key="all" value="all">
                                    <div>
                                      <ListItemText primary="All" />
                                    </div>
                                    <Checkbox
                                      className="checkboxBox"
                                      checked={allCheck}
                                    />
                                  </MenuItem>
                                ) : null}
                                {deviceName?.map((option: any) => {
                                  return (
                                    <MenuItem
                                      key={option?.value}
                                      value={option}
                                    >
                                      <div>
                                        <ListItemText primary={labels[option?.text]} />
                                        <CustomToolTip
                                          title={option?.description}
                                          placement="top"
                                        >
                                          <ListItemText
                                            className="sliceWidth"
                                            secondary={option?.description}
                                          />
                                        </CustomToolTip>
                                      </div>
                                      <Checkbox
                                        className="checkboxBox"
                                        checked={
                                          device.findIndex(
                                            (item: any) =>
                                              item?.value === option?.value
                                          ) >= 0
                                        }
                                      />
                                    </MenuItem>
                                  );
                                })}
                              </CheckboxSelectInput>
                            )}
                            name="devices"
                            control={control}
                            rules={{
                              required: "Please select product bundle type",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                      <div className="createPlanTable">
                        <div style={{ position: "relative", width: "100%" }}>
                          <DataGrid
                            // paginationMode="server"
                            // autoPageSize
                            columns={columns}
                            hideFooterPagination
                            columnVisibilityModel={{
                              tax: userInfo?.country === "IN" ? true : false,
                            }}
                            disableRowSelectionOnClick
                            disableColumnFilter
                            disableColumnSelector
                            disableColumnMenu
                            sx={{
                              "& .MuiDataGrid-row:hover": {
                                backgroundColor: "white",
                              },
                            }}
                            autoHeight
                            rows={tableData}
                            getRowId={(id) => id.value}
                            localeText={customLocaleText}
                          />
                          <div
                            style={{
                              position: "absolute",
                              bottom: 15,
                              right: 14,
                              fontWeight: "bold",
                            }}
                            className="total"
                          >
                            One-Time &nbsp;: &nbsp;
                            {userInfo?.country === "IN" ? "INR" : "USD"}{" "}
                            {handlePriceFormatting(
                              deviceTotalSum,
                              userInfo?.country === "IN" ? "INR" : "USD"
                            )}
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>You already have all device types.</>
              )}
            </>
          )}

          {deviceTypesName && deviceTypesName?.length > 0 ? (
            <Grid container>
              <Grid item xs={12} sx={{ mt: 4 }}>
                <Grid container spacing={4} justifyContent={"flex-end"}>
                  <Grid item xs={2.5}>
                    <Buttons
                      type="secondary"
                      text="CANCEL"
                      variant="contained"
                      onClick={closeModal}
                    />
                  </Grid>
                  <Grid item xs={2.5}>
                    <Buttons
                      type="primary"
                      text="NEXT"
                      variant="contained"
                      buttonType="submit"
                      onClick={handleNextClick}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : selectedSubscription === "Beneficiary" ? (
            <Grid container>
              <Grid item xs={12} sx={{ mt: 4 }}>
                <Grid container spacing={4} justifyContent={"flex-end"}>
                  <Grid item xs={2.5}>
                    <Buttons
                      type="secondary"
                      text="CANCEL"
                      variant="contained"
                      onClick={closeModal}
                    />
                  </Grid>
                  <Grid item xs={2.5}>
                    <Buttons
                      type="primary"
                      text="NEXT"
                      variant="contained"
                      buttonType="submit"
                      onClick={handleNextClick}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        {/* </form> */}
      </Modal>

      <DowngradeBeneficiaryModal
        downgrade={downgrade}
        setDowngrade={setDowngrade}
        benCounting={benCounting}
        setReview={setReview}
      />

      <DeviceShippingAddress
        shippingAddressModal={shippingAddressModal}
        setShippingAdressModal={setShippingAdressModal}
        deviceDetails={deviceDetails}
        setReview={setReview}
        planData={planData}
      />

      <SubscriptionReviewModal
        refetchPlans={refetchPlans}
        allowedBen={
          parseInt(planData[0]?.beneficiaryCount) + parseInt(planData[1]?.beneficiaryCount)
        }
        review={review}
        planData={planData}
        selectedSubscription={selectedSubscription}
        setReview={setReview}
        totalSum={totalSum}
        setShippingAdressModal={setShippingAdressModal}
        setChangeDeviceTypeModal={setChangeDeviceTypeModal}
      />
    </>
  );
};
