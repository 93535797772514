import Grid from "@mui/material/Grid";
import {
  fphLogo,
  notifiationIcon,
  notificationIconA,
  userIcon,
} from "../../../assets/icons";
import "./style.css";
import { AppBar, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { Navigate, Route, useLocation, useNavigate } from "react-router-dom";
import { Dashboard } from "../../pages/dashboard";
import { useEffect, useState } from "react";
import { Page } from "../../../routes/config";
import { ConfirmModal } from "../confirmmodal";
import { Roles, UserRoles } from "../../../constants/enum";
import { Messages } from "../../../constants/messages";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthState, logOut } from "../../../redux/features/auth/authSlice";
import { useLogOutMutation } from "../../../redux/services/authApiSlice";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { clearAllStates } from "../../../utils";
import { PasswordModal } from "../../pages/settings/modal/passwordModal";
import { clearFilterState } from "../../../redux/features/filters/filtersSlice";

export const Header = () => {
  const steps = useSelector((state: any) => state?.userSteps?.activeSteps);
  const { userInfo, fullName } = useSelector((state: any) => state?.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signOutModal, setSignOutModal] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const location = useLocation();
  const currentRoute = location.pathname;
  const [anchorEl, setAnchorEl] = useState(null);
  const [userLogOut, userLogOutData] = useLogOutMutation();

  const handleSignOut = () => {
    setSignOutModal(true);
    setAnchorEl(null);
  };

  const handleAccount = () => {
    navigate(Page.ACCOUNTSETTING);
    setAnchorEl(null);
  };

  const onCancelcallBack = () => {
    setSignOutModal(false);
  };
  const onConfirmCallBack = () => {
    setSignOutModal(false);
    userLogOut({ email: userInfo?.email });
    clearAllStates(dispatch);
    navigate("/");
    localStorage.clear();
    // window.location.href = "/";
  };

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    dispatch(clearFilterState());
  };

  const handleNotificationClick = () => {
    dispatch(clearFilterState());
    navigate(Page.NOTIFICATION);
  };

  const handleClose = (event: any) => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (userLogOutData?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "logout Successfully",
        })
      );
    }
  }, [userLogOutData?.isSuccess]);

  const openPasswordModal = () => {
    setChangePassword(true);
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        className="muiAppBarHeader my-header"
        id="appbar"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Grid container className="headerMain">
          <Grid item xs={4} className="fphLogo">
            <img src={fphLogo} className="logowithText" />
          </Grid>
          {(userInfo?.roleName == UserRoles.SUBSCRIBER && steps < 5) ||
          location.pathname.toLowerCase() == Page.PAYMENTSTATUS ? (
            <Grid item xs={8} className="flex-end">
              <span className="headerSignOut" onClick={handleSignOut}>
                Sign Out
              </span>
            </Grid>
          ) : (
            <Grid item xs={8} className="flex-end">
              <IconButton
                className="notificationIcon"
                onClick={handleNotificationClick}
              >
                <img src={notificationIconA} />
              </IconButton>
              {/* {userInfo?.roleName != UserRoles.SUBSCRIBER ? ( */}
              <IconButton className="userIcon" onClick={handleMenuClick}>
                <img src={userIcon} />
              </IconButton>
              {/* ) : (
                <img className="userIcon" src={userIcon} />
              )} */}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{ width: "4000px" }}
              >
                {userInfo?.roleName === UserRoles?.SUBSCRIBER ? (
                  <MenuItem onClick={handleSignOut}>
                    &nbsp;&nbsp;&nbsp;&nbsp;Logout&nbsp;&nbsp;&nbsp;&nbsp;
                  </MenuItem>
                ) : (
                  <>
                    <MenuItem onClick={handleAccount}>My Account</MenuItem>
                    <Divider className="divider-mp" />
                    <MenuItem onClick={openPasswordModal}>
                      Change Password
                    </MenuItem>
                    <Divider className="divider-mp" />
                    <MenuItem onClick={handleSignOut}>Logout</MenuItem>
                  </>
                )}
              </Menu>
              <div className="headerRole">
                <span className="profileText f-14">{fullName}</span>
                <span className="profileText f-12">{userInfo?.roleName}</span>
              </div>
            </Grid>
          )}
        </Grid>
      </AppBar>
      <div className="modelModal">
        <PasswordModal
          changePassword={changePassword}
          setChangePassword={setChangePassword}
          modal={true}
        />
        <ConfirmModal
          className="confirmModal"
          key="signOut"
          open={signOutModal}
          primaryText={Messages.SIGNOUT}
          cancelButton="NO"
          confirmButton="YES"
          cancelCallback={onCancelcallBack}
          confirmCallback={onConfirmCallBack}
        />
      </div>
    </>
  );
};
