import Buttons from "../../common/button";
import { Input } from "../../common/input";
import { Modal } from "../../common/modal/modal";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'
import { FormControl, FormHelperText, Grid, InputLabel } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

export const PlanDetailsModal = (props: any) => {
  const { open, onClose, setOpen, planDetails, setPlanDetails, setReviewModal, isUpdate, updatePlan } = props;
  const { handleSubmit, control } = useForm({
    mode: "onChange" || "onSubmit",
    defaultValues: {
     description: planDetails?.description || "",
     remarks: planDetails?.remarks || ""
    },
  });
  var regex = /(<([^>]+)>)/ig

  const handleDetailsSubmit = (data:any) => {
   setPlanDetails({...planDetails, ...data})
   setReviewModal(true)
 }

 const modules = {
    toolbar: [
      [{ "header": [1,2,3, false]}], 
      ['bold', 'italic', 'underline','strike'],
      [{'list': 'bullet'}],
      // ['link', 'image'],
    ],
  }
  return (
    <Modal
      open={open}
      handleClose={onClose}
      showDivider={true}
      primaryText="Plan Details"
      xs={12}
      className={"modal-title outer-padding newPartnerCreate"}
    >
      <form onSubmit={handleSubmit(handleDetailsSubmit)}>
        <Grid container columnSpacing={4} sx={{mt:2}}>
          <Grid
            item
            sx={{ alignSelf: "self-end" }}
            xs={12}
            className="selectInput-title"
          >
            <Controller
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                
                <Input
                  {...field}
                  label="Plan Sub Details"
                  required={true}
                  placeholder="Enter one-line description for plan."
                  value={field.value}
                  error={Boolean(error)}
                  helperText={error?.message ? error?.message : " "}
                  onChange={field.onChange}
                />
              )}
              name="description"
              rules={{
                required: "Please enter a description",
              }}
              control={control}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }} className="input-title" style={{minHeight: 200}}>
            <Controller
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <FormControl variant="standard" sx={{ width: "100%" }}>
                <InputLabel
                  shrink
                  className={"labelClass"}
                  htmlFor="details-input"
                  required={true}
                  error={Boolean(error)}
                >
                 Plan Details
                </InputLabel>
                <ReactQuill
                    className={Boolean(error)? "richEditor richTextError" : "richEditor"}
                    theme='snow'
                    id="details-input"
                    value={field.value}
                    onChange={field?.onChange}
                    modules={modules}
                    style={{marginTop:"32px"}}
                  />
                <FormHelperText className={`helperClass slice`}>
                  {error?.message ? error?.message : " "}
                </FormHelperText>
              </FormControl>
              )}
              name="remarks"
              rules={{
                required: "Please enter plan details",
                validate: (value, formValues) => {
                    if (value?.replace(regex, "").length <= 0) {
                      return "Please enter plan details.";
                    }
                  }
              }}
              control={control}
            />
          </Grid>
        </Grid>
        
        <Grid container columnSpacing={4} justifyContent={"center"} sx={{mt:2}}>
        <Grid item xs={4.5} sx={{ mr: 4 }}>
            <Buttons
              type="secondary"
              variant="contained"
              text="Back"
              onClick={()=>setOpen(false)}
            ></Buttons>
          </Grid>

          <Grid item xs={4.5}>
            <Buttons
              type="primary"
              variant="contained"
              buttonType="submit"
              text="Preview"
            ></Buttons>
          </Grid>
        </Grid>
      </form>
    </Modal>
  );
};
