import React, { useEffect, useState } from "react";
import { Table } from "../../common/table";
import {
  GridAddIcon,
  GridColDef,
  GridPaginationModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import "./style.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { AddDeviceModal } from "./addDeviceModal";
import Buttons from "../../common/button";
import { useGetDevicesMutation } from "../../../redux/services/devicesApiSlice";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { handleErrors, handlePriceFormatting } from "../../../utils";
import Loader from "../../common/loader/loader";
import { setPaginationFilter } from "../../../redux/features/filters/filtersSlice";

export const ManageDevice = (props: any) => {
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deviceRows, setDeviceRows] = useState<any>([]);
  const [rowCount, setRowCount] = useState(0);
  const [deviceId, setDeviceId] = useState<any>();
  const [getDevices, getDevicesData] = useGetDevicesMutation();
  const [tableHeight, setTableHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const filtersData = useSelector((state: any) => state.filters);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: filtersData?.pageSize,
    page: filtersData?.page,
  });

  const handleMenuClick = (event: any, id: any) => {
    setAnchorEl(event?.currentTarget);
    setDeviceId(deviceRows[id]?.deviceTypeId);
  };

  const handleClose = (event: any) => {
    setAnchorEl(null);
  };

  const handleEdit = (event: any) => {
    setAnchorEl(null);
    setIsEdit(true);
    setOpen(true);
  };

  const handleAddDevice = () => {
    setOpen(true);
    setIsEdit(false);
  };

  useEffect(() => {
    getDevices({
      page: paginationModel?.page + 1,
      limit: paginationModel?.pageSize,
    });
  }, [paginationModel]);

  const labels: any = {
    ["Blood Glucose"]: "Glucometer",
    ["Blood Pressure"]: "BP Monitor",
    ["Temperature"]: "Thermometer",
    ["PulseOx"]: "Pulse Oximeter",
    ["Weight"]: "Weighing Scale",
  };

  const columns: GridColDef[] = [
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      minWidth: 150,
      cellClassName: "cell-padding textCapital",
      headerAlign: "left",
      sortable: false,
      flex: 1,
    },
    {
      field: "biometricType",
      headerName: "Type",
      headerAlign: "left",
      cellClassName: "cell-padding",
      minWidth: 150,
      sortable: false,
      flex: 2,
      renderCell:(params:any) =>{
        return(
          <>
          <span>{labels[params?.row?.biometricType]}</span>
          </>
        )
      }
    },
    {
      field: "model",
      headerName: "Model No.",
      minWidth: 200,
      cellClassName: "cell-padding",
      headerAlign: "left",
      align: "left",

      sortable: false,
      flex: 2,
    },
    {
      field: "inrCost",
      headerName: "Cost\n(INR)",
      headerAlign: "center",
      align: "center",
      cellClassName: "cell-padding",
      headerClassName: "lineBreak",
      type: "number",
      minWidth: 110,
      sortable: false,
      flex: 1,
      renderCell: (params: any) => {
        const data = params?.row?.DeviceCostings?.filter(
          (item: any) => item?.country === "IN"
        );
        console.log(data,"oopppppppppppppppp")
        return data && data?.length > 0 ? handlePriceFormatting(data[0]?.value,data[0]?.country) : null;
      },
    },
    {
      field: "usdCost",
      headerName: "Cost\n(USD)",
      headerAlign: "center",
      align: "center",
      cellClassName: "cell-padding",
      headerClassName: "lineBreak",
      type: "number",
      minWidth: 110,
      sortable: false,
      flex: 1,
      renderCell: (params: any) => {
        const data = params?.row?.DeviceCostings?.filter(
          (item: any) => item?.country === "US"
        );
        console.log(data, "dddd");
        return data && data?.length > 0 ? handlePriceFormatting(data[0]?.value,data[0]?.country) : null;
      },
    },
    {
      field: "cgst",
      headerName: "CGST (%)",
      headerAlign: "center",
      align: "center",
      type: "number",
      cellClassName: "cell-padding",
      minWidth: 110,
      valueFormatter: ({ value }: any) => `${value}%`,
      sortable: false,
      flex: 1,
    },
    {
      field: "sgst",
      headerName: "SGST (%)",
      headerAlign: "center",
      align: "center",
      type: "number",
      cellClassName: "cell-padding",
      valueFormatter: ({ value }: any) => `${value}%`,
      minWidth: 110,
      sortable: false,
      flex: 1,
    },
    {
      field: "igst",
      headerName: "IGST (%)",
      headerAlign: "center",
      align: "center",
      cellClassName: "cell-padding",
      valueFormatter: ({ value }: any) => `${value}%`,
      type: "number",
      minWidth: 110,
      sortable: false,
      flex: 1,
    },
    {
      field: "sourceState",
      headerName: "Source State",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "cell-padding textCapital",
      sortable: false,
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: "Create Date",
      align: "left",
      cellClassName: "cell-padding",
      headerAlign: "left",
      minWidth: 150,
      sortable: false,
      flex: 1,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      align: "left",
      headerAlign: "left",
      minWidth: 150,
      cellClassName: "cell-padding",
      sortable: false,
      flex: 1,
    },
    {
      field: "hsn",
      headerName: "HSN Code",
      align: "center",
      headerAlign: "center",
      minWidth: 150,
      cellClassName: "cell-padding",
      sortable: false,
      flex: 1,
    },
    {
      field: "action",
      sortable: false,
      align: "left",
      headerAlign: "left",
      headerClassName: "stickyColumn",
      headerName: "",
      cellClassName: "stickyCell cell-padding",
      width: 2,
      renderCell: (params) => (
        <IconButton
          onClick={(event: any) => handleMenuClick(event, params?.row?.id)}
        >
          <MoreVertIcon />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    if (getDevicesData?.isSuccess) {
      const data = getDevicesData?.data?.result.map((row: any, index: any) => ({
        id: index,
        createdDate: dayjs(row?.createdAt).format("DD MMM, YYYY"),
        ...row,
      }));
      setRowCount(getDevicesData?.data?.count);
      setDeviceRows(data);
    }
  }, [getDevicesData?.isSuccess]);

  const dispatch = useDispatch();

  useEffect(() => {
    handleErrors(getDevicesData, dispatch);
  }, [getDevicesData?.isError]);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
    dispatch(setPaginationFilter(newPaginationModel));
  };

  const calHeaight = () => {
    let targetCompo,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetCompo = window.document.querySelector<HTMLElement>(".manageDevice");
    targetElement = targetCompo?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 25);
    setTableHeight(tableHeight);
  };

  useEffect(() => {
    calHeaight();
    const resizeObserver = new ResizeObserver(() => {
      calHeaight();
      setScreenWidth(window.innerWidth);
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <>
      <Loader show={getDevicesData?.isLoading} />
      <Grid container marginBottom={2}>
        <Grid item xs={12} sm={7} md={9} xl={10.42}>
          <span className="subscriberColumn1">Manage Device</span>
          <span className="countFonts">({rowCount})</span>
        </Grid>
        <Grid item xs={12} sm={5} md={3} xl={1.58}>
          <Buttons
            type="primary"
            variant="contained"
            onClick={handleAddDevice}
            text={"Add Device"}
            primaryBtnTextClass={"createCouponBtnText"}
          ></Buttons>
        </Grid>
      </Grid>
      <div className="manageDevice">
        <Table
          rows={deviceRows}
          columns={columns}
          rowCount={rowCount}
          isPagination={true}
          onPaginationModelChange={handlePaginationModelChange}
          pagination={paginationModel}
          tableHeight={tableHeight}
        />
      </div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ width: "4000px" }}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        {/* <Divider/> */}
        {/* <MenuItem onClick={handleClose}>Delete</MenuItem>    */}
      </Menu>
      {open && (
        <AddDeviceModal
          paginationModel={paginationModel}
          getDevices={getDevices}
          deviceId={deviceId}
          setIsEdit={setIsEdit}
          isEdit={isEdit}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
};
