import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Header } from "../common/header/header";
import Sidebar from "../common/sidebar/sidebar";
import {
  Box,
  Drawer,
  AppBar,
  useMediaQuery,
  Button,
  DialogActions,
  Divider,
} from "@mui/material";
import "./style.css";
import Card from "@mui/material/Card";
import { Page } from "../../routes/config";
import { UserRegistration } from "../pages/auth/userRegistration";
import { useDispatch, useSelector } from "react-redux";
import { MessageAlert } from "../common/alert/alert";
import { UserRoles } from "../../constants/enum";
import { setAlert } from "../../redux/features/alert/alertSlice";
import { useGetAllRoleMutation } from "../../redux/services/roleApiSlice";
import { setRole } from "../../redux/features/role/roleSlice";
import {
  setCountry,
  setSuppCountries,
  setSuppCountriesNames,
} from "../../redux/features/country/countrySlice";
import { Messages } from "../../constants/messages";
import { BootstrapDialog, ConfirmModal } from "../common/confirmmodal";
import { clearAllStates } from "../../utils";
import {
  useGetIpQuery,
  useGetSupportedCountriesQuery,
} from "../../redux/services/authApiSlice";
import { setIpAddress } from "../../redux/features/auth/authSlice";
import { cCodes, cNames } from "../../constants/constant";

const UILayout = (props: any) => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const steps = useSelector((state: any) => state?.userSteps?.activeSteps);
  const { userInfo, sessionExpirationCheck } = useSelector(
    (state: any) => state?.auth
  );
  // const [countries, setCountries] = useState<any>([]);
  const [getRole, getRoleInfo] = useGetAllRoleMutation();

  const {
    data,
    isSuccess,
    refetch,
    isError,
    fulfilledTimeStamp,
    error,
    isLoading,
  } = useGetIpQuery({
    refetchOnMountOrArgChange: true,
  });

  const { data: data1, isSuccess: isSuccess1 } = useGetSupportedCountriesQuery({
    refetchOnMountOrArgChange: true,
  });

  console.log(data1, "countryData");

  const [height, setHeight] = useState(0);

  // const drawerWidth = 314;

  // const [collapsed, setCollapsed] = useState(false);

  const match = useMediaQuery("(min-width:1300px)");

  const { open, message } = useSelector((state: any) => state.alert);

  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(setAlert({ open: false, alertType: "", message: message }));
    }, 5000);
  }, [open]);

  useEffect(() => {
    getRole({
      refetchOnMountOrArgChange: true,
    });
  }, []);

  const handleConfirmModalClose = () => {
    clearAllStates(dispatch);
  };

  useEffect(() => {
    if (getRoleInfo?.isSuccess) {
      const roles = getRoleInfo?.data?.result?.map((role: any) => {
        return { [role?.roleName]: role?.roleId };
      });
      const result = roles.reduce((obj: any, item: any) => {
        const key = Object.keys(item)[0];
        obj[key] = item[key];
        return obj;
      }, {});
      dispatch(
        setRole({ roleId: result, roleData: getRoleInfo?.data?.result })
      );
    }
  }, [getRoleInfo?.isSuccess]);

  useEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setIpAddress({ ipAddress: data?.ipAddress }));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccess1) {
      dispatch(
        setCountry({ supportedCountries: data1?.result[0]?.supportedCountries })
      );
      let codeArray:any=[]
      let codeNameArray:any=[]
      let codesData = data1?.result[0]?.supportedCountries?.map(
        (countryObj: any, index: any) => {
          cCodes?.reduce((acc: any, codeObj: any) => {
            if (countryObj?.countryCode === codeObj?.countryCode) {
              codeArray?.push(codeObj);
            } else {
              return;
            }
            return codeArray;
          },[]);
        }
      );
      let namesData = data1?.result[0]?.supportedCountries?.map(
        (countryObj: any, index: any) => {
          cNames?.reduce((acc: any, codeObj: any) => {
            if (countryObj?.countryCode === codeObj?.value) {
              codeNameArray?.push(codeObj);
            } else {
              return;
            }
            return codeNameArray;
          },[]);
        }
      );
      dispatch(setSuppCountries({ countriesCodes: codeArray?.sort((a:any, b:any) =>
        a?.text.localeCompare(b?.text)
      )}));
      dispatch(setSuppCountriesNames({ countriesNames: codeNameArray.sort((a:any, b:any) =>
        a?.text.localeCompare(b?.text)
      ) }));
    }
  }, [isSuccess1, cCodes]);

  return (
    <>
      <Header />
      <Box className="mainBox" sx={{ display: "flex" }}>
        <MessageAlert></MessageAlert>
        {userInfo?.roleName == UserRoles.SUBSCRIBER && steps < 5 ? (
          <UserRegistration />
        ) : (
          <>
            {" "}
            <Drawer
              variant="permanent"
              sx={{
                width: match ? "15.7vw" : 211,
                minWidth: 150,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                  minWidth: 150,
                  width: match ? "15.7vw" : 211,
                  position: "sticky",
                  top: 0,
                },
              }}
            >
              <Sidebar />
            </Drawer>
            <Box
              component="main"
              sx={{ flexGrow: 1, p: 4 }}
              className="mainPage"
              height={`${height}px !important`}
            >
              <Outlet />
            </Box>
          </>
        )}
      </Box>
      <div className="modelModal">
        <div className="ui-Confirm-Modal">
          <BootstrapDialog
            // onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={sessionExpirationCheck}
            className="confirmModal"
          >
            {/* <img src={crossIcon}/> */}
            <div className="normalText redText">{Messages?.SESSIONEXPIRED}</div>

            <Divider />

            <DialogActions className="buttonsDisplay">
              <Button className="confirmbtn" onClick={handleConfirmModalClose}>
                OK
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </div>
      </div>
    </>
  );
};

export default UILayout;
