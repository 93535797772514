import { Navigate, Outlet } from "react-router-dom";

import { Page } from "./config";


export const ProtectedRoute = ({ isLoggedIn }: any) => {
    return (
        !isLoggedIn ?
            <Navigate to={Page.LOGIN} replace />
            :
            <Outlet/>
    );
}

