import { createSlice } from '@reduxjs/toolkit'

interface UserStepState {
  activeSteps:number,
  completedSteps:{[k: number]:boolean},
  planSelected:boolean
}

const initialState = {activeSteps:1, completedSteps:{ [0]: true },planSelected:false} as UserStepState

const userStepSlice = createSlice({
  name: 'userSteps',
  initialState,
  reducers: {
    setUserSteps(state, {payload}) { 
      state.activeSteps=payload?.activeSteps;
      state.completedSteps=payload?.completedSteps;
      state.planSelected=payload?.planSelected;
    },
    clearUserStepState:(state)=>state=initialState,
  },
})

export const { setUserSteps,clearUserStepState } = userStepSlice.actions
export default userStepSlice.reducer
export const getUserStepInfo=(state:any)=>state.userSteps;