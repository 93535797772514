import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputLabel, ListItemText, MenuItem, Radio, RadioGroup } from "@mui/material";
import { Modal } from "../../../common/modal/modal";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../../common/input";
import Buttons from "../../../common/button";
import { RegExpressions } from "../../../../constants/regExp";
import {
  CheckboxSelectInput,
  CountryCodeSelectInput,
  SelectInput,
} from "../../../common/selectinput";
import { cCodes } from "../../../../constants/constant";
import { useEffect, useRef, useState } from "react";
import {
  handleErrors,
  handleInputFocus,
  phoneNumberAutoFormat,
  getCountryByPhoneCode
} from "../../../../utils";
import { DatePickers } from "../../../common/datepicker";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import {
  useAddNewBeneficaryMutation,
  useGetAllUsersofSubscriberMutation,
  useUpdateUserMutation,
} from "../../../../redux/services/userApiSlice";
import dayjs from "dayjs";
import Loader from "../../../common/loader/loader";
import { UserRoles } from "../../../../constants/enum";
import { useGetAccountSettingsQuery } from "../../../../redux/services/accountsettingsApiSlice";
import { State } from "country-state-city";

export const AddBeneficiaryModal = (props: any) => {
  const {
    addBeneficiaryModal,
    setAddBeneficiaryModal,
    selectedRowData,
    refetchData,
    editable,
    getSubscriptionPlanData,
  } = props;
  const { countriesCodes, supportedCountries, countriesNames } = useSelector(
    (state: any) => state?.countries
  );
  // const { userInfo } = useSelector((state: any) => state?.auth);
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { handleSubmit, setValue, control, getValues, trigger, reset } =
    useForm({ mode: "onChange" || "onSubmit" });
  const [key, setKey] = useState("");
  const [planOption, setPlanOption] = useState<any>();
  const [beneficiaryMembers, setBeneficiaryMembers] = useState<any>([]);
  const [allCheck, setAllCheck] = useState(false);
  const { userInfo } = useSelector((state: any) => state?.auth);
  const [optionData, setOptionData] = useState<any>([]);
  const [isDisabled, setIsDisabled] = useState<any>(true);
  console.log(optionData, "option");

  const genderValues = [
    { text: 'Male', value: 'male' },
    { text: 'Female', value: 'female' },
    { text: 'Others', value: 'others' },
  ]

  const [stateNames, setStateNames] = useState();
  const [selectedCountry, setSelectedCountry] = useState(false);
  const [addressOptional, setAddressOptional] = useState(false);

  const handleCountryChange = (e: any) => {
    setSelectedCountry(true);
    const stateNames: any = State.getStatesOfCountry(e?.target?.value).map(
      (states: any) => {
        return { text: states?.name, value: `${states?.isoCode}` };
      }
    );
    setStateNames(stateNames);
  }

  // API INTEGRATION
  const [addBenificiarydata, addBenificiaryInfo] =
    useAddNewBeneficaryMutation();
  const [selectedfamilyData, setSelectedFamilyData] = useState<any>();

  const [editBeneficiary, editBeneficiaryData] = useUpdateUserMutation();
  const [getAllUsersofSubscriber, AllUsersofSubscriberData] =
    useGetAllUsersofSubscriberMutation();
  const {
    data: selectedUserData,
    refetch,
    isError,
    error,
    isLoading,
    isSuccess,
  } = useGetAccountSettingsQuery(selectedRowData?.User?.userId, {
    refetchOnMountOrArgChange: true,
    skip: selectedRowData === undefined,
  });

  const handleMobileNumber = (e: any) => {
    if (hasPartnerPlan) {
      var value = handleInputFocus(e, key);
      setValue("mobileNumber", value);
      trigger("mobileNumber");
    }
  };

  const handleEnterAddress = (e: any) => {
    setAddressOptional(!e?.target?.checked);
    if(e?.target?.checked == false && !editable) {
      setValue("address1", null);
      setValue("address2", null);
      setValue("city", null);
      setValue("state", null);
      setValue("country", null);
      setValue("zipCode", null);
    }
  }

  useEffect(() => {
    if (userInfo?.roleName === UserRoles.SUBSCRIBER) {
      getAllUsersofSubscriber({
        subscriberId: userInfo?.userId,
      });
    }
  }, [addBeneficiaryModal]);

  useEffect(() => {
    setIsDisabled(!(optionData && optionData.length > 0))
  }, [optionData])

  useEffect(() => {
    if (AllUsersofSubscriberData?.isSuccess) {
      let data: any = [];
      let filteredData = AllUsersofSubscriberData?.data?.result?.users?.filter(
        (item: any) => item?.roleName === UserRoles?.FAMILY
      );
      console.log(filteredData, "filter Data");
      filteredData?.map((x: any) => {
        data.push({
          text: x?.User?.fullName,
          description: x?.User?.mobileNumber,
          value: x?.User?.userId,
        });
      });

      setOptionData(data);
    }
  }, [AllUsersofSubscriberData]);

  const onConfirmCallBack = (data: any) => {
    var familyArray = [];
    for (var i = 0; i < beneficiaryMembers?.length; i++) {
      familyArray.push(beneficiaryMembers[i]?.value);
    }
    let requestData: any = {
      userSubscriptionId: planOption[0]?.value,
      family: familyArray,
      beneficiary: [
        {
          mobileNumber: data?.mobileNumber?.replaceAll("-", ""),
          fullName: data?.fullName,
          countryCode: data?.countryCode?.split(" ")[0]
            ? data?.countryCode?.split(" ")[0]
            : "",
          gender: data?.gender,
          ...(data?.address1 || data?.address2 || data?.city || data?.state || data?.country || data?.zipCode
            ? {
              address: {
                address1: data?.address1,
                address2: data?.address2,
                city: data?.city,
                state: data?.state,
                country: data?.country,
                zipCode: data?.zipCode,
              },
            }
            : {}
          )
        },
      ],
    };
    if (data?.dob) {
      requestData.beneficiary[0].dob = dayjs(data?.dob)?.format("DD/MM/YYYY");
    }
    let editData: any = {
      userId: selectedRowData?.User?.userId,
      mobileNumber: data?.mobileNumber?.replaceAll("-", ""),
      countryCode: data?.countryCode?.split(" ")[0]
        ? data?.countryCode?.split(" ")[0]
        : "",
      fullName: data?.fullName,
      gender: data?.gender,
      family: familyArray,
      ...(data?.address1 || data?.address2 || data?.city || data?.state || data?.country || data?.zipCode
        ? {
          address: {
            address1: data?.address1,
            address2: data?.address2,
            city: data?.city,
            state: data?.state,
            country: data?.country,
            zipCode: data?.zipCode,
          },
        }
        : {}
      )
    };
    if (data?.dob) {
      editData.dob = dayjs(data?.dob)?.format("DD/MM/YYYY");
    }
    if (!editable) {
      addBenificiarydata(requestData);
    } else {
      editBeneficiary(editData);
    }
  };

  const onBeneficiarySelect = (e: any, field: any) => {
    const { name, value } = e?.target;
    var check = value?.indexOf("all");
    if (check !== -1 && allCheck === false) {
      let data: any = [];
      optionData?.map((x: any) => {
        data.push({
          text: x?.text,
          value: x?.value,
        });
      });
      setBeneficiaryMembers(data);
      setAllCheck(true);
    } else if (check !== -1 && allCheck === true) {
      setBeneficiaryMembers([]);
      setAllCheck(false);
    } else {
      if (allCheck === true) {
        // let data: any = [];
        let data = optionData?.filter((x: any) => {
          return x?.value !== value[value?.length - 1]?.value;
        });
        setAllCheck(false);
        setBeneficiaryMembers(data);
      } else {
        setBeneficiaryMembers(value);
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setSelectedFamilyData(selectedUserData);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (addBenificiaryInfo?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: addBenificiaryInfo?.data?.message,
        })
      );
      setValue("fullName", "");
      setAddBeneficiaryModal(false);
      reset({
        fullName: "",
        mobileNumber: "",
        dob: null,
      });
      refetchData();
    }
  }, [addBenificiaryInfo?.isSuccess]);

  useEffect(() => {
    handleErrors(addBenificiaryInfo, dispatch);
  }, [addBenificiaryInfo?.isError]);

  useEffect(() => {
    if (editBeneficiaryData?.isSuccess) {
      dispatch(
        setAlert({
          open: "true",
          alertType: "success",
          message: "Beneficiary updated successfully",
        })
      );
      setAddBeneficiaryModal(false);
      reset({
        fullName: "",
        mobileNumber: "",
        dob: null,
      });
      refetchData();
      // refetchDataOnAddFamily()
    }
  }, [editBeneficiaryData?.isSuccess]);

  useEffect(() => {
    handleErrors(editBeneficiaryData, dispatch);
  }, [editBeneficiaryData?.isError]);

  const onCancelcallBack = () => {
    setAddBeneficiaryModal(false);
    reset({
      fullName: "",
      mobileNumber: "",
      dob: null,
    });
  };

  useEffect(() => {
    if (selectedRowData && selectedfamilyData) {
      const beneficiary = selectedfamilyData?.result?.family.map((x: any) => {
        return optionData.filter((y: any) => {
          return y?.value === x;
        });
      });
      setBeneficiaryMembers(beneficiary?.map((item: any) => item[0]));
      const selectBeneficiary = beneficiary?.map((x: any) => {
        return x[0];
      });

      setValue("text", selectBeneficiary);
      if(!selectedRowData?.User?.address?.country) {
        setSelectedCountry(false);
      }
      let country = getCountryByPhoneCode(selectedRowData?.User?.countryCode);
      const countryCode = selectedRowData?.User?.countryCode;
      setValue("countryCode", (countryCode+" "+country?.isoCode));
      setValue("fullName", selectedRowData?.User?.fullName);
      var dt =
        selectedRowData?.User?.dob && selectedRowData?.User?.dob?.split("/");
      setValue(
        "dob",
        selectedRowData?.User?.dob ? dayjs(`${dt[1]}/${dt[0]}/${dt[2]}`) : null
      );
      setValue(
        "mobileNumber",
        phoneNumberAutoFormat(selectedRowData?.User?.mobileNumber)
      );
      setValue(
        "address1", selectedRowData?.User?.address?.address1
      )
      setValue(
        "address2", selectedRowData?.User?.address?.address2
      )
      setValue(
        "city", selectedRowData?.User?.address?.city
      )
      setValue(
        "country", selectedRowData?.User?.address?.country
      )
      setValue(
        "zipCode", selectedRowData?.User?.address?.zipCode
      )
      let allStates: any = State.getStatesOfCountry(selectedRowData?.User?.address?.country);
      allStates = allStates.map((ele: any) => {
        return {
          text: ele?.name,
          value: ele?.isoCode
        }
      });
      setStateNames(allStates);
      setSelectedCountry(true);
      const state = State.getStateByCode(selectedRowData?.User?.address?.state);
      setValue(
        "state", state?.isoCode
      )
      setValue("gender", selectedRowData?.User?.gender);
    }

  }, [selectedRowData, optionData, selectedfamilyData]);

  const [hasPartnerPlan, setHasPartnerPlan] = useState(false);

  useEffect(() => {
    // setValue("countryCode", "+91 IN");
    setTimeout(() => {
      if (inputRef.current) {
        inputRef?.current?.focus();
      }
    }, 0);
    const plan = getSubscriptionPlanData?.data?.result?.map((x: any) => {
      return {
        text: x?.SubscriptionPlan?.name,
        value: x?.userSubscriptionId,
      };
    });
    if (getSubscriptionPlanData?.data?.result[0]?.SubscriptionPlan?.PartnerPlan?.length > 0) {
      setHasPartnerPlan(true);
      setAddressOptional(false);
      setValue("countryCode", "+91 IN");
      setValue("country", "IN");
      setSelectedCountry(true);
      const stateNames: any = State.getStatesOfCountry("IN").map(
        (states: any) => {
          return { text: states?.name, value: `${states?.isoCode}` };
        }
      );
      setStateNames(stateNames);
    }
    setValue("planType", plan[0]?.value);
    setPlanOption(plan);
  }, []);

  return (
    <>
      <Loader
        show={addBenificiaryInfo?.isLoading || editBeneficiaryData?.isLoading}
      />
      <Modal
        key="addBeneficiary"
        open={addBeneficiaryModal}
        handleClose={() => {
          setAddBeneficiaryModal(false);
          reset({
            fullName: "",
            mobileNumber: "",
            dob: null,
          });
        }}
        primaryText={editable ? "Edit Beneficiary" : "Add New Beneficiary"}
        className="accountSetingModal benificiaryModal"
      >
        <div className="addEditBenificiary">
          <form data-testid="form" onSubmit={handleSubmit(onConfirmCallBack)}>
            <Grid container sx={{ marginTop: "40px" }}>
              <Grid item xs={11.9}>
                <Controller
                  render={({
                    field: { ref, ...field },
                    fieldState: { invalid, error },
                  }) => (
                    <SelectInput
                      {...field}
                      value={`${field.value}`}
                      error={Boolean(error)}
                      helperText={error?.message ? error?.message : " "}
                      required={true}
                      onChange={field.onChange}
                      inputRef={ref}
                      label="Select Plan"
                      disabled
                      optionValue={planOption}
                      placeholder="Select"
                    />
                  )}
                  name="planType"
                  control={control}
                  rules={{
                    required: true,
                  }}
                />
              </Grid>
              <Grid item xs={11.9} paddingTop={"17px !important"}>
                <Controller
                  render={({
                    field: { ref, ...field },
                    fieldState: { invalid, error },
                  }) => (
                    <Input
                      {...field}
                      label="Full Name"
                      value={field.value}
                      required={true}
                      onChange={field.onChange}
                      helperText={error?.message ? error?.message : " "}
                      error={Boolean(error)}
                      inputRef={(e: any) => {
                        ref(e);
                        inputRef.current = e;
                      }}
                      autoFocus={true}
                    />
                  )}
                  name="fullName"
                  control={control}
                  rules={{
                    required: "Please enter full name",
                    pattern: {
                      value: RegExpressions?.FullName,
                      message:
                        "Enter a valid name with alphanumeric characters only.",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={11.9} paddingTop={"17px !important"}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <Grid container>
                      <Grid
                        paddingTop={"22px !important"}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        sx={{ alignSelf: "self-end" }}
                      >
                        <Controller
                          render={({
                            field: { ref, ...field },
                            fieldState: { invalid, error },
                          }) => (
                            <CountryCodeSelectInput
                              {...field}
                              value={field.value ? `${field.value}` : ""}
                              error={Boolean(error)}
                              helperText={error?.message ? error?.message : " "}
                              required={true}
                              onChange={field.onChange}
                              labelClassName="countryCodeSelect"
                              inputRef={ref}
                              label="Country Code"
                              disabled={(editable || hasPartnerPlan) ? true : false}
                              optionValue={countriesCodes}
                              placeholder="Select"
                              isCountryCode={true}
                            />
                          )}
                          name="countryCode"
                          control={control}
                          rules={{
                            required: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={9}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Controller
                          render={({
                            field: { ref, ...field },
                            fieldState: { invalid, error },
                          }) => (
                            <Input
                              {...field}
                              label="Mobile Number"
                              value={field.value}
                              required={true}
                              placeholder="Enter mobile number"
                              disabled={editable ? true : false}
                              error={Boolean(error)}
                              onKeyDown={(e: any) => setKey(e.key)}
                              helperText={error?.message ? error?.message : " "}
                              onChange={(e: any) => {
                                field.onChange(e);
                                handleMobileNumber(e);
                              }}
                              inputRef={ref}
                            />
                          )}
                          name="mobileNumber"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Please enter a valid mobile number",
                            },
                            pattern: {
                              value: (!hasPartnerPlan) ? RegExpressions?.InternationalMobileNumber : RegExpressions?.MobileNumber,
                              message: "Please enter a valid mobile number",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          render={({
                            field: { ref, ...field },
                            fieldState: { invalid, error },
                          }) => (
                            <DatePickers
                              {...field}
                              label="Date of Birth"
                              value={field.value}
                              // required={true}
                              error={Boolean(error)}
                              helperText={error?.message ? error?.message : " "}
                              onChange={field.onChange}
                              inputRef={ref}
                              disableFuture={true}
                            />
                          )}
                          name="dob"
                          control={control}
                          rules={{
                            validate: () => {
                              const dobValue = getValues("dob");
                              const dobYear = new Date(dobValue).getFullYear();
                              if (dobValue && dobValue > Date.now()) {
                                return "Date cannot be a future date";
                              } else if (
                                dobValue &&
                                (dobValue > Date.now() ||
                                  dobValue < new Date("1899-12-31") ||
                                  isNaN(dobYear))
                              ) {
                                return "Please enter a valid date";
                              }
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} sx={{ mt: 3 }}>
                    <Controller
                      render={({
                        field: { ref, ...field },
                        fieldState: { invalid, error },
                      }) => (
                        <SelectInput
                          className="Gender"
                          value={field?.value ? `${field.value}` : ""}
                          disabled={false}
                          optionValue={genderValues}
                          placeholder="Select Gender"
                          label="Select Gender"
                          required={true}
                          error={Boolean(error)}
                          helperText={error?.message ? error?.message : " "}
                          onChange={field.onChange}
                          inputRef={(e: any) => {
                            ref(e);
                            inputRef.current = e;
                          }}
                        />
                      )}
                      name="gender"
                      control={control}
                      rules={{
                        required: "Please select a gender.",
                      }}
                    />
                  </Grid>

                  <Grid item xs={8} sx={{ mt: 3 }}>
                    <Controller
                      render={({
                        field: { ref, ...field },
                        fieldState: { invalid, error },
                      }) => (
                        <CheckboxSelectInput
                          {...field}
                          label="Select Family Members"
                          // placeholder="Select Family Members"
                          placeholder={
                            isDisabled
                              ? "No family member is available for association."
                              : "Select Family Member"
                          }
                          value={beneficiaryMembers}
                          error={Boolean(error)}
                          helperText={error?.message ? error?.message : " "}
                          onChange={(e: any) => {
                            field.onChange(e);
                            onBeneficiarySelect(e, field);
                          }}
                          inputRef={ref}
                          multiple={true}
                          disabled={isDisabled}
                        >
                          {optionData && optionData?.length > 0 ? (
                            <MenuItem key="all" value="all">
                              <div>
                                <ListItemText primary="All" />
                              </div>
                              <Checkbox
                                className="checkboxBox"
                                checked={allCheck}
                              />
                            </MenuItem>
                          ) : null}
                          {optionData?.map((option: any) => {
                            return (
                              <MenuItem key={option?.value} value={option}>
                                <div>
                                  <ListItemText primary={option?.text} />
                                </div>
                                <Checkbox
                                  className="checkboxBox"
                                  checked={
                                    beneficiaryMembers.findIndex(
                                      (item: any) =>
                                        item?.value === option?.value
                                    ) >= 0
                                  }
                                />
                              </MenuItem>
                            );
                          })}
                        </CheckboxSelectInput>
                      )}
                      name="text"
                      control={control}
                    />
                  </Grid>
                  {(editable || hasPartnerPlan) && (
                    <>
                      <Grid container columnSpacing={1} rowSpacing={1}>
                        <Grid item xs={12} className="billingHeading">
                          {editable && !hasPartnerPlan && (
                            <Checkbox
                              style={{ marginLeft: "-10px" }}
                              className="checkboxBox"
                              checked={!addressOptional}
                              disabled={hasPartnerPlan}
                              onChange={handleEnterAddress}
                            />
                          )}
                          <span>Enter Beneficiary (Elder) Address</span>
                        </Grid>
                        {!addressOptional && (
                          <>
                            <Grid item xs={12} lg={12} md={6}>
                              <Controller
                                render={({
                                  field: { ref, ...field },
                                  fieldState: { invalid, error },
                                }) => (
                                  <Input
                                    {...field}
                                    label="House No, Street"
                                    value={field.value}
                                    required={true}
                                    error={Boolean(error)}
                                    placeholder="Enter House No., Street"
                                    helperText={
                                      error?.message ? error?.message : " "
                                    }
                                    onChange={field.onChange}
                                    inputRef={ref}
                                  />
                                )}
                                name="address1"
                                control={control}
                                rules={{
                                  required: "Please enter house No, street",
                                }}
                              />
                            </Grid>
                            <Grid item xs={6} lg={6}>
                              <Controller
                                render={({
                                  field: { ref, ...field },
                                  fieldState: { invalid, error },
                                }) => (
                                  <Input
                                    {...field}
                                    label="Area, Locality"
                                    value={field.value}
                                    error={Boolean(error)}
                                    placeholder="Enter Area, Locality"
                                    helperText={
                                      error?.message ? error?.message : " "
                                    }
                                    onChange={field.onChange}
                                    inputRef={ref}
                                  />
                                )}
                                name="address2"
                                control={control}
                              />
                            </Grid>
                            <Grid item xs={6} lg={6}>
                              <Controller
                                render={({
                                  field: { ref, ...field },
                                  fieldState: { invalid, error },
                                }) => (
                                  <Input
                                    {...field}
                                    label="Town/City"
                                    value={field.value}
                                    required={true}
                                    error={Boolean(error)}
                                    placeholder="Enter Town or City"
                                    helperText={
                                      error?.message ? error?.message : " "
                                    }
                                    onChange={field.onChange}
                                    inputRef={ref}
                                  />
                                )}
                                name="city"
                                control={control}
                                rules={{
                                  required: "Please enter town/city",
                                  pattern: {
                                    value:
                                      RegExpressions?.noSpaceatBeginningAndEnd,
                                    message: "Please enter valid town/city",
                                  },
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              lg={4}
                              className="selectInputAlign stateMarginTop"
                            >
                              <Controller
                                render={({
                                  field: { ref, ...field },
                                  fieldState: { invalid, error },
                                }) => (
                                  <SelectInput
                                    {...field}
                                    disabled={!selectedCountry}
                                    value={`${field.value}`}
                                    error={Boolean(error)}
                                    helperText={
                                      error?.message ? error?.message : " "
                                    }
                                    required={true}
                                    onChange={field.onChange}
                                    inputRef={ref}
                                    label="State"
                                    optionValue={stateNames}
                                    placeholder={
                                      !selectedCountry
                                        ? "Select Country First"
                                        : "Select State"
                                    }
                                  />
                                )}
                                name="state"
                                control={control}
                                rules={{
                                  required: "Please select state ",
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                              <Controller
                                render={({
                                  field: { ref, ...field },
                                  fieldState: { invalid, error },
                                }) => (
                                  <Input
                                    {...field}
                                    label="ZipCode"
                                    value={field.value}
                                    required={true}
                                    error={Boolean(error)}
                                    helperText={
                                      error?.message ? error?.message : " "
                                    }
                                    onChange={field.onChange}
                                    placeholder="Enter ZipCode/PinCode"
                                    inputRef={ref}
                                  />
                                )}
                                name="zipCode"
                                control={control}
                                rules={{
                                  required: "Please enter ZipCode",
                                  pattern: {
                                    value: RegExpressions?.ZipCode,
                                    message: "Please enter valid zip code",
                                  },
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              lg={4}
                              className="selectInputAlign stateMarginTop"
                            >
                              <Controller
                                render={({
                                  field: { ref, ...field },
                                  fieldState: { invalid, error },
                                }) => (
                                  <SelectInput
                                    disabled={hasPartnerPlan}
                                    {...field}
                                    value={field.value ? field.value : ""}
                                    error={Boolean(error)}
                                    helperText={
                                      error?.message ? error?.message : " "
                                    }
                                    placeholder="Select Country"
                                    required={true}
                                    onChange={(e: any) => {
                                      field.onChange(e);
                                      handleCountryChange(e);
                                    }}
                                    inputRef={(e: any) => {
                                      ref(e);
                                      inputRef.current = e;
                                    }}
                                    label="Country"
                                    optionValue={countriesNames}
                                  />
                                )}
                                name="country"
                                control={control}
                                rules={{
                                  required: "Please select the country",
                                }}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid container spacing={2} className="modalBtn">
                  <Grid item xs={4.6} md={4.6} lg={4.6}>
                    <Buttons
                      type="secondary"
                      variant="contained"
                      text="CANCEL"
                      onClick={onCancelcallBack}
                    ></Buttons>
                  </Grid>
                  <Grid item xs={4.6} md={4.6} lg={4.6}>
                    <Buttons
                      type="primary"
                      variant="contained"
                      buttonType="submit"
                      text="SAVE"
                      data-testid="submit-btn"
                    ></Buttons>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </Modal>
    </>
  );
};
