import { Box, FormHelperText, Grid } from "@mui/material";
import { SelectInput } from "../../common/selectinput";
import Buttons from "../../common/button";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Table } from "../../common/table";
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid";

export const SubscriptionPending = () => {
  const [validateFromDate, setValidateFromDate] = useState<boolean>(false);
  const [validateToDate, setValidateToDate] = useState<boolean>(false);
  const [tableHeight, setTableHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });
  const [filterData, setFilterData] = useState<any>({
    search: "",
    from: "",
    to: "",
    orderStatus: "",
  });


  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
  };

  const handleChange = (e: any, { type }: any) => {
    if (type === "fromDate") {
      if (isNaN(dayjs(e).year())) {
        setValidateFromDate(true);
      } else {
        setValidateFromDate(false);
        setFilterData({
          ...filterData,
          ["from"]: dayjs(e)?.format("YYYY-MM-DD"),
        });
      }
    } else if (type === "toDate") {
      if (isNaN(dayjs(e).year())) {
        setValidateToDate(true);
      } else {
        setValidateToDate(false);
        setFilterData({
          ...filterData,
          ["to"]: dayjs(e)?.format("YYYY-MM-DD"),
        });
      }
    }
  };

  const columns: GridColDef[] = [
    {
      field: "subscribername",
      headerName: "Subscriber Name",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
    },
    {
      field: "mobilenumber",
      headerName: "Mobile Number",
      minWidth: 150,
      headerAlign: "left",
      sortable: false ,
      cellClassName: "cell-padding",
      flex:1
    },
    {
      field:"email",
      headerName: "Email",
      minWidth:200,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex:1
    },
    {
      field:"accountsetup",
      headerName: "Account Setup Date",
      minWidth:200,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex:1
    },
    {
      field: "billingcountry",
      headerName: "Billing Country",
      minWidth:200,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex:1
    },
    {
      field: "lastsetupflow",
      headerName: "Last Step Of Setup Flow",
      minWidth:200,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex:1
    },
  ]

  const calHeight = () => {
    let targetComp,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetComp = window.document.querySelector<HTMLElement>(".subscriptionPending");
    targetElement = targetComp?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 25);
    setTableHeight(tableHeight);
  };

  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
      setScreenWidth(window.innerWidth);
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);



  return (
    <Box>
    <Grid
      container
      rowSpacing={1.2}
      marginBottom="3vh"
      className="manageOrderHeader"
      // columnSpacing={screenWidth > 1199 && screenWidth < 1536 ? 2 : 3.75}
    >
      <Grid item className="Manage-order-Container">
        <span className="manageOrderHeading">Subscription Pending</span>
      </Grid>

      <Grid item xs={12} sm={12} md={screenWidth > 980 ? 7  : 12} lg={7} xl={5}>
        <Grid container  spacing={2}>
          <Grid item xs={6} sm={4.5} md={4.5} lg={4} xl={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="manageOrderDatePicker"
                label="From"
                format="DD/MM/YYYY"
                closeOnSelect={true}
                defaultValue={null}
                disableFuture={true}
                onChange={(e: any) => {
                  handleChange(e, { type: "fromDate" });
                }}
              />
            </LocalizationProvider>
            {validateFromDate ? (
              <FormHelperText className="helper-text">
                Enter a Valid Date
              </FormHelperText>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={6} sm={4.5} md={4.5} lg={4} xl={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className="manageOrderDatePicker"
                format="DD/MM/YYYY"
                label="To"
                closeOnSelect={true}
                disableFuture={true}
                defaultValue={null}
                onChange={(e: any) => {
                  handleChange(e, { type: "toDate" });
                }}
              />
            </LocalizationProvider>
            {validateToDate ? (
              <FormHelperText className="helper-text">
                Enter a Valid Date
              </FormHelperText>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={4} xl={4}>
            <Buttons
              buttonType="submit"
              variant="contained"
              text="EXPORT"
              type="primary"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    
    <Grid container>
      <Grid item xs={12}>
        <div className="subscriptionPending">
        <Table 
        //  rowCount={rowCount}
         rows={[]}
         columns={columns}
         isPagination={true}
         onPaginationModelChange={handlePaginationModelChange}
         pagination={paginationModel}
         tableHeight={tableHeight}
        />
        </div>
      </Grid>
    </Grid>
    </Box>
  );
};
