import { Dialog, Grid } from "@mui/material";
import Buttons from "../../../../common/button";
import { reviewCheck } from "../../../../../assets/images";

export const PaymentStatusModal = (props: any) => {
    const { paymentModal, setPaymentModal } = props;

    const handleClick=()=>{
        setPaymentModal(false)
    }


    return (
        <>
            <Dialog
                // onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={paymentModal}
                className="PaymentModal"
            >
                <div className="OrderConformationContainer">
                    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
                        <img src={reviewCheck} />
                        <div className="orderConfPrimaryText primaryTextFont">
                            <span>Your Subscription has been updated successfully.</span>
                        </div>
                        <div className="orderConfSecondaryText secondaryFont">
                            <span>Your monthly subscription amount will be $1 form your next bill cycle starting from DATE . You can now setup "count" active beneficiaries.</span>
                        </div>
                        <Buttons
                            onClick={handleClick}
                            variant="contained"
                            type="primary"
                            text="OK"
                            className="OrderConfBtn"
                        />
                    </Grid>
                </div>
            </Dialog>
        </>
    )
}