import { Controller, useForm } from "react-hook-form";
import { PlatformUserModal } from "../../../../models/common";
import { Modal } from "../../../common/modal/modal";
import { Grid } from "@mui/material";
import { CountryCodeSelectInput, SelectInput } from "../../../common/selectinput";
import { Input } from "../../../common/input";
import { RegExpressions } from "../../../../constants/regExp";
import { cCodes, cNames } from "../../../../constants/constant";
import { handleErrors, handleInputFocus } from "../../../../utils";
import { useEffect, useState } from "react";
import { State } from "country-state-city";
import Buttons from "../../../common/button";
import { useDispatch, useSelector } from "react-redux";
import { UserRoles } from "../../../../constants/enum";
import { useCreateUserMutation } from "../../../../redux/services/userApiSlice";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import { useGetAllTenantMutation } from "../../../../redux/services/tenantManagementApiSlice";

export const AddPlatformModal = (props: PlatformUserModal) => {
  const { addPlatformModal, setAddPlatformModal, refetchData } = props;
  const dispatch = useDispatch();
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    trigger,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" || "onSubmit" });
  const [key, setKey] = useState("");
  const [tanentId, setTanentId] = useState<string>("");
  const { roleData } = useSelector((state: any) => state?.role);
  const { userInfo } = useSelector((state: any) => state?.auth);
  const [userType, setUserType] = useState();

  // API INTEGRATION
  const [getTenant, getTenantData] = useGetAllTenantMutation();
  const [addPlatformUser, addPlatformUserData] = useCreateUserMutation();
  const {countriesCodes,countriesNames  } = useSelector(
    (state: any) => state?.countries || {}
  );

  useEffect(() => {
    let filterValue: any = [];
    if (roleData && Array.isArray(roleData)) {
      filterValue = roleData?.filter((item: any) => {
        return (
          item?.roleName === UserRoles.PLATFORM_ADMIN ||
          item?.roleName === UserRoles.PLATFORM_SUPPORT
        );
      });
    }
    if (filterValue.length) {
      const userOption = filterValue.map((x: any) => {
        return { text: x?.roleName, value: x?.roleId };
      });
      setUserType(userOption);
    }
  }, [roleData]);

  useEffect(() => {
    if (addPlatformUserData?.isSuccess) {
      setAddPlatformModal(false);
      dispatch(
        setAlert({
          open: "true",
          alertType: "success",
          message: "User added succesfully",
        })
      );
    }
    reset({
      usertype: "",
      fullName: "",
      mobileNumber: "",
      email: "",
      address: "",
      areaLocality: "",
      townCity: "",
      state: "",
      zipcode: "",
    });
    refetchData();
    // setValue("countryCode", "+91 IN");
    // setValue("country", "INDIA");
  }, [addPlatformUserData?.isSuccess]);

  useEffect(() => {
    handleErrors(addPlatformUserData, dispatch);
  }, [addPlatformUserData?.isError]);

  useEffect(() => {
    if (getTenantData) {
      setTanentId(getTenantData?.data?.result[0]?.tenantId);
    }
  }, [getTenantData]);

  useEffect(() => {
    getTenant({});
  }, []);

  const handleAddPlatformUser = (data: any) => {
    var requestData: any = {
      roleId: data?.usertype,
      mobileNumber: data?.mobileNumber?.replaceAll("-", ""),
      fullName: data?.fullName,
      createdBy: userInfo?.userId,
      email: data?.email,
      countryCode: data?.countryCode?.split(" ")[0]
        ? data?.countryCode?.split(" ")[0]
        : "",
      address: {
        address1: data?.address,
        address2: data?.areaLocality,
        city: data?.townCity,
        zipCode: data?.zipcode,
        state: data?.state,
      },
      country: data?.country,
      tenantId: tanentId,
    };
    addPlatformUser(requestData);
  };

  const handleMobileNumber = (e: any) => {
    var value = handleInputFocus(e, key);
    setValue("mobileNumber", value);
    trigger("mobileNumber");
  };

  const indiaStateNames = State.getStatesOfCountry("IN").map((states: any) => {
    return { text: states?.name, value: `${states?.isoCode}` };
  });

  const onCancelcallBack = () => {
    setAddPlatformModal(false);
    reset({
      usertype: "",
      fullName: "",
      mobileNumber: "",
      email: "",
      address: "",
      areaLocality: "",
      townCity: "",
      state: "",
      zipcode: "",
    });
  };

  const handleCountryCodeChange = (e:any) =>{
    const selectCode = countriesCodes.find((item:any, index:any) => {
      return item?.value === e?.target?.value
    })
    if(selectCode){
      setValue("country", selectCode?.countryCode)
      const states=State.getStatesOfCountry(selectCode?.countryCode).map((states: any) => {
        return { text: states?.name, value: `${states?.isoCode}` };
      });
      setStatesForCountry(states)
    }
  }

  console.log(countriesCodes,"counnnnnnnnnnnnn")

  const [statesForCountry, setStatesForCountry] = useState<any>([]);

  return (
    <>
      <Modal
        key="addFamily"
        open={addPlatformModal}
        handleClose={() => {
          setAddPlatformModal(false);
          reset({
            usertype: "",
            fullName: "",
            mobileNumber: "",
            email: "",
            address: "",
            areaLocality: "",
            townCity: "",
            state: "",
            zipcode: "",
          });
        }}
        primaryText="Add Platform User"
        className="accountSetingModal"
      >
        <form onSubmit={handleSubmit(handleAddPlatformUser)}>
          <Grid container>
            <Grid item xs={12} sx={{ mt: 6 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <SelectInput
                    {...field}
                    label="Platform User Type"
                    value={field.value}
                    required={true}
                    placeholder="Select platform user type"
                    inputRef={ref}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    optionValue={userType}
                    // optionValue={[
                    //   { text: "Platform Admin", value: "Platform Admin" },
                    //   { text: "Platform Support", value: "Platform Support" },
                    // ]}
                  />
                )}
                name="usertype"
                control={control}
                rules={{
                  required: "Please select platform user type",
                }}
              />
            </Grid>

            <Grid item xs={12} sx={{ mt: 1 }} className="input-title">
              <Controller
                name="fullName"
                control={control}
                rules={{
                  required: "Please enter full name",
                  pattern: {
                    value: RegExpressions?.FullName,
                    message:
                      "Enter a valid name with alphanumeric characters only.",
                  },
                }}
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Full Name"
                    value={field.value}
                    placeholder="Enter full name"
                    required={true}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sx={{ mt: 1 }}>
              <Grid container columnSpacing={2}>
                <Grid item xs={3}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      paddingTop={"22px !important"}
                      sx={{ alignSelf: "self-end" }}
                    >
                      <Controller
                        render={({
                          field: { ref, ...field },
                          fieldState: { invalid, error },
                        }) => (
                          <CountryCodeSelectInput
                            {...field}
                            value={field.value ? `${field.value}` : ""}
                            error={Boolean(error)}
                            helperText={error?.message ? error?.message : " "}
                            onChange={(e:any) => {
                              field.onChange(e)
                              handleCountryCodeChange(e)
                            }}
                            labelClassName="countryCodeSelect"
                            inputRef={ref}
                            label="Country Code"
                            required={true}
                            optionValue={countriesCodes}
                            placeholder="Select"
                            isCountryCode={true}

                          />
                        )}
                        name="countryCode"
                        control={control}
                        rules={{
                          required: "Please select country code",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={9}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="Mobile Number"
                        value={field.value}
                        required={true}
                        placeholder="Enter Mobile Number"
                        error={Boolean(error)}
                        onKeyDown={(e: any) => setKey(e.key)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                      />
                    )}
                    name="mobileNumber"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "Please enter a valid mobile number",
                      },
                      pattern: {
                        value: RegExpressions?.InternationalMobileNumber,
                        message: "Please enter a valid mobile number",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Email"
                    value={field.value}
                    required={true}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    placeholder="Enter email address"
                    inputRef={ref}
                  />
                )}
                name="email"
                control={control}
                rules={{
                  required:
                    "Please enter a valid email address. (Ex - abcd@xyz.com)",
                  pattern: {
                    value: RegExpressions?.Email,
                    message:
                      "Please enter a valid email address. (Ex - abcd@xyz.com)",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sx={{ mt: 1 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="House No, Street"
                    value={field.value}
                    required={true}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                    placeholder="Enter house no."
                  />
                )}
                name="address"
                control={control}
                rules={{
                  required: "Please enter house no, street",
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Area, Locality"
                    value={field.value}
                    required={false}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                    placeholder="Enter area, locality"
                  />
                )}
                name="areaLocality"
                control={control}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="Town/City"
                        value={field.value}
                        required={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                        placeholder="Enter town/city"
                      />
                    )}
                    name="townCity"
                    control={control}
                    rules={{
                      required: "Please enter town/city",
                      pattern: {
                        value: RegExpressions?.noSpaceatBeginningAndEnd,
                        message: "Please enter valid town/city",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} sx={{ mt: 2.7 }}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <SelectInput
                        {...field}
                        label="State"
                        value={field.value ? `${field.value}` : ""}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        placeholder="Select State"
                        required={true}
                        onChange={field.onChange}
                        optionValue={statesForCountry}
                        inputRef={ref}
                        
                      />
                    )}
                    name="state"
                    control={control}
                    rules={{
                      required: "Please select the state ",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ mt: 1 }}>
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="Zipcode"
                        value={field.value}
                        required={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                        placeholder="Enter zipcode"
                      />
                    )}
                    name="zipcode"
                    control={control}
                    rules={{
                      required: "Please enter Zipcode",
                      pattern: {
                        value: RegExpressions?.ZipCode,
                        message: "Please enter valid pin code",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} sx={{ mt: 2.7 }}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <SelectInput
                        {...field}
                        label="Country"
                        value={field.value ? `${field.value}` : ""}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        inputRef={ref}
                        onChange={(e:any) =>{
                          field.onChange(e);
                        }}
                        required={true}
                        optionValue={cNames}
                        placeholder="select country"
                        disabled
                      />
                    )}
                    name="country"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ mt: 1 }}>
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        label="Tenant Name"
                        placeholder="Enter tenant name"
                        value={"Family Pro Health"}
                        disabled
                        error={Boolean(error)}
                        inputRef={ref}
                      />
                    )}
                    name="tenantName"
                    control={control}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="Tenant Location"
                        value={"India"}
                        disabled
                        required={true}
                        placeholder="Select tenant location"
                        inputRef={ref}
                      />
                    )}
                    name="tenantLocation"
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={2} className="modalBtn">
              <Grid item xs={4.6} md={4.6} lg={4.6}>
                <Buttons
                  type="secondary"
                  variant="contained"
                  text="CANCEL"
                  onClick={onCancelcallBack}
                ></Buttons>
              </Grid>
              <Grid item xs={4.6} md={4.6} lg={4.6}>
                <Buttons
                  type="primary"
                  variant="contained"
                  buttonType="submit"
                  text="ADD"
                  data-testid="submit-btn"
                ></Buttons>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
};
