import {
  Box,
  Card,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { MessageAlert } from "../../common/alert/alert";
import { logo } from "../../../assets/images";
import { Controller, useForm } from "react-hook-form";
import { PasswordInput } from "../../common/input";
import { RegExpressions } from "../../../constants/regExp";
import Buttons from "../../common/button";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import {
  useGetIpQuery,
  useResetPasswordMutation,
} from "../../../redux/services/authApiSlice";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { handleErrors } from "../../../utils";
import { useDispatch } from "react-redux";
import { PrivacyPolicy } from "./userRegistration/privacyPolicy";
import CloseIcon from "@mui/icons-material/Close";
import { Page } from "../../../routes/config";
import Loader from "../../common/loader/loader";
import { setIpAddress } from "../../../redux/features/auth/authSlice";

export const ResetPassword = () => {
  const [height, setHeight] = useState(0);
  const [token, setToken] = useState<any>();
  const [resetPassword, resetPasswordData] = useResetPasswordMutation();
  const { data, isSuccess } = useGetIpQuery({
    refetchOnMountOrArgChange: true,
  });
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [termOfUse, setTermsofUse] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange" || "onSubmit",
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const location = useLocation();
  const dispatch = useDispatch();
  const locSearch = new URLSearchParams(location.search);

  useEffect(() => {
    setToken(locSearch.get("token"));
  }, []);

  const onSubmit = (data: any) => {
    var resetObj = {
      resetToken: token,
      password: data?.password,
    };
    resetPassword(resetObj);
  };

  useEffect(() => {
    if (resetPasswordData?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Password reset successfully",
        })
      );
      reset();
      navigate(Page.LOGIN);
    }
  }, [resetPasswordData?.isSuccess]);

  useEffect(() => {
    if (resetPasswordData?.isError) {
      handleErrors(resetPasswordData, dispatch);
    }
  }, [resetPasswordData?.isError]);

  useEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const onPrivacyPolicyClick = () => {
    setShowPrivacyModal(true);
  };
  const onModalClose = () => {
    setShowPrivacyModal(false);
    setTermsofUse(false);
  };
  const onTermsofuseClick = () => {
    setTermsofUse(true);
    setShowPrivacyModal(true);
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(setIpAddress({ ipAddress: data?.ipAddress }));
    }
  }, [isSuccess]);

  return (
    <>
      <Loader show={resetPasswordData?.isLoading} />
      <Box height={`${height}px`} className="container-body">
        <Grid container justifyContent={"center"} height={"13vh"}>
          <Grid item xs={12}>
            <MessageAlert></MessageAlert>
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"}>
          <Grid item xs={11} sm={11} md={9} lg={5.6} xl={4.4}>
            <Card className="container-card" data-testid="card-component">
              <Grid container justifyContent={"center"}>
                <Grid item xs={10} xl={8.6}>
                  <Box className="logo">
                    <img src={logo} className="loginLogo"></img>
                  </Box>
                  <Typography className="forgetPassword-title">
                    {location?.pathname === Page.RESETPASSWORD
                      ? "Reset"
                      : "Create"}{" "}
                    Password
                  </Typography>
                  <p className="reset-text">
                    Enter the new password to login your account.
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container rowSpacing={4}>
                      <Grid item xs={12}>
                        <Controller
                          render={({
                            field: { ref, ...field },
                            fieldState: { invalid, error },
                          }) => (
                            <PasswordInput
                              {...field}
                              label="Password"
                              value={field.value}
                              required={true}
                              error={Boolean(error)}
                              helperText={error?.message ? error?.message : " "}
                              onChange={field.onChange}
                              inputRef={ref}
                              placeholder="Enter password"
                              info={true}
                            />
                          )}
                          name="password"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Please enter the password",
                            },
                            pattern: {
                              value: RegExpressions?.Password,
                              message: "Please enter valid password",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          render={({
                            field: { ref, ...field },
                            fieldState: { invalid, error },
                          }) => (
                            <PasswordInput
                              {...field}
                              label="Retype Password"
                              value={field.value}
                              required={true}
                              error={Boolean(error)}
                              helperText={error?.message ? error?.message : " "}
                              onChange={field.onChange}
                              placeholder="Re-enter password"
                              inputRef={ref}
                            />
                          )}
                          name="confirmPassword"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Please enter the retype password",
                            },
                            validate: () => {
                              if (
                                getValues("password") !=
                                getValues("confirmPassword")
                              ) {
                                return "Password not Matched";
                              }
                            },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: "32px" }}>
                      <Grid item xs={12}>
                        <Buttons
                          text="SAVE"
                          buttonType="submit"
                          variant="contained"
                          type="primary"
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} className="policy-text grid-item">
                        <span data-testid="privacy-policy">
                          Click to view{" "}
                          <a
                            href="#"
                            onClick={onPrivacyPolicyClick}
                            className="addunderline"
                          >
                            Privacy Policy
                          </a>{" "}
                          and{" "}
                          <a
                            href="#"
                            onClick={onTermsofuseClick}
                            className="addunderline"
                          >
                            Terms of Use
                          </a>
                        </span>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"} className="helpContact">
          <Grid item xs={11} sm={11} md={9} lg={5.6} xl={4.4}>
            <Box className="bottom-text">
              <Box data-testid="bottom-text">
                <span className="textbtm text-font">
                  <a
                    href="https://familyprohealth.com/faq"
                    className="addunderline"
                  >
                    Help
                  </a>{" "}
                  &nbsp;|&nbsp;{" "}
                  <a
                    href="https://familyprohealth.com/contact-us"
                    className="addunderline"
                  >
                    Contact us
                  </a>
                </span>
              </Box>
              <Box>
                <span className="textbtm lastText">
                  @{new Date().getFullYear()} familyprohealth Inc. All rights
                  reserved.
                </span>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Dialog open={showPrivacyModal} className="PrivacyPolicyModal">
          <DialogTitle className="privacyCardText">
            {termOfUse ? "Terms Of Use" : "Privacy Policy"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <PrivacyPolicy
            modal={true}
            termofUseModal={termOfUse}
            setTermofUseModal={setTermsofUse}
          />
        </Dialog>
      </Box>
    </>
  );
};
