import { Grid, IconButton } from "@mui/material";
import { Table } from "../../../common/table";
import backIcon from "../../../../assets/icons/backIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import downloadIcon from "../../../../assets/icons/download.svg";
import { useGetBillingHistoryQuery } from "../../../../redux/services/accountsettingsApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import "../orderHistory/style.css";
import Loader from "../../../common/loader/loader";
import { setPaginationFilter } from "../../../../redux/features/filters/filtersSlice";
import { handlePriceFormatting } from "../../../../utils";

export const BillingHistoy = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state: any) => state?.auth?.userId);
  const [billingRow, setBillingRow] = useState<any>([]);
  const [id, setId] = useState();
  const [rowCount, setRowCount] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);
  const filtersData = useSelector((state: any) => state.filters);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: filtersData?.pageSize,
    page: filtersData?.page,
  });
  const { state } = useLocation();
  useEffect(() => {
    if (state?.view) {
      setId(state?.userData?.User?.userId);
    } else {
      setId(userId);
    }
  }, []);

  //API Integration
  const { data, isSuccess, isError, error, refetch, isLoading } =
    useGetBillingHistoryQuery(
      {
        limit: paginationModel?.pageSize,
        page: paginationModel?.page + 1,
        id: id,
        paymentStatus: "COMPLETED",
      }
    );

    const getStartEndDate = (planDetails:any, invoiceDate:any) => {
      const {billingFrequency, billingInterval} = planDetails;
      let startDate:any;
      let endDate: any;

      startDate = dayjs(invoiceDate)?.format("DD MMM, YYYY");
      if(billingFrequency == "MONTH"){    
        endDate = dayjs(invoiceDate)?.add(billingInterval, "month").format("DD MMMM, YYYY");
      } else {
        endDate = dayjs(invoiceDate)?.add(billingInterval, "year").format("DD MMMM, YYYY");

      }
      return {
        startDate, endDate
      }
    }

  const columns: GridColDef[] = [
    {
      field: "invoiceDate",
      headerName: "Invoice Date",
      headerAlign: "left",
      cellClassName: "cell-padding topAlign",
      align: "left",
      minWidth: 180,
      sortable: false,
      flex: 1,
    },
    {
      field: "subscribedPlan",
      headerName: "Type",
      minWidth: 300,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding topAlign",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="textWordBreak tableRenderCellText">
            {params?.row?.remarks? 
            (params?.row?.remarks) : 
            params?.row?.orderId ?
            (billingRow[0]?.plan?.name) :
            (`${billingRow[0]?.plan?.name} - Subscription`)
          }
            {/* {billingRow[0]?.plan?.name} {" "}
            {params?.row?.message === "Transaction: Device" ?
            ("- Devices") :
            (<span></span>)
            } */}
          </div>
        );
      },
    },
    {
      field: "period",
      headerName: "Subscription Period",
      headerAlign: "center",
      cellClassName: "cell-padding topAlign",
      align: "center",
      minWidth: 300,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const { orderId, orderNumber, createdAt, plan } = params?.row || {};

        if (orderId || orderNumber) {
          return (
            <div className="textWordBreak tableRenderCellText">
              -
            </div>
          );
        } else if (createdAt || !orderId || !orderNumber) {
          const {startDate, endDate} = getStartEndDate(plan, createdAt)

          return (
            <div className="textWordBreak tableRenderCellText">
              {startDate} - {endDate}
            </div>
          );
        } else {
          return (
            <div className="textWordBreak tableRenderCellText">
              -
            </div>
          );
        }
      },

    },
    // {
    //   field: "subTotal",
    //   headerName: "Subtotal",
    //   headerAlign: "left",
    //   align: "left",
    //   cellClassName: "cell-padding topAlign",
    //   minWidth: 170,
    //   sortable: false,
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         {params?.row?.currency}&nbsp;{(params?.row?.subTotal ? params?.row?.subTotal : params?.row?.amount)}
    //       </>
    //     );
    //   },
    // },
    {
      field: "totalAmount",
      headerName: "Amount",
      headerAlign: "center",
      align: "center",
      cellClassName: "cell-padding topAlign",
      minWidth: 180,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (handlePriceFormatting((params?.row?.totalAmount ? params?.row?.totalAmount : params?.row?.amount), params?.row?.currency)+ " " + params?.row?.currency )

        // return (
        //   <>
        //     {params?.row?.currency}&nbsp;{(params?.row?.totalAmount ? params?.row?.totalAmount : params?.row?.amount)}
        //   </>
        // );
      },
    },
    {
      field: "action",
      sortable: false,
      align: "center",
      cellClassName: "cell-padding topAlign",
      headerClassName: "invoicecss",
      headerAlign: "center",
      headerName: "Download Invoice",
      minWidth: 150,
      flex: 1,
      renderCell: (params: any) => (
        <a
          href={params?.row?.invoiceUrl}
          className="export-link"
          target="_blank"
          rel="noopener noreferrer"
          download
        >
          <IconButton>
            <img src={downloadIcon} />
          </IconButton>
        </a>
      ),
    },
  ];
  useEffect(() => {
    if (isSuccess) {
      const rowData = data?.result.map((row: any, index: any) => ({
        id: index,
        invoiceDate: dayjs(row.createdAt)?.format("DD MMM, YYYY"),
        ...row,
      }));
      setRowCount(data?.count);
      setBillingRow(rowData);
    }
  }, [data, isSuccess]);
  
  // useEffect(()=>{
  //   if(isError){
  //     handleErrors({error,isError}, dispatch);
  //   }
  // },[isError])
  
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
    dispatch(setPaginationFilter(newPaginationModel));
  };

  const calHeight = () => {
    let targetCompo,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetCompo = window.document.querySelector<HTMLElement>(".billingHistory");
    targetElement = targetCompo?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 25);
    setTableHeight(tableHeight);
  };

  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <>
      <Loader show={isLoading} />
      <div className="history-main-title">
        <span className="history-backIcon-pd">
          <img src={backIcon} alt="backIcon" onClick={() => navigate(-1)} />
        </span>
        <span className="capitalise">
          {state?.view ? state?.userData?.User?.fullName : "My Account"} /{" "}
          <span className="sub-name">Billing History</span>
        </span>
      </div>
      <Grid marginTop="2.8vh">
        <div className="billingHistory">
          <Table
            rows={billingRow}
            columns={columns}
            rowCount={rowCount}
            isPagination={true}
            onPaginationModelChange={handlePaginationModelChange}
            pagination={paginationModel}
            tableHeight={tableHeight}
          />
        </div>
      </Grid>
    </>
  );
};
