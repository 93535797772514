import { useEffect, useState } from "react";
import { Table } from "../../common/table";
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import { FormHelperText, Grid } from "@mui/material";
import dayjs from "dayjs";
import Loader from "../../common/loader/loader";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { backIcon } from "../../../assets/icons";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { SelectInput } from "../../common/selectinput";
import Buttons from "../../common/button";
import { useGetAuditQuery } from "../../../redux/services/userApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { apiRoutes, baseURL } from "../../../config/api";
import { setAuditLogsFilters, setPaginationFilter } from "../../../redux/features/filters/filtersSlice";

export const AuditLogs = (props: any) => {
  const { state } = useLocation();
  const [rowData, setRowData] = useState<any>([]);
  const [rowCount, setRowCount] = useState(0);
  const filtersData = useSelector((state: any) => state.filters);
  const navigate = useNavigate();
  const [tableHeight, setTableHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [validateToDate, setValidateToDate] = useState<boolean>(false);
  const [validateFromDate, setValidateFromDate] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: filtersData?.pageSize,
    page: filtersData?.page,
  });
  const [dateDisabled, setDateDisabled] = useState(true);
  const [userId, setUserId] = useState();
  const { userInfo } = useSelector((state: any) => state?.auth);

  const [filterData, setFilterData] = useState<any>({
    from: filtersData?.fromDate,
    to: filtersData?.toDate,
    category: filtersData?.category,
  });

  useEffect(() => {
    if (state?.view) {
      setUserId(state?.userData?.User?.userId);
    } else {
      setUserId(userInfo?.userId);
    }
  }, []);

  const handleChange = (e: any, { type }: any) => {
    if (type === "fromDate") {
      if (isNaN(dayjs(e).year())) {
        setValidateFromDate(true);
      } else {
        setValidateFromDate(false);
        setFilterData({
          ...filterData,
          ["from"]: dayjs(e)?.format("YYYY-MM-DD"),
          ["to"]: null,
        });
        setDateDisabled(false);
      }
    } else if (type === "toDate") {
      if (isNaN(dayjs(e).year())) {
        setValidateToDate(true);
      } else {
        setValidateToDate(false);
        setFilterData({
          ...filterData,
          ["to"]: dayjs(e)?.format("YYYY-MM-DD"),
        });
      }
    } else {
      const { name, value } = e?.target;
      setFilterData({ ...filterData, [name]: value });
    }
  };

  const getFilteredParams = () => {
    var data = {};
    var params = {};
    data = Object.fromEntries(
      Object.entries(filterData).filter(([_, v]) => v != "" && v != null)
    );
    if (userId !== undefined) {
      params = { ...data, userId: userId };
    } else {
      params = data;
    }

    return params;
  };

  const dispatch = useDispatch();
  const { data, isSuccess, refetch, isError, isLoading } = useGetAuditQuery(
    {
      limit: paginationModel?.pageSize,
      page: paginationModel?.page + 1,
      ...getFilteredParams(),
      userId: userId,
    },
    {
      skip: userId == undefined ? true : false,
    }
  );

  useEffect(() => {
    dispatch(setAuditLogsFilters(filterData));
  }, [filterData]);

  useEffect(() => {
    if (isSuccess) {
      setRowData(
        data?.result.map((row: any, index: any) => ({
          id: index,
          Date: dayjs(row.createdAt)?.format("DD MMM, YYYY HH:mm:ss A"),
          ...row,
        }))
      );
      setRowCount(data?.count);
    }
  }, [data, isSuccess]);

  const handleReset = () => {
    setFilterData({
      from: null,
      to: null,
      category: "",
    });
  };
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
    dispatch(setPaginationFilter(newPaginationModel));
  };

  const FilterCategoryValue = [
    { text: "Authentication", value: "Authentication" },
    { text: "Campaigns", value: "Campaigns" },
    { text: "Health", value: "Health" },
    { text: "Orders", value: "Orders" },
    { text: "Product & Plan", value: "Product & Plan" },
    { text: "Schedules", value: "Schedules" },
    { text: "Subscription & Payment", value: "Subscription & Payment" },
    { text: "User", value: "User" },
    { text: "Devices", value: "Devices" },
    { text: "System", value: "System" },
  ];

  const calHeaight = () => {
    let targetCompo,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetCompo = window.document.querySelector<HTMLElement>(".auditLogsTable");
    targetElement = targetCompo?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 25);
    setTableHeight(tableHeight);
  };

  useEffect(() => {
    calHeaight();
    const resizeObserver = new ResizeObserver(() => {
      calHeaight();
      setScreenWidth(window.innerWidth);
    });
    resizeObserver.observe(document.body);
    if (filtersData?.fromDate) {
      setDateDisabled(false);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const columns: GridColDef[] = [
    {
      field: "Date",
      headerName: "Date / Time",
      minWidth: 200,
      align: "left",
      cellClassName: "cell-padding",
      headerAlign: "left",
      sortable: false,
      flex: 1,
    },
    {
      field: "userName",
      headerName: "User Name",
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-padding",
      minWidth: 150,
      sortable: false,
      flex: 1,
    },
    {
      field: "category",
      headerName: "Category",
      cellClassName: "cell-padding",
      headerAlign: "left",
      minWidth: 150,
      align: "left",
      sortable: false,
      flex: 1,
    },
    {
      field: "eventName",
      headerName: "Event Name",
      cellClassName: "cell-padding",
      headerAlign: "left",
      minWidth: 200,
      align: "left",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.event ? (
              <span>{params?.row?.event?.name}</span>
            ) : (
              <></>
            )}
          </>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      cellClassName: "cell-padding",
      headerAlign: "left",
      minWidth: 200,
      align: "left",
      sortable: false,
      flex: 1,
      // renderCell: (params) => {
      //   return (
      //     <>
      //       {params?.row?.data?.afterValue ? (
      //         <span>{params?.row?.data?.afterValue}</span>
      //       ) : (
      //         <></>
      //       )}
      //     </>
      //   );
      // },
    },
    {
      field: "ipAddress",
      headerName: "IP Address",
      cellClassName: "cell-padding",
      headerAlign: "left",
      minWidth: 150,
      align: "left",
      sortable: false,
      flex: 1,
    },
  ];

  return (
    <>
      <Loader show={isLoading} />
      <Grid
        container
        rowSpacing={1.2}
        columnSpacing={3.8}
        sx={{ mb: "3vh", justifyContent: "end" }}
      >
        <Grid
          item
          xl={4}
          lg={5.2}
          md={9}
          sm={12}
          xs={12}
          columnSpacing={3.8}
          rowSpacing={1.2}
          order={{ md: 1, sm: 1, lg: 1 }}
        >
          <div className="history-main-title">
            <span className="history-backIcon-pd">
              <img src={backIcon} alt="backIcon" onClick={() => navigate(-1)} />
            </span>
            <span className="capitalise">
              {state?.view ? state?.userData?.User?.fullName : "My Account"} /{" "}
              <span className="sub-name">Audit Log</span>

            </span>
          </div>
        </Grid>
        <Grid
          item
          xl={1.6}
          lg={2.2}
          md={3}
          sm={4}
          xs={4}
          order={{ md: 3, sm: 2, lg: 2 }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="manageOrderDatePicker"
              label="From"
              format="DD/MM/YYYY"
              value={
                filtersData?.fromDate
                  ? dayjs(filtersData?.fromDate, "YYYY-MM-DD")
                  : null
              }
              closeOnSelect={true}
              defaultValue={null}
              disableFuture={true}
              onChange={(e: any) => {
                handleChange(e, { type: "fromDate" });
              }}
            />
          </LocalizationProvider>
          {validateFromDate ? (
            <FormHelperText className="helper-text">
              Enter a Valid Date
            </FormHelperText>
          ) : (
            <></>
          )}
        </Grid>
        <Grid
          item
          xl={1.6}
          lg={2.2}
          md={3}
          sm={4}
          xs={4}
          order={{ md: 4, sm: 3, lg: 3 }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="manageOrderDatePicker"
              format="DD/MM/YYYY"
              label="To"
              value={
                filtersData?.toDate
                  ? dayjs(filtersData?.toDate, "YYYY-MM-DD")
                  : null
              }
              closeOnSelect={true}
              disableFuture={true}
              defaultValue={null}
              onChange={(e: any) => {
                handleChange(e, { type: "toDate" });
              }}
              disabled={dateDisabled}
              minDate={dayjs(filtersData?.fromDate, "YYYY-MM-DD")}
            />
          </LocalizationProvider>
          {validateToDate ? (
            <FormHelperText className="helper-text">
              Enter a Valid Date
            </FormHelperText>
          ) : (
            <></>
          )}
        </Grid>

        <Grid
          item
          xl={1.6}
          lg={2.2}
          md={3}
          sm={4}
          xs={4}
          order={{ md: 2, sm: 4, lg: 4 }}
        >
          <SelectInput
            className="filterStyle"
            placeholder="Category"
            name="category"
            value={filtersData?.category}
            optionValue={FilterCategoryValue}
            onChange={handleChange}
          />
        </Grid>
        <Grid
          item
          xl={3.2}
          lg={4.4}
          md={6}
          sm={12}
          xs={12}
          order={{ md: 5, sm: 5, lg: 5 }}
        >
          <Grid container columnSpacing={3.8} rowSpacing={1.2}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <Buttons
                startIcon="reset"
                variant="contained"
                type="primary"
                className="resetButton"
                text="Reset"
                onClick={handleReset}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <a
                href={`${baseURL}${apiRoutes.AUDIT_EXPORT}${
                  Object.keys(getFilteredParams()).length != 0 ? "?" : ""
                }${createSearchParams(getFilteredParams())}`}
                className="export-link"
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <Buttons
                  buttonType="submit"
                  variant="contained"
                  disabled={rowCount === 0 ? true : false}
                  text="EXPORT"
                  type="primary"
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className="auditLogsTable">
        <Table
          rows={rowData}
          columns={columns}
          rowCount={rowCount}
          isPagination={true}
          onPaginationModelChange={handlePaginationModelChange}
          pagination={paginationModel}
          tableHeight={tableHeight}
        />
      </div>
    </>
  );
};