import React, { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { deviceTypeValue } from "../../../../constants/enum";
import { Modal } from "../../../common/modal/modal";
import { Grid } from "@mui/material";
import { Input } from "../../../common/input";
import { SelectInput } from "../../../common/selectinput";
import { RegExpressions } from "../../../../constants/regExp";
import Buttons from "../../../common/button";
import dayjs from "dayjs";
import { useEditUpdateDeviceMutation } from "../../../../redux/services/devicesApiSlice";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import { handleErrors } from "../../../../utils";

export const EditDeviceOrderModal = (props: any) => {
  const {
    isEdit,
    open,
    setOpen,
    deviceId,
    setIsEdit,
    paginationModel,
    refetch,
    rowData
  } = props;
  console.log(deviceId, "devviiiii")
  console.log(rowData, "dddddddd")

  const dispatch = useDispatch();
  
  const { userInfo } = useSelector((state: any) => state.auth);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { handleSubmit, control, setValue, reset } = useForm({
    mode: "onChange" || "onSubmit",
  });
  // const matches = useMediaQuery("(min-width:1500px)");

  // API INTEGRATION 

  const [updateSerialNumber, updateSerialNumberData] = useEditUpdateDeviceMutation();

  const handleUpdateDevice = (data: any) => {
    console.log(data, "ddddddaata")
    var requestData: any = {
      serialNumber: data?.serialNumber
    };
    updateSerialNumber({serialNumber: requestData, id:deviceId?.deviceUserMappingId})
  };

  useEffect(()=>{
    if(updateSerialNumberData?.isSuccess){
      handleCancel();
      refetch();
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "serial number updated Successfully.",
        })
      );
    }

  },[updateSerialNumberData?.isSuccess])

  useEffect(()=>{
    handleErrors(updateSerialNumberData, dispatch)
  },[updateSerialNumberData?.isError])

  

  const deviceOptions = [
    { text: "BP Monitor", value: deviceTypeValue.BLOODPRESSURE },
    { text: "Pulse Oximeter", value: deviceTypeValue.PULSEOXYMETER },
    { text: "Weighing Scale", value: deviceTypeValue.WEIGHT },
    { text: "Thermometer", value: deviceTypeValue.TEMPERATURE },
    { text: "Glucometer", value: deviceTypeValue.GLUCOSE },
  ];

  useEffect(()=>{
    setValue("manufacturerName", deviceId?.DeviceType?.manufacturer);
    setValue("deviceType", deviceId?.DeviceType?.biometricType);
    setValue("modelNumber", deviceId?.DeviceType?.model);
    setValue("serialNumber", deviceId?.serialNumber);
    setValue("orderNumber", deviceId?.orderNumber);
    setValue("deviceMac", deviceId?.MAC);
    setValue("shipDate", dayjs(deviceId?.shipDate)?.format("DD MMM, YYYY"));
    setValue("lastReading", deviceId?.androidId?.lastReading);
    setValue("ipAddress", deviceId?.androidId?.ipAddress)
    setValue("warrantyPeriod", deviceId?.DeviceType?.warrantyPeriod);

  },[isEdit])
  
  const handleCancel = () => {
    setIsEdit(false);
    reset();
  };

  

  return (
    <>
    {/* <Loader show={updateSerialNumberData} /> */}
     
      <Modal
        handleClose={handleCancel}
        primaryText={"Edit Device Details"}
        open={isEdit}
        className="input-padding modal-title outer-pd-device"
      >
        <form onSubmit={handleSubmit(handleUpdateDevice)}>
          <Grid container>
            <Grid item xs={12} sx={{ mt: 4 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Manufacturer Name"
                    value={field.value}
                    required={true}
                    disabled
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={(e: any) => {
                      ref(e);
                      inputRef.current = e;
                    }}
                    placeholder="Enter manufacturer name"
                    autoFocus={true}
                  />
                )}
                name="manufacturerName"
                control={control}
                rules={{
                  required: "Please enter manufacture name ",
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 4 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <SelectInput
                    {...field}
                    disabled={isEdit}
                    value={field.value ? `${field.value}` : ""}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    placeholder="Select device type"
                    required={true}
                    onChange={field.onChange}
                    inputRef={ref}
                    label="Device Type"
                    optionValue={deviceOptions}
                  />
                )}
                name="deviceType"
                control={control}
                rules={{
                  required: "Please select device type",
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Model Number"
                    value={field.value}
                    required={true}
                    disabled
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                    placeholder="Enter model number"
                  />
                )}
                name="modelNumber"
                control={control}
                rules={{
                  required: "Please enter model number ",
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Serial Number"
                    value={field.value}
                    required={true}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                    placeholder="Enter serial number"
                  />
                )}
                name="serialNumber"
                control={control}
                rules={{
                  required: "Please enter serial number",
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Order Number"
                    value={field.value}
                    disabled
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                    placeholder="Enter cost in INR"
                    required={true}
                  />
                )}
                name="orderNumber"
                control={control}
                // rules={{
                //   required: "Please enter cost(INR)",
                // }}
              />
            </Grid>

            <Grid container spacing={3}>
            <Grid item xs={6} sx={{ mt: 2 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Device MAC"
                    value={field.value}
                    disabled
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                    placeholder="Enter cost in USD"
                    required={true}
                  />
                )}
                name="deviceMac"
                control={control}
                rules={{
                  required: "Please enter cost(USD)",
                }}
              />
            </Grid>

            <Grid item xs={6} sx={{ mt: 2 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Ship Date"
                    value={field.value}
                    disabled
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                    placeholder="Enter ship date"
                    required={true}
                  />
                )}
                name="shipDate"
                control={control}
                rules={{
                  required: "Please enter cost(USD)",
                }}
              />
            </Grid>
            </Grid>
           
            
            
            <Grid container spacing={2} className="modalBtn">
              <Grid item xs={4.5}>
                <Buttons
                  type="secondary"
                  variant="contained"
                  text="CANCEL"
                  onClick={handleCancel}
                ></Buttons>
              </Grid>
              <Grid item xs={4.5}>
                <Buttons
                  type="primary"
                  variant="contained"
                  buttonType="submit"
                  text="SAVE"
                  data-testid="submit-btn"
                ></Buttons>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
};
