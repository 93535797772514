import { DataGrid } from "@mui/x-data-grid";
import { Tableprops } from "../../../models/common";
import "./style.css";
import styled from "@emotion/styled";
import { Stack } from "@mui/material";

export const StyledDataGrid = styled((props: any)=>{
  const {children, ...rest} = props
  return <DataGrid {...rest}>{children}</DataGrid>
})`
  ${(props) => props.tableHeight && `height:${props.tableHeight}px !important`}
`;

export const Table = (props: Tableprops) => {
  const {
    rows,
    rowCount,
    columns,
    checkboxSelection,
    isPagination,
    pagination,
    onPaginationModelChange,
    tableHeight,
    columnVisibilityObject,
    onRowClick,
    showHover=false
  } = props;
  const getRowClassName = (params: any) => {
    if(params?.row?.isRead ===false){
      return "bold-text";
    }
    else{
      return "";
    }
  };


  
  return (
    <>
      <StyledDataGrid
        tableHeight={tableHeight}
        rows={rows}
        className="tb-0"
       
        columns={columns}
        initialState={{
          pagination: { paginationModel: pagination },
          columns: {
            columnVisibilityModel: columnVisibilityObject,
          },
        }}
        onRowClick={onRowClick}
        getRowHeight={() => "auto"}
        rowHeight={200}
        getRowClassName={getRowClassName}
        pagination={isPagination}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationModel={pagination}
        paginationMode="server"
        onPaginationModelChange={onPaginationModelChange}
        checkboxSelection={checkboxSelection}
        disableColumnFilter
        disableColumnSelector
        disableColumnMenu
        rowCount={rowCount}
        disableRowSelectionOnClick
        sx={showHover?{
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "",
            cursor: "pointer",
          }
        }:
        {
          "& .MuiDataGrid-row:hover": {
            backgroundColor: "white",
          }
        }}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
             No data available to display
            </Stack>
          ),
        }}
      />
    </>
  );
};
