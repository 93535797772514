import { useEffect, useRef, useState } from "react";
import { Modal } from "../../common/modal/modal";
import { Checkbox, Grid, ListItemText, MenuItem } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Input } from "../../common/input";
import Buttons from "../../common/button";
import { CheckboxSelectInput, SelectInput } from "../../common/selectinput";
import { DiscountType } from "../../../constants/enum";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import { handleErrors } from "../../../utils";
import Loader from "../../common/loader/loader";
import { DatePickers } from "../../common/datepicker";
import { useGetAllPlansQuery } from "../../../redux/services/planApiSlice";
import dayjs from "dayjs";
import { useGetAllTenantMutation } from "../../../redux/services/tenantManagementApiSlice";
import {
  useCreateNewCouponsMutation,
  useGetCouponByIdMutation,
  useUpdateCouponByIdMutation,
} from "../../../redux/services/manageCouponApiSlice";
import { RegExpressions } from "../../../constants/regExp";
import { PLAN_TYPES } from "../../../constants/constant";

interface couponManageModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  refetchAllCoupons: () => void;
  isEdit: boolean;
  setIsEdit: (value: boolean) => void;
  couponId: string;
}

export const CreateCouponModal = (props: couponManageModalProps) => {
  const { open, setOpen, refetchAllCoupons, isEdit, setIsEdit, couponId } =
    props;
  const dispatch = useDispatch();
  const [planList, setPlanList] = useState<any>([]);
  const [selectedPlanList, setSelectedPlanList] = useState<any>([]);
  const [allCheck, setAllCheck] = useState(false);
  const [disable, setDisable] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [tanentId, setTanentId] = useState<string>("");
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm({ mode: "onChange" || "onSubmit" });

  //integration
  const {
    data,
    isSuccess,
    refetch,
    isError,
    fulfilledTimeStamp,
    error,
    isLoading,
  } = useGetAllPlansQuery({
    isDraft: false,
    planType: PLAN_TYPES.inHouse,
    isActive: true,
  });
  const [selectedValue, setSelectedValue] = useState<any>("");
  console.log(selectedValue, "selectedValue")
  const [getTenant, getTenantData] = useGetAllTenantMutation();
  const [createNewCoupon, createNewCouponData] = useCreateNewCouponsMutation();
  const [couponDataById, couponDataByIdData] = useGetCouponByIdMutation();
  const [updateCoupon, updateCouponData] = useUpdateCouponByIdMutation();
  const { userInfo } = useSelector((state: any) => state.auth);
  const [selectPlan, setSelectPlan] = useState<any>([]);

  const DiscountTypeOption = [
    { text: "Product Discount", value: DiscountType.PRODUCT_DISCOUNT },
    { text: "Trial Period", value: DiscountType.TRIAL_PERIOD },
    {
      text: "Subscription Discount",
      value: DiscountType?.SUBSCRIPTION_DISCOUNT,
    },
  ];

  const DiscountTypeValues_Trial = [
    { text: "15 Days Trial", value: 15 },
    { text: "1 Month Trial", value: 30 },
    { text: "2 Month Trial", value: 60 },
    { text: "3 Month Trial", value: 90 },
    { text: "6 Month Trial", value: 180 },
    { text: "12 Month Trial", value: 360 },
    { text: "24 Months Trial", value: 720 },
  ];

  const DiscountTypeValues_Product = [
    { text: "5%", value: 5 },
    { text: "10%", value: 10 },
  ];

  const SubscriptionDiscountType = [
    { text: "1 Month", value: 1 },
    { text: "2 Months", value: 2 },
    { text: "3 Months", value: 3 },
    { text: "6 Months", value: 6 },
    { text: "12 Months", value: 12 },
    { text: "24 Months", value: 24 },
    { text: "Forever", value: "forever" },
  ];

  const handleSelectSubscription=(e:any) =>{
    setSelectedValue(e?.target?.value)
    console.log(e?.target?.value, "valuemonths")
  }

  console.log(SubscriptionDiscountType.map((value:any) => value?.value), "SubscriptionDiscountType")

  const selectCouponCountry = [
    { text: "India", value: "IN" },
    { text: "United States", value: "US" },
  ];

  useEffect(() => {
    open && getTenant({});
  }, []);

  useEffect(() => {
    if (getTenantData) {
      setTanentId(getTenantData?.data?.result[0]?.tenantId);
    }
  }, [getTenantData]);

  useEffect(() => {
    if (isSuccess) {
      let plans: any = [];
      data?.result?.map((x: any) => {
        plans.push({
          text: x?.name,
          value: x?.subscriptionPlanId,
          country: x?.billingCountry,
        });
      });
      setPlanList(plans);
    }
  }, [isSuccess]);

  const [plans, setPlans] = useState<any>([]);

  const onProductBundleChange = (e: any, field: any) => {
    const { name, value } = e?.target;
    var check = value.indexOf("all");
    if (check !== -1 && allCheck === false) {
      let data: any = [];
      selectPlan?.map((x: any) => {
        data.push({
          text: x?.text,
          value: x?.value,
        });
      });
      setPlans(data);
      setAllCheck(true);
    } else if (check !== -1 && allCheck === true) {
      setPlans([]);
      setAllCheck(false);
    } else {
      if (allCheck === true) {
        let data = selectPlan?.filter((x: any) => {
          return x?.value !== value[value?.length - 1]?.value;
        });
        setAllCheck(false);
        setPlans(data);
      } else {
        setPlans(value);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    reset({
      couponName: "",
      couponCode: "",
      discountType: "",
      discountTypeValue: "",
      subscriptionPlans: [],
      startsFrom: "",
      expiresOn: "",
      description: "",
    });
    setIsEdit(false);
    setSelectedPlanList([]);
  };

  const handleCreateEditCoupon = (data: any) => {
    var planIdArray = [];
    for (var i = 0; i < plans?.length; i++) {
      planIdArray.push(plans[i]?.value);
    }
    console.log(data, "dddddddddddddddddddddddddddddddddddddddddddddddd");

    var requestData: any = {
      subscriptionPlans: planIdArray,
      startsFrom: dayjs(data.startsFrom)?.format("YYYY-MM-DD"),
      expiresOn: dayjs(data.expiresOn)?.format("YYYY-MM-DD"),
      status: "Active",
      tenantId: tanentId,
      createdBy: userInfo?.userId,
      campaignName: data.couponName,
      couponCode: data.couponCode,
      description: data?.description,
      country: data?.country,
      type: data?.discountType,
      value: data?.discountTypeValue,
      applicableOn:
        data?.discountType === DiscountType.PRODUCT_DISCOUNT
          ? "Device Cost"
          : "",
      applicableCycle:
        data?.discountType === DiscountType.PRODUCT_DISCOUNT
          ? "1"
          : data?.discountType === DiscountType?.SUBSCRIPTION_DISCOUNT
          ? data?.applicableCycle === "forever"
            ? null
            : selectedValue
          : null,
    };

    var editRequest: any = {
      subscriptionPlans: planIdArray,
      campaignName: data.couponName,
      description: data?.description,
      country: data?.country,
    };
    if (
      dayjs(data.startsFrom)?.format("YYYY-MM-DD") !==
      couponDataByIdData?.data?.result?.startsFrom
    ) {
      editRequest.startsFrom = dayjs(data.startsFrom)?.format("YYYY-MM-DD");
    }
    if (
      dayjs(data.expiresOn)?.format("YYYY-MM-DD") !==
      couponDataByIdData?.data?.result?.expiresOn
    ) {
      editRequest.expiresOn = dayjs(data.expiresOn)?.format("YYYY-MM-DD");
    }
    var payloadData = {};
    payloadData = Object.fromEntries(
      Object.entries(!isEdit ? requestData : editRequest).filter(
        ([_, v]) => v != "" && v != null && v != undefined
      )
    );
    if (!isEdit) {
      createNewCoupon(payloadData);
      console.log(payloadData, "payload");
    } else {
      updateCoupon({
        couponCode: couponDataByIdData?.data?.result?.couponCode,
        data: payloadData,
      });
    }
  };

  useEffect(() => {
    if (isEdit && planList.length > 0) {
      couponDataById(couponId);
    }
  }, [isEdit, planList]);

  useEffect(() => {
    if (createNewCouponData?.isSuccess) {
      handleClose();
      refetchAllCoupons();
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Coupon created successfully.",
        })
      );
    }
  }, [createNewCouponData?.isSuccess]);

  useEffect(() => {
    handleErrors(createNewCouponData, dispatch);
  }, [createNewCouponData?.isError]);

  useEffect(() => {
    if (updateCouponData?.isSuccess) {
      handleClose();
      refetchAllCoupons();
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Coupon updated successfully.",
        })
      );
    }
  }, [updateCouponData?.isSuccess]);

  useEffect(() => {
    handleErrors(updateCouponData, dispatch);
  }, [updateCouponData?.isError]);

  useEffect(() => {
    if (couponDataByIdData?.isSuccess) {
      let selectedPlan = planList?.filter((planListobj: any) =>
        couponDataByIdData?.data?.result?.CampaignPlans?.some(
          (CampaignPlansobj: any) =>
            CampaignPlansobj?.subscriptionPlanId === planListobj?.value
        )
      );
      handeCouponCountry({
        target: { value: couponDataByIdData?.data?.result?.country },
      });
      setValue("couponName", couponDataByIdData?.data?.result?.campaignName);
      setValue("couponCode", couponDataByIdData?.data?.result?.couponCode);
      setValue("discountType", couponDataByIdData?.data?.result?.type);
      setValue("country", couponDataByIdData?.data?.result?.country);
      setValue("discountTypeValue", couponDataByIdData?.data?.result?.value);
      setValue("subscriptionPlans", selectedPlan);
      setValue("applicableCycle", couponDataByIdData?.data?.result?.applicableCycle === null ?  "forever" : couponDataByIdData?.data?.result?.applicableCycle )
      setPlans(selectedPlan);
      if (couponDataByIdData?.data?.result?.startsFrom) {
        var startDate =
          couponDataByIdData?.data?.result?.startsFrom?.split("-");
        setValue(
          "startsFrom",
          dayjs(`${startDate[1]}/${startDate[2]}/${startDate[0]}`) //"08/25/2023"
        );
        let initialCouponDate = dayjs(
          `${startDate[1]}/${startDate[2]}/${startDate[0]}`
        );
        let currentDate = dayjs(Date.now());
        if (initialCouponDate < currentDate) {
          setDisable(true);
        } else {
          setDisable(false);
        }
      } else {
        setValue("startsFrom", "");
      }
      if (couponDataByIdData?.data?.result?.expiresOn) {
        var startDate = couponDataByIdData?.data?.result?.expiresOn?.split("-");
        setValue(
          "expiresOn",
          dayjs(`${startDate[1]}/${startDate[2]}/${startDate[0]}`)
        );
        minEndDate = dayjs(
          dayjs(getValues("startsFrom"))?.format("YYYY-MM-DD")
        );
      } else {
        setValue("expiresOn", "");
      }
      setValue("description", couponDataByIdData?.data?.result?.description);
    }
  }, [couponDataByIdData?.isSuccess]);
  useEffect(() => {
    handleErrors(couponDataByIdData, dispatch);
  }, [couponDataByIdData?.isError]);

  let minStartDate: any;
  minStartDate = dayjs(dayjs(Date.now())?.format("YYYY-MM-DD"));

  let minEndDate = dayjs(dayjs(getValues("startsFrom"))?.format("YYYY-MM-DD"));

  const [selectCountry, setSelectCountry] = useState<any>("");
  console.log(selectCountry, "country");
  console.log(selectPlan, "selectPlan");

  const handeCouponCountry = (e: any) => {
    const selectCountry = e?.target?.value;
    setSelectCountry(selectCountry);
    console.log(selectCountry, "vvvvvv");

    const filteredPlans = planList.filter((plan: any) => {
      console.log(plan, "planplanplan");
      return plan?.country === selectCountry;
    });
    setSelectPlan(filteredPlans);
    console.log(filteredPlans, "filterplannnn");
  };

  return (
    <>
      <Loader
        show={couponDataByIdData?.isLoading || getTenantData?.isLoading}
      />
      <Modal
        handleClose={handleClose}
        primaryText={!isEdit ? "Create Coupon" : "Edit Coupon"}
        open={open}
        className="CreateEditCouponModal input-padding"
      >
        <form onSubmit={handleSubmit(handleCreateEditCoupon)}>
          <Grid container>
            <Grid item xs={12} sx={{ mt: 5 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <SelectInput
                    {...field}
                    value={field.value ? `${field.value}` : ""}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    placeholder="Select billing country"
                    autoFocus={true}
                    required={true}
                    // disabled={isEdit ? true : false}
                    onChange={(e: any) => {
                      field.onChange(e);
                      handeCouponCountry(e);
                    }}
                    inputRef={ref}
                    label="Select Country"
                    optionValue={selectCouponCountry}
                  />
                )}
                name="country"
                control={control}
                rules={{
                  required: "Please select billing country",
                }}
              />
            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Coupon Name"
                    value={field.value}
                    required={true}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={(e: any) => {
                      ref(e);
                      inputRef.current = e;
                    }}
                    placeholder="Enter coupon name"
                    autoFocus={true}
                  />
                )}
                name="couponName"
                control={control}
                rules={{
                  required: "Please enter coupon name ",
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2.5 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Coupon Code"
                    value={field.value}
                    required={true}
                    disabled={isEdit ? true : false}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={(e: any) => {
                      ref(e);
                      inputRef.current = e;
                    }}
                    placeholder="Enter coupon code"
                  />
                )}
                name="couponCode"
                control={control}
                rules={{
                  required: "Please enter coupon code",
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 5 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <SelectInput
                    {...field}
                    value={field.value ? `${field.value}` : ""}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    placeholder="Select type"
                    required={true}
                    disabled={isEdit ? true : false}
                    onChange={(e: any) => {
                      field.onChange(e);
                      trigger("discountType");
                    }}
                    inputRef={ref}
                    label="Discount Type"
                    optionValue={DiscountTypeOption}
                  />
                )}
                name="discountType"
                control={control}
                rules={{
                  required: "Please select discount type",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                mt:
                  getValues("discountType") === DiscountType.TRIAL_PERIOD
                    ? 5
                    : 2.3,
              }}
            >
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) =>
                  getValues("discountType") === DiscountType.TRIAL_PERIOD ? (
                    <SelectInput
                      {...field}
                      value={field.value ? `${field.value}` : ""}
                      error={Boolean(error)}
                      helperText={error?.message ? error?.message : " "}
                      placeholder="Select value"
                      required={true}
                      disabled={isEdit ? true : false}
                      onChange={field.onChange}
                      inputRef={ref}
                      label="Discount Type Value"
                      optionValue={DiscountTypeValues_Trial}
                    />
                  ) : (
                    <Input
                      {...field}
                      value={field.value}
                      error={Boolean(error)}
                      helperText={error?.message ? error?.message : " "}
                      placeholder="Select value"
                      required={true}
                      disabled={isEdit ? true : false}
                      onChange={field.onChange}
                      inputRef={ref}
                      label="Discount Type Value (%)"
                    />
                  )
                }
                name="discountTypeValue"
                control={control}
                rules={{
                  required: "Please enter discount type value",
                  pattern: {
                    value: RegExpressions?.totalDiscountDecimal,
                    message:
                      "Please enter valid discount price upto 100 percent",
                  },
                }}
              />
            </Grid>

            {getValues("discountType") ===
              DiscountType?.SUBSCRIPTION_DISCOUNT && (
              <Grid item xs={12} sx={{ mt: 5 }}>
                <Controller
                  render={({
                    field: { ref, ...field },
                    fieldState: { invalid, error },
                  }) => (
                    <SelectInput
                      {...field}
                      value={field.value ? `${field.value}` : ""}
                      error={Boolean(error)}
                      helperText={error?.message ? error?.message : " "}
                      placeholder="Select type"
                      required={
                        getValues("discountType") ===
                        DiscountType?.SUBSCRIPTION_DISCOUNT
                          ? true
                          : false
                      }
                      disabled={isEdit ? true : false}
                      onChange={(e: any) => {
                        field.onChange(e);
                        trigger("discountType");
                        handleSelectSubscription(e)
                      }}
                      inputRef={ref}
                      label="Discount Period"
                      optionValue={SubscriptionDiscountType}
                    />
                  )}
                  name="applicableCycle"
                  control={control}
                  rules={{
                    required: "Please select discount type",
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12} sx={{ mt: 5 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <CheckboxSelectInput
                    {...field}
                    label="Select Plan"
                    required={true}
                    placeholder={
                      planList?.length
                        ? "Select plan apply for this coupon."
                        : "No plan to select."
                    }
                    value={plans}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={(e: any) => {
                      field.onChange(e);
                      onProductBundleChange(e, field);
                    }}
                    inputRef={ref}
                    disabled={selectCountry?.length > 0 ? false : true}
                    multiple={true}
                  >
                    {selectPlan && selectPlan?.length > 0 ? (
                      <MenuItem key="all" value="all">
                        <div>
                          <ListItemText primary="All" />
                        </div>
                        <Checkbox className="checkboxBox" checked={allCheck} />
                      </MenuItem>
                    ) : null}
                    {selectPlan?.map((option: any) => {
                      return (
                        <MenuItem key={option?.value} value={option}>
                          <ListItemText primary={option?.text} />
                          <Checkbox
                            className="checkboxBox"
                            checked={
                              plans.findIndex(
                                (item: any) => item?.value === option?.value
                              ) >= 0
                            }
                          />
                        </MenuItem>
                      );
                    })}
                  </CheckboxSelectInput>
                )}
                name="subscriptionPlans"
                control={control}
                rules={{
                  required: "Please select Plan",
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2.5 }}>
              <Grid container columnSpacing={4}>
                <Grid item xs={6}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <DatePickers
                        {...field}
                        label="Start Date"
                        value={field.value}
                        required={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={(e: any) => {
                          field.onChange(e);
                          if (
                            getValues("expiresOn")?.format("DD/MM/YYYY") <
                            e?.format("DD/MM/YYYY")
                          ) {
                            setValue("expiresOn", null);
                          }
                          trigger("startsFrom");
                        }}
                        inputRef={ref}
                        disablePast={!isEdit ? true : false}
                        disabled={isEdit ? disable : false}
                        minDate={!disable ? minStartDate : null}
                      />
                    )}
                    name="startsFrom"
                    control={control}
                    rules={{
                      validate: () => {
                        var startDateValue = new Date(getValues("startsFrom")); //dd-mm-YYYY
                        var currentDate = new Date()?.setHours(0, 0, 0, 0); //dd-mm-YYYY
                        var startsFromYear = startDateValue?.getFullYear();
                        if (
                          startDateValue &&
                          startDateValue?.setHours(0, 0, 0, 0) < currentDate &&
                          !disable
                        ) {
                          return "Date cannot be a past date";
                        } else if (
                          startDateValue &&
                          (startDateValue < new Date("1899-12-31") ||
                            isNaN(startsFromYear))
                        ) {
                          return "Please enter a valid date";
                        }
                      },
                      required: "Please enter start date",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <DatePickers
                        {...field}
                        className="endDatePicker"
                        label="End Date"
                        value={field.value}
                        required={true}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        onChange={field.onChange}
                        inputRef={ref}
                        minDate={minEndDate}
                      />
                    )}
                    name="expiresOn"
                    control={control}
                    rules={{
                      validate: () => {
                        var expireDateValue = new Date(getValues("expiresOn"));
                        var currentDate = new Date()?.setHours(0, 0, 0, 0);
                        const expiresOnYear = expireDateValue.getFullYear();
                        if (
                          expireDateValue &&
                          expireDateValue < new Date(getValues("startsFrom"))
                        ) {
                          return "End date should be greater";
                        } else if (
                          isEdit &&
                          currentDate !== expireDateValue?.setHours(0, 0, 0, 0)
                        ) {
                          if (
                            expireDateValue?.setHours(0, 0, 0, 0) <
                              currentDate &&
                            !disable
                          ) {
                            var endDateElement = document.querySelector(
                              ".endDatePicker .MuiInputBase-root"
                            );
                            endDateElement?.classList?.add("Mui-error");
                            return "Date should not be a past date";
                          } else {
                            var endDatePicElement = document?.querySelector(
                              ".endDatePicker .MuiInputBase-root"
                            );
                            endDatePicElement?.classList?.remove("Mui-error");
                          }
                        } else if (
                          expireDateValue &&
                          (expireDateValue < new Date("1899-12-31") ||
                            isNaN(expiresOnYear))
                        ) {
                          return "Please enter a valid date";
                        } else {
                          var endDatePicElement = document?.querySelector(
                            ".endDatePicker .MuiInputBase-root"
                          );
                          endDatePicElement?.classList?.remove("Mui-error");
                        }
                      },
                      required: "Please enter end date",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2.5 }}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Description"
                    value={field.value ? field.value : ""}
                    error={Boolean(error)}
                    helperText={error?.message ? error?.message : " "}
                    onChange={field.onChange}
                    inputRef={ref}
                    placeholder="Description"
                  />
                )}
                name="description"
                control={control}
              />
            </Grid>
            <Grid container columnSpacing={4} className="modalBtn">
              <Grid item>
                <Buttons
                  type="secondary"
                  variant="contained"
                  text="CANCEL"
                  onClick={handleClose}
                  className="createModalCouponBtn"
                ></Buttons>
              </Grid>
              <Grid item>
                <Buttons
                  type="primary"
                  variant="contained"
                  buttonType="submit"
                  text="SAVE"
                  data-testid="submit-btn"
                  className="createModalCouponBtn"
                ></Buttons>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
};
