import {
  alpha,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputBase,
  InputLabel,
  styled,
} from "@mui/material";
import { Inputprops } from "../../../models/common";
import "./style.css";
import { useState } from "react";
import { closeEye, infoIcon, openEye } from "../../../assets/icons";
import { CustomToolTip } from "../tooltip";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    height: 18,
    borderRadius: 4,
    position: "relative",
    // backgroundColor: theme.palette.mode === "light" ? "#fcfcfb" : "#2b2b2b",
    border: "1px solid #8F909A",
    fontSize: 14,
    width: "100%",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: "Roboto",
    color:"#1D2026",
    "&:focus": {
      boxShadow: "0px 0px 5px 2px rgba(63, 150, 221, 0.5)",
      borderColor: "#3F96DD",
      },
      "&::placeholder": {
        color: "#AAACAF"
      },
  },
}));
export const Input = (props: Inputprops) => {
  const {
    autoFocus,
    disabled,
    error,
    onKeyDown,
    labelClassName,
    customInput,
    required,
    label,
    name,
    onBlur,
    onChange,
    placeholder,
    value,
    type,
    helperText,
    helperTextClass,
    inputRef,
    endAdornment,
    useSlice,
  } = props;
  

  return (
    <FormControl variant="standard" sx={{ width: "100%" }}>
      <InputLabel
        shrink
        className={
          labelClassName ? `${labelClassName} labelClass` : "labelClass"
        }
        htmlFor="bootstrap-input"
        required={required}
        error={error}
        disabled={disabled}
      >
        {label}
      </InputLabel>
      <BootstrapInput
        className={customInput && customInput}
        autoFocus={autoFocus}
        id="bootstrap-input"
        disabled={disabled}
        error={error}
        fullWidth={true}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        value={value}
        type={type}
        inputRef={inputRef}
        endAdornment={endAdornment}
      />
      <FormHelperText className={`helperClass slice`}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export const PasswordInput = (props: Inputprops) => {
  const {
    autoFocus,
    disabled,
    error,
    labelClassName,
    customInput,
    required,
    label,
    name,
    onBlur,
    onChange,
    placeholder,
    value,
    helperText,
    inputRef,
    info,
    passwordInfo,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <FormControl variant="standard" sx={{ width: "100%" }}>
      <InputLabel
        shrink
        className={
          labelClassName ? `${labelClassName} labelClass` : "labelClass"
        }
        htmlFor="bootstrap-input"
        required={required}
        error={error}
      >
        {label}
      </InputLabel>
      {info && (
        <span
        className= { passwordInfo ? `${passwordInfo} newPasswordInfo` : "passwordInfo" }
        
         >
          <CustomToolTip
            title={
              "A minimum 8 characters password  contains at least one lowercase letter, one uppercase letter, one number, and one special character."
            }
            placement="top"
          >
            <img src={infoIcon}></img>
          </CustomToolTip>
        </span>
      )}
      <BootstrapInput
        className={
          customInput ? ` ${customInput} passwordInput` : "passwordInput"
        }
        autoFocus={autoFocus}
        id="bootstrap-input"
        disabled={disabled}
        error={error}
        fullWidth={true}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        inputRef={inputRef}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment
            position="end"
            sx={{ marginLeft: "-24px", position: "relative", right: "12px" }}
          >
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <img src={openEye} /> : <img src={closeEye} />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText className="helperClass slice">
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};
