import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Modal } from "../../../common/modal/modal";
import { Grid } from "@mui/material";
import { Input } from "../../../common/input";
import { SelectInput } from "../../../common/selectinput";
import Buttons from "../../../common/button";
import { State } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateUserMutation } from "../../../../redux/services/userApiSlice";
import { getCountryNameByCountryCode, handleErrors } from "../../../../utils";
import Loader from "../../../common/loader/loader";
import { RegExpressions } from "../../../../constants/regExp";
import { UserRoles } from "../../../../constants/enum";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import { cNames } from "../../../../constants/constant";

export const BillingAddressModal = (props: any) => {
  const { userInfo } = useSelector((state: any) => state.auth);
  const { address, setAddress, billingData, refetch } = props;
  const {
    handleSubmit,
    setValue,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" || "onSubmit" });
  console.log(State.getStatesOfCountry(billingData?.country),'cjjjjjjjjjjjjjjjj')
  const outsideStateNames = State.getStatesOfCountry(billingData?.country).map((states: any) => {
    return { text: states?.name, value: `${states?.isoCode}` };
  });
  const inStateNames = State.getStatesOfCountry("IN").map((states: any) => {
    return { text: states?.name, value: `${states?.isoCode}` };
  });
  const dispatch = useDispatch();
  const userId = useSelector((state: any) => state?.auth?.userId);
  const [updateAddress, updateAddressInfo] = useUpdateUserMutation();

  useEffect(() => {
    setValue("address", billingData?.address1);
    setValue("area", billingData?.address2);
    setValue("town", billingData?.city);
    setValue("state", billingData?.state);
    setValue("zip", billingData?.zipCode);
    setValue("country", billingData?.country);
  }, []);
  const handleCancel = () => {
    setAddress(false);
    reset({
      address: "",
      area: "",
      town: "",
      state: "",
      zip: "",
      country: "",
    });
  };
  const handleAddressSubmit = (data: any) => {
    updateAddress({
      userId: userId,
      address: {
        address1: data?.address,
        address2: data?.area,
        city: data?.town,
        country: data?.country,
        zipCode: data?.zip,
        state: data?.state,
      },
    });
  };
  useEffect(() => {
    if (updateAddressInfo?.isSuccess) {
      setAddress(false);
      dispatch(
        setAlert({
          open: true,
            alertType: "success",
            message: updateAddressInfo?.data?.message,
        })
      )
      refetch();
    }
  }, [updateAddressInfo?.isSuccess]);

  useEffect(() => {
    if (updateAddressInfo?.isError) {
      handleErrors(updateAddressInfo, dispatch);
    }
  }, [updateAddressInfo?.isError]);

  // useEffect(() => {
  //   setValue(
  //     "country",
  //     userInfo?.roleName === UserRoles.BENEFICIARY
  //       ? "India"
  //       : getCountryNameByCountryCode(billingData?.country)
  //   );
  // }, []);
  return (
    <>
      <Loader show={updateAddressInfo?.isLoading} />
      <Modal
        className="accountSetingModal"
        key="billingAddress"
        open={address}
        primaryText={
          userInfo?.roleName === UserRoles.SUBSCRIBER
            ? "Change Billing Address"
            : "Change Address"
        }
        handleClose={() => {
          setAddress(false);
          reset({
            address: "",
            area: "",
            town: "",
            state: "",
            zip: "",
            country: "",
          });
        }}
      >
        <form onSubmit={handleSubmit(handleAddressSubmit)}>
          <Grid container sx={{ marginTop: "40px" }} rowSpacing={2}>
            <Grid item xs={11.9} className="billingAddPadding">
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="House No, Street"
                    value={field.value}
                    required={true}
                    placeholder="Enter house no, street"
                    onChange={field.onChange}
                    helperText={error?.message ? error?.message : " "}
                    error={Boolean(error)}
                    inputRef={ref}
                    autoFocus={true}
                  />
                )}
                name="address"
                control={control}
                rules={{
                  required: "Please enter house no.",
                }}
              />
            </Grid>
            <Grid item xs={11.9}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <Input
                    {...field}
                    label="Area, Locality"
                    placeholder="Enter area, locality"
                    value={field.value}
                    required={false}
                    onChange={field.onChange}
                    helperText={error?.message ? error?.message : " "}
                    error={Boolean(error)}
                    inputRef={ref}
                  />
                )}
                name="area"
                control={control}
              />
            </Grid>
            <Grid item xs={11.9}>
              <Grid container spacing={4.3}>
                <Grid item xs={6}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label="Town/City"
                        placeholder="Enter town/city"
                        value={field.value}
                        required={true}
                        onChange={field.onChange}
                        helperText={error?.message ? error?.message : " "}
                        error={Boolean(error)}
                        inputRef={ref}
                      />
                    )}
                    name="town"
                    control={control}
                    rules={{
                      required: "Please enter town/city",
                      pattern: {
                        value: RegExpressions?.noSpaceatBeginningAndEnd,
                        message: "Please enter valid town/city",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} className="selectInputAlign">
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <SelectInput
                        {...field}
                        label="State"
                        value={field.value ? `${field.value}` : ""}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        required={true}
                        onChange={field.onChange}
                        optionValue={
                          userInfo?.roleName === UserRoles.BENEFICIARY
                            ? inStateNames
                            : outsideStateNames
                        }
                        placeholder="Select state"
                        inputRef={ref}
                      />
                    )}
                    name="state"
                    control={control}
                    rules={{
                      required: "Please select the state ",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={11.9}>
              <Grid container spacing={4.3}>
                <Grid item xs={6}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <Input
                        {...field}
                        label={
                          userInfo?.roleName !== UserRoles.BENEFICIARY
                            ? "Zip Code"
                            : "Pincode"
                        }
                        placeholder={
                          userInfo?.roleName !== UserRoles.BENEFICIARY
                            ? "Enter zip"
                            : "Enter pincode"
                        }
                        value={field.value}
                        onChange={field.onChange}
                        helperText={error?.message ? error?.message : " "}
                        error={Boolean(error)}
                        inputRef={ref}
                        required={true}
                      />
                    )}
                    name="zip"
                    control={control}
                    rules={{
                      required:
                        userInfo?.roleName !== UserRoles.BENEFICIARY
                          ? "Please enter zip code"
                          : "Please enter pin code",
                      pattern: {
                        value:
                          userInfo?.roleName !== UserRoles.BENEFICIARY
                            ? RegExpressions?.ZipCode
                            : RegExpressions?.PinCode,
                        message:
                          userInfo?.roleName !== UserRoles.BENEFICIARY
                            ? "Please enter valid zip code"
                            : "Please enter valid pincode",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} sx={{ mt: 2.6 }}>
                  <Controller
                    render={({
                      field: { ref, ...field },
                      fieldState: { invalid, error },
                    }) => (
                      <SelectInput
                        {...field}
                        label="Country"
                        value={field.value ? `${field.value}` : ""}
                        error={Boolean(error)}
                        helperText={error?.message ? error?.message : " "}
                        inputRef={ref}
                        onChange={(e:any) =>{
                          field.onChange(e);
                        }}
                        required={true}
                        optionValue={cNames}
                        placeholder="Select country"
                        disabled
                      />
                    )}
                    name="country"
                    control={control}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="modalBtn">
            <Grid item xs={6} md={5} lg={4.6}>
              <Buttons
                type="secondary"
                variant="contained"
                text="CANCEL"
                onClick={handleCancel}
              ></Buttons>
            </Grid>
            <Grid item xs={6} md={5} lg={4.6}>
              <Buttons
                type="primary"
                variant="contained"
                buttonType="submit"
                text="SAVE"
              ></Buttons>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </>
  );
};
