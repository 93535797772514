import * as React from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import "./style.css";
import { IconButton } from "@mui/material";

interface searchBarProps {
  value: string;
  setValue: (data: string) => void;
  handleSearchClick: any;
  onChange:any;
  disabled:boolean;
  placeholder?:string;
}

const Search = styled("div")({
  borderRadius: "5px",
  height: "40px",
  backgroundColor: "#E2E3E6",
  display: "flex",
  alignItems: "center",
  paddingLeft: "8px",
  "& .MuiInputBase-root": {
    width: "100%",
  },
});

const StyledInputBase = styled(InputBase)({
  "& .MuiInputBase-input": {
    fontFamily: "Roboto",
    fontSize: "96%",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "1px",
    padding: "0px",
    color: "#5C5F67",
    marginRight: "10px",
  },
});
export const SEARCHBAR = (props: searchBarProps) => {
  const { handleSearchClick , value ,onChange, setValue , disabled ,placeholder} = props;

  return (
    <>
    <form onSubmit={handleSearchClick}>
      <Search>
        
        <StyledInputBase
          placeholder={placeholder ? placeholder : "Search"}
          inputProps={{ "aria-label": "search" }}
          onChange={onChange}
          value={value}
          data-testid="search-bar"
          className="searchTextStyle"
        />
        <IconButton
          type="submit"
          aria-label="search"
          onClick={() => {
            handleSearchClick();
          }}
          className="searchIconBtn"
          disabled={disabled}
        >
          <SearchIcon style={{color:!value?"black":"white"}} className="searchIcon" />
        </IconButton>
      </Search>
      </form>
    </>
  );
};
