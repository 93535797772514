import { useEffect, useState } from "react";
import backIcon from "../../../../assets/icons/backIcon.svg";
import downloadIcon from "../../../../assets/icons/download.svg";
import { useNavigate } from "react-router";
import { Table } from "../../../common/table";
import { Grid, IconButton } from "@mui/material";
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import { addressFormat, handlePriceFormatting, phoneNumberAutoFormat } from "../../../../utils";
import { useGetAllOrdersByUserIdMutation } from "../../../../redux/services/orderApiSlice";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { OrderStatus } from "../../../../constants/enum";
import "./style.css";
import { useLocation } from "react-router-dom";
import Loader from "../../../common/loader/loader";
import { apiRoutes, baseURL } from "../../../../config/api";
import { setPaginationFilter } from "../../../../redux/features/filters/filtersSlice";

export const OrderHistory = () => {
  const dispatch = useDispatch();
  const [getOrders, getOrdersData] = useGetAllOrdersByUserIdMutation();
  const { userInfo } = useSelector((state: any) => state.auth);
  const [rowCount, setRowCount] = useState(0);
  const [orderRows, setOrderRows] = useState<any>([]);
  const navigate = useNavigate();
  const [tableHeight, setTableHeight] = useState(0);
  const filtersData = useSelector((state: any) => state.filters);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: filtersData?.pageSize,
    page: filtersData?.page,
  });
  const { state } = useLocation();
  const manageOrderStatus = (status: string) => {
    return (
      <>
        {status === OrderStatus.CONFIRMED ? (
          <label className="statusConfirmed">Confirmed</label>
        ) : status === OrderStatus.PAYMENT_PENDING ? (
          <label className="statusPending">Pending</label>
        ) : status === OrderStatus.INTRANSIT ? (
          <label className="statusInTransit">InTransit</label>
        ) : status === OrderStatus.PARTIALLY_DELIVERED ? (
          <label className="statusPartiallyDelivered">
            Partially Delivered
          </label>
        ) : status === OrderStatus.DELIVERED ? (
          <label className="statusDelivered">Delivered</label>
        ) : (
          <label className="">{status}</label>
        )}
      </>
    );
  };
  const columns: GridColDef[] = [
    {
      field: "orderFormatDate",
      headerName: "Date",
      headerAlign: "left",
      cellClassName: "cell-padding topAlign",
      align: "left",
      minWidth: 180,
      sortable: false,
      flex: 1,
    },
    {
      field: "orderNumber",
      headerName: "Order No.",
      minWidth: 200,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding topAlign",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "left",
      cellClassName: "cell-padding topAlign",
      align: "left",
      minWidth: 200,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return(
          <>
          {/* {params?.row?.name} {" "} */}
          {params?.row?.remarks !== null ? 
          (params?.row?.remarks):
          params?.row?.name ?
            (params?.row?.name) : 
            ( `${params?.row?.orderType} Order`  ) 
             }
          </>
        )
      }
    },
    {
      field: "orderStatus",
      headerName: "Order Status",
      headerAlign: "center",
      align: "center",
      cellClassName: "cell-padding topAlign",
      minWidth: 180,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return manageOrderStatus(params?.row?.orderStatus);
      },
    },
    {
      field: "trackingNumber",
      headerName: "Tracking Number",
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-padding topAlign",
      minWidth: 170,
      sortable: false,
      flex: 1,
    },
    {
      field: "trackingUrl",
      headerName: "Tracking URL",
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-padding topAlign",
      minWidth: 170,
      sortable: false,
      flex: 1,
    },
    {
      field: "deliveryContact",
      headerName: "Delivery Contact/Address",
      headerAlign: "left",
      align: "left",
      cellClassName: "cell-padding topAlign",
      minWidth: 400,
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        if (params?.row?.orderType === "Upgrade") {
          return (
            <>
              <span>-</span>
            </>
          );
        } else {
          return (
            <>
              {params?.row?.orderaddresses ? (
                <div>
                  <p className="boldText textWordBreak">
                    {params?.row?.orderaddresses[0]?.fullName}
                  </p>
                  <p className="p-0">
                    <span className="lightText">Mobile Number: </span>
                    <span className="mobileNoStyle textWordBreak">
                      &nbsp;{params?.row?.orderaddresses[0]?.countryCode}&nbsp;
                      {phoneNumberAutoFormat(
                        params?.row?.orderaddresses[0]?.mobileNumber
                      )}
                      ,&nbsp;
                    </span>
                  </p>
                  <p className="mobileNoStyle textWordBreak">
                    {addressFormat(params?.row?.orderaddresses[0])}
                  </p>
                </div>
              ) : (
                <></>
              )}
            </>
          );
        }
      },
    },
    {
      field: "totalAmount",
      headerName: "Total",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "cell-padding topAlign",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const orderType = params?.row?.orderType;
        const currency = params?.row?.currency;
        const totalAmount = params?.row?.totalAmount;
    
        if (orderType === "Upgrade") {
          return (<span className="hyphen">-</span>) ;
        } else {
          return (handlePriceFormatting(totalAmount, currency) + " " + currency)
        }
      },
      
      // renderCell: (params) => `${params?.row?.currency} ${params?.row?.totalAmount}`,
    },
    {
      field: "action",
      sortable: false,
      align: "center",
      cellClassName: "cell-padding topAlign",
      headerClassName: "downloadInvoiceHeader",
      headerAlign: "center",
      headerName: "Download Invoice",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        if (params?.row?.orderType === "Upgrade") {
          return (
            <p>-</p>
          );
        } else {
          return (
            <a
              href={`${baseURL}${apiRoutes.DOWNLOAD_ORDER_INVOICE}/${params?.row?.orderId}`}
              className="export-link"
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              <IconButton>
                <img src={downloadIcon} alt="Download" />
              </IconButton>
            </a>
          );
        }
      },
    },
  ];
  const rows = [
    {
      id: 1,
      orderId: "1234567890",
      trackingNumber: "9090909090",
      deliveryContact:
        "Mr. Rajesh Gulati  |  Mobile Number: +091 1234567890 #873, Trinethra Complex, Narayana Shastri Road, Krishna Raja Mohalla, Mysore - KA, India - 570004",
      description: "Premium Plan with Device Bundle",
      orderDate: "30 Apr, 2023",
      total: "$ 100",
      orderStatus: "Pending",
    },
    {
      id: 2,
      orderId: "1234567890",
      trackingNumber: "9090909090",
      deliveryContact:
        "Mr. Rajesh Gulati  |  Mobile Number: +091 1234567890 #873, Trinethra Complex, Narayana Shastri Road, Krishna Raja Mohalla, Mysore - KA, India - 570004",
      description: "Premium Plan with Device Bundle",
      orderDate: "30 Apr, 2023",
      total: "$ 100",
      orderStatus: "Pending",
    },
    {
      id: 3,
      orderId: "1234567890",
      trackingNumber: "9090909090",
      deliveryContact:
        "Mr. Rajesh Gulati  |  Mobile Number: +091 1234567890 #873, Trinethra Complex, Narayana Shastri Road, Krishna Raja Mohalla, Mysore - KA, India - 570004",
      description: "Premium Plan with Device Bundle",
      orderDate: "30 Apr, 2023",
      total: "$ 100",
      orderStatus: "Pending",
    },
    {
      id: 4,
      orderId: "1234567890",
      trackingNumber: "9090909090",
      deliveryContact:
        "Mr. Rajesh Gulati  |  Mobile Number: +091 1234567890 #873, Trinethra Complex, Narayana Shastri Road, Krishna Raja Mohalla, Mysore - KA, India - 570004",
      description: "Premium Plan with Device Bundle",
      orderDate: "30 Apr, 2023",
      total: "$ 100",
      orderStatus: "Pending",
    },
    {
      id: 5,
      orderId: "1234567890",
      trackingNumber: "9090909090",
      deliveryContact:
        "Mr. Rajesh Gulati  |  Mobile Number: +091 1234567890 #873, Trinethra Complex, Narayana Shastri Road, Krishna Raja Mohalla, Mysore - KA, India - 570004",
      description: "Premium Plan with Device Bundle",
      orderDate: "30 Apr, 2023",
      total: "$ 100",
      orderStatus: "Pending",
    },
  ];

  useEffect(() => {
    if (state?.view) {
      getOrders({
        limit: paginationModel?.pageSize,
        page: paginationModel?.page + 1,
        userId: state?.userData?.User?.userId,
      });
    } else {
      getOrders({
        limit: paginationModel?.pageSize,
        page: paginationModel?.page + 1,
        userId: userInfo?.userId,
      });
    }
  }, [paginationModel]);

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPaginationModel(newPaginationModel);
    dispatch(setPaginationFilter(newPaginationModel));
  };

  useEffect(() => {
    if (getOrdersData?.isSuccess) {
      const data = getOrdersData?.data?.result.map((row: any, index: any) => ({
        id: index,
        orderFormatDate: dayjs(row?.orderDate)?.format("DD MMM, YYYY"),
        ...row,
      }));
      setRowCount(getOrdersData?.data?.count);
      setOrderRows(data);
    }
  }, [getOrdersData?.isSuccess]);

  const calHeight = () => {
    let targetCompo,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetCompo = window.document.querySelector<HTMLElement>(".orderHistory");
    targetElement = targetCompo?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;
    tableHeight = elementHeight - (targetElement + 25);
    setTableHeight(tableHeight);
  };

  useEffect(() => {
    calHeight();
    const resizeObserver = new ResizeObserver(() => {
      calHeight();
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <>
      <Loader show={getOrdersData?.isLoading} />
      <div className="history-main-title">
        <span className="history-backIcon-pd">
          <img src={backIcon} alt="backIcon" onClick={() => navigate(-1)} />
        </span>
        <span className="capitalise">
          {state?.view ? state?.userData?.User?.fullName : "My Account"} /{" "}
          <span className="sub-name">Order History</span>
        </span>
      </div>
      <Grid marginTop="2.8vh">
        <div className="orderHistory">
          <Table
            rows={orderRows}
            columns={columns}
            rowCount={rowCount}
            isPagination={true}
            onPaginationModelChange={handlePaginationModelChange}
            pagination={paginationModel}
            tableHeight={tableHeight}
          />
        </div>
      </Grid>
    </>
  );
};
